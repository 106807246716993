import { Injectable } from '@angular/core';

import { L10nLocale, L10nTranslationService } from 'angular-l10n';

import { PbdsDaterangeLocale, PbdsDaterangeService } from 'pb-design-system/daterange-popover';

// import angular locales per language to set LOCALE_ID
import locale_en_us from '@angular/common/locales/en';
// import locale_en_gb from '@angular/common/locales/en-GB';
import locale_zh from '@angular/common/locales/zh';

// import D3 locales for number and time formats
import { formatDefaultLocale } from 'd3-format';
import * as d3format_en_us from '../../../../../../../node_modules/d3-format/locale/en-US.json';
// import d3format_en_gb from 'd3-format/locale/en-GB.json';
import * as d3format_zh_cn from '../../../../../../../node_modules/d3-format/locale/zh-CN.json';

import { timeFormatDefaultLocale } from 'd3-time-format';
import * as d3timeformat_en_us from '../../../../../../../node_modules/d3-time-format/locale/en-US.json';
// import d3timeformat_en_gb from 'd3-time-format/locale/en-GB.json';
import * as d3timeformat_zh_cn from '../../../../../../../node_modules/d3-time-format/locale/zh-CN.json';

// language interface
export interface Language {
  language: string;
  country: string;
  currency: string;
  locale: any;
  d3Format: any;
  d3TimeFormat: any;
}

// supported languages
const languages: Language[] = [
  {
    language: 'en',
    country: 'US',
    currency: 'USD',
    locale: locale_en_us,
    d3Format: d3format_en_us,
    d3TimeFormat: d3timeformat_en_us
  },
  // {
  //   language: 'en',
  //   country: 'GB',
  //   currency: 'GBP',
  //   locale: locale_en_gb,
  //   d3Format: d3format_en_gb,
  //   d3TimeFormat: d3timeformat_en_gb
  // },
  {
    language: 'zh',
    country: 'CN',
    currency: 'CNY',
    locale: locale_zh,
    d3Format: d3format_zh_cn,
    d3TimeFormat: d3timeformat_zh_cn
  }
];

@Injectable({
  providedIn: 'root'
})
export class LanguageLocaleService {
  constructor(
    private readonly l10nTranslationService: L10nTranslationService,
    private readonly daterangeService: PbdsDaterangeService
  ) {}

  /**
   * Sets the locale for angularl10n and D3 based on the current browser locale
   *
   * @returns
   * void
   */
  public setLocale(): void {
    localStorage.removeItem('defaultLocale');

    const browserLang: string = navigator.language.toLowerCase();
    const browserLangArr: string[] = browserLang.split('-');
    let language: Language;

    if (browserLangArr.length === 1) {
      language = languages.find((lang: Language) => lang.language === browserLangArr[0]);
    } else {
      language = languages.find(
        (lang: Language) => lang.language === browserLangArr[0] && lang.country.toLowerCase() === browserLangArr[1]
      );
    }

    // default to en-us if language is undefined
    if (!language) {
      language = languages[0];
    }

    if (language && language.country.toLowerCase() !== 'us') {
      // set the angular LOCALE_ID dynamically for ng-bootstrap datepicker
      const locale: PbdsDaterangeLocale = {
        language: `${language.language}`,
        country: `${language.country}`,
        locale: language.locale
      };
      this.daterangeService.setLocale(locale);

      // set D3 locale formats
      formatDefaultLocale(language.d3Format);
      timeFormatDefaultLocale(language.d3TimeFormat);
    }

    // set angular-l10n locale
    this.l10nTranslationService.setLocale({
      language: `${language.language}-${language.country}`,
      currency: language.currency
    });
  }

  /**
   * Get the current locale
   *
   * @returns L10NLocale
   */
  public getLocale(): L10nLocale {
    return this.l10nTranslationService.getLocale();
  }
}
