import { Injectable } from '@angular/core';

export interface NotAllowedMessage {
  pageTitle: string;
  title: string;
  description?: string;
  summary?: string;
  url: string;
  underMaintainanceMessage?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotAllowedService {
  private message: NotAllowedMessage;

  /**
   * Set the message to displpay on the not-allowed page
   * @param message
   */
  public setMessage(message: NotAllowedMessage): void {
    this.message = message;
  }

  /**
   * Get the message to display on the not-allowed page
   *
   * @returns NotAllowedMessage with title, discription and optional path
   */
  public getMessage(): NotAllowedMessage {
    return this.message;
  }
}
