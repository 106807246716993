import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { CookiePolicyModule } from '@app/cookie-policy/cookie-policy.module';
if (environment.production) {
  enableProdMode();
}
if (window.location.href.indexOf('/cookie-policy') > 0) {
  platformBrowserDynamic()
    .bootstrapModule(CookiePolicyModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
}
