<div>
  <div class="page-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 l10nTranslate>CURRENT_INVENTORY.TITLE</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="toolbar margin-bottom">
          <div class="toolbar-left">
            <div ngbDropdown>
              <button
                class="btn btn-secondary btn-sm mr-2 ml-2"
                id="export"
                ngbDropdownToggle
                aria-label="Export"
                l10nTranslate
              >
                <i class="pbi-icon-mini pbi-download"></i>
                CURRENT_INVENTORY.DOWNLOAD
              </button>
              <div ngbDropdownMenu aria-labelledby="export">
                <button class="dropdown-item" (click)="downloadExcel('all')" l10nTranslate>
                  CURRENT_INVENTORY.DOWNLOAD_ALL_DATA
                </button>
                <button class="dropdown-item" (click)="downloadExcel('filtered')" l10nTranslate>
                  CURRENT_INVENTORY.DOWNLOAD_FILTERED_DATA
                </button>
              </div>
            </div>
          </div>

          <div class="toolbar-right">
            <div class="toolbar-divider">&nbsp;</div>

            <div class="input-group">
              <div class="input-group-text">
                <span class="input-group-text" id="basic-addon4" aria-label="Search">
                  <i class="pbi-icon-mini pbi-search" aria-hidden="true"></i>
                </span>
              </div>
              <input
                type="search"
                class="form-control"
                placeholder="{{ searchSKU }}"
                aria-label="Search"
                aria-describedby="basic-addon4"
                [(ngModel)]="skuValue"
                (keypress)="searchBySKU($event)"
                (input)="handleSearchInput($event)"
              />
            </div>
            <div class="toolbar-divider">&nbsp;</div>
            <pbds-column-toggle [columns]="cols" [minimum]="1"> </pbds-column-toggle>
          </div>
        </div>

        <p-treeTable
          #dt
          [value]="inventoryData"
          [columns]="cols"
          [paginator]="true"
          [rows]="rowSize"
          [first]="startIndex"
          [totalRecords]="totalElements"
          [rowsPerPageOptions]="[10, 25, 50, 100]"
          selectionMode="single"
          [loading]="isLoading"
          class="p-datatable-hover"
          [showCurrentPageReport]="false"
          [lazy]="true"
          (onLazyLoad)="loadNodes($event)"
          [sortField]="sortType.name"
          [sortOrder]="sortType.sortOrder"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <ng-container *ngIf="col.sortable">
                  <th
                    [ngStyle]="{ width: col.width, 'text-align': col.align, 'padding-left': col.paddingLeft }"
                    *ngIf="col.toggle.selected"
                    scope="col"
                    [ttSortableColumn]="col.field"
                  >
                    {{ col.header }}
                    <p-treeTableSortIcon [field]="col.field"> </p-treeTableSortIcon>
                  </th>
                </ng-container>
                <ng-container *ngIf="!col.sortable">
                  <th
                    *ngIf="col.toggle.selected"
                    scope="col"
                    [ngStyle]="{ width: col.width, 'text-align': col.align, 'padding-left': col.paddingLeft }"
                  >
                    {{ col.header }}
                    <i
                      class="pbi-icon-mini pbi-info-circle-open"
                      ngbTooltip="{{ col.tooltipMsg }}"
                      placement="top"
                      triggers="hover focus:blur"
                      container="body"
                      *ngIf="col.isTooltip"
                    ></i>
                  </th>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
            <tr class="pl-3">
              <ng-container *ngFor="let col of columns; let i = index">
                <td
                  *ngIf="col.toggle.selected"
                  class="container"
                  [ngStyle]="{ width: col.width, 'text-align': col.align }"
                >
                  <div class="row">
                    <div class="center-block">
                      <p-treeTableToggler
                        class="col pr-1"
                        inputStyleClass="p-treetable-toggler.p-link"
                        [rowNode]="rowNode"
                        *ngIf="i == 0"
                      ></p-treeTableToggler>
                    </div>
                    <div class="col col-xl-18 pl-0">
                      <div class="">
                        <ng-container *ngIf="col.field === 'item'">
                          <a>{{ rowData['productDescription'] }}</a>
                          <span *ngIf="rowData['productDescription']"><br /></span>
                          <span [innerHtml]="rowData[col.field]"></span>
                        </ng-container>

                        <ng-container *ngIf="col.field !== 'item'">
                          {{ rowData[col.field] }}
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!isLoading">
              <td class="text-center" colspan="7">
                <div class="empty-state empty-state-search">
                  <div *ngIf="inventoryData?.length === 0">
                    <div class="empty-state-icon" aria-hidden="true">
                      <img src="assets/images/commonIcon/empty-state-icon.svg" />
                    </div>
                    <div class="empty-state-head" l10nTranslate>CURRENT_INVENTORY.NO_RECORDS_FOUND</div>
                    <div class="empty-state-body" l10nTranslate>CURRENT_INVENTORY.TRY-OTHER-FILTERS</div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="paginatorleft" let-state>
            <span aria-live="assertive" class="px-2">
              <ng-container> Showing {{ state.totalRecords ? state.first + 1 : 0 }}</ng-container>

              <ng-container *ngIf="state.first + 1 + state.rows > state.totalRecords; else count">
                to {{ state.totalRecords }}
              </ng-container>

              <ng-template #count> to {{ state.first + state.rows }}</ng-template>

              <ng-container> of {{ state.totalRecords }} {{ entriesText }}</ng-container>
            </span>
          </ng-template>
        </p-treeTable>
      </div>
    </div>
  </div>
</div>
