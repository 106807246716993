import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { L10nResolver } from 'angular-l10n';

import { BillingCanLoadGuard, SupportCanLoadGuard, SettingsCanLoadGuard } from '@app/_guards';
import { ErrorComponent } from '@app/error/error.component';
import { AppGuard } from '@app/_guards/app-guard/app.guard';
import { LogoutComponent } from './shared/logout/logout.component';
import { UserManagementCanLoadGuard } from './_guards/user-management.can-load.guard';
import { AnalyticsCanLoadGuard } from './_guards/analytics-can-load.guard';
import { PreferenceManagementCanLoadGuard } from './_guards/preference-management.can-load.guard';
import { ForecastCanLoadGuard } from './_guards/forecast-can-load.guard';
import { DocHubCanLoadGuard } from './_guards/doc-hub.can-load.guard';
import { EnterpriseSsoLogoutComponent } from './shared/components/enterprise-sso-logout/enterprise-sso-logout.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';

const routes: Routes = [
  // azure-ad: new route for employee sso
  {
    path: 'cc-internal',
    loadChildren: () => import('./employee-login/employee-login.module').then((m) => m.EmployeeLoginModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'cc-internal',
      featureToggle: ['featurePBUserCustomList'],
      l10nProviders: [
        { name: 'choose-account', asset: 'assets/i18n/employee-login/choose-account/' },
        { name: 'employeeloginheader', asset: 'assets/i18n/employee-login/header/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'home',
    canActivate: [AppGuard],
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Overview',
      l10nProviders: [
        { name: 'home', asset: 'assets/i18n/home/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' },
        { name: 'fulfillment-menu', asset: 'assets/fulfillment/i18n/header/' }
      ]
    }
  },
  {
    path: 'analytics',
    canLoad: [AnalyticsCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'analytics',
      featureToggle: ['ui_Header_Analytics'],
      l10nProviders: [
        { name: 'induct-packages', asset: 'assets/i18n/analytics/induct-packages/' },
        { name: 'cycle-time', asset: 'assets/i18n/analytics/cycle-time/' },
        { name: 'cbds-cycle-time', asset: 'assets/i18n/analytics/cbds-cycle-time/' },
        { name: 'expedited-delivery-cycle-time', asset: 'assets/i18n/analytics/expedited-delivery-cycle-time/' },
        { name: 'returns-cycle-time', asset: 'assets/i18n/analytics/returns-cycle-time/' },
        { name: 'edr', asset: 'assets/i18n/analytics/EDR/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' },
        { name: 'usps-delivery', asset: 'assets/i18n/analytics/usps-delivery/' },
        { name: 'velocity-report', asset: 'assets/i18n/analytics/fulfillment/' },
        { name: 'facilities', asset: 'assets/i18n/more/forecast/' },
        { name: 'fulfillment-report', asset: 'assets/i18n/analytics/fulfillment/' },
        { name: 'returns-package-activity', asset: 'assets/i18n/analytics/returns-package-activity/' },
        { name: 'returns-delivered-packages', asset: 'assets/i18n/analytics/returns-delivered-packages/' },
        { name: 'payment-activity', asset: 'assets/i18n/billing/payment-activity/' }
      ]
    }
  },
  {
    path: 'billing',
    canLoad: [BillingCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'billing',
      l10nProviders: [
        { name: 'invoices-transactions', asset: 'assets/i18n/billing/invoices-transactions/' },
        { name: 'invoices-transactions-details', asset: 'assets/i18n/billing/invoices-transactions-details/' },
        { name: 'self-bills', asset: 'assets/i18n/billing/self-bills/' },
        { name: 'self-bills-details', asset: 'assets/i18n/billing/self-bills-details/' },
        { name: 'payment-activity', asset: 'assets/i18n/billing/payment-activity/' },
        { name: 'faq', asset: 'assets/i18n/billing/faq/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'services', asset: 'assets/i18n/shared/services/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'support',
    canLoad: [SupportCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'support',
      featureToggle: ['featureSupport'],
      l10nProviders: [
        { name: 'case-details', asset: 'assets/i18n/support/case-details/' },
        { name: 'case-list', asset: 'assets/i18n/support/case-list/' },
        { name: 'case-create', asset: 'assets/i18n/support/case-create/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'user-management',
    canLoad: [UserManagementCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'user-management',
      featureToggle: ['featureUserManagement'],
      l10nProviders: [
        { name: 'user-list', asset: 'assets/i18n/user-management/user-list/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'forecasts',
    canLoad: [ForecastCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./more/forecast/forecast.module').then((m) => m.ForecastModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'forecasts',
      l10nProviders: [
        { name: 'forecast', asset: 'assets/i18n/more/forecast/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'services', asset: 'assets/i18n/shared/services/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'doc-hub',
    canLoad: [DocHubCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./more/doc-hub/doc-hub.module').then((m) => m.DocHubModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'doc-hub',
      l10nProviders: [
        { name: 'doc-hub-list', asset: 'assets/i18n/doc-hub/doc-hub-list/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'profile',
    canActivate: [AppGuard],
    canLoad: [PreferenceManagementCanLoadGuard],
    loadChildren: () =>
      import('./preference-management/preference-management.module').then((m) => m.PreferenceManagementModule),
    resolve: { l10n: L10nResolver },
    data: {
      name: 'profile',
      l10nProviders: [
        { name: 'preference-management', asset: 'assets/i18n/preference-management/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' },
        { name: 'error', asset: 'assets/i18n/error/' }
      ]
    }
  },
  {
    path: 'not-allowed',
    canActivate: [AppGuard],
    loadChildren: () => import('./not-allowed/not-allowed.module').then((m) => m.NotAllowedModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Not Allowed',
      l10nProviders: [
        { name: 'not-allowed', asset: 'assets/i18n/not-allowed/not-allowed-page/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    path: 'multi-not-allowed',
    canActivate: [AppGuard],
    loadChildren: () =>
      import('./multi-parent-not-allowed/multi-parent-not-allowed.module').then((m) => m.MultiParentNotAllowedModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Multiple Parent Not Allowed',
      l10nProviders: [
        { name: 'multi-not-allowed', asset: 'assets/i18n/multi-parent-not-allowed/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'modal', asset: 'assets/i18n/shared/modal/' }
      ]
    }
  },
  {
    // this will be used in future when we will implement 401 for logout
    path: 'error/:errorStatus',
    component: ErrorComponent
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Terms and Conditions',
      l10nProviders: [{ name: 'header', asset: 'assets/i18n/shared/header/' }]
    }
  },
  {
    path: 'cookie-policy',
    loadChildren: () => import('./cookie-policy/cookie-policy.module').then((m) => m.CookiePolicyModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Cookie Policy',
      l10nProviders: [{ name: 'header', asset: 'assets/i18n/shared/header/' }]
    }
  },
  {
    path: 'settings',
    canLoad: [SettingsCanLoadGuard],
    canActivate: [AppGuard],
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'settings',
      l10nProviders: [
        { name: 'settings', asset: 'assets/i18n/settings/session-timeout/' },
        { name: 'settings-as', asset: 'assets/i18n/settings/account-security/' },
        { name: 'error', asset: 'assets/i18n/error/' },
        { name: 'header', asset: 'assets/i18n/shared/header/' }
      ]
    }
  },
  {
    canActivate: [AppGuard],
    path: 'fulfillment',
    loadChildren: () => import('@fulfillment-services').then((m) => m.FulfillmentModule),
    resolve: { l10n: L10nResolver },
    data: {
      title: 'Fulfillment',
      l10nProviders: [
        { name: 'header', asset: 'assets/i18n/shared/header/' },
        { name: 'fulfillment-menu', asset: 'assets/fulfillment/i18n/header/' },
        { name: 'inventory', asset: 'assets/fulfillment/i18n/inventory/' },
        { name: 'receiving', asset: 'assets/fulfillment/i18n/receiving/' },
        { name: 'order', asset: 'assets/fulfillment/i18n/order/' }
      ]
    }
  },
  {
    path: 'logout',
    component: LogoutComponent
  },

  {
    path: 'enterprise-sso-logout',
    component: EnterpriseSsoLogoutComponent,
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'sso-logout', asset: 'assets/i18n/shared/enterprise-sso/' }]
    }
  },

  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'not-allowed', asset: 'assets/i18n/not-allowed/not-allowed-page/' }]
    }
  },

  {
    path: 'enterprise-sso-session-expired',
    loadChildren: () => import('./shared/session-expired/session-expired.module').then((m) => m.SessionExpiredModule)
  },
  {
    path: 'session-expired',
    loadChildren: () => import('./shared/session-expired/session-expired.module').then((m) => m.SessionExpiredModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100],
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
