export class BackOrderInventoryDetail {
  productID?: number;
  productSKU?: string;
  productDescription?: string;
  backorderedQTY?: number;
  backorderedShipments?: number;
  availableInventory?: number;
  oldestOrder?: Date;
  heldForOrders?: number;
  heldForKitting?: number;
}

export class BackOrderInventory {
  totalRecords?: number;
  inventoryBackOrderDetails: Array<BackOrderInventoryDetail> = [];
}

export class BackOrderFilter {
  page?: number;
  size?: number;
  sku?: string;
}

export class BackOrderRequest {
  ngsMerchantId?: string;
  searchFields?: [];
}

export interface Search {
  name: string;
  values: [];
}

export class DownloadReportRequest {
  ngsMerchantId?: string;
  searchFields?: [];
  reportType?: string;
  from?: Date;
  to?: Date;
}
