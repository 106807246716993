<ngx-loading-bar color="#fff" ref="http"></ngx-loading-bar>
<ngx-loading-bar color="#fff" ref="router"></ngx-loading-bar>

<router-outlet name="header"></router-outlet>

<div class="site-content">
  <div class="" [@routerAnimation]="prepareRoute(routerContentOutlet)">
    <router-outlet #routerContentOutlet="outlet"></router-outlet>
  </div>
</div>
