import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/services/auth-service/auth.service';
import { environment } from '@env/environment';
import { globalFlags } from '@app/shared/models/launch-darkley-global-flags';
import { LaunchDarklyService } from '@gec-shared-services';

@Component({
  selector: 'fulfillment-menu',
  templateUrl: './fulfillment-menu.component.html',
  styleUrls: ['./fulfillment-menu.component.css']
})
export class FulfillmentMenuComponent implements OnInit {
  private legacyPortalExternalUrl = environment.fulfillmentLegacyPortalSsoExternalUrl;
  private legacyPortaInternallUrl = environment.fulfillmentLegacyPortalSsoInternalUrl;
  public launchDarkleyGlobalFlags = globalFlags;
  constructor(private readonly authService: AuthService, private readonly launchDarklyService: LaunchDarklyService) {}

  ngOnInit(): void {
    this.launchDarkleyGlobalFlags = this.launchDarklyService.getGlobalFlags();
  }

  redirectToLegacyPortal() {
    if (this.authService.isPbAccount()) {
      window.open(this.legacyPortaInternallUrl, '_blank');
    } else {
      window.open(this.legacyPortalExternalUrl, '_blank');
    }
  }
}
