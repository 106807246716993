<div class="container-fluid">
  <div class="row justify-content-md-center">
    <!-- httperror 404 template -->
    <ng-container [ngSwitch]="httpError">
      <ng-template [ngSwitchCase]="'404'">
        <div class="mt-7 px-4" role="main">
          <div class="text-center">
            <img src="assets\images\commonIcon\error404MsgIcon.svg" />
          </div>
          <h1 l10nTranslate>ERROR.FOURZEROFOUR.PAGENOTAVAILABLE</h1>
          <h3 l10nTranslate>ERROR.FOURZEROFOUR.PAGEDOSENOTEXIST</h3>
          <p l10nTranslate>ERROR.FOURZEROFOUR.CHECKTYPOSANDTRY</p>
          <p class="text-muted mt-4" l10nTranslate>ERROR.FOURZEROFOUR.ERROR404</p>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'500'">
        <div class="mt-7 px-4" role="main">
          <div class="text-center">
            <img src="assets\images\commonIcon\genericError.svg" />
          </div>
          <h1 l10nTranslate>ERROR.FIVEHUNDRED.INTERNALSERVERERROR</h1>
          <h3 l10nTranslate>ERROR.FIVEHUNDRED.WORKINGONIT</h3>
          <p l10nTranslate>ERROR.FIVEHUNDRED.TRYRELOADINGPAGE</p>
          <p class="text-muted mt-4" l10nTranslate>ERROR.FIVEHUNDRED.ERROR500</p>
        </div>
      </ng-template>

      <ng-template ngSwitchDefault>
        <div class="mt-7 px-4" role="main">
          <div class="text-center">
            <img src="assets\images\commonIcon\genericError.svg" />
          </div>
          <div>
            <h1 l10nTranslate>ERROR.DEFAULTERROR.ISSUEWITHOURSERVER</h1>
            <h3 l10nTranslate>ERROR.DEFAULTERROR.GOODCHANCEPROBLEMINOUREND</h3>
            <p l10nTranslate>ERROR.DEFAULTERROR.SOMETHINGTOTRY</p>
            <ul>
              <li l10nTranslate>ERROR.DEFAULTERROR.WAITFEWMINUTESANDTRYRELOADING</li>
              <li l10nTranslate>ERROR.DEFAULTERROR.CLEARBROWSERCACHE</li>
              <li l10nTranslate>ERROR.DEFAULTERROR.TRYLOADINGWITHDIFFERENTBROWSER</li>
              <li l10nTranslate>ERROR.DEFAULTERROR.CHECKWITHNETWORKADMINISTRATOR</li>
              <p class="text-muted mt-4"><span l10nTranslate>ERROR.DEFAULTERROR.ERROR</span> {{ httpError }}</p>
              <p class="text-muted mt-4" *ngIf="httpError === undefined" l10nTranslate>ERROR.FIVEHUNDRED.ERROR500</p>
            </ul>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
