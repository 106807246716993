import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  L10nConfig,
  L10nLoader,
  L10nStorage,
  L10nLocale,
  L10nTranslationLoader,
  L10nProvider,
  L10nTranslationService,
  L10nDateTimeFormatOptions
} from 'angular-l10n';

export const DATETIMEFORMAT: L10nDateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

export const l10nConfig: L10nConfig = {
  format: 'language-region',
  cache: true,
  keySeparator: '.',
  defaultLocale: { language: 'en-US', currency: 'USD' },
  schema: [
    {
      text: 'English (United States)',
      locale: {
        language: 'en-US',
        currency: 'USD'
      },
      dir: 'ltr'
    },
    // {
    //   text: 'English (United Kingdom)',
    //   locale: {
    //     language: 'en-GB',
    //     currency: 'GBP'
    //   },
    //   dir: 'ltr'
    // },
    {
      text: 'Chinese (Simplified)',
      locale: {
        language: 'zh-CN',
        currency: 'CNY'
      },
      dir: 'ltr'
    },
    {
      text: 'Chinese (Simplified)',
      locale: {
        language: 'zh-CN',
        currency: 'CNY'
      },
      dir: 'ltr'
    }
  ],
  providers: []
};

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}

export function l10nPreload(
  translation: L10nTranslationService,
  translationLoader: L10nTranslationLoader
): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      const locale = translation.getLocale();

      translationLoader.get(locale.language, { name: 'tour', asset: './assets/i18n/tour/' }).subscribe({
        next: (data) => translation.addData(data, locale.language),
        complete: () => resolve()
      });
    });
}

@Injectable()
export class AppStorage implements L10nStorage {
  private readonly hasStorage: boolean;

  constructor() {
    this.hasStorage = typeof Storage !== 'undefined';
  }

  public async read(): Promise<L10nLocale | null> {
    if (this.hasStorage) {
      return Promise.resolve(JSON.parse(localStorage.getItem('locale')));
    }
    return Promise.resolve(null);
  }

  public async write(locale: L10nLocale): Promise<void> {
    if (this.hasStorage) {
      localStorage.setItem('locale', JSON.stringify(locale));
    }
  }
}

@Injectable()
export class HttpTranslationLoader implements L10nTranslationLoader {
  private readonly headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(@Optional() private readonly http: HttpClient) {}

  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
    const url = `${provider.asset}${language}.json`;
    const options = {
      headers: this.headers
    };
    return this.http.get(url, options);
  }
}
