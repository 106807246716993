import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FulfillmentRoutingModule } from './fulfillment-routing.module';
import { CurrentInventoryComponent } from './current-inventory/current-inventory.component';
import { InventoryActivityComponent } from './inventory-activity/inventory-activity.component';
import { SharedModule } from './shared/shared.module';
import { BackorderedComponent } from './backordered/backordered.component';
import { RouteGuardModalComponent } from './modals/route-guard-modal/route-guard-modal.component';
import { InventoryVelocityComponent } from './inventory-velocity/inventory-velocity.component';

@NgModule({
  imports: [SharedModule, FulfillmentRoutingModule],
  declarations: [
    CurrentInventoryComponent,
    InventoryActivityComponent,
    InventoryVelocityComponent,
    BackorderedComponent,
    RouteGuardModalComponent
  ],
  exports: [],
  providers: [DatePipe]
})
export class FulfillmentModule {}
