export const environment = {
  production: false,
  isDebugMode: true,
  logLevel: 2,
  ipsosgid : '1477',
  homeAppContext: '/home',
  CBDSAppContext: '/CBDS',
  oktaTargetResource:
    'https://pitneybowes-stable.oktapreview.com/oauth2/ausmkz31c9iIcS4Ha0h7/v1/authorize?client_id=0oamkv5nz2cmiVCFK0h7&scope=openid%20offline_access%20profile%20email&response_type=code&redirect_uri=https://client.pitneycloud.com&nonce=NONCE45931547586106161&state=2293&response_mode=fragment',
  loginCCBSURL:
    // eslint-disable-next-line max-len
    'https://login2-pre.saase2e.pitneycloud.com/login?productId=ClientConnect&TargetResource=https:%2F%2Fpitneybowes-stable.oktapreview.com%2Foauth2%2Fausmkz31c9iIcS4Ha0h7%2Fv1%2Fauthorize%3Fclient_id%3D0oamkv5nz2cmiVCFK0h7%26scope%3Dopenid%20offline_access%20profile%20email%26response_type%3Dcode%26redirect_uri%3Dhttps:%2F%2Fclient.pitneycloud.com%26nonce%3DNONCE45931547586106161%26state%3D2293%26response_mode%3Dfragment%26&theme=sunset',
  logoutURL: 'https://login2-pre.saase2e.pitneycloud.com/logout?redirect=https://client.pitneycloud.com&theme=sunset',
  employeeSSOURL:
    'https://login.microsoftonline.com/8a4925a9-fd8e-4866-b31c-f719fb05dce6/oauth2/v2.0/authorize?client_id=46ee8b4e-7054-4dfd-a32f-7e94055477e3&response_type=code&redirect_uri=https://client.pitneycloud.com/azure/sso/callback&response_mode=fragment&scope=openid profile offline_access email https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=8e77dfc3-8834-4faa-b244-706348230757',
  employeeLogoutURL: 'https://login.microsoftonline.com/8a4925a9-fd8e-4866-b31c-f719fb05dce6/oauth2/v2.0/logout',
  employeeCsmAccountName: 'PB Internal',
  redirectURL: 'https://client.pitneycloud.com',
  sessionTime: '1800000', // 30 * 60 * 1000
  idleSessionTime: '3600', // 60 * 60 sec
  fileSize: 39321600, // 37.5MB
  pbInternalGid: '304',
  subscription: 'ClientConnect',
  weatherAPIURL: 'https://api-qa.precisely.services/dynamicweather/weatherevent/getData',
  trackingAPIURL: 'https://clientconnect.shipment.co/track',
  serviceDocsURL: 'https://docs.pitneybowes.com/',
  gaEventFilter: true,
  adobeAnalyticsScriptUrl: 'https://assets.adobedtm.com/485c68b96f14/f1506da98e89/launch-2d3f5adfa47e-staging.min.js',
  launchDarklyClientId: '61420545bdf98424fb238e72',
  fulfillmentLegacyPortalSsoExternalUrl:
    'https://login2-pre.saase2e.pitneycloud.com/login?productId=login_gec_fulfillment&TargetResource=https:%2F%2Fpitneybowes-stable.oktapreview.com%2Foauth2%2Fausmkz31c9iIcS4Ha0h7%2Fv1%2Fauthorize%3Fclient_id%3D0oa1emutzngt3oh2Z0h8%26scope%3Dopenid%20offline_access%20profile%20email%26response_type%3Dcode%26redirect_uri%3Dhttps:%2F%2Fstaging.newgisticsfulfillment.com%2Fsingle_signon.asp%26nonce%3DNONCE45931547586106161%26state%3D2293%26response_mode%3Dquery%26&theme=sunset',
  fulfillmentLegacyPortalSsoInternalUrl:
    'https://login.microsoftonline.com/8a4925a9-fd8e-4866-b31c-f719fb05dce6/oauth2/v2.0/authorize?client_id=ae93a2a2-e2d8-4b8b-a478-0f55c9980166&response_type=code&response_mode=query&state=12345&scope=ae93a2a2-e2d8-4b8b-a478-0f55c9980166%2f.default&&redirect_uri=https://staging.newgisticsfulfillment.com/single_signon.asp'
};
