import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-discard-current-changes-modal',
  templateUrl: './discard-current-changes-modal.component.html'
})
export class DiscardCurrentChangesModalComponent {
  constructor(public modal: NgbActiveModal, public loading: LoadingBarService) {}
}
