<ngx-loading-bar color="#fff" ref="http"></ngx-loading-bar>
<ngx-loading-bar color="#fff" ref="router"></ngx-loading-bar>
<header pbdsheadershadow="" class="bg-brand-header fixed-top has-alert">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button
            id="navbar-toggler"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler p-0"
          >
            <span class="navbar-toggler-icon"></span></button
          ><span id="product-name" class="navbar-brand" style="margin-top: 12px"> Client Connect </span>
        </nav>
      </div>
    </div>
  </div>
</header>
<div class="site-content">
  <ngx-loading-bar color="#fff"></ngx-loading-bar>
  <div class="page-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1>Cookie Policy</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-7 pb-7" style="font-family: PrecisionSans_W_Rg">
    <div class="row">
      <div class="col">
        <!-- OneTrust Cookies List start -->
        <div id="ot-sdk-cookie-policy"></div>
        <!-- OneTrust Cookies List end -->
        <!-- OneTrust Cookies Settings button start -->

        <!-- OneTrust Cookies Settings button end -->
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align: center">
        <button
          id="ot-sdk-btn"
          class="ot-sdk-show-settings btn btn-secondary"
          style="
            border-color: var(--btn_primary);
            background-color: var(--btn_secondary_bg);
            color: var(--btn_secondary_label);
            font-size: 0.9em;
          "
        >
          Cookie Settings
        </button>
      </div>
    </div>
  </div>
</div>
