import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LaunchDarklyService } from '@gec-shared-services';

@Injectable({
  providedIn: 'root'
})
export class SettingsCanLoadGuard implements CanLoad {
  constructor(private readonly launchDarklyService: LaunchDarklyService, private readonly router: Router) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    // check if settings feature toggle is set 'true'
    if (!this.launchDarklyService.getFlags().featureSettings) {
      this.router.navigate(['home']);
      return false;
    }
    return true;
  }
}
