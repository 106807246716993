import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, Observable, of } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';

export interface GlobalAlert {
  alertColor: string;
  alertFontColor: string;
  alertFontSize: string;
  alertMessage: SafeHtml;
  alertClosed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {
  private alerts: GlobalAlert[];
  private alertsResponse: GlobalAlert[];

  constructor(private readonly http: HttpClient) {}

  /**
   * Get file data from S3
   *
   * @param s3FileNameKey String of the filename key to retrieve from S3
   *
   * @returns Observable of any
   */
  public s3ContentData(s3FileNameKey: string): Observable<any> {
    return this.http.get(`api/dynamiccontent/s3/${s3FileNameKey}`);
  }

  /**
   * Get global alerts from Wordpress API
   *
   * @returns Observable of GlobalAlert[]
   */
  public getGlobalAlerts(): Observable<GlobalAlert[]> {
    return this.http.get<GlobalAlert[]>('api/dynamiccontent/wordpress/v1/banners').pipe(
      map((alerts: GlobalAlert[]) => {
        const alertsCurrent = JSON.stringify(this.alertsResponse || []);
        const alertsLatest = JSON.stringify(alerts);

        // if cached alerts are the same as the latest response, return from cache
        if (alertsCurrent === alertsLatest) {
          alerts = this.alerts;
          return alerts;
        } else {
          this.alertsResponse = JSON.parse(alertsLatest);

          // set closed to false on all alerts
          alerts.map((alert) => {
            alert.alertClosed = false;
            return alert;
          });

          this.alerts = alerts;

          return alerts;
        }
      }),
      catchError(() => {
        return of([]);
      })
    );
  }
}
