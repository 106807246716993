import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { LaunchDarklyService } from '@gec-shared-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreferenceManagementCanLoadGuard implements CanLoad {
  private readonly featureFlags = this.launchDarklyService.getFlags();
  constructor(private readonly launchDarklyService: LaunchDarklyService) {}
  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.featureFlags.featurePreferenceManagement) {
      return true;
    }
    return false;
  }
}
