import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';
import { ApplicationContext } from '@app/shared/services/application-context/application-context';
import { AuthService } from '@app/shared/services/auth-service/auth.service';
import { StorefrontService } from '@gec-shared-services';

@Injectable({
  providedIn: 'root'
})
export class BillingCanLoadGuard implements CanLoad {
  constructor(
    private readonly router: Router,
    private readonly notAllowedService: NotAllowedService,
    private readonly applicationContext: ApplicationContext,
    private readonly storefrontService: StorefrontService,
    private readonly authService: AuthService
  ) {}

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // monorepo-mw: call middleware internal api to determine if session is active or not
    return this.authService.isAuthenticated().then(
      (isAuthenticated) => {
        if (
          isAuthenticated &&
          this.authService.isInternalAccount() &&
          this.applicationContext.productOrgInfo === null
        ) {
          this.router.navigate(['cc-internal', 'choose-account']);
          return false;
        }

        let isSelfBillingPage = false;
        segments.forEach((segment) => {
          if (segment.path.indexOf('self-bills') !== -1) {
            isSelfBillingPage = true;
          }
        });
        // check billing view permssions for at least one storefront
        const isValid = this.storefrontService.checkAndSelectFirstSFwithBillingViewPermission(isSelfBillingPage);
        const storefront = this.storefrontService.getSelectedStorefront();

        // handle deep linking, storefront will be undefined
        if (!isValid && !storefront) {
          // redirect if storefront does not have self-bills
          this.notAllowedService.setMessage({
            pageTitle: 'NOT-ALLOWED.FINANCIALS.PAGE-TITLE',
            title: 'NOT-ALLOWED.FINANCIALS.TITLE',
            summary: 'NOT-ALLOWED.FINANCIALS.SUMMARY',
            url: ''
          });

          this.router.navigate(['not-allowed']);
        }
        return true;
      },
      () => {
        return false;
      }
    );
  }
}
