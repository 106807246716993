import { ApplicationContext } from './shared/services/application-context/application-context';
import { AuthService, AuthenticatedSession } from './shared/services/auth-service/auth.service';

export function initApp(auth: AuthService, applicationContext: ApplicationContext) {
  return () => {
    const code: string | null = auth.parseHashCode();
    const promise = Promise;
    const jwtResponse = auth.getJwtResponse();

    const pathname = window.location.pathname;
    const isInternalPath = pathname.startsWith('/cc-internal');
    const isExpiredPath = pathname.startsWith('/session-expired');
    const isEnterpriseSsoPath = pathname.startsWith('/enterprise/sso');

    if (code) {
      return auth.accountLogin(code).then(
        (isPbAccount: boolean) => {
          // if pb account  and internal account, redirect to choose account
          if (isPbAccount) {
            auth.redirectChooseAccount();
            return promise.resolve();
          }
          // if not internal employee, continue client login
          return auth.clientLogin();
        },
        () => {
          auth.clearLocalStorage();
          auth.login();
        }
      );
    }

    if (!jwtResponse && !isInternalPath && !isEnterpriseSsoPath) {
      auth.login();
      //return promise.resolve();
    } else if (jwtResponse) {
      const idToken = auth.getIdToken(jwtResponse);
      auth.setCurrentUser(idToken);
    }

    return auth.isAuthenticated().then(
      (isAuthenticated: AuthenticatedSession) => {
        if (isAuthenticated.active) {
          const isPbAccount = auth.isPbAccount();
          const isInternalAccount = auth.isInternalAccount();
          if (isPbAccount && isInternalAccount) {
            const account = applicationContext.getSelectedAccount();
            if (
              account &&
              account.globalClientName.toLowerCase() ==
                applicationContext.customStoreName.globalClientName.toLowerCase()
            ) {
              return auth.refershAppWithViewAllClients().then(
                (clientLists: any) => {
                  const clientIds = [];
                  // if pb account  and internal account, redirect to choose account
                  if (clientLists.length) {
                    clientLists.forEach((globalClient) => {
                      clientIds.push(globalClient.globalClientId);
                    });
                    return auth.employeeLoginWithViewAllClients(clientIds);
                  }
                },
                () => {
                  return auth.logout();
                }
              );
            }
            if (
              account &&
              account.globalClientName.toLowerCase() !==
                applicationContext.customStoreName.globalClientName.toLowerCase()
            ) {
              return auth.employeeLogin(account.globalClientId);
            } else {
              return auth.logout();
            }
          } else if (pathname && isEnterpriseSsoPath) {
            // inbound-fed allow enterprise login
            return auth.enterpriseLogin();
          }

          return auth.clientLogin();
        }
      },
      () => {
        if (pathname && isEnterpriseSsoPath) {
          // inbound-fed allow enterprise login
          auth.enterpriseLogin();
          return promise.reject();
        } else if (pathname && (isInternalPath || isExpiredPath)) {
          // azure-ad: allow to choose login
          auth.internalLogin();
          return promise.reject();
        } else if (pathname && pathname !== '/') {
          // check for and save a deep link path
          localStorage.setItem('segments', window.location.pathname);
        }
        /* else {
          return promise.reject();
        }*/

        auth.clearLocalStorage();
        auth.login();
        return promise.reject();
      }
    );
  };
}
