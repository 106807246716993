import { Component, Inject } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent {

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {
  }

  // ngOnInit(): void { }

}
