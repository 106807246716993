import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { L10nTranslationModule, L10nIntlModule } from 'angular-l10n';

import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbCollapseModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';

import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Angulartics2Module } from 'angulartics2';
import { PbdsColumnToggleModule } from 'pb-design-system/column-toggle';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { ToastrModule } from 'ngx-toastr';
import { DropdownModule } from 'primeng/dropdown';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PbdsPageTitleModule } from 'pb-design-system/page-title';
import { PbdsDaterangePopoverModule } from 'pb-design-system/daterange-popover';
import { FileUploadModule } from 'primeng/fileupload';
import { PickListModule } from 'primeng/picklist';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbCollapseModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbModalModule,
    MatCheckboxModule,
    MatTabsModule,
    MatRadioModule,
    MatRippleModule,
    A11yModule,
    Angulartics2Module,
    FeatureToggleModule,
    L10nTranslationModule,
    L10nIntlModule,
    NgbModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    FeatureToggleModule,
    L10nTranslationModule,
    PbdsColumnToggleModule,
    TreeTableModule,
    ToastrModule,
    TableModule,
    PbdsPageTitleModule,
    PbdsDaterangePopoverModule,
    DropdownModule,
    PickListModule,
    FileUploadModule,
    InputNumberModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbCollapseModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbNavModule,
    NgbModalModule,
    MatCheckboxModule,
    MatTabsModule,
    MatRadioModule,
    MatRippleModule,
    A11yModule,
    Angulartics2Module,
    FeatureToggleModule,
    L10nTranslationModule,
    L10nIntlModule,
    NgbModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    FeatureToggleModule,
    L10nTranslationModule,
    PbdsColumnToggleModule,
    TreeTableModule,
    ToastrModule,
    TableModule,
    PbdsPageTitleModule,
    PbdsDaterangePopoverModule,
    DropdownModule,
    PickListModule,
    FileUploadModule,
    InputNumberModule
  ]
})
export class GecCoreModule {}
