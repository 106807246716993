import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { ApplicationContext } from '@app/shared/services/application-context/application-context';
import { AuthService } from '@app/shared/services/auth-service/auth.service';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';
import { StorefrontService } from '@gec-shared-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportCanLoadGuard implements CanLoad {
  constructor(
    private readonly router: Router,
    private readonly notAllowedService: NotAllowedService,
    private readonly applicationContext: ApplicationContext,
    private readonly authService: AuthService,
    private readonly storefrontService: StorefrontService
  ) {}

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    // monorepo-mw: call middleware internal api to determine if session is active or not
    return this.authService.isAuthenticated().then(
      (isAuthenticated) => {
        if (
          isAuthenticated &&
          this.authService.isInternalAccount() &&
          this.applicationContext.productOrgInfo === null
        ) {
          this.router.navigate(['cc-internal', 'choose-account']);
          return false;
        }
        // find out if details page is loading
        let isDetailsPage = false;
        segments.forEach((segment) => {
          if (segment.path.indexOf('detail') !== -1) {
            isDetailsPage = true;
          }
        });

        let isValid = false;
        if (isDetailsPage) {
          // if details page, find first SF for the provided clientId
          isValid = this.storefrontService.checkAndSelectFirstSFwithSupportViewPermissionForClientId(segments[2].path);
          if (!isValid) {
            isValid = this.storefrontService.checkAndSelectFirstSFwithSupportViewPermission();
            if (isValid) {
              this.router.navigate(['home']);
            }
          }
        } else {
          isValid = this.storefrontService.checkAndSelectFirstSFwithSupportViewPermission();
        }

        // handle deep linking, storefront will be undefined
        if (!isValid) {
          // redirect if storefront does not have support
          this.notAllowedService.setMessage({
            pageTitle: 'NOT-ALLOWED.SUPPORT.PAGE-TITLE',
            title: 'NOT-ALLOWED.SUPPORT.TITLE',
            summary: 'NOT-ALLOWED.SUPPORT.SUMMARY',
            url: ''
          });

          this.router.navigate(['not-allowed']);
        }
        return true;
      },
      () => {
        return false;
      }
    );
  }
}
