import { CurrencyPipe } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';

import { GecCoreModule } from '@gec-core';
import { GecSharedServicesModule } from '@gec-shared-services';

import { PbdsHeaderShadowModule } from 'pb-design-system/header-shadow';
import { PbdsDatavizModule } from 'pb-design-system/dataviz';
import { PbdsColumnToggleModule } from 'pb-design-system/column-toggle';
import { PbdsDaterangePopoverModule } from 'pb-design-system/daterange-popover';

import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';

import { ErrorComponent } from '@app/error/error.component';
import { HeaderComponent } from '@app/shared';
import { ModalComponent } from '@app/shared/modal/modal.component';
import { FormControlValueDirective } from './directives/form-control-value.directive';
import { WidgetErrorComponent } from '@app/error/widget-error/widget-error.component';
import { FileSizePipe, FormatBytesPipe, FormatFileSizePipe } from './pipes/fileSize.pipe';
import { GlobalErrorHandler } from '@app/shared/global-error-handler';
import { LogoutComponent } from './logout/logout.component';
import { RemoveStringFromNumberPipe } from './pipes/string-to-number.pipe';
import { CarrierMessagePipe } from './pipes/carrierMessage.pipe';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ContactUsModalComponent } from './modal/contact-us-modal/contact-us-modal.component';
import { TextareaAutoresizeDirective } from './directives/textarea-autoresize.directive';
import { AddAttachmentModalComponent } from './modal/add-attachment-modal/add-attachment-modal.component';
import { AddAttachmentComponent } from './components/add-attachment/add-attachment.component';
import { AboutClientConnectComponent } from './modal/about-client-connect/about-client-connect.component';
import { DynamicGlobalAlertComponent } from './dynamic-global-alert/dynamic-global-alert.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { InvoiceDetailsModalComponent } from './modal/invoice-details-modal/invoice-details-modal.component';
import { ForecastDatePipe } from './pipes/forecast-date.pipe';
import { ForecastServicePipe } from './pipes/forecast-service.pipe';
import { EnterpriseSsoLogoutComponent } from './components/enterprise-sso-logout/enterprise-sso-logout.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { FulfillmentMenuModule } from '@fulfillment-services';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FcUploadTemplateModalComponent } from './modal/fc-upload-template-modal/fc-upload-template-modal.component';

@NgModule({
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }, CurrencyPipe],
  imports: [
    GecCoreModule,
    GecSharedServicesModule,
    PbdsDatavizModule,
    PbdsHeaderShadowModule,
    PbdsColumnToggleModule,
    PbdsDaterangePopoverModule,
    TableModule,
    FileUploadModule,
    GecSharedServicesModule,
    FulfillmentMenuModule,
    NgbDropdownModule
  ],
  declarations: [
    HeaderComponent,
    ModalComponent,
    ContactUsModalComponent,
    RemoveStringFromNumberPipe,
    FileSizePipe,
    FormatBytesPipe,
    FormatFileSizePipe,
    CarrierMessagePipe,
    ErrorComponent,
    FormControlValueDirective,
    WidgetErrorComponent,
    LogoutComponent,
    TermsConditionsComponent,
    TextareaAutoresizeDirective,
    ClickStopPropagationDirective,
    AddAttachmentModalComponent,
    AddAttachmentComponent,
    AboutClientConnectComponent,
    DynamicGlobalAlertComponent,
    ClickStopPropagationDirective,
    InvoiceDetailsModalComponent,
    ForecastDatePipe,
    ForecastServicePipe,
    EnterpriseSsoLogoutComponent,
    AccessDeniedComponent,
    FcUploadTemplateModalComponent
  ],
  exports: [
    GecCoreModule,
    GecSharedServicesModule,
    HeaderComponent,
    DynamicGlobalAlertComponent,
    ModalComponent,
    ContactUsModalComponent,
    PbdsDatavizModule,
    PbdsHeaderShadowModule,
    PbdsColumnToggleModule,
    PbdsDaterangePopoverModule,
    TableModule,
    ErrorComponent,
    RemoveStringFromNumberPipe,
    FileSizePipe,
    FormatFileSizePipe,
    CarrierMessagePipe,
    FormControlValueDirective,
    WidgetErrorComponent,
    TermsConditionsComponent,
    TextareaAutoresizeDirective,
    ClickStopPropagationDirective,
    AddAttachmentModalComponent,
    AddAttachmentComponent,
    AboutClientConnectComponent,
    InvoiceDetailsModalComponent,
    FormatBytesPipe,
    ForecastDatePipe,
    NgbDropdownModule,
    ForecastServicePipe,
    FcUploadTemplateModalComponent
  ]
})
export class SharedModule {}
