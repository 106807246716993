<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Client Connect Terms & Conditions</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="terms-conditions scrollbox border mb-3">
    <p>
      These Standard Terms and Conditions for Client Connect Services (these <strong>“T&C’s"</strong>), together with
      the Commerce Services Standard Terms and Conditions (<strong>“Standard Terms”</strong>), the Framework Agreement,
      Statement of Work (“SOW”), amendment(s) and attachment(s) (together, the “Agreement”), govern the Client’s
      acquisition and use of the Services described herein. By accessing Client Connect, Client accepts the terms of
      these T&C’s and agrees to be bound by them for so long as Client uses the Services. Except as otherwise provided
      hereunder, in the event of a conflict between the terms of the Agreement and the T&Cs, the terms most favorable to
      Service Provider shall prevail.
    </p>

    <ol>
      <li>
        <strong class="border-bottom">Definitions</strong>
        <ol>
          <li>
            <strong>“Client Content”</strong> means all information, data and other content (including but not limited
            to photographs, images, graphics, videos, logos, designs and other source identifiers and works of
            authorship) that are directly or indirectly, uploaded or otherwise made available or accessible to the
            Platform or otherwise to Service Provider by, or on behalf of, Client in connection with the Services.
          </li>
          <li>
            “<strong>Platform”</strong> means Service Provider’s proprietary software platform that Service Provider
            will make available to Client in connection with the Services, and all new versions, updates, revisions,
            improvements and modifications of the foregoing.
          </li>
          <li>
            <strong>“Resulting Data”</strong> means information, data, and other content that is derived or otherwise
            resulting from processing Client Content and/or Tracking Data (whether individually or in combination) in
            connection with the Services.
          </li>
          <li>
            <strong>“Service Provider Property”</strong> means the Services, the Platform (including all templates and
            designs available thereon), Feedback, Blind Data, and Resulting Data and any inventions, discoveries,
            designs, methods, trade secrets, works-of-authorship, ideas or information made or conceived or reduced to
            practice by the parties related thereto or derivative thereof, including all associated intellectual
            property and other proprietary rights embodied in or arising from any of the foregoing.
          </li>
          <li>
            <strong>“Tracking Data”</strong> means parcel tracking-related information, data and other content that
            Service Provider uses, or otherwise requires, to provide the Services. For the avoidance of doubt, Tracking
            Data may be created by Service Provider or supplied by a third party.
          </li>
          <li>
            “Personal Information” means any information relating to an identified or identifiable natural person that
            is provided to Service Provider, including but not limited to names, addresses, and email addresses.
          </li>
          <li>
            Capitalized terms used but not otherwise defined herein shall have the meanings ascribed to such terms as
            set forth in the SOW, and if not set forth therein, as ascribed in the Framework Agreement, and if not set
            forth therein, as ascribed in the Standard Terms.
          </li>
        </ol>
      </li>

      <li>
        <strong class="border-bottom">Client Obligations</strong>
        <ol>
          <li>
            <span class="border-bottom">Privacy Policies; Permissions.</span> Client shall: (a) implement and maintain a
            privacy policy or other agreement or acknowledgment that accurately and clearly describes Client’s provision
            of its customers' data and personal information to Service Provider in connection with this Agreement
            (<strong>“Privacy Policy”</strong>), and (b) ensure each such customer knowingly and affirmatively agrees to
            the applicable Privacy Policy. Without limiting the following, Client shall obtain and maintain all rights,
            licenses and other permissions from its customers necessary for Service Provider to provide the Services.
            <div class="mt-1">
              If you provide any information, data, or files (collectively, "Uploaded Data") on this Client Portal, you
              represent and warrant that you have the appropriate rights, permissions and/or licenses to provide such
              Uploaded Data, and that by providing said Uploaded Data, you are not violating any third-party rights and
              any applicable laws, rules and regulations.
            </div>
          </li>
          <li>
            <span class="border-bottom">Platform Users.</span> Client may allow such number of its employees as it
            desires to use the Platform solely for the purpose of enjoying the Services under the Agreement; provided,
            however, as a condition to such use, each user must abide by the usage and other restrictions set forth in
            Section 3 and any other end-user terms of use which Service Provider may adopt from time to time. As between
            Client and Service Provider, Client is solely responsible for use of the Platform by any user utilizing
            Client’s account (whether or not authorized by Client). Client must immediately notify Service Provider of
            any violation of the restrictions set forth in Section 3 by any user utilizing Client’s account upon
            becoming actually or constructively aware of such violation, and Client shall be, as between Client and
            Service Provider, solely and wholly liable for any such violation.
          </li>
          <li>
            <span class="border-bottom">Security and Systems.</span> Client is solely responsible for (a) securing, and
            maintaining the confidentiality of, any of its account and other information, including passwords, relating
            to the Platform or Services, (b) obtaining and maintaining—both the functionality and security of—any
            equipment and ancillary services needed to connect to, access or otherwise use the Services, including
            hardware, software, operating systems, networking and other similar technology, and (c) backing-up Client
            Content and Resulting Data. Client hereby acknowledges it will not have access to Client Content or
            Resulting Data through the Platform following the expiration or termination of the SOW.
          </li>
          <li>
            <span class="border-bottom">Feedback.</span> Client shall provide feedback to Service Provider concerning
            the functionality, performance and other aspects of the Services and Platform from time to time as
            reasonably requested by Service Provider, including, without limitation, identifying potential errors and
            suggesting potential improvements (collectively, <strong>"Feedback"</strong>).
          </li>
          <li>
            <span class="border-bottom">Cooperation.</span> Without limiting any other obligation in this Section,
            Client shall make commercially reasonable efforts to cooperate with Service Provider in all matters relating
            to the Services, including responding promptly to any request by Service Provider to provide direction,
            information, approvals, authorizations, access, materials or decisions for purposes of providing the
            Services.
          </li>
        </ol>
      </li>

      <li>
        <strong class="border-bottom">Platform Restrictions and Changes</strong>
        <ol>
          <li>
            <span class="border-bottom">Prohibited Uses.</span> Client shall not, and shall not permit any other person
            to, access or use the Platform except as expressly permitted by this Agreement. Without limiting the
            foregoing, Client shall not, and shall not permit, direct or encourage any Authorized User or other person
            to, directly or indirectly: (a) in whole or part, reverse engineer (except to the extent permitted by law),
            decompile, disassemble or otherwise attempt to discover or derive the source code, object code or underlying
            structure, ideas, know-how or algorithms of the Platform or any software, documentation or data related
            thereto, (b) modify, translate, or create derivative works based on the Platform, (c) use the Platform for
            timesharing or service bureau purposes or other computer service to a third party, (d) modify, remove or
            obstruct any proprietary notices or labels from the Platform, or (e) use the Platform or Services in any
            manner to assist or take part in the development, marketing or sale of a product potentially competitive
            with the Services. For the avoidance of doubt, Service Provider’s proprietary processes, patterns,
            structure, ideas, know-how and algorithms related to the Platform are Service Provider’s Confidential
            Information and subject to Section 6 of the Standard Terms.
          </li>
          <li>
            <span class="border-bottom">Prohibited Content.</span> Client shall not, and shall not permit, direct or
            encourage any person to, directly or indirectly, submit to, or process through, the Services any Client
            Content that (a) is defamatory, harmful to minors, obscene, indecent, pornographic, libelous, threatening,
            harassing, false, misleading or inaccurate, (b) contains or causes to be placed on Service Provider's or
            other third party's systems any Trojan horses, worms, viruses or programming routines intended to interfere,
            damage, corrupt, surreptitiously intercept or expropriate any system, data or personal information, (c)
            violates any applicable local, state, federal or foreign law, rule or regulation, including privacy laws and
            standards and those relating to the marketing, advertisement or sale of goods or services, (d) contains any
            health, medical, financial or other protected information of any individual or any information of any person
            under the age of 13, or (e) infringes, misappropriates, dilutes or otherwise violates the intellectual
            property or proprietary rights of any person.
          </li>
          <li>
            <span class="border-bottom">Changes to Platform.</span> Service Provider may, in its sole discretion, make
            any changes to the Services or Platform it deems necessary or useful to: (a) maintain or enhance the
            quality, delivery, efficiency, performance, or competitive strength of its services, or (b) comply with
            applicable Law.
          </li>
        </ol>
      </li>

      <li>
        <strong class="border-bottom">Intellectual Property Ownership and Licenses</strong>
        <ol>
          <li>
            <span class="border-bottom">Ownership.</span> Client Content shall be considered Client Owned Property and
            Service Provider Property shall be considered Company Owned Property (as such terms are defined in the
            Standard Terms). To the extent Client has or acquires any right, title or interest in to or under any
            Service Provider Property, Client hereby assigns all such right, title and interest, in, to and under such
            Service Provider Property to Service Provider.
          </li>
          <li>
            <span class="border-bottom">Client Content Licenses; Blind Data.</span> Notwithstanding anything to the
            contrary contained in the Standard Terms, including Section 7 thereof, Client hereby grants Service Provider
            a non-exclusive, worldwide, fully paid-up, royalty free, sub-licensable and transferable license: (a) to
            reproduce, transmit, distribute, process and otherwise use and exploit Client Content as necessary or useful
            to perform the Services (including to generate Resulting Data) and to otherwise exercise its rights
            hereunder, and (b) to display, distribute and otherwise use Client's name, logos and other identifying marks
            to identify Client as a customer of Service Provider in compliance with any branding guidelines provided by
            Client.<br /><br />
            Notwithstanding anything to the contrary contained in the Standard Terms, including Section 7 thereof,
            Client hereby grants Service Provider a non-exclusive, worldwide, fully paid-up, royalty free,
            sub-licensable, transferable and perpetual license: (a) to use Client Content to collect, develop, create,
            extract and otherwise generate statistics and other information, and to compile, synthesize and analyze such
            data ("Blind Data"), and (b) to use Client Content to the extent necessary for Service Provider to exercise
            its full rights in Resulting Data.
          </li>
          <li>
            <span class="border-bottom">Resulting Data License.</span> Subject to the terms of the Agreement, Service
            Provider hereby grants Client a non-exclusive, non-transferrable, non-sublicensable license during the term
            of the SOW to reproduce, use, display, create derivative works of and otherwise exploit Resulting Data that
            Service Provider makes available to Client through the Platform solely for the purpose of enjoying the
            Services under the Agreement.
          </li>
          <li>
            <span class="border-bottom">Integration.</span> If Client desires for a contractor, vendor or any other
            third-party to access Service Provider Owned Property in connection with Client’s receipt of Services
            (including in connection with any systems integration), Client shall cause such third-party to agree in
            writing to be bound by Service Provider’s terms and conditions with respect to such access. Nothing herein
            will be deemed to grant, by implication, estoppel or otherwise, Client any rights to sublicense, share, or
            otherwise make available Service Provider Owned Property to any third-party, including vendors of Client.
          </li>
        </ol>
      </li>

      <li>
        <strong class="border-bottom">Termination</strong>
        <ol>
          <li>
            <span class="border-bottom">For Cause.</span> Service Provider may terminate the SOW immediately on notice
            to Client, if Client breaches Section 2.1, 2.2, 2.3, 3.1, or 3.2 of these T&Cs.
          </li>
          <li>
            <span class="border-bottom">Effects of Termination.</span> Upon expiration or termination of the SOW for any
            reason Client must immediately cease all use of and access to the Platform (and Service Provider may disable
            all Client access thereto).
          </li>
        </ol>
      </li>

      <li>
        <strong class="border-bottom">Additional Disclaimers.</strong> Without limiting the SOW, Standard Terms or
        anything contained in these T&Cs, and for the avoidance of doubt: (a) RESULTING DATA IS NOT GUARANTEED TO BE
        COMPLETE, ACCURATE, OR WITHOUT ERRORS AND CLIENT SHOULD EXERCISE JUDGMENT IN ITS USE THEREOF, (b) SERVICE
        PROVIDER DOES NOT PROVIDE ANY LEGAL, TAX OR OTHER PROFESSIONAL ADVICE AND CLIENT SHOULD CONSULT WITH ITS
        PROFESSIONAL ADVISERS TO CONFIRM, INTERPRET, AND APPLY RESULTING DATA—SERVICE PROVIDER HAS NO LIABILITY TO
        CLIENT FOR CLIENT’S RELIANCE ON RESULTING DATA, AND (c) SERVICE PROVIDER WILL NOT BE RESPONSIBLE OR LIABLE FOR
        DATA ERRORS.
      </li>

      <li>
        <strong class="border-bottom">Indemnification.</strong> In addition to the Indemnification obligations set forth
        in the SOW, Client shall indemnify and hold harmless Service Provider and its affiliated and controlling
        entities, and their respective directors, employees, officers and agents (each, a
        <strong>“Service Provider Indemnitee”</strong>) from all liabilities, fines, damages, losses, deficiencies,
        judgments, interest, awards, penalties costs and expenses of whatever kind (including reasonable attorneys’ fees
        and the cost of enforcing any right to indemnification hereunder and the cost of pursuing any insurance
        providers) and defend the Service Provider Indemnitees against any third party (including but not limited to
        Client’s customers or a governmental authority) claim, suit, action or proceeding , in each case, arising out
        of, resulting from or related to: (a) Client’s or any of Client’s customer’s employee’s, consultant’s, vendor’s
        or agent’s actual or alleged violation of Law in connection with its or their use of the Platform or Services,
        (b) Client Content (or a Service Provider Indemnitee’s use thereof pursuant to this Agreement), actually or
        allegedly infringing, misappropriating, diluting or otherwise violating any intellectual property or proprietary
        right of any person or violating any data privacy laws or regulations , and (c) Client’s obligations under or
        breach of Section 2.1, 2.2, 2.3, 3.1, or 3.2 hereof.
      </li>
      <li>
        <strong class="border-bottom">Liability Cap.</strong> Notwithstanding
        <span class="border-bottom">Section 8.3</span> Clause 2 of the Standard Terms, TO THE EXTENT PERMITTED BY LAW,
        EACH PARTY’S LIABILITY TO THE OTHER FOR ALL DAMAGES AND INDEMNITY OF ANY KIND ARISING UNDER OR RELATING TO THE
        SOW IS CAPPED SUCH THAT THE CUMULATIVE LIABILITY IN RESPECT OF ANY AND ALL CLAIMS SHALL NOT EXCEED THE LESSER OF
        (i) $1,000,000, OR (ii) THE AMOUNTS PAID BY CLIENT TO COMPANY UNDER THE SOW FOR THE TWELVE (12) MONTHS
        IMMEDIATELY PRECEDING THE DATE OF THE APPLICABLE CLAIM. THE FOREGOING LIMITATIONS WILL NOT APPLY TO (a) ANY
        CLAIMS FOR PERSONAL INJURY OR DEATH; (b) ANY CLAIMS BASED UPON A PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT;
        (c) ANY CLAIMS BASED ON A WILLFUL VIOLATION OF EITHER PARTY'S PROPRIETARY RIGHTS; (d) EITHER PARTY'S BREACH OF
        ITS CONFIDENTIALITY OBLIGATIONS; (e) ANY CLAIMS FOR INFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS; OR
        (f) CLIENT'S INDEMNIFICATION OBLIGATIONS AND ANY LIABILITY ARISING THEREUNDER. FOR PURPOSES OF CLARIFICATION, IN
        NO EVENT WILL AMOUNTS DUE AND PAYABLE TO COMPANY UNDER THE SOW BE DEEMED SUBJECT TO THE LIMITATIONS SET FORTH IN
        THIS SECTION.
      </li>
    </ol>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">I Agree</button>
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Sign Out</button>
</div>
