<li class="nav-item" ngbDropdown>
  <a type="button" id="fulfillment-menu" class="nav-link" role="button" ngbDropdownToggle l10nTranslate>
    HEADER.FULFILLMENT.MENU.TITLE
    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
  </a>

  <div ngbDropdownMenu aria-labelledby="financials-menu" style="max-height: 1000px !important">
    <a
      *ngIf="launchDarkleyGlobalFlags?.ui_Header_Fulfillment_Orders"
      id="fulfillment-menu-item-0"
      ngbDropdownItem
      routerLink="/fulfillment/orders"
      l10nTranslate
      >HEADER.FULFILLMENT.MENU.ORDERS
    </a>
    <a
      id="fulfillment-menu-item-1"
      ngbDropdownItem
      routerLink="/fulfillment/current-inventory"
      routerLinkActive="active"
      l10nTranslate
      >HEADER.FULFILLMENT.MENU.CURRENT_INVENTORY</a
    >
    <a id="fulfillment-menu-item-2" ngbDropdownItem routerLink="/fulfillment/inventory-activity" l10nTranslate
      >HEADER.FULFILLMENT.MENU.INVENTORY_ACTIVITY</a
    >
    <a id="fulfillment-menu-item-2" ngbDropdownItem routerLink="/fulfillment/inventory-velocity" l10nTranslate
      >HEADER.FULFILLMENT.MENU.INVENTORY_VELOCITY</a
    >
    <a id="fulfillment-menu-item-3" ngbDropdownItem routerLink="/fulfillment/backordered" l10nTranslate
      >HEADER.FULFILLMENT.MENU.BACKORDERED</a
    >
    <div class="dropdown-divider"></div>
    <a id="fulfillment-menu-item-1" ngbDropdownItem routerLink="/fulfillment/receiving" l10nTranslate
      >HEADER.FULFILLMENT.MENU.RECEIVING</a
    >
    <div class="dropdown-divider"></div>
    <a id="fulfillment-menu-item-5" ngbDropdownItem (click)="redirectToLegacyPortal()" l10nTranslate
      >HEADER.FULFILLMENT.MENU.OLD_PORTAL <small><i class="pbi-icon-mini pbi-link-external"></i></small
    ></a>
  </div>
</li>
