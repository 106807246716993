import { UtilityService } from '../services/utility.service';

export class BackOrderedColumns {
  constructor(private utilityService: UtilityService) {}

  BACKORDERED_TABLE_COLUMNS = [
    {
      field: 'productSKU',
      align: 'left',
      header: this.utilityService.translate('BACKORDER.ITEM'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'backorderedQTY',
      align: 'right',
      header: this.utilityService.translate('BACKORDER.QTY'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'backorderedShipments',
      align: 'right',
      header: this.utilityService.translate('BACKORDER.ORDERS'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'oldestOrder',
      align: 'left',
      header: this.utilityService.translate('BACKORDER.OLDEST_ORDER'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'availableInventory',
      align: 'right',
      header: this.utilityService.translate('BACKORDER.AVAILABLE_INVENTORY'),
      sortable: true,
      isTooltip: true,
      tooltipMsg: this.utilityService.translate('BACKORDER.INVENTORY_TOOLTIP'),
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'heldForOrders',
      align: 'right',
      header: this.utilityService.translate('BACKORDER.HELD_FOR_ORDERS'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    }
  ];
}
