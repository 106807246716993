import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InventoryService } from '../services/inventory-service';
import { InventoryVelocityColumns } from './inventory-velocity-columns';
import { FormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbCalendar, NgbPopover, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Table } from 'primeng/table';
import {
  PbdsDaterangeChange,
  PbdsDaterangeFilter,
  PbdsDaterangePreset,
  PbdsDaterangePopoverComponent
} from 'pb-design-system/daterange-popover';
import { LazyLoadEvent } from 'primeng/api';
import { UtilityService } from '../services/utility.service';

import { DownloadRequest } from '../models/download.request';
import { ReportDownloadService } from '../services/report-download.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import {
  Inventory,
  InventoryVelocity,
  InventoryVelocityDetail,
  InventoryVelocityRequest,
  InventoryVelocityTotal,
  InventoryVelocityTree,
  SearchFields
} from '../models/inventory-velocity.model';

@Component({
  selector: 'fulfillment-inventory-velocity',
  templateUrl: './inventory-velocity.component.html',
  styleUrls: ['./inventory-velocity.component.css']
})
export class InventoryVelocityComponent implements OnInit, OnDestroy {
  inventoryVelocityData: InventoryVelocity | undefined;
  inventoryData: Inventory[] = [];
  data?: InventoryVelocityTotal[] = [];
  searchFields: SearchFields[] = [];
  cols: unknown[] | any;
  totalElements: number = 0;
  startIndex = 0;
  initialRowsCount = 10;
  rowSize = 10;
  pageNumber = 1;
  isLoading: boolean = true;
  isSkuDetailsLoading: boolean = true;
  warehouseDetails: any[] = [];
  productSKU: string | undefined;
  viewedRecord = 0;
  first = 0;
  showWarning = false;
  warningMessage = '';
  unsubscription$: Subject<boolean> = new Subject<boolean>();
  public toDate: NgbDate = this.calendar.getToday();
  public fromDate: NgbDate = this.calendar.getPrev(this.toDate, 'd', 7);
  searchSKU = this.utilityService.translate('INVENTORY_VELOCITY.SEARCH_SKU');
  entriesText = this.utilityService.translate('INVENTORY_VELOCITY.ENTRIES');

  isSKUSearched: boolean = false;

  @ViewChild('dt', { static: true })
  public table: Table | undefined;

  @ViewChild('daterange', { static: true })
  public daterange: PbdsDaterangePopoverComponent | undefined;

  @ViewChild('search', { static: true })
  public search: unknown;

  @ViewChild('popoverFacilities')
  public popoverFacilities: NgbPopover | undefined;

  @ViewChild('popoverStatuses')
  public popoverStatuses: NgbPopover | undefined;
  daterangeFilters: Array<PbdsDaterangeFilter> = [{ field: 'createdDate', label: 'Timestamp' }];

  public daterangeMinDate: NgbDateStruct = this.calendar.getPrev(this.toDate, 'm', 12);
  public applyTextLocalization = 'Apply';
  public cancelTextLocalization = 'Cancel';
  public customRangeTextLocalization = this.utilityService.translate('DATE_FILTER.CUSTOM');
  daterangePresets: Array<PbdsDaterangePreset> = [
    {
      label: this.utilityService.translate('DATE_FILTER.LAST_SEVEN_DAYS'),
      value: 7
    },
    {
      label: this.utilityService.translate('DATE_FILTER.LAST_THIRTY_DAYS'),
      value: 30
    },
    {
      label: this.utilityService.translate('DATE_FILTER.LAST_SIXTY_DAYS'),
      value: 60
    },
    {
      label: this.utilityService.translate('DATE_FILTER.LAST_NINTY_DAYS'),
      value: 90
    },
    {
      label: this.utilityService.translate('DATE_FILTER.YTD'),
      value: 365
    }
  ];

  daterangePresetSelected = 7;

  calendarForm: FormGroup | undefined;

  @ViewChild('pbdsdaterange') pbdsdaterange: PbdsDaterangePopoverComponent | undefined;

  serviceQueryParams: any = {
    first: this.startIndex,
    rows: this.initialRowsCount,
    sortField: 'productsku',
    sortOrder: -1,
    filters: {},
    globalFilter: null,
    multiSortMeta: undefined
  };

  constructor(
    private readonly calendar: NgbCalendar,
    private inventoryService: InventoryService,
    private readonly dateParserFormatter: NgbDateParserFormatter,
    private readonly utilityService: UtilityService,
    private readonly downloadService: ReportDownloadService
  ) {}

  ngOnInit(): void {
    this.cols = new InventoryVelocityColumns(this.utilityService).COLUMNS;
    this.sortType.name = 'productSKU';
  }

  ngOnDestroy(): void {
    this.unsubscription$.unsubscribe();
  }

  sortType = {
    name: 'productsku',
    sortOrder: 'desc'
  };

  public onDaterangeChange($event: PbdsDaterangeChange): void {
    if ($event.fromDate === null || $event.toDate === null) {
      switch ($event.value) {
        case '7':
          this.fromDate = this.calendar.getPrev(this.toDate, 'd', 7);
          break;
        case '30':
          this.fromDate = this.calendar.getPrev(this.toDate, 'd', 30);
          break;
        case '60':
          this.fromDate = this.calendar.getPrev(this.toDate, 'd', 60);
          break;
        case '90':
          this.fromDate = this.calendar.getPrev(this.toDate, 'd', 90);
          break;
        case '365':
          this.fromDate = this.calendar.getPrev(this.toDate, 'y', 1);
          break;
      }
    } else {
      this.fromDate = $event.fromDate;
      this.toDate = $event.toDate;
    }

    this.first = 0;
    this.getReportData(this.fromDate, this.toDate, 1, this.rowSize);
  }

  getReportData(fromDate: NgbDate, toDate: NgbDate, page: number, currentSize: number, nextSize?: number) {
    const request = this.generateReportDataRequest();
    this.inventoryService
      .getInventoryVelocity(page, currentSize, request, nextSize)
      .pipe(
        takeUntil(this.unsubscription$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          this.totalElements = res.totalRecords || 0;
          this.data = res.inventoryVelocityTotalDetail;
        },
        error: (err) => {
          this.utilityService.error('TOAST_MESSAGE.ERROR');
        }
      });
  }

  loadNodes(event: LazyLoadEvent) {
    let isChanged = !this.isEqual(this.serviceQueryParams, event);
    if (isChanged) {
      this.isLoading = true;
      this.serviceQueryParams = JSON.parse(JSON.stringify(event));
      this.modifyRequest();
      if (event?.first == 0) {
        this.getReportData(this.fromDate, this.toDate, this.pageNumber, this.serviceQueryParams.rows);
      } else {
        this.getReportData(
          this.fromDate,
          this.toDate,
          this.pageNumber,
          this.serviceQueryParams.rows,
          this.serviceQueryParams.rows
        );
      }
    }
  }

  modifyRequest() {
    this.rowSize = this.serviceQueryParams.rows;
    this.pageNumber = this.serviceQueryParams.first / this.serviceQueryParams.rows + 1;
    this.sortType.name = this.serviceQueryParams.sortField;
    this.sortType.sortOrder = this.serviceQueryParams.sortOrder === -1 ? 'desc' : 'asc';
  }

  isEqual(object1: object, object2: object) {
    try {
      return JSON.stringify(object1) === JSON.stringify(object2);
    } catch (e) {
      return false;
    }
  }

  searchBySKU(event: { keyCode: number }) {
    if (event.keyCode === 13) {
      this.first = 0;
      this.showWarning = false;
      this.isLoading = true;
      this.getReportData(this.fromDate, this.toDate, 1, this.rowSize);
      this.isSKUSearched = true;
    }
  }

  download(type: string) {
    this.downloadService
      .getDownloadData(this.getDownloadRequest(type))
      .pipe(
        takeUntil(this.unsubscription$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {},
        error: (err) => {
          this.utilityService.error('TOAST_MESSAGE.DOWNLOADERROR');
        }
      });
  }

  getDownloadRequest(type: string) {
    const downloadRequest = new DownloadRequest();
    downloadRequest.reportType = 'InventoryVelocity';
    downloadRequest.from = this.dateParserFormatter.format(this.fromDate) + 'T00:00:00-00:00';
    downloadRequest.to = this.dateParserFormatter.format(this.toDate) + 'T24:00:00-00:00';
    this.searchFields = [];
    const productSKUSearch = new SearchFields();
    productSKUSearch.Name = 'itemtype';
    productSKUSearch.Values?.push('PRODUCT');
    this.searchFields.push(productSKUSearch);

    if (type === 'filtered') {
      if (this.productSKU) {
        const productSKUValue: string[] = [this.productSKU];
        const productSKUSearch = new SearchFields();
        (productSKUSearch.Name = 'productSKU'), (productSKUSearch.Values = productSKUValue);
        this.searchFields.push(productSKUSearch);
      }
    }
    downloadRequest.searchFields = this.searchFields;
    return downloadRequest;
  }

  handleSearchInput(event: any): void {
    //to reset table data when user clicks on cross icon of search sku field
    if (!event.data && event.data !== null && this.isSKUSearched) {
      //reset variables
      this.isSKUSearched = false;
      this.isLoading = true;
      this.getReportData(this.fromDate, this.toDate, 1, this.rowSize);
    }
  }

  generateReportDataRequest(): InventoryVelocityRequest {
    const request = new InventoryVelocityRequest();
    request.From = this.dateParserFormatter.format(this.fromDate) + 'T00:00:00-00:00';
    request.To = this.dateParserFormatter.format(this.toDate) + 'T24:00:00-00:00';
    this.searchFields = [];
    request.sortType = [];
    request.sortType.push(this.sortType);
    const productSKUSearch = new SearchFields();
    productSKUSearch.Name = 'itemtype';
    productSKUSearch.Values?.push('PRODUCT');
    this.searchFields.push(productSKUSearch);
    request.SearchFields = this.searchFields;
    if (this.productSKU) {
      const productSKUValue: string[] = [this.productSKU];
      const productSKUSearch = new SearchFields();
      (productSKUSearch.Name = 'ProductSKU'), (productSKUSearch.Values = productSKUValue);

      this.searchFields.push(productSKUSearch);
    }
    return request;
  }

  onRowExpand(rowData: any) {
    if (this.warehouseDetails[0]?.productSKU !== rowData.data.productSKU) {
      this.isSkuDetailsLoading = true;
      this.warehouseDetails = [];

      const itemTypeSearch = new SearchFields();
      itemTypeSearch.Name = 'itemType';
      itemTypeSearch.Values = ['product'];

      const request = new InventoryVelocityRequest();
      request.NgsMerchantId = request.From = this.dateParserFormatter.format(this.fromDate) + 'T00:00:00-00:00';
      request.To = this.dateParserFormatter.format(this.toDate) + 'T24:00:00-00:00';
      request.SearchFields = [];
      request.SearchFields.push(itemTypeSearch);

      this.inventoryService
        .getInventoryVelocityBySku(rowData?.data.productSKU, request)
        .pipe(
          takeUntil(this.unsubscription$),
          finalize(() => (this.isLoading = false))
        )
        .subscribe({
          next: (res) => {
            this.isSkuDetailsLoading = false;
            this.warehouseDetails = res?.inventoryVelocityDetail;
          },
          error: (err) => {
            this.utilityService.error('TOAST_MESSAGE.ERROR');
          }
        });
    }
  }

  isColumnSelected(columnName: string): boolean {
    return this.cols.filter((x: any) => x.field.toString() == columnName).map((x: any) => x.toggle)[0].selected;
  }

  formatFacilityName(facilityName: string) {
    return this.utilityService
      .getFacilityList()
      .filter((x) => x.state === facilityName.split(',')[1].trim())
      .map((facility) => facility.field);
  }
}
