import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LaunchDarklyService } from '@gec-shared-services';

import { L10nTranslationService } from 'angular-l10n';

import { EMPTY, of, forkJoin, Observable, throwError, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface Invoice {
  account: Account;
  address: Address;
  amount: number;
  billType: string;
  date: string;
  downloadInvoice: DownloadInvoice;
  downloadTransaction: DownloadTransaction;
  dueDate: string;
  id: string;
  invoiceNumber: string;
  invoiceType: string;
  payable: boolean;
  service: string;
  status: string;
  tddfExists: boolean;
}

export interface BillObject {
  bills: Invoice[];
}

export interface PaymentActivity {
  account: PaymentAccount;
  currency: string;
  invoices: PaymentInvoice[];
  numberOfInvoices: number;
  paymentDate: string;
  paymentType: string;
  totalPaymentAmount: number;
  transactionId: string;
}

export interface PaymentAccount {
  accountNumber: string;
  accountType: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  country: string;
}

export interface PaymentInvoice {
  account: string;
  amountDue: number;
  amountPaid: number;
  billId: string;
  billToAddress1: string;
  billToAddress2: string;
  billToCity: string;
  billToCountry: string;
  billToName: string;
  billToZip: string;
  billType: string;
  currency: string;
  dueDate: string;
  invoiceAmount: number;
  invoiceDate: string;
  invoiceNumber: string;
  invoicePeriod: string;
  pastDue: boolean;
  paymentAmount: number;
  status: string;
}

export interface DownloadTransaction {
  hasError: boolean;
  message: string;
}
export interface DownloadInvoice {
  hasError: boolean;
  message: string;
}
export interface Address {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  companyName: string;
}
export interface Account {
  number: string;
  type: string;
}

export interface Payment {
  account: string;
  billAmount: number;
  billDate: string;
  billId: string;
  dateRange: string;
  downloadTransaction: DownloadTransaction;
  invoiceNumber: string;
  paymentMethod: string;
  receivingBankAccountNumber: string;
  receivingBankName: string;
  service: string;
}
export interface InvoicesAndTransactionDetail {
  body: BillDetails;
}
export interface BillDetails {
  account: string;
  address: Address;
  amountTotal: AmountTotal;
  dueDate: string;
  id: string;
  invoiceBreakUp: InvoiceBreakUp;
  invoiceNumber: string;
  invoiceType: string;
  payableDetails: string;
  summary: SummaryDetails;
}

export interface SummaryDetails {
  accountNumber: ObjectDescriptionDetails;
  amountDue: ObjectDescriptionDetails;
  currency: ObjectDescriptionDetails;
  invoiceDate: ObjectDescriptionDetails;
  invoiceNumber: ObjectDescriptionDetails;
  invoicePeriod: ObjectDescriptionDetails;
}

export interface ObjectDescriptionDetails {
  description: string;
  name: string;
  quantity: string;
  value: string;
}

export interface InvoiceBreakUp {
  item: ObjectDescriptionDetails[];
}

export interface AmountTotal {
  subTotal: ObjectDescriptionDetails;
  taxList: ObjectDescriptionDetails[];
  total: ObjectDescriptionDetails;
}

export interface OverviewDetailsBody {
  body: BPNAndSelfBills;
}

export interface BPNAndSelfBills {
  BPN: BPNAndSelfBillsDetails;
  SELF_BILLS: BPNAndSelfBillsDetails;
}

export interface BPNAndSelfBillsDetails {
  accountList: AccountDetails[];
  bfBalance: number;
  bfCredits: number;
  bfPurchases: number;
  dueDate: string;
  openBills: number;
}

export interface AccountDetails {
  accountNumber: string;
  bfBalance: number;
  bfCredits: number;
  bfPurchases: number;
  openBills: number;
}

export interface DownloadedData {
  body: Blob;
}

export interface SrcDir {
  srcDir: string;
  invoiceNumber?: number;
}
export interface MultiSelectDownload {
  email: string;
  files: SrcDir[];
  destDir: string;
}

interface RegistrationInfo {
  id: string;
  status: string;
  accounts: Account[];
}

interface InvoicePayment {
  transactionId: string;
  paymentAmount: string;
  paymentDate: any;
  paymentType: string;
}

interface PreSignedObject {
  signedUrl: string;
  awsRequestId: string;
  file: string;
  exp: string;
}

interface SizeDetails {
  folderDetails: FolderDetails[];
  totalSize: 193758;
}

interface FolderDetails {
  folderPath: string;
  folderSize: number;
  invoiceNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private baseUrl = 'api/billing';
  private registrationInfo: RegistrationInfo;
  private translations = this.l10nTranslationService.translate(['SERVICES']);


  constructor(private readonly l10nTranslationService: L10nTranslationService, private readonly http: HttpClient, private readonly launchDarklyService: LaunchDarklyService) {}

  /**
   * Get invoice list
   *
   * @param {string[]} accountTypes
   * @param {string} bpn
   *
   * @returns Observable of Invoice[]
   */
  public getInvoicesTransactions(accountTypes: string[], bpn: string[]): Observable<Invoice[]> {
    const observableBatch: Observable<any>[] = [];
    let invoices = [];

    if (accountTypes === undefined || accountTypes.length === 0) {
      return EMPTY;
    }

    accountTypes.forEach((type) => {
      observableBatch.push(
        this.http.get(`${this.baseUrl}/bill/open/`, {
          params: {
            accountType: type,
            bpn: bpn.join(',')
          }
        })
      );

      observableBatch.push(
        this.http.get(`${this.baseUrl}/bill/closed/`, {
          params: {
            accountType: type,
            bpn: bpn.join(',')
          }
        })
      );
    });

    return forkJoin(observableBatch).pipe(
      map((bills: BillObject[]) => {
        bills.forEach((bill: BillObject) => {
          if (bill !== null) {
            invoices = invoices.concat(bill.bills);
          }
        });

        return invoices;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Get payment list
   *
   * @param  {string[]} accountType
   * @param {string[]} bpn
   *
   * @returns Observable of Payment[]
   */
  public getPaymentListTransactions(accountType: string[], bpn: string[]): Observable<Payment[]> {
    const observableBatch: Observable<any>[] = [];
    let paymentList: Payment[] = [];

    if (accountType === undefined || accountType.length === 0) {
      return EMPTY;
    }

    accountType.forEach((type) => {
      observableBatch.push(
        this.http.get(`${this.baseUrl}/payment/list/`, {
          params: {
            accountType: type,
            bpn: bpn.join(',')
          }
        })
      );
    });

    return forkJoin(observableBatch).pipe(
      map((payments: Payment[]) => {
        payments.forEach((payment: Payment) => {
          if (payment !== null) {
            paymentList = paymentList.concat(payment);
          }
        });

        return paymentList;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   * Get invoice transaction details
   *
   * @param {string} billId
   * @param {string} bpn
   *
   * @returns Observable of InvoicesAndTransactionDetail
   */


  public getInvoiceTransactionDetails(billId: string, bpn: string, productIds?:any,creditNoteNumber?: any, clientId?:any ): Observable<InvoicesAndTransactionDetail> {
    const featureFlag = this.launchDarklyService.getFlags();
    let detailsUrl: string = "";
    if(featureFlag.featureTaxInvoiceDetails){
      detailsUrl = `${this.baseUrl}/bill/account/detailsV3/`;
    } else {
      detailsUrl = `${this.baseUrl}/bill/account/details/`;
    }
    if(creditNoteNumber == undefined) {
      return this.http
        .get<InvoicesAndTransactionDetail>(`${detailsUrl}`, {
          params: {
            billId: billId,
            bpn: bpn

          }
        })
        .pipe(catchError((err) => this.handleError(err)));
    } else {
      return this.http
        .get<InvoicesAndTransactionDetail>(`${detailsUrl}`, {
          params: {
            billId: billId,
            bpn: bpn,
            productIds:productIds.join(','),
            creditNoteNumber:creditNoteNumber,
            clientId: clientId

          }
        })
    }



  }

  /**
   * Get multiselect download files
   *
   * @params requestBody of type MultiSelectDownload
   *
   * @returns Observable of PreSignedObject
   */
  public multiSelectDownload(requestBody: MultiSelectDownload): Observable<PreSignedObject> {
    return this.http.post(`${this.baseUrl}/multiDoc`, requestBody).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Get multiselect download file size
   *
   * @params requestBody of type MultiSelectDownload
   *
   * @returns Observable of SizeDetails
   */
  public getMultiSelectDownloadSize(requestBody: MultiSelectDownload): Observable<SizeDetails> {
    return this.http.post(`${this.baseUrl}/getSize`, requestBody).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Fetching payment activities
   *
   * @param {string[]} accountType (currently only uses ['BPN'])
   *
   * @returns Observable of PaymentActivity[]
   */
  public getPaymentActivityData(accountType: string[]): Observable<PaymentActivity[]> {
    let paymentsResponse: PaymentActivity[] = [];

    if (accountType === undefined || accountType.length === 0) {
      return EMPTY;
    }

    return this.http.get<PaymentActivity[]>(`${this.baseUrl}/payment/activity`).pipe(
      map((payments: PaymentActivity[]) => {
        payments.forEach((payment: PaymentActivity) => {
          if (payment !== null) {
            paymentsResponse = paymentsResponse.concat(payment);
          }
        });

        return paymentsResponse;
      }),
      catchError((err) => this.handleError(err))
    );
  }

  /**
   *  Get billing registration info
   *
   * @returns Observable of RegistrationInfo or empty object
   */
  public registration(): Observable<RegistrationInfo | unknown> {
    return this.http
      .get<RegistrationInfo | unknown>(`${this.baseUrl}/registration`, {
        params: {
          refresh: true
        }
      })
      .pipe(
        tap((registrationInfo: RegistrationInfo) => {
          this.registrationInfo = registrationInfo;
        }),
        catchError(() => {
          return of({});
        })
      );
  }

  /**
   * Get overview details for BPNs
   *
   * @param bpnList Array of BPNs
   *
   * @returns OverviewDetailsBody as a Blob
   */
  public getOverviewDetails(bpnList: string[]): Observable<OverviewDetailsBody> {
    return this.http
      .get<OverviewDetailsBody>(`${this.baseUrl}/account/overview/`, {
        params: {
          bpn: bpnList.join(','),
          refresh: true
        }
      })
      .pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Download invoice
   *
   * @param {string} invoiceNumber
   * @param {string} bpn
   */
  public downloadInvoice(invoiceNumber: string, bpn: string): Observable<DownloadedData> {
    return this.http
      .get(`${this.baseUrl}/bill/invoice/`, {
        params: {
          invoiceNumber: invoiceNumber,
          bpn: bpn
        },
        headers: {
          accept: 'application/pdf'
        },
        responseType: 'blob'
      })
      .pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Get invoice payments
   *
   * @param  {string} invoiceNumber
   * @param {string} bpn
   *
   * @returns Observale of InvoicePayment[]
   */
  public getInvoicePayments(invoiceNumber: string, bpn: string): Observable<InvoicePayment[]> {
    return this.http
      .get<InvoicePayment[]>(`${this.baseUrl}/bill/invoicePayments/`, {
        params: {
          invoiceNumber: invoiceNumber,
          bpn: bpn
        }
      })
      .pipe(
        map((payments: InvoicePayment[]) => {
          if (payments.length) {
            payments.map((payment: InvoicePayment) => {
              const year = payment.paymentDate.substring(0, 4);
              const month = payment.paymentDate.substring(4, 6);
              const day = payment.paymentDate.substring(6, 8);

              payment.paymentDate = new Date(year, month - 1, day);
            });
          }

          return payments;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  /**
   * Gets the presigned url for downloading transaction details
   *
   * @param bpn string
   * @param invoiceNumber string
   *
   * @returns PreSignedUrl
   */
  public getPreSignedUrl(bpn: string, invoiceNumber: string): Observable<PreSignedObject> {
    return this.http
      .post<PreSignedObject>(`${this.baseUrl}/bpn/invoice/`, undefined, {
        params: {
          invoiceNumber: invoiceNumber,
          bpn: bpn
        }
      })
      .pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Download payment transaction details
   *
   * @param {string} billId
   * @param {string} bpn
   *
   * @returns Observable of DownloadedData
   */
  public downloadTransactionDetailsPayment(billId: string, bpn: string): Observable<DownloadedData> {
    return this.http
      .get(`${this.baseUrl}/payment/transaction/`, {
        params: {
          billId: billId,
          bpn: bpn
        },
        headers: {
          accept: 'application/csv'
        },
        responseType: 'blob'
      })
      .pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Download current invoice
   *
   * @param  {number} account
   *
   * @returns Observable of Blob
   */
  public downloadCurrentInvoice(account: number): Observable<any> {
    return this.http
      .get(`${this.baseUrl}/bill/current/invoice/`, {
        params: {
          account: account,
          bpn: account
        },
        headers: {
          accept: 'application/octet-stream'
        },
        responseType: 'blob'
      })
      .pipe(
        map((data) => {
          return { data, account };
        }),
        catchError((err) => this.handleError(err))
      );
  }

  /**
   * Get unique billing account types from registration info
   *
   * @returns Array of account types, for example ['BPN', 'SELF_BILLS']
   */
  public getAccountTypesForBilling(): string[] {
    if (!!this.registrationInfo) {
      const accountTypes = this.registrationInfo.accounts.map((account: Account) => account.type);
      const accountTypesSet = new Set(accountTypes);

      return Array.from(accountTypesSet).sort();
    } else {
      return [];
    }
  }

  /**
   * For error handling
   *
   * @param  {HttpErrorResponse} errorResponse
   */
  private handleError(errorResponse: HttpErrorResponse) {
    let errorResponseMessage: {
      status?: number;
      message?: string;
      isError?: boolean;
      error?: string;
    };

    const errorStatus = errorResponse.status;

    switch (errorStatus) {
      case 404: {
        let message = '';

        if (
          errorResponse instanceof HttpErrorResponse &&
          errorResponse.error instanceof Blob &&
          errorResponse.error.type === 'application/json'
        ) {
          // When request of type Blob, the error is also in Blob instead of object of the json data
          return new Promise<any>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const parsedErrors = JSON.parse(reader.result as string).errors || [];

                for (const parsedError of parsedErrors) {
                  message = parsedError.message;
                }

                if (message.includes('No activity file has been uploaded for')) {
                  errorResponseMessage = {
                    status: errorStatus,
                    message: this.translations.SERVICES.THISFILENOTAVAILABLE
                  };
                } else if (message.includes('Oracle error Connection lost')) {
                  errorResponseMessage = {
                    message: this.translations.SERVICES.SOMETHINGWENTWRONG
                  };
                } else {
                  errorResponseMessage = {
                    message: this.translations.SERVICES.THISFILENOTAVAILABLE,
                    status: errorStatus
                  };
                }

                reject(
                  new HttpErrorResponse({
                    error: errorResponseMessage.message,
                    status: errorResponseMessage.status
                  })
                );
              } catch (e) {
                reject(errorResponse);
              }
            };

            reader.onerror = () => {
              reject(errorResponse);
            };

            reader.readAsText(errorResponse.error);
          });
        } else {
          errorResponseMessage = {
            isError: true,
            error: 'This file is not available.',
            status: errorStatus
          };
        }
        break;
      }

      case 406:
        errorResponseMessage = {
          error: this.translations.SERVICES.THISFILENOTAVAILABLE,
          status: errorStatus
        };
        break;

      case 400:
      case 403:
      case 500:
      case 501:
      case 503:
        errorResponseMessage = {
          isError: true,
          status: errorStatus,
          error: 'Something went wrong. Try again.'
        };
        break;

      default:
        errorResponseMessage = {
          error: 'Internal Server Error'
        };
        break;
    }

    return throwError(() => errorResponseMessage);
  }
}
