import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @HostBinding('class.errors')
  @Input()
  public httpError: HttpError;
  @Input() public options: any;

  constructor(private readonly route: ActivatedRoute) {}

  public ngOnInit() {
    if (!this.httpError) {
      this.httpError = this.route.snapshot.paramMap.get('errorStatus') as HttpError;
    }
    if (!this.options) {
      this.options = this.route.snapshot.data;
    }
  }
}

export enum HttpError {
  NotFound = '404',
  InternalServerError = '500',
  BadRequest = '400'
}
