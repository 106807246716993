import { UtilityService } from '../services/utility.service';

export class CurrentInventoryColumns {
  constructor(private utilityService: UtilityService) {}

  COLUMNS = [
    {
      field: 'item',
      width: '30%',

      align: 'left',
      paddingLeft: '35px',
      header: this.utilityService.translate('CURRENT_INVENTORY.ITEM'),
      sortable: true,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'currentQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.CURRENT'),
      sortable: false,
      isTooltip: true,
      tooltipMsg: this.utilityService.translate('CURRENT_INVENTORY.CURRENT_TOOLTIP'),
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'heldQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.HELD'),
      sortable: false,
      isTooltip: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'availableQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.AVAILABLE'),
      sortable: false,
      isTooltip: true,
      tooltipMsg: this.utilityService.translate('CURRENT_INVENTORY.AVAILABLE_TOOLTIP'),
      toggle: {
        visible: true,
        selected: true
      }
    },

    {
      field: 'damagedQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.DAMAGED'),
      sortable: false,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'quarantineQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.QUARANTINED'),
      sortable: false,
      isTooltip: true,
      tooltipMsg: this.utilityService.translate('CURRENT_INVENTORY.QUARANTINED_TOOLTIP'),
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'expiredQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.EXPIRED'),
      sortable: false,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'heldForKittingQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.HELD_FOR_KITTING'),
      sortable: false,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'recallQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.RECALLED'),
      sortable: false,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'putAwayQty',
      width: '20%',
      align: 'right',
      header: this.utilityService.translate('CURRENT_INVENTORY.PUTAWAY'),
      sortable: false,
      isTooltip: false,
      tooltipMsg: '',
      toggle: {
        visible: true,
        selected: false
      }
    }
  ];
}
