<div class="signin sso-logout">
  <div class="signin-wrapper" role="main">
    <a class="signin-logo d-block mt-2 mb-4" href="http://www.pb.com"></a>
    <div class="signin-box">
      <h1 class="signin-title mb-6 sso-logout-header" l10nTranslate>HEADER</h1>
      <h5 class="signin-subheading"></h5>
      <div class="form-group">
        <h2 class="okta-form-title o-form-head sso-logout-txt-color" l10nTranslate>SIGNOUT</h2>
        <span class="sso-logout-txt-color" l10nTranslate>SIGNOUTPARA </span>
        <button type="button" class="btn btn-link sso-btn-link" l10nTranslate (click)="signInWithSSO()">
          SIGNINAGAIN
        </button>
      </div>
    </div>
  </div>
</div>
