import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styles: []
})
export class TermsConditionsComponent {
  constructor(public modal: NgbActiveModal) {}
}
