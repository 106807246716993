import { TreeNode } from 'primeng/api';
import { SearchFields } from './inventory-activity.model';

export class CurrentInventoryFilter {
  sku?: string;
  fromDate?: string;
  toDate?: string;
  page?: string;
  size?: number;
  first?: number;
  sortOrder?: string;
}

export class CurrentInventoryTree {
  item?: string;
  productDescription?: string;
  currentQty?: number;
  availableQty?: number;
  heldQty?: number;
  damagedQty?: number;
  expiredQty?: number;
  quarantineQty?: number;
  heldForKittingQty?: number;
  recallQty?: number;
  putAwayQty?: number;
  expiryDate?: string;
}

export interface Inventory {
  data?: CurrentInventoryTree;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

export class DownloadReportRequest {
  ngsMerchantId?: string;
  searchFields?: [];
  reportType?: string;
  from?: string;
  to?: string;
}
export interface HsClassification {
  hsTariffCode: string;
  countryCode: string;
}

export interface Image {
  imageURL: string;
  fileFormat: string;
  imageContent: string;
  fileName: string;
}

export interface HazmatClassification {
  dotHazmatCode: string;
  unHazmatCode: string;
  hazmatDescription: string;
}

export interface Identifier {
  number: string;
  source: string;
}

export interface Details {
  description: string;
  hsClassification: HsClassification[];
  image: Image;
  originCountryCode: string;
  priceCurrency: string;
  longDescription: string;
  hazmatClassification: HazmatClassification;
  identifiers: Identifier[];
  unitPrice?: number;
  retailPrice?: number;
  category: string;
  eccn: string;
  manufacturer: string;
  originStateProvince: string;
  url: string;
}

export interface ShippingAttributes {
  shipsAlone: boolean;
  dropShip: boolean;
  safetyFactor: number;
}

export interface ReturnParameters {
  returnable: boolean;
  returnWindow: number;
}

export interface InventoryParameters {
  lotTracked: boolean;
  expiryDateTracked: boolean;
  expiryWindow: number;
  serialTracked: boolean;
  fragile: boolean;
  temperatureControlled: boolean;
  highValue: boolean;
  requiresQuarantine: boolean;
  additionalQualityControls: string[];
}

export interface Dimensions {
  length: number;
  height: number;
  width: number;
  dimensionUOM: string;
}

export interface PhysicalUnitDetails {
  weight: number;
  weightUOM: string;
  dimensions: Dimensions;
}

export interface CaseDimension {
  length: number;
  height: number;
  width: number;
  dimensionUOM: string;
}

export interface PhysicalCaseDetails {
  caseWeight: number;
  unitOfMeasurement: string;
  caseDimension: CaseDimension;
  standardQuantity: number;
}

export interface AdditionalLanguageSupport {
  reference: string;
  value: string;
}

export interface VendorDetails {
  name: string;
  number: string;
}

export interface Option {
  name: string;
  code: string;
  description: string;
}

export interface Product {
  clientId: string;
  productType: string;
  sku: string;
  pbCreateDate: Date;
  fulfillmentRegion: string;
  details: Details;
  active: boolean;
  status: string;
  shippingAttributes: ShippingAttributes;
  returnParameters: ReturnParameters;
  prePackagedBoxQuantity: number;
  inventoryParameters: InventoryParameters;
  physicalUnitDetails: PhysicalUnitDetails;
  physicalCaseDetails: PhysicalCaseDetails;
  additionalLanguageSupport: AdditionalLanguageSupport[];
  vendorDetails: VendorDetails;
  options: Option[];
  createDate?: Date;
  pbUpdateDate?: Date;
}

export interface ProductDetails {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  page: number;
  sort: string[];
  products: Product[];
}
