<header class="bg-brand-header fixed-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button
            id="navbar-toggler"
            class="navbar-toggler p-0"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <a id="product-name" class="navbar-brand pt-3"> Client Connect </a>
        </nav>
      </div>
    </div>
  </div>
</header>
<div class="site-content">
  <main class="h-100">
    <div class="errors">
      <div class="my-7 px-4">
        <div class="text-center">
          <svg width="139" height="135" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
            <defs>
              <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="a">
                <stop stop-color="var(--grad_start)" offset="0%" />
                <stop stop-color="var(--grad_end)" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M11.48 102.653c7.45 13.35 19.268 23.795 33.397 29.455a1.5 1.5 0 11-1.115 2.784c-14.77-5.916-27.118-16.829-34.902-30.777a1.5 1.5 0 112.62-1.462zm125.773-34.247a1.5 1.5 0 011.5 1.5c0 28.83-17.482 54.384-43.601 64.923a1.5 1.5 0 01-1.123-2.782c24.992-10.084 41.724-34.542 41.724-62.14a1.5 1.5 0 011.5-1.5zm-122.283.107a1.5 1.5 0 011.5 1.5c0 29.6 23.805 53.59 53.164 53.59 24.432 0 45.55-16.754 51.512-40.286a1.5 1.5 0 112.909.737c-6.298 24.851-28.604 42.55-54.42 42.55-31.022 0-56.165-25.34-56.165-56.591a1.5 1.5 0 011.5-1.5zM69.377 35.62c8.624 0 15.67 7.1 15.67 15.78v13.548a25.322 25.322 0 019.445 19.773c0 13.97-11.242 25.3-25.115 25.3-13.874 0-25.115-11.33-25.115-25.3a25.322 25.322 0 019.445-19.773V51.401c0-8.68 7.045-15.78 15.67-15.78zm0 26.801c-12.212 0-22.115 9.981-22.115 22.3 0 12.318 9.903 22.3 22.115 22.3 12.211 0 22.115-9.982 22.115-22.3 0-12.319-9.904-22.3-22.115-22.3zm0 8.9c4.744 0 8.585 3.87 8.585 8.64 0 4.255-3.058 7.795-7.086 8.509v5.77a1.5 1.5 0 01-3 0v-5.77c-4.027-.715-7.084-4.254-7.084-8.509 0-4.77 3.84-8.64 8.585-8.64zm0 3c-3.082 0-5.585 2.523-5.585 5.64 0 3.108 2.488 5.625 5.557 5.64l.028-.001h.027l.192-.003c2.98-.116 5.366-2.593 5.366-5.636 0-3.117-2.503-5.64-5.585-5.64zm0-74.321c12.163 0 23.872 3.159 34.22 9.082a1.5 1.5 0 11-1.49 2.603C92.21 6.021 81.012 3 69.376 3 32.72 3 3 32.953 3 69.906a1.5 1.5 0 11-3 0C0 31.301 31.059 0 69.377 0zm0 38.62c-6.963 0-12.67 5.753-12.67 12.78v11.471a24.86 24.86 0 0112.67-3.45 24.86 24.86 0 0112.67 3.45v-11.47c0-7.029-5.708-12.78-12.67-12.78zm.256-25.198c22.133 0 41.919 13.017 51.025 32.913a1.5 1.5 0 11-2.728 1.249c-8.623-18.84-27.35-31.162-48.297-31.162a52.585 52.585 0 00-25.441 6.523 1.5 1.5 0 01-1.447-2.628 55.584 55.584 0 0126.888-6.895z"
              fill="url(#a) var(--grad_start)"
            />
          </svg>
        </div>

        <h1 l10nTranslate>NOT-ALLOWED.ACCESS-DENIED.PAGE-TITLE</h1>

        <h3 l10nTranslate>NOT-ALLOWED.ACCESS-DENIED.TITLE</h3>
        <p l10nTranslate>NOT-ALLOWED.ACCESS-DENIED.SUMMARY</p>
        <p class="text-muted mt-4" l10nTranslate>NOT-ALLOWED.ACCESS-DENIED.ERROR</p>
      </div>
    </div>
  </main>
</div>
