import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CurrentInventoryFilter } from '../models/current-inventory.model';
import { InventoryActivityRequest, SearchFields } from '../models/inventory-activity.model';
import { InventoryActivity } from '../models/inventory-activity.model';
import { Observable, Subject } from 'rxjs';
import { StorefrontService, Storefront } from '@gec-shared-services';
import { v1 as uuid } from 'uuid';
import { InventoryVelocity } from '../models/inventory-velocity.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  searchFields: SearchFields[] = [];
  selectedStoreFront: Storefront | undefined;
  removedSKU: Subject<any> = new Subject<any>();
  clientId: string;
  brand: string;
  constructor(private http: HttpClient, private storefrontService: StorefrontService) {
    this.selectedStoreFront = this.storefrontService.getSelectedStorefront();
    this.clientId = this.selectedStoreFront.organizationIds[0];
    this.brand = this.selectedStoreFront.name;
  }

  getCurrentInventory(filter: CurrentInventoryFilter): Observable<any> {
    const params = new HttpParams().set('size', filter.size).set('page', filter.page);

    const data = {
      clientId: this.clientId,
      sort: [filter.sortOrder],
      searchFields: []
    };

    if (filter.sku && filter.sku.length > 0) {
      const value = {
        name: 'sku',
        values: [filter.sku]
      };
      data.searchFields.push(value);
    }
    const url = '/api/fulfillment/inventory/current';

    return this.http.post(url, data, {
      headers: this.headers(),
      params: params
    });
  }

  getProductsDetails(request: any, filter: CurrentInventoryFilter): Observable<any> {
    let url = '/api/fulfillment/product';
    let params = new HttpParams().set('size', filter.size).set('page', 1);
    request.clientId = this.clientId;
    return this.http.post(url, request, {
      headers: this.headers(),
      params: params
    });
  }

  getInventoryActivity(
    page: number,
    currentSize: number,
    request: InventoryActivityRequest,
    nextSize?: number
  ): Observable<InventoryActivity> {
    let params: any;
    request.NgsMerchantId = this.clientId;
    if (nextSize != undefined) {
      params = new HttpParams().set('page', page).set('size', currentSize).set('nextpagesize', nextSize);
    } else {
      params = new HttpParams().set('page', page).set('size', currentSize);
    }

    return this.http.post<InventoryActivity>('/api/fulfillment/inventory/activity', request, {
      headers: this.headers(),
      params: params
    });
  }

  headers() {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-PB-TransactionId': uuid().toString().substring(0, 23),
      'X-PB-CorrelationId': uuid().toString().substring(0, 23),
      'user-ngs-claim': this.selectedStoreFront.roles.map((s) => s.slice(3))
    });
  }

  getCurrentInventoryBySku(productSku: string[], page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page).set('size', size);
    this.searchFields = [];
    const productSKUSearch = new SearchFields();
    (productSKUSearch.Name = 'sku'), (productSKUSearch.Values = productSku);

    this.searchFields.push(productSKUSearch);

    const data: any = {};
    data.ClientId = this.clientId;
    data.SearchFields = this.searchFields;
    const url = '/api/fulfillment/inventory/current';

    return this.http.post(url, data, {
      headers: this.headers(),
      params: params
    });
  }

  onRemoveSKU(sku: any) {
    this.removedSKU.next(sku);
  }

  getInventoryVelocity(
    page: number,
    currentSize: number,
    request: InventoryActivityRequest,
    nextSize?: number
  ): Observable<InventoryVelocity> {
    let params: any;
    request.NgsMerchantId = this.clientId;
    if (nextSize != undefined) {
      params = new HttpParams().set('page', page).set('size', currentSize).set('nextpagesize', nextSize);
    } else {
      params = new HttpParams().set('page', page).set('size', currentSize);
    }

    return this.http.post<InventoryVelocity>('/api/fulfillment/inventory/velocity', request, {
      headers: this.headers(),
      params: params
    });
  }

  getInventoryVelocityBySku(productSku: string, request: any): Observable<any> {
    const url = `/api/fulfillment/inventory/velocity/${productSku}`;
    request.NgsMerchantId = this.clientId;
    return this.http.post<InventoryVelocity>(url, request, {
      headers: this.headers()
    });
  }
}
