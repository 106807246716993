<div
  *ngIf="!alert.alertClosed"
  class="global-alert"
  style="overflow: hidden"
  [ngStyle]="{
    'background-color': alert.alertColor,
    'font-size.px': alert.alertFontSize,
    color: alert.alertFontColor
  }"
  [@fadeInOut]
>
  <div class="container-fluid" style="overflow: hidden">
    <div class="row" style="overflow: hidden">
      <div class="col" style="overflow: hidden" [innerHtml]="alert.alertMessage"></div>

      <div class="col-auto" style="overflow: hidden">
        <a
          href="#"
          class="text-white"
          angulartics2On="click"
          angularticsAction="Close Banner"
          angularticsCategory="banner"
          angularticsLabel="Close Banner"
          (click)="onClose($event)"
        >
          <i class="pbi-icon-mini pbi-close small"></i>
        </a>
      </div>
    </div>
  </div>
</div>
