import { TreeNode } from 'primeng/api';

export class InventoryVelocityTotal {
  productSKU?: string;
  productDescription?: string;
  availableInventory?: number;
  numOfShipments?: number;
  pendingShipments?: number;
  thirtyDaysReplnQty?: number;
  totalProductRetailValue?: number;
  totalProductValue?: number;
  totalShipped?: number;
  velocity?: number;
}

export class InventoryVelocity {
  totalRecords?: number;
  inventoryVelocityTotalDetail?: InventoryVelocityDetail[];
}

export interface Warehouse {
  numOfShipments: number;
  warehouseName: string;
  totalShipped: number;
  totalProductValue?: number;
  totalProductRetailValue: number;
  availableInventory: number;
  pendingShipments: number;
  thirtyDaysReplnQty: number;
  velocity: number;
  outofStockDate: Date;
  lastReceivedDate?: Date;
  lastShippedDate: Date;
}

export interface TotalQuantities {
  totalNumberofShipments: number;
  totalShippedQuantity: number;
  totalProductValueQuantity: number;
  totalProductRetailValueQuantity: number;
  totalAvailableInventory: number;
  velocity: number;
  totalProductRetailValue: number;
  totaltotalInventoryVelocity: number;
  totalPendingShipments: number;
  totalReplenishmentQuantity: number;
}

export interface InventoryVelocityDetail {
  productSKU: string;
  productDescription: string;
  availableInventory: number;
  numOfShipments: number;
  pendingShipments: number;
  thirtyDaysReplnQty: number;
  totalProductRetailValue: number;
  totalProductValue: number;
  totalShipped: number;
  velocity: number;
}

export class InventoryVelocityRequest {
  NgsMerchantId?: string;
  From?: string;
  To?: string;
  SearchFields?: SearchFields[];
  sortType?: any[];
}

export class SearchFields {
  Name?: string;
  Values?: string[] = [];
}

export interface Inventory {
  data?: InventoryVelocityTree;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

export interface InventoryVelocityTree {
  itemName?: string;
  productDescription?: string;
  numofshipments: number;
  totalshipped: number;
  totalProductValue?: number;
  totalproductretailvalue: number;
  availableinventory: number;
  cssClass: string;
  pendingShipments: number;
  totalReplenishmentQuantity: number;
  velocity: number;
  outofStockDate: Date;
  lastReceivedDate?: Date;
  lastShippedDate: Date;
}
