import { NgModule } from '@angular/core';

import { CookiePolicyRoutingModule } from './cookie-policy-routing.module';
import { CookiePolicyComponent } from './cookie-policy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
@NgModule({
  imports: [
    CookiePolicyRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule
  ],
  declarations: [CookiePolicyComponent],
  exports: [],
  bootstrap: [CookiePolicyComponent]
})
export class CookiePolicyModule {}
