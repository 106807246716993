import { UtilityService } from '../services/utility.service';

export class InventoryVelocityColumns {
  constructor(private utilityService: UtilityService) {}

  COLUMNS = [
    {
      field: 'productSKU',
      align: 'left',
      width: '20%',
      paddingLeft: '50px',
      header: this.utilityService.translate('INVENTORY_VELOCITY.ITEM'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'numOfShipments',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.SHIPMENTS'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'totalShipped',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.TOTAL_SHIPPED'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'totalProductRetailValue',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.TOTAL_RETAIL_VALUE'),
      sortable: true,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'availableInventory',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.AVAILABLE'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'velocity',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.VELOCITY'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'outofStockDate',
      align: 'left',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.EST_OUT_OF_STOCK'),
      sortable: false,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'thirtyDaysReplnQty',
      align: 'right',
      width: '10%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.REPLENISH_VALUE'),
      sortable: true,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'lastReceivedDate',
      align: 'left',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.LAST_RECEIVED_DATE'),
      sortable: false,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'lastShippedDate',
      align: 'left',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.LAST_SHIPPED_DATE'),
      sortable: false,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'pendingShipments',
      align: 'right',
      width: '8%',
      header: this.utilityService.translate('INVENTORY_VELOCITY.PENDING_SHIP_QTY'),
      sortable: true,
      hidden: true,
      toggle: {
        visible: true,
        selected: false
      }
    }
  ];
}
