import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as fileSaver from 'file-saver';
import { Injectable } from '@angular/core';
import { Storefront, StorefrontService } from '@gec-shared-services';

@Injectable({
  providedIn: 'root'
})
export class ReportDownloadService {
  url = `/api/fulfillment/download`;

  selectedStoreFront: Storefront | undefined;
  clientId?: string;
  constructor(private http: HttpClient, private storefrontService: StorefrontService) {
    this.selectedStoreFront = this.storefrontService.getSelectedStorefront();
    this.clientId = this.selectedStoreFront.organizationIds[0];
  }
  getDownloadData(request: any): Observable<any> {
    request.ngsMerchantId = this.clientId;
    return this.http
      .post(this.url, request, {
        headers: this.headers(),
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((res: any) => {
          fileSaver.saveAs(res.body, this.getFileName(res.headers));
        })
      );
  }

  private getFileName(header: HttpHeaders) {
    let contentDisposition = header.get('content-disposition');
    return contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
  }

  headers() {
    return new HttpHeaders({
      'user-ngs-claim': this.selectedStoreFront.roles.map((s) => s.slice(3))
    });
  }
}
