import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storefront, StorefrontService } from '@gec-shared-services';
import { Observable } from 'rxjs';
import { v1 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class BackOrderedService {
  selectedStoreFront: Storefront | undefined;
  clientId?: string;

  constructor(private http: HttpClient, private storefrontService: StorefrontService) {
    this.selectedStoreFront = this.storefrontService.getSelectedStorefront();
    this.clientId = this.selectedStoreFront.organizationIds[0];
  }

  getBackOrderDetails(queryParams: any, backorderRequest: any): Observable<any> {
    const page = queryParams?.first / queryParams?.rows + 1;

    let params = new HttpParams().set('size', queryParams?.rows).set('page', page.toString());
    params = page > 1 ? params.append('nextPageSize', queryParams?.rows) : params;

    backorderRequest.ngsMerchantId = this.clientId;

    const url = `/api/fulfillment/backorder`;
    return this.http.post(url, backorderRequest, {
      headers: this.getHeaderDetails(),
      params: params
    });
  }

  getHeaderDetails() {
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-PB-CorrelationId': uuid().toString().substring(0, 23),
      'X-PB-TransactionId': uuid().toString().substring(0, 23),
      'user-ngs-claim': this.selectedStoreFront.roles.map((s) => s.slice(3))
    });
  }
}
