import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';

@Injectable({
  providedIn: 'root'
})
export class AngularticsService {
  constructor(private readonly angulartics: Angulartics2) {}

  public eventTrack(category: string, action: string, label: string) {
    if (!!this.angulartics.eventTrack) {
      this.angulartics.eventTrack.next({
        action,
        properties: {
          category,
          label
        }
      });
    }
  }

  public setDeveloperMode() {
    this.angulartics.settings.developerMode = true;
  }
}
