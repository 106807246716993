import { Component, OnInit } from '@angular/core';
import { RouterOutlet, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { RoutingState } from './shared/services/routing-state-service/routing-state.service';
import { TermsConditionsComponent } from './shared/terms-conditions/terms-conditions.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TourService } from './shared/services/tour-service/tour.service';
import {
  ApplicationContext,
  Configuration,
  Organization,
  ProductOrganization
} from './shared/services/application-context/application-context';
import { routerAnimation } from './shared/animations/router-animation';
import { AuthService } from './shared/services/auth-service/auth.service';
import { StorefrontService } from '@gec-shared-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerAnimation]
})
export class AppComponent implements OnInit {
  public isUserLoggedIn = false;

  constructor(
    private readonly angulartics: Angulartics2GoogleGlobalSiteTag,
    private readonly routingState: RoutingState,
    private readonly modalService: NgbModal,
    private readonly applicationContext: ApplicationContext,
    private readonly tourService: TourService,
    private readonly title: Title,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly storefrontService: StorefrontService
  ) {
    this.angulartics.startTracking();
    this.routingState.loadRouting();
  }

  public ngOnInit() {
    this.authService.startSessionTimeoutTimer();
    // set the page title on route change for google analytics
    this.setTitle();

    // monorepo-mw: call middleware internal api to determine if session is active or not
    this.authService.isAuthenticated().then(
      (isAuthenticated) => {
        const redirectURL = localStorage.getItem('segments');
        const storefronts = this.storefrontService.storefronts;
        const roles = this.applicationContext.calculatePermissionsForParentStoreFront(storefronts);
        const roleName = roles.findIndex((role) => {
          return role.toUpperCase() === 'CC_START_RETURN_ONLY';
        });

        // handle deep link
        if (redirectURL && redirectURL !== '' && isAuthenticated) {
          localStorage.removeItem('segments');
          this.router.navigate([redirectURL]);
          return;
        }

        const productOrgs: ProductOrganization[] = this.applicationContext.productOrgInfo.productOrganizations;
        let returnsStorefronts: Organization[];
        let rolesOtherThanStartAReturn;

        productOrgs.forEach((productOrg: ProductOrganization) => {
          returnsStorefronts = productOrg.organizations.filter((org: Organization) => {
            const hasDrs = org.productId.toUpperCase() === 'DRS';
            rolesOtherThanStartAReturn = org.configuration.filter(
              (config: Configuration) =>
                config.configName.toUpperCase() === 'START_A_RETURN' &&
                config.configValue.toUpperCase() === 'FALSE' &&
                roles.length > 1
            );

            return hasDrs && rolesOtherThanStartAReturn && rolesOtherThanStartAReturn.length;
          });
        });

        if (!this.authService.isTermsConditionsAccepted && (roleName === -1 || returnsStorefronts.length)) {
          this.modalService
            .open(TermsConditionsComponent, {
              backdrop: 'static',
              centered: true,
              keyboard: false,
              size: 'lg',
              ariaLabelledBy: 'modal-basic-title'
            })
            .result.then(
              () => {
                this.authService.updateTermAndConditionAcceptedFlag(true).subscribe(() => {
                  this.tourService.storefrontMenu();
                });
              },
              () => {
                this.router.navigate(['logout']);
              }
            );
        }
      },
      () => {
        this.router.navigate(['logout']);
      }
    );
  }

  public prepareRoute = (outlet: RouterOutlet) => {
    return outlet.isActivated ? outlet.activatedRoute : '';
  };

  private setTitle() {
    const appTitle = this.title.getTitle();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return `${appTitle} - ${child.snapshot.data['title']}`;
          }
          return appTitle;
        })
      )
      .subscribe((title: string) => {
        this.title.setTitle(title);
      });
  }
}
