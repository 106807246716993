import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export const globalFlags: LDFlagSet = {
  ui_Header_Analytics: true,
  ui_Header_Analytics_Delivery_CycleTime: true,
  ui_Header_Analytics_CBDS_CycleTime: true,
  ui_Header_Analytics_Delivery_DeliveryExceptions: true,
  ui_Header_Analytics_Delivery_InductedPackages: true,
  ui_Header_Analytics_ExpeditedDelivery_CycleTime: true,
  ui_Header_Analytics_Fulfillment_InventoryVelocity: true,
  ui_Header_Analytics_Returns_CycleTime: true,
  ui_Header_Analytics_Returns_DeliveredPackages: true,
  ui_Header_Analytics_Returns_EstimatedDelivery: true,
  ui_Header_Analytics_Returns_PackageActivity: true,
  ui_Header_Financials: true,
  ui_Header_Financials_InvoicesTransactions: true,
  ui_Header_Financials_InvoicesTransactions_Download: true,
  ui_Header_Financials_InvoicesTransactions_InvoiceDownload: true,
  ui_Header_Financials_InvoicesTransactions_TddfDownload: true,
  ui_Header_Financials_SelfBills: true,
  ui_Header_Financials_SelfBills_Invoices: true,
  ui_Header_Financials_SelfBills_Invoices_Download: true,
  ui_Header_Financials_SelfBills_Invoices_InvoiceDownload: true,
  ui_Header_Financials_SelfBills_Invoices_TddfDownload: true,
  ui_Header_Financials_SelfBills_Payments: true,
  ui_Header_Financials_PaymentActivity: true,
  ui_Header_Financials_BillingFAQS: true,
  ui_Header_More: true,
  ui_Header_More_Documents: true,
  ui_Header_More_Forecasts: true,
  ui_Header_Profile_ForecastEmails: true,
  ui_Header_Fulfillment_Orders: true,
  ui_Support_caseCreateFAQ: true
};
