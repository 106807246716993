export class InventoryActivity {
  totalRecords?: number;
  inventoryActivityDetail?: InventoryActivityDetail[];
}

export class InventoryActivityDetail {
  ProductId?: number;
  ShipmentId?: number;
  InventoryTimeStamp?: Date;
  ProductSku?: string;
  ProductDescription?: string;
  Reason?: string;
  Action?: string;
  Qty?: number;
  LotName?: string;
  ManufactureDate?: Date;
  ExpirationDate?: Date;
  ProductLotExpirationThreshold?: number;
  NoteText?: string;
  WarehouseID?: number;
  ProductValue?: number;
  ShipmentOrderID?: string;
  ManifestID?: number;
  ShipmentPO?: string;
  ContainerID?: number;
  InventoryType?: string;
  RunningTotal?: number;
}

export class InventoryActivityRequest {
  NgsMerchantId?: string;
  From?: string;
  To?: string;
  SearchFields?: SearchFields[];
  sortType?: any[];
}

export class SearchFields {
  Name?: string;
  Values?: string[];
}
