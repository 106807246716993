<header class="bg-brand-header fixed-top has-alert" pbdsHeaderShadow>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button
            id="navbar-toggler"
            class="navbar-toggler p-0"
            type="button"
            (click)="isMenuCollapsed = !isMenuCollapsed"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <a id="product-name" class="navbar-brand" routerLink="/home"> Client Connect </a>

          <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item divider-vertical d-none d-lg-block mr-3 ml-3"></li>
              <ng-container *featureToggle="'featureFulfillmentPortal'">
                <fulfillment-menu *ngIf="isFulfillmentMenu && !hideFFMenu"></fulfillment-menu>
              </ng-container>
              <ng-container
                *ngIf="
                  launchDarkleyGlobalFlags.ui_Header_Analytics &&
                  ((inductPackagesFlag &&
                    launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_InductedPackages &&
                    insightcheck) ||
                    (cycleTimeFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_CycleTime &&
                      insightcheck) ||
                    (inductPackagesFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_DeliveryExceptions &&
                      insightcheck) ||
                    (cbdsCycleTimeFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_CBDS_CycleTime &&
                      insightcheck) ||
                    (expDelCycleTimeFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_ExpeditedDelivery_CycleTime &&
                      insightcheck) ||
                    (edrFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_EstimatedDelivery &&
                      insightcheck) ||
                    (launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_EstimatedDelivery && edrcheck) ||
                    (packageActivityReturnsFlag &&
                      launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_PackageActivity &&
                      insightcheck) ||
                    (BIFlag && launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_CycleTime && insightcheck))
                "
              >
                <li
                  *ngIf="
                    (analyticsMenuFlag &&
                      (cbdsCycleTimeFlag ||
                        inductPackagesFlag ||
                        edrFlag ||
                        BIFlag ||
                        expDelCycleTimeFlag ||
                        ffVelocityCReportFlag)) ||
                    edrcheck
                  "
                  class="nav-item dropdown has-megamenu"
                  ngbDropdown
                >
                  <a l10nTranslate id="analytics-menu" class="nav-link dropdown-toggle" role="button" ngbDropdownToggle>
                    HEADER.NAVIGATION.ANALYTICS
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>
                  <div ngbDropdownMenu aria-labelledby="analytics-menu" class="dropdown-menu megamenu">
                    <div class="columns">
                      <ng-container>
                        <div
                          class="column"
                          *ngIf="
                            (inductPackagesFlag || cycleTimeFlag) &&
                            (launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_InductedPackages ||
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_CycleTime ||
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_DeliveryExceptions)
                          "
                        >
                          <p class="megamenu-subheader" l10nTranslate>HEADER.NAVIGATION.DELIVERYSERVICES</p>
                          <a
                            *ngIf="
                              inductPackagesFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_InductedPackages &&
                              insightcheck
                            "
                            #analyticsDeliveryLink
                            l10nTranslate
                            id="analytics-induct-packages"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/induct-packages"
                            angulartics2On="click"
                            angularticsAction="navigated to Inducted Packages"
                            angularticsCategory="navigation"
                            angularticsLabel="Inducted Packages"
                            >HEADER.NAVIGATION.INDUCTEDPACKAGES</a
                          >

                          <a
                            *ngIf="
                              cycleTimeFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_CycleTime &&
                              insightcheck
                            "
                            #analyticsDeliveryLink
                            l10nTranslate
                            id="analytics-cycle-time"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/cycle-time"
                            angulartics2On="click"
                            angularticsAction="navigated to Cycle Time"
                            angularticsCategory="navigation"
                            angularticsLabel="Cycle Time"
                            >HEADER.NAVIGATION.CYCLETIME</a
                          >
                          <a
                            *ngIf="
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Delivery_DeliveryExceptions && insightcheck
                            "
                            #analyticsDeliveryLink
                            l10nTranslate
                            id="analytics-usps-delivery"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/usps-delivery"
                            angulartics2On="click"
                            angularticsAction="navigated to USPS Delivery Exception"
                            angularticsCategory="navigation"
                            angularticsLabel="USPS Delivery Exception"
                            >HEADER.NAVIGATION.USPS</a
                          >
                        </div>
                      </ng-container>

                      <ng-container *ngIf="launchDarkleyGlobalFlags.ui_Header_Analytics_CBDS_CycleTime && insightcheck">
                        <div
                          class="column"
                          *ngIf="cbdsCycleTimeFlag && launchDarkleyGlobalFlags.ui_Header_Analytics_CBDS_CycleTime"
                        >
                          <p class="megamenu-subheader" l10nTranslate>HEADER.NAVIGATION.CDBSSERVICES</p>
                          <a
                            *ngIf="
                              cbdsCycleTimeFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_CBDS_CycleTime &&
                              insightcheck
                            "
                            #analyticsCbdsLink
                            l10nTranslate
                            id="analytics-cbds-cycle-time"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/cbds-cycle-time"
                            angulartics2On="click"
                            angularticsAction="navigated to Cbds Cycle Time"
                            angularticsCategory="navigation"
                            angularticsLabel="Cycle Time"
                            >HEADER.NAVIGATION.CYCLETIME</a
                          >
                        </div>
                      </ng-container>

                      <ng-container
                        *ngIf="launchDarkleyGlobalFlags.ui_Header_Analytics_ExpeditedDelivery_CycleTime && insightcheck"
                      >
                        <div
                          class="column"
                          [ngClass]="{ 'mb-4': isAnalyticsSpacerClass() }"
                          *ngIf="
                            expDelCycleTimeFlag &&
                            launchDarkleyGlobalFlags.ui_Header_Analytics_ExpeditedDelivery_CycleTime
                          "
                        >
                          <p class="megamenu-subheader" l10nTranslate>HEADER.NAVIGATION.EXPEDITEDDELIVERYSERVICES</p>

                          <a
                            *ngIf="
                              expDelCycleTimeFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_ExpeditedDelivery_CycleTime &&
                              insightcheck
                            "
                            #analyticsReturnsLink
                            l10nTranslate
                            id="analytics-expedited-delivery-cycle-time"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/expdel-cycle-time"
                            angulartics2On="click"
                            angularticsAction="navigated to Expedited Delivery Cycle Time Reports"
                            angularticsCategory="navigation"
                            angularticsLabel="Expedited Delivery Cycle Time"
                          >
                            HEADER.NAVIGATION.CYCLETIME</a
                          >
                        </div>
                      </ng-container>

                      <ng-container *ngIf="edrFlag || BIFlag || edrcheck">
                        <div
                          class="column"
                          *ngIf="
                            launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_EstimatedDelivery ||
                            launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_PackageActivity ||
                            launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_CycleTime
                          "
                        >
                          <p class="megamenu-subheader" l10nTranslate>HEADER.NAVIGATION.RETURNSERVICES</p>
                          <a
                            *ngIf="
                              (edrFlag &&
                                launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_EstimatedDelivery &&
                                insightcheck) ||
                              (launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_EstimatedDelivery && edrcheck)
                            "
                            #analyticsReturnsLink
                            l10nTranslate
                            id="analytics-edr"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/edr"
                            angulartics2On="click"
                            angularticsAction="navigated to EDR"
                            angularticsCategory="navigation"
                            angularticsLabel="EDR"
                          >
                            HEADER.NAVIGATION.EDR</a
                          >
                          <a
                            *ngIf="
                              deliveredpackagesFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_DeliveredPackages &&
                              insightcheck
                            "
                            #analyticsReturnsLink
                            l10nTranslate
                            id="analytics-delivered-packages"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/returns-delivered-packages"
                            angulartics2On="click"
                            angularticsAction="navigated to delivered packages"
                            angularticsCategory="navigation"
                            angularticsLabel="Delivered Packages"
                          >
                            HEADER.NAVIGATION.DELIVEREDPACKAGES</a
                          >
                          <a
                            *ngIf="
                              packageActivityReturnsFlag &&
                              launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_PackageActivity &&
                              insightcheck
                            "
                            #analyticsReturnsLink
                            l10nTranslate
                            id="analytics-return-package-activity"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/returns-package-activity"
                            angulartics2On="click"
                            angularticsAction="navigated to return-package-activity"
                            angularticsCategory="navigation"
                            angularticsLabel="Return-Package-Activity"
                          >
                            HEADER.NAVIGATION.RETURNSPACKAGEACTIVITY</a
                          >
                          <a
                            *ngIf="
                              BIFlag && launchDarkleyGlobalFlags.ui_Header_Analytics_Returns_CycleTime && insightcheck
                            "
                            #analyticsReturnsLink
                            l10nTranslate
                            id="analytics-returns-cycle-time"
                            class="dropdown-item"
                            ngbDropdownItem
                            routerLink="/analytics/returns-cycle-time"
                            angulartics2On="click"
                            angularticsAction="navigated to Returns Cycle Time Reports"
                            angularticsCategory="navigation"
                            angularticsLabel="Returns Cycle Time"
                          >
                            HEADER.NAVIGATION.CYCLETIME</a
                          >
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>
              </ng-container>

              <li
                *ngIf="
                  billingMenuFlag &&
                  launchDarkleyGlobalFlags.ui_Header_Financials &&
                  (launchDarkleyGlobalFlags.ui_Header_Financials_InvoicesTransactions ||
                    launchDarkleyGlobalFlags.ui_Header_Financials_SelfBills ||
                    launchDarkleyGlobalFlags.ui_Header_Financials_PaymentActivity ||
                    launchDarkleyGlobalFlags.ui_Header_Financials_BillingFAQS)
                "
                class="nav-item cursor"
                ngbDropdown
              >
                <a
                  href="#"
                  id="financials-menu"
                  class="nav-link"
                  role="button"
                  ngbDropdownToggle
                  (click)="onMenuClick($event)"
                  l10nTranslate
                  >HEADER.NAVIGATION.FINANCIALS
                  <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="financials-menu">
                  <a
                    *ngIf="
                      checkIfScreenVisible('billing', 'invoices-transactions') &&
                      launchDarkleyGlobalFlags.ui_Header_Financials_InvoicesTransactions
                    "
                    id="financials-invoices-transactions"
                    ngbDropdownItem
                    routerLink="/billing/invoices-transactions"
                    angulartics2On="click"
                    angularticsAction="navigated to Invoices & Transactions"
                    angularticsCategory="navigation"
                    angularticsLabel="Invoices & Transactions##{{ productId }}##"
                    l10nTranslate
                    >HEADER.NAVIGATION.INVOICESANDTRANSACTION</a
                  >
                  <a
                    id="financials-self-bills"
                    *ngIf="
                      showSelfBills &&
                      checkIfScreenVisible('billing', 'self-bills') &&
                      launchDarkleyGlobalFlags.ui_Header_Financials_SelfBills
                    "
                    ngbDropdownItem
                    routerLink="/billing/self-bills"
                    angulartics2On="click"
                    angularticsAction="navigated to Self-Bills"
                    angularticsCategory="navigation"
                    angularticsLabel="Self-Bills##{{ productId }}##"
                    l10nTranslate
                    >HEADER.NAVIGATION.SELFBILLS</a
                  >

                  <a
                    id="financials-payment-activity"
                    *ngIf="
                      checkIfScreenVisible('billing', 'payment-activity') &&
                      launchDarkleyGlobalFlags.ui_Header_Financials_PaymentActivity
                    "
                    ngbDropdownItem
                    routerLink="/billing/payment-activities"
                    angulartics2On="click"
                    angularticsAction="navigated to Payment Activity"
                    angularticsCategory="navigation"
                    angularticsLabel="Payment Activity##{{ productId }}##"
                    l10nTranslate
                    >HEADER.NAVIGATION.PAYMENTACTIVITY</a
                  >
                  <a
                    *ngIf="launchDarkleyGlobalFlags.ui_Header_Financials_BillingFAQS"
                    id="financials-billing-faq"
                    ngbDropdownItem
                    angulartics2On="click"
                    angularticsAction="navigated to Billing FAQs"
                    angularticsCategory="navigation"
                    angularticsLabel="Billing FAQs##{{ productId }}##"
                    routerLink="/billing/faq"
                    l10nTranslate
                    >HEADER.NAVIGATION.BILLINGFAQ</a
                  >
                </div>
              </li>

              <ng-container *featureToggle="'featureSupport'">
                <li *ngIf="supportMenuFlag && globalClientId !== ipsosGid" class="nav-item cursor" ngbDropdown>
                  <a
                    href="#"
                    id="support-menu"
                    class="nav-link"
                    l10nTranslate
                    role="button"
                    ngbDropdownToggle
                    (click)="onMenuClick($event)"
                  >
                    HEADER.NAVIGATION.SUPPORT
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>
                  <div ngbDropdownMenu aria-labelledby="support-menu">
                    <a
                      id="support-case-create"
                      *ngIf="isCaseCreateOptionVisible"
                      ngbDropdownItem
                      (click)="onCaseCreate($event)"
                      angulartics2On="click"
                      angularticsAction="navigated to Create a Case"
                      angularticsCategory="navigation"
                      angularticsLabel="Create a Case"
                      l10nTranslate
                      >HEADER.NAVIGATION.CREATEACASE</a
                    >
                    <a
                      id="support-cases"
                      ngbDropdownItem
                      routerLink="/support/cases"
                      angulartics2On="click"
                      angularticsAction="navigated to Cases"
                      angularticsCategory="navigation"
                      angularticsLabel="Cases"
                      l10nTranslate
                      >HEADER.NAVIGATION.CASES</a
                    >
                    <a
                      *featureToggle="'featureTracking'"
                      id="support-track"
                      ngbDropdownItem
                      angulartics2On="click"
                      angularticsAction="navigated to Track a Package"
                      angularticsCategory="navigation"
                      angularticsLabel="Pitney Bowes Tracking"
                      [href]="trackingURL"
                      target="_blank"
                      >Pitney Bowes <span l10nTranslate>HEADER.NAVIGATION.TRACKING</span></a
                    >

                    <a
                      *featureToggle="'featureServiceDocs'"
                      id="support-service-docs"
                      ngbDropdownItem
                      angulartics2On="click"
                      angularticsAction="navigated to Service Docs"
                      angularticsCategory="navigation"
                      angularticsLabel="Service Docs"
                      [href]="serviceDocsURL"
                      target="_blank"
                      l10nTranslate
                      >HEADER.NAVIGATION.SERVICEDOCS</a
                    >

                    <a
                      id="support-api-status"
                      ngbDropdownItem
                      angulartics2On="click"
                      angularticsAction="navigated to API Status"
                      angularticsCategory="navigation"
                      angularticsLabel="API Status"
                      href="https://apistatus.pitneybowes.com"
                      target="_blank"
                      >API <span l10nTranslate>HEADER.NAVIGATION.STATUS</span>
                    </a>
                  </div>
                </li>
              </ng-container>
              <div *ngIf="globalClientId !== ipsosGid">
                <li
                  class="nav-item cursor"
                  ngbDropdown
                  *ngIf="
                    (checkIsSingleProductIdvisible() &&
                      forecastFlag &&
                      launchDarkleyGlobalFlags.ui_Header_More_Forecasts &&
                      launchDarkleyGlobalFlags.ui_Header_More) ||
                    (showDocHub &&
                      launchDarkleyGlobalFlags.ui_Header_More_Documents &&
                      launchDarkleyGlobalFlags.ui_Header_More)
                  "
                >
                  <!-- More menu -->
                  <a
                    href="#"
                    id="more-menu"
                    class="nav-link"
                    role="button"
                    ngbDropdownToggle
                    (click)="onMenuClick($event)"
                    l10nTranslate
                    >HEADER.NAVIGATION.MORE
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>
                  <div ngbDropdownMenu aria-labelledby="more-menu">
                    <a
                      *ngIf="showDocHub && launchDarkleyGlobalFlags.ui_Header_More_Documents"
                      ngbDropdownItem
                      [routerLink]="['/doc-hub']"
                      l10nTranslate
                      >HEADER.NAVIGATION.APPLICATIONS.DOCHUB.TITLE
                      <div class="text-small" l10nTranslate>HEADER.NAVIGATION.APPLICATIONS.DOCHUB.DESCRIPTION</div>
                    </a>
                    <ng-container
                      *ngIf="
                        checkIsSingleProductIdvisible() &&
                        forecastFlag &&
                        launchDarkleyGlobalFlags.ui_Header_More_Forecasts
                      "
                    >
                      <a
                        *featureToggle="'featureForecast'"
                        #analyticsDeliveryLink
                        l10nTranslate
                        id="more-forecast"
                        ngbDropdownItem
                        [routerLink]="['/forecasts']"
                        angulartics2On="click"
                        angularticsAction="navigated to Forecasts"
                        angularticsCategory="navigation"
                        angularticsLabel="Forecast"
                        >HEADER.NAVIGATION.FORECAST</a
                      ></ng-container
                    >
                  </div>
                </li>
              </div>
              <div
                *ngIf="
                  globalClientId === ipsosGid &&
                  ((checkIsSingleProductIdvisible() &&
                    forecastFlag &&
                    launchDarkleyGlobalFlags.ui_Header_More_Forecasts &&
                    launchDarkleyGlobalFlags.ui_Header_More) ||
                    (showDocHub &&
                      launchDarkleyGlobalFlags.ui_Header_More_Documents &&
                      launchDarkleyGlobalFlags.ui_Header_More))
                "
                style="color: white"
              >
                <a
                  style="color: white; text-decoration: none; display: block; font-size: 18px"
                  *ngIf="showDocHub && launchDarkleyGlobalFlags.ui_Header_More_Documents"
                  [routerLink]="['/doc-hub']"
                  l10nTranslate
                >
                  HEADER.NAVIGATION.APPLICATIONS.DOCHUB.TITLE
                  <div style="color: rgb(197, 186, 186); font-size: 13px" l10nTranslate>
                    HEADER.NAVIGATION.APPLICATIONS.DOCHUB.DESCRIPTION
                  </div>
                </a>
              </div>
            </ul>

            <ul class="navbar-nav navbar-right">
              <!-- storefront menu multiple and pb employee with view all my client -->
              <ng-container *ngIf="!isSingleStorefront && isPbAccount && isCustomClientList">
                <li #myDrop="ngbDropdown" id="storefront-menu" class="nav-item storefront-menu nn" ngbDropdown>
                  <a
                    #dropDownTogg
                    href="#"
                    id="storefront-menu"
                    class="nav-link"
                    ngbDropdownToggle
                    aria-expanded="false"
                    (click)="onMenuClick($event)"
                  >
                    <i *ngIf="isSelectedStoreFrontParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                    >{{ selectedStorefront.name }} <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>

                  <div class="dropdown-menu" ngbDropdownMenu>
                    <ng-container *ngFor="let storefront of storefronts">
                      <button
                        *ngIf="!storefront.isCustomChild"
                        id="client-{{ storefront.name | lowercase }}"
                        class="dropdown-item"
                        [ngClass]="{ 'nav-tabs nav-tabs-custom': storefront.isParent || storefront.isSingleStore }"
                        ngbDropdownItem
                        (click)="selectStorefront(storefront)"
                      >
                        <i *ngIf="storefront.isParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                        >{{ storefront.name }}
                      </button>
                    </ng-container>

                    <!-- internal choose account -->
                    <ng-container *ngIf="isPbAccount">
                      <div class="custom-client-divider" role="separator"></div>
                      <button type="button" class="dropdown-item" (click)="changeAccount()" l10nTranslate>
                        HEADER.NAVIGATION.CHANGEACCOUNT
                      </button>
                    </ng-container>
                  </div>
                </li>
              </ng-container>

              <!-- storefront menu multiple -->
              <ng-container *ngIf="!isSingleStorefront && !isCustomClientList">
                <li id="storefront-menu" class="nav-item storefront-menu" ngbDropdown>
                  <a
                    href="#"
                    id="storefront-menu"
                    class="nav-link"
                    ngbDropdownToggle
                    aria-expanded="false"
                    (click)="onMenuClick($event)"
                  >
                    <i *ngIf="isSelectedStoreFrontParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                    >{{ selectedStorefront.name }} <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>

                  <div class="dropdown-menu" ngbDropdownMenu>
                    <ng-container *ngFor="let storefront of storefronts">
                      <button
                        id="client-{{ storefront.name | lowercase }}"
                        class="dropdown-item"
                        [ngClass]="{ 'nav-tabs': storefront.isParent }"
                        ngbDropdownItem
                        (click)="selectStorefront(storefront)"
                      >
                        <i *ngIf="storefront.isParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                        >{{ storefront.name }}
                      </button>
                    </ng-container>

                    <!-- internal choose account -->
                    <ng-container *ngIf="isPbAccount">
                      <div class="dropdown-divider my-2" role="separator"></div>
                      <button type="button" class="dropdown-item" (click)="changeAccount()" l10nTranslate>
                        HEADER.NAVIGATION.CHANGEACCOUNT
                      </button>
                    </ng-container>
                  </div>
                </li>
              </ng-container>

              <!-- storefront menu single and pb employee -->
              <ng-container *ngIf="isSingleStorefront && isPbAccount">
                <li id="storefront-menu" class="nav-item storefront-menu" ngbDropdown>
                  <a
                    href="#"
                    id="storefront-menu"
                    class="nav-link"
                    ngbDropdownToggle
                    aria-expanded="false"
                    (click)="onMenuClick($event)"
                  >
                    <i *ngIf="selectedStorefront.isParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                    >{{ selectedStorefront.name }} <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </a>

                  <div class="storefront-menu" ngbDropdownMenu aria-labelledby="client-menu">
                    <!-- internal choose account -->
                    <ng-container *ngIf="isPbAccount">
                      <button
                        type="button"
                        class="dropdown-item"
                        ngbDropdownItem
                        (click)="changeAccount()"
                        l10nTranslate
                      >
                        HEADER.NAVIGATION.CHANGEACCOUNT
                      </button>
                    </ng-container>
                  </div>
                </li>
              </ng-container>

              <!-- single account and NOT internal employee -->
              <ng-container *ngIf="isSingleStorefront && !isPbAccount">
                <li id="storefront-menu" class="nav-item storefront-menu">
                  <a class="nav-link">
                    <i *ngIf="selectedStorefront.isParent" class="pbi-icon-mini pbi-hubvis-hierarchy mr-1"></i
                    >{{ selectedStorefront.name }}
                  </a>
                </li>
              </ng-container>

              <div id="appsMenu" *ngIf="showBoxPoll">
                <!-- Apps menu -->
                <li class="nav-item dropdown" ngbDropdown>
                  <a
                    href="#"
                    id="applications"
                    class="nav-link"
                    ngbDropdownToggle
                    aria-labelledby="settings-offscreen"
                    aria-expanded="false"
                    (click)="onMenuClick($event)"
                  >
                    <i class="pbi-icon-outline pbi-apps d-none d-lg-inline" aria-hidden="true"></i>

                    <span class="d-lg-none" id="app-offscreen" l10nTranslate>
                      <i class="pbi-icon-mini pbi-grid-4" aria-hidden="true"></i>&nbsp;
                    </span>
                  </a>
                  <div class="dropdown-menu fade" *ngIf="showBoxPoll" ngbDropdownMenu>
                    <a
                      ngbDropdownItem
                      class="dropdown-item"
                      href="https://boxtour.pitneybowes.com/overview"
                      l10nTranslate
                      >HEADER.NAVIGATION.APPLICATIONS.BOXTOUR.TITLE
                      <p class="text-small" l10nTranslate>HEADER.NAVIGATION.APPLICATIONS.BOXTOUR.DESCRIPTION</p>
                    </a>
                  </div>
                </li>
              </div>

              <!-- settings menu -->
              <div id="settingsMenu" *ngIf="featureUserManagement || featureAccountSecurity || featureSessionTimeOut">
                <li class="nav-item menu-user cursor" ngbDropdown>
                  <a
                    href="#"
                    id="user-settings"
                    class="nav-link"
                    ngbDropdownToggle
                    aria-labelledby="settings-offscreen"
                    aria-expanded="false"
                    (click)="onMenuClick($event)"
                  >
                    <i class="pbi-icon-outline pbi-settings mt-2 mb-2" aria-hidden="true"></i>

                    <span class="d-lg-none" id="settings-offscreen" l10nTranslate>
                      <i class="pbi-icon-mini pbi-settings" aria-hidden="true"></i>&nbsp;HEADER.NAVIGATION.SETTINGS
                    </span>
                  </a>

                  <div class="storefront-menu" ngbDropdownMenu>
                    <ng-container *featureToggle="'featureAccountSecurity'">
                      <a
                        ngbDropdownItem
                        [routerLink]="['/settings/account-security']"
                        (click)="navigatedToAccountSecurity()"
                        l10nTranslate
                        >HEADER.NAVIGATION.ACCOUNTSECURITY
                      </a>
                    </ng-container>
                    <ng-container *featureToggle="'featureSessionTimeOut'">
                      <a
                        ngbDropdownItem
                        [routerLink]="['/settings/session-timeout']"
                        (click)="navigatedToSessionTimeout()"
                        l10nTranslate
                        >HEADER.NAVIGATION.SESSIONTIMEOUT
                      </a>
                    </ng-container>
                    <ng-container *featureToggle="'featureUserManagement'"
                      ><a
                        *ngIf="isUserManagementEnable && !isInternalGlobalClientName && !isEnterpriseUser"
                        ngbDropdownItem
                        (click)="navigatedToUserManagement()"
                        [routerLink]="['/user-management']"
                        l10nTranslate
                        >HEADER.NAVIGATION.USERS
                      </a></ng-container
                    >
                  </div>
                </li>
              </div>
              <!-- User menu -->
              <li class="nav-item dropdown cursor" ngbDropdown>
                <a
                  href="#"
                  id="user-menu"
                  class="nav-link"
                  ngbDropdownToggle
                  aria-labelledby="username-offscreen"
                  aria-expanded="false"
                  (click)="onMenuClick($event)"
                >
                  <div class="d-none d-lg-block">
                    <!-- <div class="menu-user-initials">{{ userInitials }}</div>
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i> -->
                    <i class="pbi-icon-outline pbi-user-circle lg" aria-hidden="true"></i>
                    &nbsp;{{ userDisplayName }}
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </div>

                  <span class="d-lg-none" id="username-offscreen">
                    <i class="pbi-icon-mini pbi-user-circle" aria-hidden="true"></i>
                    &nbsp;{{ userDisplayName }}
                    <i class="pbi-icon-mini pbi-minimal-down align-middle ml-2"></i>
                  </span>
                </a>

                <div ngbDropdownMenu>
                  <div class="menu-user-info pt-4 pb-3 pl-3 pr-3 d-flex align-items-center">
                    <div>
                      <p id="user-name" class="mb-0">{{ userDisplayName }}</p>
                      <p id="user-email" class="mb-0 text-muted">{{ userEmail }}</p>
                    </div>
                  </div>

                  <div class="divider my-2" role="separator"></div>
                  <ng-container *featureToggle="'featurePreferenceManagement'">
                    <a ngbDropdownItem routerLink="/profile" (click)="navigatedToProfile()" l10nTranslate
                      >HEADER.NAVIGATION.PROFILE</a
                    >
                  </ng-container>

                  <a href="#" id="user-contact-us" ngbDropdownItem (click)="contactUs($event)" l10nTranslate>
                    <i class="pbi-icon-mini pbi-mail"></i>
                    HEADER.NAVIGATION.CONTACTUS
                  </a>

                  <div class="divider my-2" role="separator"></div>

                  <a
                    id="about-client-connect"
                    ngbDropdownItem
                    href=""
                    (click)="navigatedToAboutClientConnect($event)"
                    l10nTranslate
                    >HEADER.NAVIGATION.ABOUTCLIENTCONNECT</a
                  >

                  <div class="divider my-2" role="separator"></div>

                  <a id="user-sign-out" ngbDropdownItem href="" (click)="logout($event)" l10nTranslate
                    >HEADER.NAVIGATION.SIGNOUT</a
                  >
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>

<ng-container *featureToggle="'featureDynamicContent'">
  <ng-container *ngIf="alert$ | async as alerts">
    <div class="bg-brand-subheader" *ngFor="let alert of alerts">
      <app-dynamic-global-alert [alert]="alert"></app-dynamic-global-alert>
    </div>
  </ng-container>
</ng-container>
