export class Constants {
  public static readonly WAREHOUSE_LIST = [
    { id: '50', warehouseName: 'Austin, TX' },
    { id: '162', warehouseName: 'Bloomington, CA' },
    { id: '163', warehouseName: 'Columbus, OH' },
    { id: '156', warehouseName: 'Commerce, CA' },
    { id: '11', warehouseName: 'Denver, CO' },
    { id: '160', warehouseName: 'Greenwood, IN' },
    { id: '157', warehouseName: 'Hebron, KY' },
    { id: '161', warehouseName: 'Monroe, NJ' },
    { id: '158', warehouseName: 'Postal Products - Milwaukee' },
    { id: '159', warehouseName: 'Reno, NV' },
    { id: 'Unassigned', warehouseName: 'Unassigned' }
  ];

  // Current inventory supply type
  public static readonly CURRENT = 'CURRENT';
  public static readonly AVAILABLE = 'AVAILABLE';
  public static readonly PENDING = 'PENDING';
  public static readonly DAMAGED = 'DAMAGED';
  public static readonly EXPIRED = 'EXPIRED';
  public static readonly QUARANTINE = 'QUARANTINE';
  public static readonly KITTING = 'KITTING';
  public static readonly RECALLED = 'RECALLED';
  public static readonly PUTAWAY = 'PUTAWAY';

  public static list = [
    'ASN#',
    'PurchaseOrder',
    'sku',
    'expectedQuantity',
    'receivingNode',
    'originCountryCode',
    'vendorName',
    'vendorCode'
  ];
}
