import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { Subject, takeUntil, finalize } from 'rxjs';

import {
  BackOrderFilter,
  BackOrderInventory,
  BackOrderInventoryDetail,
  DownloadReportRequest
} from '../models/backordered.model';
import { BackOrderedService } from '../services/backordered.service';
import { BackOrderedColumns } from './backordered-columns';
import { ReportDownloadService } from '../services/report-download.service';

@Component({
  selector: 'fulfillment-backordered',
  templateUrl: './backordered.component.html',
  styleUrls: ['./backordered.component.css']
})
export class BackorderedComponent implements OnInit, OnDestroy {
  cols: any = [];
  backorderedInventory: BackOrderInventory;
  totalRecordCount = 0;
  pageSize = 1;
  startIndex = 0;
  initialRowsCount = 10;
  isLoading = true;
  productSKU: string | undefined;
  backOrderFilter: BackOrderFilter;
  downloadRequest?: DownloadReportRequest;
  unsubscription$: Subject<boolean> = new Subject<boolean>();
  searchSKU = this.utilityService.translate('BACKORDER.SEARCH_SKU');
  isSKUSearched: boolean = false;
  serviceQueryParams: any = {
    first: this.startIndex,
    rows: this.initialRowsCount,
    sortField: 'oldestOrder',
    sortOrder: -1,
    filters: null,
    globalFilter: null
  };

  backorderRequest: any = {
    searchFields: [
      {
        name: 'productSKU',
        values: []
      }
    ],
    sortType: [
      {
        name: 'oldestorder',
        sortOrder: 'asc'
      }
    ]
  };

  sortType = {
    name: 'oldestOrder',
    sortOrder: -1
  };

  backorderedInventoryDetails: Array<BackOrderInventoryDetail> = [];
  constructor(
    private backOrderService: BackOrderedService,
    private downloadService: ReportDownloadService,
    private readonly utilityService: UtilityService
  ) {
    this.backorderedInventory = new BackOrderInventory();
    this.backOrderFilter = new BackOrderFilter();
  }

  ngOnInit(): void {
    this.cols = new BackOrderedColumns(this.utilityService).BACKORDERED_TABLE_COLUMNS;
    this.modifyRequest();
    this.getInventoryDetails();
  }

  ngOnDestroy(): void {
    this.unsubscription$.unsubscribe();
  }

  getInventoryDetails() {
    this.backOrderService
      .getBackOrderDetails(this.serviceQueryParams, this.backorderRequest)
      .pipe(
        takeUntil(this.unsubscription$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          this.backorderedInventory = res;
          this.backorderedInventoryDetails = this.backorderedInventory.inventoryBackOrderDetails;
          this.totalRecordCount = this.backorderedInventory.totalRecords as number;
        },
        error: (err) => {
          this.utilityService.error('TOAST_MESSAGE.ERROR');
        }
      });
  }

  searchBySKU(event: any) {
    if (event.keyCode === 13) {
      this.startIndex = 0;
      this.serviceQueryParams.first = this.startIndex;
      this.backorderRequest.searchFields[0].values = [];
      if (this.productSKU) {
        this.backorderRequest.searchFields[0].values.push(this.productSKU);
      }
      this.isSKUSearched = true;
      this.getInventoryDetails();
    }
  }

  getParametersDetails(event: LazyLoadEvent) {
    let isChanged = !this.isEqual(this.serviceQueryParams, event);
    if (isChanged) {
      this.isLoading = true;
      this.serviceQueryParams = JSON.parse(JSON.stringify(event));
      this.modifyRequest();
      this.getInventoryDetails();
    }
  }

  modifyRequest() {
    this.backorderRequest.sortType[0].name = this.serviceQueryParams?.sortField.toLowerCase();
    this.backorderRequest.sortType[0].sortOrder = this.serviceQueryParams?.sortOrder === -1 ? 'desc' : 'asc';
  }

  isEqual(object1: object, object2: object) {
    try {
      return JSON.stringify(object1) === JSON.stringify(object2);
    } catch (e) {
      return false;
    }
  }

  downloadExcel(type: string) {
    this.getDownloadRequest();
    switch (type) {
      case 'all':
        this.downloadRequest.searchFields = [];
        break;
      case 'filtered':
        break;
      default:
        break;
    }

    this.downloadService
      .getDownloadData(this.downloadRequest)
      .pipe(
        takeUntil(this.unsubscription$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {},
        error: (err) => {
          this.utilityService.error('TOAST_MESSAGE.DOWNLOADERROR');
        }
      });
  }

  getDownloadRequest() {
    this.downloadRequest = new DownloadReportRequest();
    this.downloadRequest.searchFields = this.backorderRequest.searchFields;
    this.downloadRequest.reportType = 'InventoryBackordered';
    this.downloadRequest.from = new Date();
    this.downloadRequest.to = new Date();
  }

  handleSearchInput(event: any): void {
    //to reset table data when user clicks on cross icon of search sku field
    if (!event.data && event.data !== null && this.isSKUSearched) {
      //reset variables
      this.isSKUSearched = false;
      this.isLoading = true;
      //empty sku searchfield values array from requestobject
      this.backorderRequest.searchFields = this.backorderRequest.searchFields.map((searchField: any) => {
        if (searchField.name === 'productSKU') {
          searchField.values = [];
        }
        return searchField;
      });
      //api call
      this.getInventoryDetails();
    }
  }
}
