import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  ProductOrganizationInfo,
  ApplicationContext
} from '@app/shared/services/application-context/application-context';
import { CaseResponse, CaseCreateService } from '@app/support/service/case-create/case-create.service';
import { caseTypes, CaseTypes } from '@app/support/case-create/models/case-types';
import { L10nTranslationService } from 'angular-l10n';
import { LaunchDarklyService, StorefrontService, AngularticsService } from '@gec-shared-services';
import { AuthService, User } from '@app/shared/services/auth-service/auth.service';

@Component({
  selector: 'app-contact-us-modals',
  templateUrl: './contact-us-modal.component.html'
})
export class ContactUsModalComponent {
  @Input()
  public modalTitle: string;

  @Input()
  public modalBody1: string;

  @Input()
  public modalBody2: string;

  @Input()
  public modalBody3: string;

  @Input()
  public modalBody4: string;

  @Input()
  public modalCaseCreate = false;

  // @Input()
  // public modalButtonText = 'Send';

  @Input()
  public isDisableCancelButton = false;

  @Input()
  public errorMessage: string;

  // public contactUsForm: FormGroup;
  public messageForm: UntypedFormGroup;
  public isSubmitted = false;
  public isPosting = false;
  public characterLimitMessage: string | null = null;
  public caseTypes: CaseTypes = caseTypes;
  private readonly featureFlags = this.launchDarklyService.getFlags();

  constructor(
    public modal: NgbActiveModal,
    private readonly router: Router,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly applicationContext: ApplicationContext,
    private readonly l10nTranslationService: L10nTranslationService,
    private readonly caseCreateService: CaseCreateService,
    private readonly angularticsService: AngularticsService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly storefrontService: StorefrontService,
    private readonly authService: AuthService
  ) {}

  private createCase(summary, description) {
    const selectedStorefront = this.storefrontService.getSelectedStorefront();
    const productIds = selectedStorefront.productIds;
    const user: User = this.authService.getCurrentUser();
    const productOrgInfo: ProductOrganizationInfo = this.applicationContext.productOrgInfo;
    const caseCreateObject = this.getProductsOnTheBasisOfPriority(productIds);
    const caseObject = {
      caseCreatorOktaID: productOrgInfo.user.id,
      clientConnectCaseReporterName: `${user.firstName} ${user.lastName}`,
      clientId: this.storefrontService.getSingleClientIdForSelectedProductId(
        selectedStorefront.supportAppIds,
        caseCreateObject.product
      ),
      eventId: caseCreateObject.eventId,
      merchantCreatedBy: user.email,
      product: caseCreateObject.product,
      summary,
      description
    };
    this.caseCreateService.caseCreate(caseObject, this.featureFlags.ui_Support_CaseVisibilityCEM).subscribe(
      (createResponse: CaseResponse) => {
        const caseId = createResponse.caseId;
        const caseNumber = createResponse.caseNumber;
        const clientId = caseObject.clientId;
        this.renderPopupModalWithThankYou(clientId, caseId, caseNumber);
      },
      (error) => {
        if (error.status === 401) {
          this.router.navigate(['logout']);
        }
        this.isPosting = false;
        this.isSubmitted = false;
        this.errorMessage = this.l10nTranslationService.translate('MODAL.ERRORMSGANDTOASTR.SOMETHINGWENTWRONG');
      }
    );
  }

  /**
   * set the event id base on the priority
   * @param  {any[]} productIds
   */
  private getProductsOnTheBasisOfPriority(productIds: any[]): any {
    if (productIds.includes('fdrfulfillment')) {
      return this.getProductIdAndEvent(this.caseTypes.fdrfulfillment, 'fdrfulfillment');
    } else if (productIds.includes('dds')) {
      return this.getProductIdAndEvent(this.caseTypes.dds, 'dds');
    } else if (productIds.includes('drs')) {
      return this.getProductIdAndEvent(this.caseTypes.drs, 'drs');
    } else if (productIds.includes('cbds')) {
      return this.getProductIdAndEvent(this.caseTypes.cbds, 'cbds');
    } else {
      return this.getProductIdAndEvent(this.caseTypes.expdeliver, 'expdeliver');
    }
  }

  /**
   * set the event id based on the reason
   * @param  {any[]} caseTypeWithProduct
   * @param  {} productId
   */
  private getProductIdAndEvent(caseTypeWithProduct: any[], productId) {
    const eventId = '';
    const product = '';
    const caseCreateObject = {
      eventId,
      product
    };
    caseCreateObject.product = productId;
    caseTypeWithProduct.forEach((val) => {
      val.reasons.forEach((reason) => {
        if (reason.value === 'i-dont-see-my-issue') {
          if (!!this.featureFlags && this.featureFlags.featureNewEventIds) {
            caseCreateObject.eventId = reason.eventIdNew;
          } else {
            caseCreateObject.eventId = reason.eventId;
          }
        }
      });
    });
    return caseCreateObject;
  }

  private renderPopupModalWithThankYou(clientId, caseId, caseNumber) {
    this.isPosting = false;
    this.modalTitle = this.l10nTranslationService.translate('MODAL.CONFIRMATIONMSG.THANKSFORMSG');
    this.modalBody1 = this.l10nTranslationService.translate('MODAL.CONFIRMATIONMSG.GETBACKTOYOU');
    this.modalCaseCreate = false;
    this.isDisableCancelButton = true;
  }
}
