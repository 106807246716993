import { Component, HostBinding, OnInit, OnDestroy, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService, StorefrontService, Storefront, AngularticsService } from '@gec-shared-services';
import { L10nTranslationService } from 'angular-l10n';
import { ApplicationContext } from '@app/shared/services/application-context/application-context';
import { PreferenceManagementService } from '@app/preference-management/services/preference-management.service';
import { DynamicContentService } from '../services/dynamic-content/dynamic-content.service';
import { AuthService } from '../services/auth-service/auth.service';
import { ForecastService } from '../../more/forecast/service/forecast.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsModalComponent } from '../modal/contact-us-modal/contact-us-modal.component';
import { AboutClientConnectComponent } from '../modal/about-client-connect/about-client-connect.component';
import { DiscardCurrentChangesModalComponent } from '../../more/forecast/modals/discard-current-changes-modal/discard-current-changes-modal.component';
import { globalFlags } from '../models/launch-darkley-global-flags';

const ANALYTICSMEGAMENULIMIT = 2;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @HostBinding('class.site-header')
  public true;

  public alert$ = this.dynamicContentService.getGlobalAlerts();
  public isFulfillmentMenu = false;
  public isMenuCollapsed = true;
  public selectedStorefront: Storefront;
  public isSelectedStoreFrontParent: boolean;
  public storefronts: Storefront[];
  public isSingleStorefront = false;
  public isSingleProductId = false;
  public userInitials: string;
  public userDisplayName: any;
  public userEmail: any;
  public hideFFMenu: boolean = false;
  public supportMenuFlag = false;
  public analyticsMenuFlag = false;
  public billingMenuFlag = false;
  public showSelfBills = false;
  public accountSwitcher$: Subscription;
  public isCaseCreateOptionVisible = false;
  public trackingURL: string = environment.trackingAPIURL;
  public serviceDocsURL: string = environment.serviceDocsURL;
  public isPbAccount: boolean = false;
  public isCustomClientList: boolean = false;
  public isUserManagementEnable: boolean = false;
  public inductPackagesFlag = false;
  public ffVelocityCReportFlag = false;
  public cycleTimeFlag = false;
  public cbdsCycleTimeFlag = false;
  public BIFlag = false;
  public edrFlag = false;
  public deliveredpackagesFlag = false;
  public packageActivityReturnsFlag = false;
  public forecastFlag = false;
  public analyticsMegaMenu = false;
  public showBoxPoll = false;
  public showDocHub = false;
  public featureAccountSecurity = false;
  public featureSessionTimeOut = false;
  public featureUserManagement = false;
  public productId: string[];
  public isInternalUser: boolean = false;
  public isEnterpriseUser: boolean = false;
  public isInternalGlobalClientName: boolean = false;
  public insightcheck: boolean;
  public edrcheck: boolean;
  public expDelCycleTimeFlag: boolean = false;
  private readonly productIds = ['dds', 'drs', 'cbds', 'cbrconsulate', 'expdeliver', 'fdrfulfillment'];
  private readonly fulfillmentRolePrefix = 'CC_FF';
  public launchDarkleyGlobalFlags = globalFlags;
  @ViewChild('myDrop', { static: false }) dropDown: NgbDropdown;
  messages: any[] = [];
  subscription: Subscription;
  public contextSwitcher$: Subscription;
  public globalClientId: string;
  @ViewChildren('analyticsDeliveryLink') private readonly analyticsDeliveryLinks: QueryList<any>;
  @ViewChildren('analyticsReturnsLink') private readonly analyticsReturnsLinks: QueryList<any>;
  public ipsosGid: string;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly storefrontService: StorefrontService,
    private readonly applicationContext: ApplicationContext,
    private readonly angularticsService: AngularticsService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly preferenceManagementService: PreferenceManagementService,
    private readonly l10nTranslationService: L10nTranslationService,
    private readonly modal: NgbModal,
    private readonly dynamicContentService: DynamicContentService,
    private readonly authService: AuthService,
    private forecastService: ForecastService
  ) {
    this.subscription = this.storefrontService.getMessage().subscribe((message) => {
      if (message) {
        this.dropDown.open();
        this.messages.push(message);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
  }

  public ngOnDestroy(): void {
    if (!!this.accountSwitcher$) {
      this.accountSwitcher$.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  public ngOnInit() {
    this.ipsosGid = environment.ipsosgid;
    const featureFlags = this.launchDarklyService.getFlags();
    this.launchDarkleyGlobalFlags = this.launchDarklyService.getGlobalFlags();
    // fetch user management access from this this.applicationContext.getProductOrgInfo(); and set
    // isUserManagementEnable variable to true
    const productOrgInfo = this.applicationContext.productOrgInfo;
    // const productOrgInfo = this.applicationContext.productOrgInfo;
    this.contextSwitcher$ = this.storefrontService.get().subscribe((storeFront: Storefront) => {
      this.globalClientId = storeFront.globalClientId;
    });
    productOrgInfo.productOrganizations.forEach((el) => {
      el.organizations.forEach((ele) => {
        if (ele.permissions.includes('CC_INSIGHTS_VIEW') || ele.permissions.includes('CC_ANALYTICS_VIEW')) {
          this.insightcheck = true;
          this.edrcheck = false;
        } else if (ele.permissions.includes('CC_ANALYTICS_EDR')) {
          this.edrcheck = true;
          this.insightcheck = false;
        }
      });
    });
    //   productOrgInfo.productOrganizations.forEach((el) => {
    //   el.organizations.forEach((ele) => {
    //     if (ele.permissions.includes('CC_ANALYTICS_EDR')) {
    //       this.edrcheck = true;
    //     }
    //     else {
    //       this.edrcheck = false
    //     }
    //   });
    // });

    productOrgInfo.productOrganizations.forEach((el) => {
      if (el.globalClientName === 'PB Internal') {
        this.isInternalGlobalClientName = true;
      }
      el.organizations.forEach((ele) => {
        ele.permissions.forEach((e) => {
          if (e === 'CC_USER_MANAGEMENT') {
            this.isUserManagementEnable = true;
          }
        });
      });
    });
    // check whether user is internal or not
    this.isInternalUser = this.authService.isInternalAccount();

    this.isEnterpriseUser = this.authService.isEnterpriseUser();

    this.isPbAccount = this.authService.isPbAccount();
    this.isCustomClientList = this.authService.isCustomClientList();

    this.storefronts = this.storefrontService.storefronts;

    if (this.storefronts && this.storefronts.length === 1) {
      this.isSingleStorefront = true;
    }

    const savedStoreFront = this.storefrontService.getSelectedStorefront();
    const selectedAccount = savedStoreFront !== null ? savedStoreFront : this.storefronts[0];
    this.storefrontService.set(selectedAccount);

    const user = this.authService.getCurrentUser();

    if (user !== null && user !== undefined) {
      // set user initials and menu info
      this.userInitials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
      this.userDisplayName = `${user.firstName} ${user.lastName}`;
      this.userEmail = user.email;
    }

    this.storefronts = this.storefrontService.storefronts;

    this.isSingleStorefront = this.storefrontService.isSingleStorefront();
    this.storefrontService.set(selectedAccount);
    this.preferenceManagementService.onUserNameChanged$.subscribe((result) => {
      this.userDisplayName = result;
      const userDetail = this.authService.getCurrentUser();
      this.userInitials = `${userDetail.firstName.charAt(0)}${userDetail.lastName.charAt(0)}`;
    });

    // Handling account switch
    this.accountSwitcher$ = this.storefrontService
      .get()
      .pipe(
        tap((storefront: Storefront) => {
          this.productId = this.storefrontService.getSelectedProductIds();
          this.trackingURL = environment.trackingAPIURL;
          this.serviceDocsURL = environment.serviceDocsURL;
          this.isSelectedStoreFrontParent = storefront.isParent;
          const isSameStorefront = this.storefrontService.getSameStorefront();
          this.selectedStorefront = storefront;
          this.isCaseCreateOptionVisible = this.storefrontService.checkSupportCreatePermission();
          this.showSelfBills = this.storefrontService.checkSelfBillAllowed();
          this.showBoxPoll = storefront.isBoxpoll;
          this.showDocHub = storefront.isDochub;
          if (featureFlags.featureUserManagement && storefront.isUserManagement) {
            this.featureUserManagement = true;
          }
          this.featureSessionTimeOut = featureFlags.featureSessionTimeOut;
          this.featureAccountSecurity = featureFlags.featureAccountSecurity;

          this.isFulfillmentMenu =
            storefront?.roles?.filter((element) => element.includes(this.fulfillmentRolePrefix)).length > 0;

          // const productIds: any = storefront.productIds;
          // this.productIdObjList.forEach((val) => (val.allow = this.validateVisibleProducts(productIds, val.name)));
          if (storefront !== undefined && storefront.roles !== undefined) {
            this.validateUserRolesForMenu();
          }

          if (!isSameStorefront) {
            this.angularticsService.eventTrack('navigation', 'account switched', 'specific account');
          }

          const currentStoreFront = this.storefrontService.getSelectedStorefront();
          if (
            currentStoreFront.globalClientId === this.storefrontService.customClientStore.globalClientId &&
            currentStoreFront.name.toLowerCase() ===
              this.storefrontService.customClientStore.globalClientName.toLowerCase()
          ) {
            if (this.isFulfillmentMenu && this.router.url.indexOf('/fulfillment/') > -1) {
              this.hideFFMenu = true;
              this.storefrontService.showFFWarning = true;
              this.router.navigate(['home'], { state: { isAccountMenu: false } });
              return;
            } else if (this.isFulfillmentMenu) {
              this.hideFFMenu = true;
            }
          }

          if (
            this.isFulfillmentMenu &&
            currentStoreFront.globalClientId !== this.storefrontService.customClientStore.globalClientId &&
            currentStoreFront.name.toLowerCase() !==
              this.storefrontService.customClientStore.globalClientName.toLowerCase()
          ) {
            this.storefrontService.showFFWarning = true;
            this.hideFFMenu = false;
          } else if (
            !this.isFulfillmentMenu &&
            currentStoreFront.globalClientId !== this.storefrontService.customClientStore.globalClientId &&
            currentStoreFront.name.toLowerCase() !==
              this.storefrontService.customClientStore.globalClientName.toLowerCase()
          ) {
            this.hideFFMenu = false;
            this.storefrontService.showFFWarning = false;
          }

          // trigger navigation to run route guards
          this.router.navigate([this.location.path()], { state: { isAccountMenu: false } });
        })
      )
      .subscribe();
    this.storefrontService.omsIds = this.getProdOrgIDs();
  }
  // Condition for fdrFulfillment visiblity for navigation
  public checkIsSingleProductIdvisible(): boolean {
    return this.isInternalUser || this.productId[0] !== 'fdrfulfillment';
  }

  public isAnalyticsSpacerClass() {
    let deliveryLinks = 0;
    let returnsLinks = 0;
    let isReturnsLinks = false;

    if (this.analyticsDeliveryLinks) {
      deliveryLinks = this.analyticsDeliveryLinks.length;
    }

    if (this.analyticsReturnsLinks) {
      returnsLinks = this.analyticsReturnsLinks.length;
    }

    if (deliveryLinks > ANALYTICSMEGAMENULIMIT || returnsLinks > ANALYTICSMEGAMENULIMIT) {
      this.analyticsMegaMenu = true;
    }

    if (this.analyticsReturnsLinks && this.analyticsReturnsLinks.length > 0) {
      isReturnsLinks = true;
    }

    return isReturnsLinks && !this.analyticsMegaMenu;
  }

  public onCaseCreate($event) {
    $event.preventDefault();

    const url = this.router.url;

    this.router.navigate(['support', 'case', 'create'], { state: { previousUrl: url } });
  }

  public selectStorefront = (storefront: Storefront) => {
    this.forecastService.forecastContextSwitcher = true;
    if (this.forecastService.forecastError) {
      const modalRef = this.modal.open(DiscardCurrentChangesModalComponent, {
        backdrop: 'static',
        centered: true,
        keyboard: false,
        ariaLabelledBy: 'modal-basic-title'
      });

      return modalRef.result.then(
        () => {
          this.forecastService.forecastError = false;

          this.selectedStorefront = storefront;
          this.storefrontService.set(storefront);
          if (this.storefrontService.checkIfScreenVisible('billing', 'invoices-transactions')) {
            setTimeout(() => {
              if (this.router.url === '/home') {
                // this.tourService.invoicesTour();
              }
            }, 1000);
          }
          return true;
        },
        () => {
          return false;
        }
      );
    } else {
      this.selectedStorefront = storefront;

      this.storefrontService.set(storefront);

      if (this.storefrontService.checkIfScreenVisible('billing', 'invoices-transactions')) {
        setTimeout(() => {
          if (this.router.url === '/home') {
            // this.tourService.invoicesTour();
          }
        }, 1000);
      }
    }
  };

  public validateUserRolesForMenu() {
    const featureFlags = this.launchDarklyService.getFlags();
    this.supportMenuFlag = this.storefrontService.checkSupportViewPermission();
    this.analyticsMenuFlag = this.storefrontService.checkAnalyticsViewPermission();
    this.billingMenuFlag = this.storefrontService.checkBillingViewPermission();
    this.edrFlag = this.storefrontService.isEdr();
    this.deliveredpackagesFlag = this.storefrontService.checkreturnsDeliveredPackagesPermission();
    this.packageActivityReturnsFlag = this.storefrontService.checkReturnsPackageActivityPermission();
    this.forecastFlag = this.storefrontService.isForecastReport() && featureFlags.featureForecast;
    this.inductPackagesFlag = this.storefrontService.isInductReport();
    this.BIFlag = this.storefrontService.checkReturnsCycleTimePermission();
    this.cycleTimeFlag = this.storefrontService.checkCycleTimePermission();
    this.cbdsCycleTimeFlag = this.storefrontService.checkCbdsCycleTimePermission();
    this.expDelCycleTimeFlag = this.storefrontService.checkExpeditedDeliveryCycleTimePermission();
    this.ffVelocityCReportFlag = this.storefrontService.isFFVelocityReport();
  }

  public checkIfScreenVisible(zone: string, screenName: string): boolean {
    return this.storefrontService.checkIfScreenVisible(zone, screenName);
  }

  public onMenuClick($event: Event) {
    $event.preventDefault();
  }

  public logout($event) {
    $event.preventDefault();

    this.angularticsService.eventTrack('navigation', 'signed out', 'sign out');
    this.router.navigate(['logout']);
  }

  public navigatedToAboutClientConnect($event: Event) {
    $event.preventDefault();
    this.renderPopupModalForAboutClientConnect();
    this.angularticsService.eventTrack('navigation', 'navigated to about client connect', 'about client connect');
  }

  public navigatedToSessionTimeout() {
    this.angularticsService.eventTrack('navigation', 'navigated to session timeout', 'session timeout');
  }

  public navigatedToAccountSecurity() {
    this.angularticsService.eventTrack('navigation', 'navigated to account security', 'account security');
  }

  public contactUs($event: Event) {
    $event.preventDefault();

    const isBillingPermission = this.storefrontService.checkBillingViewPermission();
    const isSupportPermission = this.storefrontService.checkSupportViewPermission();
    if (isBillingPermission && !isSupportPermission) {
      window.location.href = 'mailto:financialops@pb.com';
      this.angularticsService.eventTrack('navigation', 'Contact Us', 'Email Launcher');
    } else {
      this.renderPopupModal();
    }
    this.angularticsService.eventTrack('navigation', 'navigated to contact us', 'contact us');
  }

  public navigatedToUserManagement(): void {
    if (this.isUserManagementEnable) {
      this.angularticsService.eventTrack('navigation', 'navigated to Users', 'Users');
    }
  }

  public navigatedToProfile(): void {
    this.storefrontService.omsIds = this.getProdOrgIDs();
    const omIds = this.getProdOrgIDs();
    this.angularticsService.eventTrack('navigation', 'navigated to profile', `Profile ##${omIds}##`);
  }

  public changeAccount() {
    this.router.navigate(['cc-internal', 'choose-account']);
  }

  private renderPopupModalForAboutClientConnect() {
    this.modal.open(AboutClientConnectComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false
    });
  }

  private renderPopupModal() {
    const modalRef = this.modal.open(ContactUsModalComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    });

    modalRef.componentInstance.modalTitle = this.l10nTranslationService.translate(
      'HEADER.HEADERCOMPCONTENT.CONTACTUSTITLE'
    );
    if (this.isCaseCreateOptionVisible) {
      modalRef.componentInstance.modalBody1 = this.l10nTranslationService.translate(
        'HEADER.HEADERCOMPCONTENT.IFYOUHAVEISSUEREPORTYOUCAN'
      );
    } else {
      modalRef.componentInstance.modalBody1 = this.l10nTranslationService.translate(
        'HEADER.HEADERCOMPCONTENT.IFYOUHAVEISSUE'
      );
    }
    modalRef.componentInstance.modalBody2 = 'To: Pitney Bowes Client Services Support';
    modalRef.componentInstance.modalButtonText = this.l10nTranslationService.translate('HEADER.HEADERCOMPCONTENT.SEND');
    modalRef.componentInstance.modalCaseCreate = true;
    modalRef.componentInstance.isDisableCancelButton = false;

    return modalRef.result.then(
      () => {
        return true;
      },
      () => {
        return false;
      }
    );
  }

  private selectDefaultProductId(storefront: Storefront): string[] {
    const availableProductIds = storefront.productIds;
    const selectedProductId = [];
    // This logic make sure, very first product(on UI) is selected by default.
    for (const val of this.productIds) {
      if (availableProductIds.includes(val)) {
        selectedProductId.push(val);
      }
    }
    return selectedProductId;
  }

  private getProdOrgIDs(): string {
    const selectedStorFront = this.storefrontService.getSelectedStorefront();
    const financeAppIds = this.storefrontService.getSelectedStorefront().financeAppIds;
    const supportAppIds = selectedStorFront.supportAppIds;
    if (!!supportAppIds && supportAppIds.length > 0) {
      const omids = supportAppIds.map((appId) => appId.productOrganizationId);
      return omids.join(',');
    } else if (!!financeAppIds && financeAppIds.length > 0) {
      const omIds = financeAppIds.map((finAppId) => {
        return finAppId.productOrganizationId;
      });
      return omIds.join(',');
    } else {
      return selectedStorFront.productOrganizationId;
    }
  }
}
