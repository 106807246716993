import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

import { JwtHelperService } from '@auth0/angular-jwt';

import { firstValueFrom, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { ApplicationContext } from '@app/shared/services/application-context/application-context';
import { BillingService } from '@app/shared/services/billing-service/billing.service';
import { IdleService } from '../idle-service/idle-service';
import { TourService } from '../tour-service/tour.service';
import { StorefrontService, AngularticsService } from '@gec-shared-services';
import { CasesService } from '@app/support/service/case-list/case-list.service';
import { LaunchDarklyService } from '@gec-shared-services';

export interface JwtResponse {
  scope: string;
  access_token: string;
  token_type: string;
  id_token: string;
  enterprise_user: boolean;
  read_only: boolean;
  forecast_upload_role: boolean;
}

export interface User {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  sub: string;
  ccbsLocale?: string;
}

export interface OktaUser {
  idp: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  ccbsLocale: string;
  locale: string;
  exp: number;
  iss: string;
  sub: string;
  oid: string;
}

export interface AzureUser {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  ctry: string;
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  oid: string;
  preferred_username: string;
  rh: string;
  roles: string[];
  sub: string;
  tid: string;
  uti: string;
  ver: string;
}

export interface UserDetails {
  id: string;
  termConditionAcceptedFlag: boolean;
  caseCreateClaimsFlag: boolean;
  forecastReminders: boolean;
  updateDate: string;
  sessionTime: string;
  tours: any;
  toursList: any; // TODO: add interface
  user: UserDetailsUser;
}

export interface UserDetailsUser {
  email: string;
  userName: string;
}

export interface AuthenticatedSession {
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly jwthelper = new JwtHelperService();
  private readonly oktaTargetResource = environment.oktaTargetResource;
  private readonly loginCCBSURL = environment.loginCCBSURL;
  private readonly logoutURL = environment.logoutURL;
  private readonly employeeSSOURL = environment.employeeSSOURL;
  private readonly employeeLogoutURL = environment.employeeLogoutURL;
  private sessionTimeoutSubs$: Subscription;
  private _sessionTimeout: string = environment.idleSessionTime;
  public customClientList: boolean = false;
  public customClientToken: any;
  public enterpriseSSOClientName: string;
  private _isTermsConditionsAccepted = false;
  private _isClaimsFlag = false;
  private _forecastReminders = false;
  private readonly redirectURL = environment.redirectURL;
  private readonly azureRedirectUrl = environment.redirectURL;

  private user: User;
  private idpUser: OktaUser | AzureUser;

  constructor(
    private readonly injector: Injector,
    private readonly idleService: IdleService,
    private readonly http: HttpClient
  ) {}

  private get billingService(): BillingService {
    return this.injector.get(BillingService);
  }

  private get applicationContext(): ApplicationContext {
    return this.injector.get(ApplicationContext);
  }
  private get storefrontService(): StorefrontService {
    return this.injector.get(StorefrontService);
  }

  private get tourService(): TourService {
    return this.injector.get(TourService);
  }

  private get caseService(): CasesService {
    return this.injector.get(CasesService);
  }

  private get angulartics() {
    return this.injector.get(AngularticsService);
  }

  private get launchDarklyService(): LaunchDarklyService {
    return this.injector.get(LaunchDarklyService);
  }

  private get router() {
    return this.injector.get(Router);
  }

  /**
   * Get the user from the jwt id_token
   *
   * @param jwtResponse
   *
   * @returns User
   */
  public getIdToken(jwtResponse: JwtResponse): OktaUser {
    const idToken: OktaUser = this.jwthelper.decodeToken(jwtResponse.id_token);

    return idToken;
  }

  /**
   * Logs the user in with Okta code
   *
   * @param code Okta code in the redirect url
   *
   * @returns Promise<boolean>, true when the account is an internal user
   */
  public accountLogin(code: string): Promise<any> {
    return firstValueFrom(
      this.exchangeCodeFortoken(code).pipe(
        // store jwt token response
        tap((apiResponse: HttpResponse<JwtResponse>) => {
          const jwtResponse: JwtResponse = apiResponse.body;
          const idToken = this.getIdToken(jwtResponse);
          this.customClientToken = idToken;
          this.setCurrentUser(idToken);
          this.setJwtResponse(jwtResponse);
        }),
        map(() => {
          const isPbAccount = this.isPbAccount();
          const isInternalAccount = this.isInternalAccount();

          // prevent the terms and conditions modal from displaying
          if (isPbAccount && isInternalAccount) {
            this.isTermsConditionsAccepted = true;
            this.isClaimsFlag = true;
            this.forecastReminders = true;
          }

          return isPbAccount && isInternalAccount;
        }),
        catchError((error: any) => {
          if (error.status === 403) {
            this.accessDenied();
            return of({});
          } else {
            this.logout();
          }
        })
      )
    );
  }

  /**
   * Performs the client login process
   *
   * @description
   * Gets the OMS info, billing registration, then user details and feature flags
   *
   * @returns Promise of any type
   */
  public clientLogin(): Promise<any> {
    return firstValueFrom(
      this.applicationContext.getOmsInfo(this.getCurrentUser()).pipe(
        tap(() => {
          const storefronts = this.applicationContext.createStorefrontsAndStore();
          this.storefrontService.storefronts = storefronts;
        }),
        // get billing registration
        concatMap(() => {
          if (this.storefrontService.checkBillingPermissionInAnyStorefront()) {
            return this.billingService.registration();
          } else {
            return of({});
          }
        }),
        // get user details and feature flags
        switchMap(() => {
          return forkJoin({
            userDetails: this.getUserDetails(), // gets user details from MongoDB
            ldFlags: this.launchDarklyService.init(environment.launchDarklyClientId)
          });
        }),
        tap(({ userDetails }: { userDetails: UserDetails }) => {
          const user = this.getCurrentUser();

          this.launchDarklyService.identify(user.sub, user.email, user.name);
          this.isTermsConditionsAccepted = userDetails.termConditionAcceptedFlag;
          this.isClaimsFlag = userDetails.caseCreateClaimsFlag;
          this.forecastReminders = userDetails.forecastReminders;
          this.tourService.setTours(userDetails.toursList);
          this.sessionTimeout = userDetails.sessionTime;
        }),
        // google analytics event filtering
        tap(() => {
          this.setAngularticsDeveloperMode();
        }),
        catchError((error) => {
          if (error.status === 403) {
            this.accessDenied();
            return of({});
          } else {
            this.logout();
          }
        })
      )
    );
  }

  /**
   * Performs the internal user login process
   *
   * @description
   * Gets the OMS info for a particular account, billing registration, then user details and feature flags
   *
   * @param id number - account id
   *
   * @returns Promise of any type
   */
  public employeeLogin(id: number): Promise<any> {
    return firstValueFrom(
      this.applicationContext.getOmsInfoById(id).pipe(
        tap(() => {
          const storefronts = this.applicationContext.createStorefrontsAndStore();
          this.storefrontService.storefronts = storefronts;
        }),
        // get billing registration
        concatMap(() => {
          if (this.storefrontService.checkBillingPermissionInAnyStorefront()) {
            return this.billingService.registration();
          } else {
            return of({});
          }
        }),
        // get user details and feature flags
        switchMap(() => {
          return forkJoin({
            userDetails: this.getUserDetails(), // gets user details from MongoDB
            ldFlags: this.launchDarklyService.init(environment.launchDarklyClientId)
          });
        }),
        tap(({ userDetails }: { userDetails: UserDetails }) => {
          const user = this.getCurrentUser();

          this.launchDarklyService.identify(user.sub, user.email, user.name);
          this.isTermsConditionsAccepted = userDetails.termConditionAcceptedFlag;
          this.isClaimsFlag = userDetails.caseCreateClaimsFlag;
          this.forecastReminders = userDetails.forecastReminders;
          this.tourService.setTours(userDetails.toursList);
          this.sessionTimeout = userDetails.sessionTime;
        }),
        // google analytics event filtering
        tap(() => {
          this.setAngularticsDeveloperMode();
        }),
        catchError((error) => {
          if (error.status === 400) {
            //this.redirectChooseAccount();
            this.router.navigate(['access-denied']);
          }
          return throwError(() => error);
        })
      )
    );
  }

  public refershAppWithViewAllClients(): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      const jwtResponse = this.getJwtResponse();
      const idToken = this.getIdToken(jwtResponse);
      this.http
        .get(`/api/auth/client/list?id=${idToken.oid}`)
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (err) => {
            reject([]);
          }
        );
    });
    return promise;
  }

  public employeeLoginWithViewAllClients(clientIds: any): Promise<any> {
    // here oms call will goes with
    this.customClientList = true;
    return firstValueFrom(
      this.applicationContext.getOmsInfoByViewAllClient(clientIds).pipe(
        tap(() => {
          const storefronts = this.applicationContext.createStorefrontsAndStore();
          this.storefrontService.storefronts = storefronts;
        }),
        // get billing registration
        concatMap(() => {
          if (this.storefrontService.checkBillingPermissionInAnyStorefront()) {
            return this.billingService.registration();
          } else {
            return of({});
          }
        }),
        // get user details and feature flags
        switchMap(() => {
          return forkJoin({
            userDetails: this.getUserDetails(), // gets user details from MongoDB
            ldFlags: this.launchDarklyService.init(environment.launchDarklyClientId)
          });
        }),
        tap(({ userDetails }: { userDetails: UserDetails }) => {
          const user = this.getCurrentUser();

          this.launchDarklyService.identify(user.sub, user.email, user.name);
          this.isTermsConditionsAccepted = userDetails.termConditionAcceptedFlag;
          this.isClaimsFlag = userDetails.caseCreateClaimsFlag;
          this.forecastReminders = userDetails.forecastReminders;
          this.tourService.setTours(userDetails.toursList);
          this.sessionTimeout = userDetails.sessionTime;
        }),
        // google analytics event filtering
        tap(() => {
          this.setAngularticsDeveloperMode();
        }),
        catchError((error) => {
          if (error.status === 400) {
            //this.redirectChooseAccount();
            this.router.navigate(['access-denied']);
          }
          return throwError(() => error);
        })
      )
    );
  }

  /**
   * Clears local storage items for internal employee when choosing another account
   *
   * @returns void
   */
  public changeAccountClearLocalStorage(): void {
    localStorage.removeItem('selectedAccount');
    localStorage.removeItem('selectedStoreFront');
    localStorage.removeItem('caseList');
  }

  /**
   * Redirects to the choose account screen for internal employees
   *
   * @returns void
   */
  public redirectChooseAccount(): void {
    this.router.navigate(['cc-internal', 'choose-account']);
  }

  /**
   * Forces window location to the CCBS login url
   *
   * @returns
   * void
   */
  public login(): void {
    window.location.href = this.loginCCBSURL;
  }

  /**
   * Forces window location to the CCBS login url
   *
   * @returns
   * void
   */
  public async enterpriseLogin() {
    const client = this.isEnterpriseLogin();
    this.enterpriseSSOClientName = client;
    if (client) {
      const idp = await this.getEnterpriseIdp(client);
      if (idp) {
        const tr = this.oktaTargetResource;
        window.location.href = `${tr}&idp=${idp}`;
      } else {
        this.login();
      }
    } else {
      this.login();
    }
  }

  private isEnterpriseLogin(): string {
    const pathname = location.pathname && location.pathname.startsWith('/enterprise/sso');
    if (pathname) {
      const paths = window.location.pathname.split('/');
      if (paths && paths.length > 0) {
        const enterprise = paths[paths.length - 1];
        this.enterpriseSSOClientName = enterprise ? enterprise.toLowerCase() : null;
        if (enterprise) {
          localStorage.setItem('enterpriseSSOClientName', enterprise.toLowerCase());
        }
        return enterprise ? enterprise.toLowerCase() : null;
      }
    }
    return null;
  }

  /**
   * inbound-fed: get enterprise IDP based on the client
   *
   * @param client global client name
   *
   * @returns String idp value
   */
  private async getEnterpriseIdp(client: string): Promise<any> {
    const req = this.http.get(`api/internal/config/sso/${client}`);
    return firstValueFrom(req.pipe(tap((result) => result))).catch(() => false);
  }

  /**
   * Forces window location to the employee login url
   *
   * @returns void
   */
  public internalLogin(): void {
    window.location.href = this.employeeSSOURL;
  }

  /**
   * azure-ad: Revokes the token then clears local storage
   *
   * @returns Promise of any
   */
  public logout(): Promise<any> {
    const isPbUser = this.isPbAccount();
    const isInternalAccount = this.isInternalAccount();
    // azure-ad: issue Azure OIDC logout for PB employees
    const logoutUrl = isPbUser && isInternalAccount ? this.employeeLogoutURL : this.logoutURL;
    return firstValueFrom(this.revokeToken()).finally(() => {
      this.clearLocalStorage();
      window.location.href = logoutUrl;
    });
  }

  public accessDenied() {
    this.clearLocalStorage();
    this.router.navigate(['access-denied']);
  }

  public enterpriseUserLogout() {
    this.clearLocalStorage();
    this.router.navigate(['enterprise-sso-logout']);
  }
  public provideEnterpriseUserLogin() {
    this.enterpriseSSOClientName = localStorage.getItem('enterpriseSSOClientName');
    return (window.location.href = this.redirectURL + `/enterprise/sso/${this.enterpriseSSOClientName}`);
  }

  /**
   * azure-ad: Gets the access token from the Azure auth API
   *
   * @param code String from window location hash with the login code
   *
   * @returns Observable of HttpResponse with JwtResponse body
   */
  public getAzureToken(code: string): Observable<HttpResponse<JwtResponse>> {
    const redirectUrl = `${this.azureRedirectUrl}/azure/sso/callback`;
    // monorepo-mw: call middleware to exchange code for access token
    return this.http.post<JwtResponse>(
      `api/internal/auth/azure/callback/${code}?redirectUrl=${redirectUrl}`,
      {},
      { observe: 'response' }
    );
  }

  /**
   * Gets the access token from the auth API
   *
   * @param code String from window location hash with the login code
   *
   * @returns Observable of HttpResponse with JwtResponse boy
   */
  public getOktaToken(code: string): Observable<HttpResponse<JwtResponse>> {
    // monorepo-mw: middleware to handle code - token exchange
    return this.http.get<JwtResponse>(`api/internal/auth/okta/callback/${code}?redirectUrl=${this.redirectURL}`, {
      observe: 'response'
    });
  }

  /**
   * Checks if the token and session is valid
   *
   * @returns
   * boolean
   */
  // monorepo-mw: middleware handles this logic
  public isAuthenticated(): Promise<AuthenticatedSession> {
    // call middleware to check the session state
    return firstValueFrom(this.http.get<AuthenticatedSession>('api/internal/session'));
  }

  /**
   * azure-ad: call appropriate revoke token API based on employee/client login
   */
  public revokeToken(): Observable<any> {
    // monorepo-mw: clear middleware session
    return this.http.delete('api/internal/session');
  }

  /**
   * Checks the window location hash exists and includes `#code=`
   *
   * @returns `null` or code string
   */
  public parseHashCode(): null | string {
    const hash = window.location.hash;
    let code = null;

    if (hash && hash !== undefined && hash !== null && hash !== '' && hash.includes('#code=')) {
      code = hash.split('#code=')[1].split('&state=')[0];
    }

    return code;
  }

  /**
   * Checks for a email address fo @mailinator.com or @pitneybowes.com email, and the environment
   * `gaEventFilter`. If true, then set Angulartics2 to developer mode.
   *
   * @returns void
   */
  public setAngularticsDeveloperMode(): void {
    if ((this.isMailinatorAccount() || this.isPbAccount()) && environment.gaEventFilter) {
      this.angulartics.setDeveloperMode();
    }
  }

  /**
   * Get user details
   *
   * @returns Observable of UserDetails
   */
  public getUserDetails(): Observable<UserDetails> {
    return this.http.get<UserDetails>('api/auth/user/info/');
  }

  /**
   * Check for an internal user
   *
   * @returns boolean
   */
  public isPbAccount(): boolean {
    let isPb = false;
    const user = this.getCurrentUser();

    if (user && user.email) {
      isPb = user.email.includes('@pb.com');
    }

    return isPb;
  }

  public isCustomClientList(): boolean {
    let isCustomClientList = false;
    if (this.isPbAccount && this.isInternalAccount && this.customClientList) {
      isCustomClientList = true;
    }
    return isCustomClientList;
  }

  public isMailinatorAccount(): boolean {
    let isMailinator = false;
    const user = this.getCurrentUser();

    if (user && user.email) {
      isMailinator = user.email.includes('@mailinator.com');
    }

    return isMailinator;
  }

  public isInternalAccount() {
    let isInternalAccount = false;

    if (this.idpUser && 'iss' in this.idpUser) {
      isInternalAccount = this.idpUser.iss.startsWith('https://login.microsoftonline.com/');
    }

    return isInternalAccount;
  }

  public isEnterpriseUser(): boolean {
    const jwtResponse = this.getJwtResponse();
    if (jwtResponse !== null) {
      return jwtResponse.enterprise_user;
    } else {
      return false;
    }
  }

  public isReadOnlyAccess(): boolean {
    let isReadOnly = false;
    const jwtResponse = this.getJwtResponse();
    const isInternalAccount = this.isInternalAccount();

    if (isInternalAccount) {
      if (jwtResponse.read_only === true) {
        isReadOnly = true;
      }
    }

    return isReadOnly;
  }

  public isForecastUploadAccess(): boolean {
    let isForecastUpload = false;
    const jwtResponse = this.getJwtResponse();
    const isInternalAccount = this.isInternalAccount();

    if (isInternalAccount) {
      if (jwtResponse.forecast_upload_role === true) {
        isForecastUpload = true;
      }
    }
    return isForecastUpload;
  }

  /**
   * Checks if the jwt access token is expired, returns `true` if the token is expired.
   *
   * @returns
   * boolean
   */
  public isTokenExpired(): boolean {
    const token: JwtResponse = this.getJwtResponse();

    if (token && token !== undefined && token !== null) {
      return this.jwthelper.isTokenExpired(token.access_token);
    }

    return true;
  }

  /**
   * Update session timeout
   *
   * @param sessionTimeout string
   *
   * @returns Observable of any
   */
  public updateSessionTimeout(sessionTimeout: string): Observable<any> {
    return forkJoin({
      sessionTime: this.http.put(`api/auth/user/info/sessionTime/${sessionTimeout}`, null),
      internalSession: this.http.post(`api/internal/session/${sessionTimeout}`, null)
    }).pipe(
      tap(() => {
        this.sessionTimeout = sessionTimeout;
        this.startSessionTimeoutTimer();
      })
    );
  }

  /**
   * Start, or restart, the session timeout timer
   *
   * @note Starts, or restarts, the session timeout timer
   */
  public startSessionTimeoutTimer() {
    if (!!this.sessionTimeoutSubs$) {
      this.sessionTimeoutSubs$.unsubscribe();
    }

    this.idleService.stopTimer();

    this.sessionTimeoutSubs$ = this.idleService.startWatching(this.sessionTimeout).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        const isInternalAccount = this.isInternalAccount();
        if (isInternalAccount) {
          // show session expired page for internal user
          this.clearLocalStorage();
          this.sessionTimeoutSubs$.unsubscribe();
          this.router.navigate(['session-expired']);
        } else if (this.isEnterpriseUser()) {
          this.router.navigate(['enterprise-sso-session-expired']);
        } else {
          this.router.navigate(['logout']);
        }
      }
    });
  }

  public async launchDarklyInit() {
    return this.launchDarklyService.init(environment.launchDarklyClientId);
  }

  public launchDarklyIdentify() {
    const user = this.getCurrentUser();

    this.launchDarklyService.identify(user.sub, user.email, user.name);
  }

  /**
   * azure-ad: call appropriate endpoint (azure/okta) to exchange code for access token.
   *
   * @returns void
   */
  private exchangeCodeFortoken(code: string): Observable<HttpResponse<JwtResponse>> {
    const pathname = window.location.pathname;
    const isAzureCallBack = pathname && pathname.startsWith('/azure/sso/callback');
    if (isAzureCallBack) {
      return this.getAzureToken(code);
    } else {
      return this.getOktaToken(code);
    }
  }

  /**
   * Update the terms and condition status
   *
   * @param isAccepted boolean to set the flag to true or false
   *
   * @returns Observable of any
   */
  public updateTermAndConditionAcceptedFlag(isAccepted: boolean): Observable<any> {
    const url = `api/auth/user/info/tNc/accepted/${isAccepted}`;

    return this.http.put(url, { flag: true }).pipe(
      tap(() => {
        this.isTermsConditionsAccepted = isAccepted;
      })
    );
  }
  /**
   * Update the Case Create Claims Flag status
   * @param  {boolean} flag
   */
  public updateCaseCreateClaimsFlag(flag: boolean): Observable<any> {
    const url = `api/auth/user/info/caseCreateClaims/viewed/${flag}`;

    return this.http.put(url, { flag: true }).pipe(
      tap(() => {
        this.isClaimsFlag = flag;
      })
    );
  }

  /**
   * Update the Case Create Claims Flag status
   * @param  {boolean} flag
   */
  public updatePreferenceForecastFlag(flag: boolean): Observable<any> {
    const url = `api/auth/user/info/forecastReminders/checked/${flag}`;

    // return this.http.put(url, { flag: flag }).pipe(
    //   tap(() => {
    //     this.forecastReminders = flag;
    //   })
    // );

    return this.http.put(url, { flag: flag }).pipe(catchError((err) => this.handleError(err)));
  }

  /**
   * Get or set the terms and conditions accepted property
   *
   * @type boolean
   */
  public get isTermsConditionsAccepted(): boolean {
    return this._isTermsConditionsAccepted;
  }
  public set isTermsConditionsAccepted(isAccepted: boolean) {
    this._isTermsConditionsAccepted = isAccepted;
  }

  /**
   * Get or set the Claims Flag accepted property
   *
   * @type boolean
   */
  public get isClaimsFlag(): boolean {
    return this._isClaimsFlag;
  }
  public set isClaimsFlag(flag: boolean) {
    this._isClaimsFlag = flag;
  }

  /**
   * Get or set the Claims Flag accepted property
   *
   * @type boolean
   */
  public get forecastReminders(): boolean {
    return this._forecastReminders;
  }
  public set forecastReminders(flag: boolean) {
    this._forecastReminders = flag;
  }

  public getCurrentUser(): User {
    return this.user;
  }

  public getIdpUser(): OktaUser | AzureUser {
    return this.idpUser;
  }

  public setCurrentUser(user: OktaUser | AzureUser): void {
    const tempUser = {} as User;

    this.idpUser = user;

    // azure-ad: azure token holds firstName and lastName in give_name and family_name claim
    if ('oid' in user) {
      // remove this condition once Azure has fix that returns family_name claims
      tempUser.id = user.oid;
    }
    if ('family_name' in user && 'given_name' in user) {
      // uncomment below line once Azure has fix that returns family_name claims
      // tempUser.id = user.oid;
      tempUser.firstName = user.given_name;
      tempUser.lastName = user.family_name;
    } else {
      // remvoe tempUser.id default once Azure has fix that returns family_name claims
      tempUser.firstName = user.firstName || tempUser.id || '';
      tempUser.lastName = user.lastName || '';
      tempUser.ccbsLocale = user.ccbsLocale;
    }

    tempUser.name = user.name || '';
    tempUser.email = user.email;
    tempUser.sub = user.sub;

    this.user = tempUser;
  }

  public getJwtResponse(): JwtResponse {
    return JSON.parse(localStorage.getItem('jwt_response'));
  }

  public setJwtResponse(jwtResponse: JwtResponse) {
    localStorage.setItem('jwt_response', JSON.stringify(jwtResponse));
  }

  /**
   *
   * Get or set the session timeout
   *
   * @type string: The session time in milliseconds (i.e. 900, 1800, 3600, etc)
   *
   */
  public get sessionTimeout(): string {
    return this._sessionTimeout;
  }
  public set sessionTimeout(sessionTime: string) {
    this._sessionTimeout = sessionTime;
  }

  /**
   * Clears local storage items
   *
   * @returns void
   */
  public clearLocalStorage(): void {
    localStorage.removeItem('jwt_response');
    localStorage.removeItem('selectedAccount');
    localStorage.removeItem('selectedStoreFront');
    localStorage.removeItem('caseList');
    localStorage.removeItem('commentAddedForCaseId');

    // remove search filter from recent case request
    this.caseService.resetCaseRequestSearchText();
  }

  public getSavedClientListForCEM(): Observable<any> {
    // get the custom client list on home page
    const jwtResponse = this.getJwtResponse();
    const idToken = this.getIdToken(jwtResponse);
    this.launchDarklyService.init(environment.launchDarklyClientId).then(
      (response: any) => {
        /*if anything needs to be done after getting LD flags*/
      },
      (err) => {
        this.handleError(err);
      }
    );
    const url = `/api/auth/client/list?id=${idToken.oid}`;
    return this.http.get(url).pipe(catchError((err) => this.handleError(err)));
  }

  public updateCustomClientList(clientList): Observable<any> {
    const jwtResponse = this.getJwtResponse();
    const idToken = this.getIdToken(jwtResponse);
    const editCustomClientParam = {
      id: idToken.oid,
      email: idToken.email,
      globalClientId: clientList
    };
    return this.http
      .post(`/api/auth/client/list/update`, editCustomClientParam)
      .pipe(catchError((err) => this.handleError(err)));
  }

  public handleError(errorRespose: HttpErrorResponse) {
    let errorResponseMessage: {
      status?: number;
      message?: string;
      isError?: boolean;
      error?: string;
    };
    const errorStatus = errorRespose.status;
    switch (errorStatus) {
      case 401:
        this.router.navigate(['logout']);
        break;
      default:
        errorResponseMessage = {
          error: 'Internal Server Error'
        };
        break;
    }
    return throwError(errorResponseMessage);
  }
}
