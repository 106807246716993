import { PbdsDaterangePreset } from 'pb-design-system/daterange-popover';

export const MAX_CASES_TO_DISPLAY = 1000;

export const COLUMNS = [
  {
    field: 'caseNumber',
    header: 'CASE-LIST.PTABLE.THEAD.CASE-ID',
    sortable: true,
    hidden: true,
    toggle: {
      visible: false,
      selected: true
    }
  },
  {
    field: 'account',
    header: 'CASE-LIST.PTABLE.THEAD.ACCOUNT',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'productName',
    header: 'CASE-LIST.PTABLE.THEAD.SERVICE',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'caseReporter',
    header: 'CASE-LIST.PTABLE.THEAD.CREATED-BY',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'createdDate',
    header: 'CASE-LIST.PTABLE.THEAD.CREATED',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'lastModified',
    header: 'CASE-LIST.PTABLE.THEAD.LAST-MODIFIED',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'reason',
    header: 'CASE-LIST.PTABLE.THEAD.REQUEST',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'summary',
    header: 'CASE-LIST.PTABLE.THEAD.SUMMARY',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'assigned',
    header: 'CASE-LIST.PTABLE.THEAD.ASSIGNED',
    sortable: false,
    hidden: true,
    toggle: {
      visible: true,
      selected: false
    }
  },
  {
    field: 'casePriority',
    header: 'CASE-LIST.PTABLE.THEAD.PRIORITY',
    sortable: true,
    hidden: false,
    toggle: {
      visible: false,
      selected: false
    }
  },
  {
    field: 'status',
    header: 'CASE-LIST.PTABLE.THEAD.STATUS',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: true
    }
  },
  {
    field: 'domesticTrackingNumber',
    header: 'Inbound Tracking #',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: false
    }
  },
  {
    field: 'upid',
    header: 'Universal Parcel ID',
    sortable: true,
    hidden: true,
    toggle: {
      visible: true,
      selected: false
    }
  }
];

export const STATUSES = [
  {
    label: 'CASE-LIST.PTABLE.THEAD.ALLSTATUS',
    value: 'All Status'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.CLOSED',
    value: 'Closed'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.CEMRESPONSENEEDED',
    value: 'CEM Response Needed'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.INPROGRESS',
    value: 'In Progress'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.MERCHANDRESPONSENEEDED',
    value: 'Merchant Response Needed'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.NEW',
    value: 'New'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.OPEN',
    value: 'Open'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.REOPENED',
    value: 'Reopened'
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.RESOLVED',
    value: 'Resolved'
  }
];

export const SERVICES = [
  { id: 'allServices', displayName: 'All Services', label: 'CASE-LIST.PTABLE.THEAD.ALLSERVICES', visible: true },
  {
    id: 'cbrconsulate',
    displayName: 'Cross Border Retail',
    label: 'CASE-LIST.PTABLE.THEAD.CBRCONSUATE',
    visible: false
  },
  { id: 'fdrfulfillment', displayName: 'Fulfillment', label: 'CASE-LIST.PTABLE.THEAD.FDRFULFILLMENT', visible: false },
  { id: 'dds', displayName: 'Delivery', label: 'CASE-LIST.PTABLE.THEAD.DDS', visible: false },
  { id: 'cbds', displayName: 'Cross-Border Delivery', label: 'CASE-LIST.PTABLE.THEAD.CBDS', visible: false },
  { id: 'expdeliver', displayName: 'Expedited Delivery', label: 'CASE-LIST.PTABLE.THEAD.EXPDELIVERY', visible: false },
  { id: 'drs', displayName: 'Returns', label: 'CASE-LIST.PTABLE.THEAD.DRS', visible: false }
];

export const DATERANGE_PRESETS: PbdsDaterangePreset[] = [
  {
    label: 'CASE-LIST.PTABLE.THEAD.ALLDATES',
    value: null
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.LASTSEVENDAYS',
    value: 7
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.LASTTHIRTYDAYS',
    value: 30
  },
  {
    label: 'CASE-LIST.PTABLE.THEAD.YEARTODAYE',
    value: 'YEAR_TO_DATE'
  }
];

export const DATERANGE_FILTERS = [
  { field: 'createdDate', label: 'CASE-LIST.PTABLE.THEAD.CREATED' },
  { field: 'lastModified', label: 'CASE-LIST.PTABLE.THEAD.LAST-MODIFIED' }
];
