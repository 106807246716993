<div class="modal-header">
  <h2 id="modal-comment-form" class="modal-title" [innerHtml]="modalTitle"></h2>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    [disabled]="isPosting"
    angulartics2On="click"
    angularticsAction="Contact Us"
    angularticsCategory="navigation"
    angularticsLabel="Discard Modal"
    (click)="modal.dismiss()"
  >
    <span aria-hidden="true"></span>
  </button>
</div>
<div class="modal-body">
  <div class="alert-wrapper" *ngIf="errorMessage">
    <div class="mt-7 alert alert-danger" l10nTranslate>
      {{ errorMessage }}
    </div>
  </div>
  <h4
    class="mb-3"
    angulartics2On="click"
    angularticsAction="Contact Us"
    angularticsCategory="navigation"
    angularticsLabel="create a case"
    [innerHtml]="modalBody1"
  ></h4>
</div>
<div class="modal-footer">
  <a href="/support/case/create" type="button" class="btn btn-primary" l10nTranslate>HEADER.NAVIGATION.CREATEACASE</a>
  <button
    type="button"
    *ngIf="!isDisableCancelButton"
    class="btn btn-link"
    [disabled]="isPosting"
    angulartics2On="click"
    angularticsAction="Contact Us"
    angularticsCategory="navigation"
    angularticsLabel="Cancel"
    (click)="modal.dismiss()"
    l10nTranslate
  >
    HEADER.HEADERCOMPCONTENT.CANCEL
  </button>
</div>
