import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StorefrontModule } from './storefront/storefront.module';
import { AngularticsModule } from './angulartics/angulartics.module';
import { LaunchDarklyModule } from './launch-darkly/launch-darkly.module';

@NgModule({
  imports: [CommonModule, StorefrontModule, AngularticsModule, LaunchDarklyModule]
})
export class GecSharedServicesModule {}
