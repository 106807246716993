import { Component, OnInit, Inject, LOCALE_ID, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { NgbCalendar, NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../services/utility.service';
import {
  CurrentInventoryFilter,
  CurrentInventoryTree,
  DownloadReportRequest,
  Inventory,
  Product
} from '../models/current-inventory.model';
import { Constants } from '../shared/constants';
import { CurrentInventoryColumns } from './current-inventory-columns';
import { ReportDownloadService } from '../services/report-download.service';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { InventoryService } from '../services/inventory-service';
import { catchError, finalize, of, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'fulfillment-current-inventory',
  templateUrl: './current-inventory.component.html',
  styleUrls: ['./current-inventory.component.css']
})
export class CurrentInventoryComponent implements OnInit, OnDestroy {
  @ViewChild('datePicker') public datePicker?: NgbInputDatepicker;
  @ViewChild('dt') dt!: Table;
  hoveredDate?: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  dateRange: string;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  maxDate = this.calendar.getToday();
  products: Product[] = [];
  inventoryData: Inventory[] = [];
  inventoryRecords = [];
  productSearchList = [];
  skuValue = '';
  cols: any = [];
  totalElements?: number = 0;
  sortOrder = 'sku-desc';
  rowSize = 10;
  startIndex = 0;
  pageNumber = '1';
  currentInventoryFilter: CurrentInventoryFilter;
  downloadRequest?: DownloadReportRequest;
  isLoading = false;
  unsubscription$: Subject<boolean> = new Subject<boolean>();
  searchSKU = this.utilityService.translate('CURRENT_INVENTORY.SEARCH_SKU');
  entriesText = this.utilityService.translate('CURRENT_INVENTORY.ENTRIES');
  serviceQueryParams: any = {
    first: this.startIndex,
    rows: this.rowSize,
    sortField: 'item',
    sortOrder: -1,
    filters: {},
    globalFilter: null
  };
  isSKUSearched: boolean = false;

  sortType = {
    name: 'item',
    sortOrder: -1
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private calendar: NgbCalendar,
    private downloadService: ReportDownloadService,
    private inventoryService: InventoryService,
    private readonly utilityService: UtilityService
  ) {
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 10);
    this.toDate = this.calendar.getToday();
    this.dateRange = `${this.getFormattedDate(this.fromDate)} to ${this.getFormattedDate(this.toDate)}`;
    this.currentInventoryFilter = new CurrentInventoryFilter();
    this.loadInventoryData();
  }

  ngOnInit(): void {
    this.cols = new CurrentInventoryColumns(this.utilityService).COLUMNS;
  }

  ngOnDestroy(): void {
    this.unsubscription$.unsubscribe();
  }

  /**
   * This function is used to get the current inventory data from the API using filters.
   */
  loadInventoryData() {
    this.isLoading = true;
    this.currentInventoryFilter.size = this.rowSize;
    this.currentInventoryFilter.page = (this.serviceQueryParams?.first / this.serviceQueryParams?.rows + 1).toString();
    this.currentInventoryFilter.first = this.startIndex;
    this.currentInventoryFilter.sortOrder = this.sortOrder;
    this.currentInventoryFilter.fromDate = new Date(this.getFormattedDate(this.fromDate)).toISOString();
    this.currentInventoryFilter.toDate = new Date(this.getFormattedDate(this.toDate)).toISOString();
    this.inventoryService
      .getCurrentInventory(this.currentInventoryFilter)
      .pipe(
        tap((res) => {
          this.totalElements = res['totalElements'];
          this.inventoryRecords = res.inventoryRecords;
        }),
        catchError((err) => {
          this.isLoading = false;
          this.utilityService.error('TOAST_MESSAGE.ERROR');
          return of(err);
        }),
        takeUntil(this.unsubscription$),
        switchMap((res) => this.inventoryService.getProductsDetails(this.getRequest(), this.currentInventoryFilter))
      )
      .subscribe({
        next: (res) => {
          this.products = res.products;
          this.generateResponse();
          this.isLoading = false;
        },
        error: (err) => {
          this.products = [];
          this.generateResponse();
          this.isLoading = false;
        }
      });
  }

  getRequest() {
    this.productSearchList = [...new Set(this.inventoryRecords?.map((inventory) => inventory.sku))];
    const request = {
      searchFields: [
        {
          name: 'sku',
          values: this.productSearchList
        },
        {
          name: 'pbCreateDate',
          from: this.getFromDate(),
          to: new Date().toISOString().split('T')[0]
        }
      ]
    };
    return request;
  }

  /**
   * This function is called from UI when date range is changed using date picker.
   */
  dateRangeChange = (date: NgbDate) => {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.dateRange = `${this.getFormattedDate(this.fromDate)}`;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.dateRange = `${this.getFormattedDate(this.fromDate)} to ${this.getFormattedDate(this.toDate)}`;
      this.loadInventoryData();
      this.datePicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.dateRange = `${this.getFormattedDate(this.fromDate)}`;
    }
  };

  getFormattedDate(date: NgbDate) {
    return formatDate(`${date.month}/${date.day}/${date.year}`, 'MM/dd/yyyy', this.locale);
  }

  isHovered = (date: NgbDate) =>
    this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  isInside = (date: NgbDate) => date.after(this.fromDate) && date.before(this.toDate);
  isRange = (date: NgbDate) =>
    date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);

  /**
   * This function is called from UI to search for the records in inventory by sku.
   */
  searchBySKU = (event: any) => {
    if (event.keyCode === 13) {
      this.startIndex = 0;
      this.serviceQueryParams.first = this.startIndex;
      this.currentInventoryFilter.sku = this.skuValue.trim() ? this.skuValue.trim() : '';
      this.isSKUSearched = true;
      this.loadInventoryData();
    }
  };

  loadNodes(event: LazyLoadEvent) {
    let isChanged = !this.isEqual(this.serviceQueryParams, event);
    if (isChanged) {
      this.serviceQueryParams = JSON.parse(JSON.stringify(event));
      this.sortOrder = this.serviceQueryParams.sortOrder === 1 ? 'sku-asc' : 'sku-desc';
      this.rowSize = this.serviceQueryParams.rows;
      this.loadInventoryData();
    }
  }

  isEqual(object1: object, object2: object) {
    try {
      return JSON.stringify(object1) === JSON.stringify(object2);
    } catch (e) {
      return false;
    }
  }
  /**
  * This function will mapped the value obtain from the current inventory API response according to the required schema of the tree table.
  * Schema Example: {
      data?: any;
      children?: TreeNode[];
    }
  */
  private generateResponse() {
    this.inventoryData = [];

    this.inventoryRecords.forEach((element) => {
      let inventoryNode = {
        children: []
      };
      let rowData = {} as CurrentInventoryTree;
      rowData.item = element.sku;
      // product decription from products list
      rowData.productDescription = this.products.find((p) => p.sku === rowData.item)?.details?.description;
      rowData = this.getItemInfo(rowData, element.inventoryTotal);
      inventoryNode['data'] = rowData;

      element.inventoryDetails.forEach((value) => {
        let warehouseNode = {
          data: {} as CurrentInventoryTree,
          children: []
        };
        const name = Constants.WAREHOUSE_LIST.filter((y) => y.id === value.node)
          .map((x) => x.warehouseName)
          .toString();
        warehouseNode.data.item = name || 'Unassigned';
        this.getItemInfo(warehouseNode.data, value.inventoryTypeDetails);
        value.inventoryTypeDetails.forEach((detail) => {
          let lotNode = {};
          let lotData = {} as CurrentInventoryTree;
          if (
            warehouseNode.children.length > 0 &&
            warehouseNode.children.some((el) => el.data.item === detail.lotNumber)
          ) {
            const index = warehouseNode.children.findIndex((el) => el.data.item === detail.lotNumber);
            this.getLotInfo(warehouseNode.children[index].data, detail);
          } else {
            this.getLotInfo(lotData, detail);
            lotData.item =
              '<span>' +
              `${this.utilityService.translate('CURRENT_INVENTORY.LOT')}: <a class = "text-color"> ${
                detail.lotNumber ? detail.lotNumber : ''
              }</a> <br/> <span> ${this.utilityService.translate('CURRENT_INVENTORY.EXPIRES')}: ${
                detail.expiryDate ? new DatePipe('en-US').transform(detail.expiryDate, 'MM-dd-yyyy') : ''
              }</span>` +
              '</span>';
            lotNode['data'] = lotData;
            warehouseNode.children.push(lotNode);
          }
        });
        inventoryNode.children.push(warehouseNode);
      });

      this.inventoryData.push(inventoryNode);
    });
  }

  /**
   * This function will mapped the value of supply type for each lot.
   */
  private getLotInfo(data, detail) {
    switch (detail.supplyType) {
      case 'AVAILABLE':
        data.availableQty = detail.quantity;
        break;
      case 'CURRENT':
        data.currentQty = detail.quantity;
        break;
      case 'PENDING':
        data.heldQty = detail.quantity;
        break;
      case 'DAMAGED':
        data.damagedQty = detail.quantity;
        break;
      case 'EXPIRED':
        data.expiredQty = detail.quantity;
        break;
      case 'RECALLED':
        data.recallQty = detail.quantity;
        break;
      case 'PUTAWAY':
        data.putAwayQty = detail.quantity;
        break;
      case 'KITTING':
        data.heldForKittingQty = detail.quantity;
        break;
      case 'QUARANTINE':
        data.quarantineQty = detail.quantity;
        break;
    }
  }

  private getItemInfo(rowData, data) {
    rowData.availableQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'AVAILABLE')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.currentQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'CURRENT')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.heldQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'PENDING')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.damagedQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'DAMAGED')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.expiredQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'EXPIRED')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.recallQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'RECALLED')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.putAwayQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'PUTAWAY')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.heldForKittingQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'KITTING')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();
    rowData.quarantineQty = data
      .filter((y) => y.supplyType.toUpperCase() === 'QUARANTINE')
      .reduce((sum, x) => sum + x.quantity, 0)
      .toString();

    return rowData;
  }

  downloadExcel(type: string) {
    this.getDownloadRequest();
    switch (type) {
      case 'all':
        this.downloadRequest.searchFields = [];
        break;
      case 'filtered':
        break;
      default:
        break;
    }
    this.downloadService
      .getDownloadData(this.downloadRequest)
      .pipe(
        takeUntil(this.unsubscription$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {},
        error: (err) => {
          this.utilityService.error('TOAST_MESSAGE.DOWNLOADERROR');
        }
      });
  }

  getDownloadRequest() {
    this.downloadRequest = new DownloadReportRequest();
    let search = [];
    if (this.currentInventoryFilter.sku) {
      search = [
        {
          name: 'productsku',
          values: [this.currentInventoryFilter.sku]
        }
      ];
    }
    this.downloadRequest.searchFields = search as [];
    this.downloadRequest.reportType = 'CurrentInventory';
    this.downloadRequest.from = this.currentInventoryFilter.fromDate;
    this.downloadRequest.to = this.currentInventoryFilter.toDate;
  }

  handleSearchInput(event: any) {
    //to reset table data when user clicks on cross icon of search sku field
    if (!event.data && event.data !== null && this.isSKUSearched) {
      //reset variables
      this.currentInventoryFilter.sku = '';
      this.isSKUSearched = false;
      //make API request
      this.loadInventoryData();
    }
  }

  initializeQueryParams(): any {
    return {
      first: this.startIndex,
      rows: this.rowSize,
      sortField: 'item',
      sortOrder: 1,
      filters: {},
      globalFilter: null
    };
  }

  getFromDate(): string {
    const current = new Date();
    const setYears = current.getFullYear() - 3;
    console.log(new Date(current.setFullYear(current.getFullYear() - 3)).toISOString().split('T')[0]);
    return new Date(current.setFullYear(setYears)).toISOString().split('T')[0];
  }
}
