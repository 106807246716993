<pbds-page-title l10nTranslate ttl="INVENTORY_ACTIVITY.TITLE" layout="container-fluid"></pbds-page-title>
<div class="container-fluid">
  <div *ngIf="showWarning" class="alert alert-warning" role="alert">
    {{ warningMessage }}
    <i
      class="pbi-icon-mini lg ml-2 pbi-info-circle-open"
      style="color: #605077"
      aria-hidden="true"
      ngbTooltip="Currently only Product SKU filter is being supported. Enter the product SKU in the serach text box to get filtered data"
      placement="auto"
      triggers="click hover focus blur"
      placement="right auto"
    ></i>
  </div>
  <div class="row">
    <div class="col">
      <div class="toolbar">
        <div class="toolbar-left">
          <div ngbDropdown>
            <button
              class="btn btn-secondary btn-sm mr-2 ml-2"
              id="export"
              ngbDropdownToggle
              aria-label="Export"
              l10nTranslate
            >
              <i class="pbi-icon-mini pbi-download" l10nTranslate></i>
              INVENTORY_ACTIVITY.DOWNLOAD
            </button>
            <div ngbDropdownMenu aria-labelledby="export">
              <button class="dropdown-item" (click)="download('all')" l10nTranslate>
                INVENTORY_ACTIVITY.DOWNLOAD_ALL_DATA
              </button>
              <button class="dropdown-item" (click)="download('filtered')" l10nTranslate *ngIf="rowData.length != 0">
                INVENTORY_ACTIVITY.DOWNLOAD_FILTERED_DATA
              </button>
            </div>
          </div>
        </div>

        <div class="toolbar-right">
          <!-- with Reactive Form -->
          <pbds-daterange-popover
            #daterange
            [minDate]="daterangeMinDate"
            [applyText]="applyTextLocalization"
            [cancelText]="cancelTextLocalization"
            [customRangeText]="customRangeTextLocalization"
            [presets]="daterangePresets"
            [presetSelected]="daterangePresetSelected"
            [filters]="daterangeFilters"
            (dateChange)="onDaterangeChange($event)"
          ></pbds-daterange-popover>

          <form [formGroup]="calendarForm"></form>
          <div class="toolbar-divider">&nbsp;</div>

          <div class="input-group">
            <div class="input-group-text">
              <span class="input-group-text" id="basic-addon4" aria-label="Search">
                <i class="pbi-icon-mini pbi-search" aria-hidden="true"></i>
              </span>
            </div>
            <input
              type="search"
              class="form-control"
              placeholder="{{ searchSKU }}"
              aria-label="Search"
              aria-describedby="basic-addon4"
              [(ngModel)]="productSKU"
              (keypress)="searchBySKU($event)"
              (input)="handleSearchInput($event)"
            />
          </div>
          <div class="toolbar-divider">&nbsp;</div>
          <pbds-column-toggle [columns]="cols" [minimum]="1"> </pbds-column-toggle>
        </div>
      </div>
      <div class="table-responsive">
        <p-table
          [value]="rowData"
          responsiveLayout="scroll"
          [paginator]="true"
          [rows]="rowSize"
          [totalRecords]="totalElements"
          [showCurrentPageReport]="true"
          [currentPageReportTemplate]="
            totalElements ? 'Showing {first} to {last} of ' + totalElements + ' entries' : ''
          "
          [rowsPerPageOptions]="[10, 25, 50, 100]"
          [lazy]="true"
          [lazyLoadOnInit]="false"
          [autoLayout]="true"
          dataKey="inventoryTimeStamp"
          rowExpandMode="single"
          (onLazyLoad)="nextPage($event)"
          [loading]="isLoading"
          [sortOrder]="sortType.sortOrder === 'asc' ? 1 : -1"
          [sortField]="sortType.name"
          [(first)]="first"
          tableStyleClass="table-open table-hover"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 0.1em" scope="col"></th>
              <ng-container *ngFor="let col of cols">
                <ng-container>
                  <th
                    *ngIf="col.sortable && col.toggle.selected"
                    pSortableColumn="inventoryTimeStamp"
                    [ngStyle]="{ 'text-align': col.align }"
                  >
                    {{ col.header }} <p-sortIcon [field]="'inventoryTimeStamp'"></p-sortIcon>
                  </th>
                  <th *ngIf="col.toggle.selected && !col.sortable" scope="col" [ngStyle]="{ 'text-align': col.align }">
                    {{ col.header }}
                    <i
                      class="pbi-icon-mini pbi-info-circle text-info"
                      ngbTooltip="{{ col.tooltipMsg }}"
                      placement="top"
                      triggers="hover focus:blur"
                      container="body"
                      *ngIf="col.isTooltip"
                    ></i>
                  </th>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
            <tr>
              <td>
                <a
                  href="#"
                  [pRowToggler]="rowData"
                  class="table-row-toggle"
                  [attr.aria-label]="expanded ? 'Collapse row' : 'Expand row'"
                >
                  <i
                    [ngClass]="expanded ? 'pbi-icon-mini pbi-minimal-down' : 'pbi-icon-mini pbi-minimal-right'"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
              <ng-container *ngFor="let col of cols; let i = index">
                <td *ngIf="col.toggle.selected" allign="center" [ngStyle]="{ 'text-align': col.align }">
                  <div>
                    <a *ngIf="col.header === 'Item'"> {{ rowData['productDescription'] }} </a>
                  </div>
                  <div *ngIf="col.header !== 'Timestamp'">{{ rowData[col.field] }}</div>
                  <div *ngIf="col.header === 'Timestamp'">
                    {{ rowData[col.field] | date : 'MM/dd/YYYY h:mm' }}
                    {{ rowData[col.field] | date : 'a' | lowercase }}
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" let-i="rowIndex">
            <tr id="rowexpandid" class="table-details bg-white" aria-live="polite" role="alert">
              <td colspan="13">
                <p class="sr-only">Row {{ i + 1 }} details</p>

                <div class="container-fluid ml-5">
                  <div class="row">
                    <div class="col" style="border-right: solid 1px #c1bcbc">
                      <div class="container">
                        <div class="row">
                          <div class="col-6">
                            <small class="" l10nTranslate>INVENTORY_ACTIVITY.CUSTOMERORDERID</small>
                          </div>
                          <div class="col">{{ rowData['shipmentOrderID'] ? rowData['shipmentOrderID'] : '-' }}</div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <small class="" l10nTranslate>INVENTORY_ACTIVITY.SHIPMENTID</small>
                          </div>
                          <div class="col">{{ rowData['shipmentId'] ? rowData['shipmentId'] : '-' }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col" style="border-right: solid 1px #c1bcbc">
                      <div class="container">
                        <div class="row">
                          <div class="col-6">
                            <small class="" l10nTranslate>INVENTORY_ACTIVITY.LOTNAME</small>
                          </div>
                          <div class="col">{{ rowData['lotName'] ? rowData['lotName'] : '-' }}</div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <small class="" l10nTranslate>INVENTORY_ACTIVITY.EXPIRES</small>
                          </div>
                          <div class="col">
                            {{ rowData['expirationDate'] ? (rowData['expirationDate'] | date : 'MM/dd/YYYY') : '-' }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col">
                      <div class="container">
                        <div class="row">
                          <div class="col-6">
                            <small l10nTranslate>INVENTORY_ACTIVITY.EXPIRY_WINDOW</small>
                          </div>
                          <div class="col">
                            {{
                              rowData['productLotExpirationThreshold'] ? rowData['productLotExpirationThreshold'] : '-'
                            }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <small l10nTranslate>INVENTORY_ACTIVITY.MANUFACTURED</small>
                          </div>
                          <div class="col">
                            {{ rowData['manufactureDate'] ? (rowData['manufactureDate'] | date : 'MM/dd/YYYY') : '-' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!isLoading">
              <td class="text-center" colspan="13">
                <div class="empty-state empty-state-search">
                  <div *ngIf="rowData?.length === 0">
                    <div class="empty-state-icon" aria-hidden="true">
                      <img src="assets/images/commonIcon/empty-state-icon.svg" />
                    </div>
                    <div class="empty-state-head" l10nTranslate>INVENTORY_ACTIVITY.NO-RESULTS-FOUND</div>
                    <div class="empty-state-body" l10nTranslate>INVENTORY_ACTIVITY.TRY-OTHER-FILTERS</div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div style="visibility: hidden; height: 150px"></div>
      </div>
    </div>
  </div>
</div>
