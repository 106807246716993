import { Component } from '@angular/core';
import { AuthService } from '../../services/auth-service/auth.service';

@Component({
  selector: 'app-enterprise-sso-logout',
  templateUrl: './enterprise-sso-logout.component.html'
})
export class EnterpriseSsoLogoutComponent {
  constructor(private readonly authService: AuthService) {}

  public signInWithSSO() {
    this.authService.provideEnterpriseUserLogin();
  }
}
