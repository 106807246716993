import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { ApplicationContext } from '@app/shared/services/application-context/application-context';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';
import { LaunchDarklyService } from '@gec-shared-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsCanLoadGuard implements CanLoad {
  private readonly featureFlags = this.launchDarklyService.getFlags();
  constructor(
    private readonly router: Router,
    private readonly applicationContext: ApplicationContext,
    private readonly notAllowedService: NotAllowedService,
    private readonly launchDarklyService: LaunchDarklyService
  ) {}
  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    let isAnalyticsModuleLoad: boolean = false;
    if (this.featureFlags.ui_Header_Analytics) {
      const productOrgInfo = this.applicationContext.productOrgInfo;

      productOrgInfo.productOrganizations.forEach((el) => {
        if (!isAnalyticsModuleLoad) {
          el.organizations.forEach((ele) => {
            if (ele.permissions.includes('CC_INSIGHTS_VIEW') || ele.permissions.includes('CC_ANALYTICS_VIEW') || ele.permissions.includes('CC_ANALYTICS_EDR')) {
              isAnalyticsModuleLoad = true;
              return true;
            }
          });
        }
      });
    }
    if (!isAnalyticsModuleLoad || !this.featureFlags.ui_Header_Analytics) {
      // redirect if user does not have user management access
      this.notAllowedService.setMessage({
        pageTitle: 'NOT-ALLOWED.ANALYTICS.PAGE-TITLE',
        title: 'NOT-ALLOWED.ANALYTICS.TITLE',
        summary: 'NOT-ALLOWED.ANALYTICS.SUMMARY',
        url: ''
      });

      this.router.navigate(['not-allowed']);
    }

    return isAnalyticsModuleLoad;
  }
}
