import { UtilityService } from '../services/utility.service';

export class InventoryActivityColumns {
  constructor(private utilityService: UtilityService) {}

  COLUMNS = [
    {
      field: 'inventoryTimeStamp',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.TIMESTAMP'),
      sortable: true,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'productSku',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.ITEM'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: false,
        selected: true
      }
    },
    {
      field: 'lotName',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.LOT'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: false,
        selected: false
      }
    },
    {
      field: 'reason',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.REASON'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'action',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.ACTION'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'qty',
      align: 'right',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.QTY'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'inventoryType',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.INVENTORY_TYPE'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: true
      }
    },
    {
      field: 'runningTotal',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.RUNNING_TOTAL'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: false,
        selected: false
      }
    },
    {
      field: 'noteText',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.NOTES'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'warehouseID',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.PB_FACILITY'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'productValue',
      align: 'right',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.VALUE'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'shipmentOrderID',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.ORDER'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'manifestID',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.MANIFEST_ID'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    },
    {
      field: 'shipmentPO',
      align: 'left',
      header: this.utilityService.translate('INVENTORY_ACTIVITY.PO'),
      sortable: false,
      hidden: false,
      toggle: {
        visible: true,
        selected: false
      }
    }
  ];
}
