import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
export interface Storefront {
  productIds: string[];
  organizationIds: string[];
  globalClientId: string;
  groupdId: string;
  showSelfbillsFor: string[];
  isshowSelfbillsForOrg: boolean;
  name: string;
  financeAppIds: StorefrontConfig[];
  dataAppIds: StorefrontConfig[];
  forecastAppIds: StorefrontConfig[];
  supportAppIds: StorefrontConfig[];
  selected: boolean;
  roles: string[];
  isParent: boolean;
  isCustomChild: boolean;
  isSingleStore: boolean;
  inList: boolean;
  isVisibleToUser: boolean;
  productOrganizationId: string;
  parentVisibleToUser: boolean;
  visible_on_client_connect: boolean;
  isBoxpoll: boolean;
  isDochub: boolean;
  isUserManagement: boolean;
  isStartReturn: boolean;
  vanityUrl: string;
}

export interface StorefrontConfig {
  id: string;
  name: string;
  productId: string;
  permission: string;
  hideScreens: string[];
  organizationName: string;
  vanityURL: string;
  productOrganizationId: string;
  type: string;
  organizationId: string;
}

const USERROLES = [
  {
    name: 'analytics',
    roles: ['CC_INSIGHTS_VIEW', 'CC_ANALYTICS_VIEW', 'CC_ANALYTICS_EDR']
  },
  {
    name: 'billing',
    roles: ['CC_BILLING_VIEW']
  },
  {
    name: 'support',
    roles: ['CC_SUPPORT_VIEW', 'CC_SUPPORT_UPDATE', 'CC_SUPPORT_CREATE']
  },
  {
    name: 'support-update',
    roles: ['CC_SUPPORT_UPDATE', 'CC_SUPPORT_CREATE']
  },
  {
    name: 'support-create',
    roles: ['CC_SUPPORT_CREATE']
  },
  {
    name: 'billing-make-payment',
    roles: ['CC_BILLING_MAKEPAYMENT']
  }
];

@Injectable({
  providedIn: 'root'
})
export class StorefrontService {
  public readonly _state: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly state: Observable<Storefront> = this._state.asObservable();
  private readonly userRoles = USERROLES;
  private currentStorefront: Storefront;
  private isSameStorefront = true;
  private currentProductIds: string[];
  private isSameProductIds = true;
  private _storefronts: Storefront[];
  private subject = new Subject();
  public showFFWarning: boolean = false;

  public sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  public getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  public omsIds = '';
  public customClientStore: any = {
    globalClientId: '00000',
    globalClientName: 'All My Clients'
  };
  constructor() {
    const selectedStorefront: Storefront = this.getSelectedStorefront();

    // set the selected storefront
    if (!selectedStorefront) {
      const storefront = this.storefronts[0];
      this.set(storefront);
    } else {
      this.set(selectedStorefront);
    }
  }

  /**
   * Get the selected storefront when the menu is changed
   *
   * @returns Observable<Storefront>
   */
  public get(): Observable<Storefront> {
    return this.state;
  }

  /**
   * Get the currently selected storefront from localstorage
   *
   * @returns Storefront
   */
  public getSelectedStorefront(): Storefront {
    if (this.currentStorefront) {
      return this.currentStorefront;
    }

    let localStorageStorefront = localStorage.getItem('selectedStoreFront');
    let storefront: Storefront;

    if (localStorageStorefront !== 'undefined') {
      storefront = JSON.parse(localStorageStorefront);
    } else {
      storefront = this.storefronts[0];
    }

    return storefront;
  }

  /**
   * Set the selected storefront.
   *
   * @param storefront Storefront
   *
   * @returns void
   */
  public set(storefront: Storefront): void {
    const currentStorefront = this.currentStorefront;
    const selectedStorefront = storefront;
    let areStorefrontArraysEqual = false;
    let areStorefrontNamesEqual = false;

    // if there is a current storefront, check if it matches the selected storefront
    if (currentStorefront) {
      areStorefrontArraysEqual = this.checkIfTwoArraysAreEqual(
        currentStorefront.organizationIds,
        selectedStorefront.organizationIds
      );

      areStorefrontNamesEqual = currentStorefront.name === selectedStorefront.name;
    }

    // if there is a current storefront, check if it matches the selected storefront
    if (currentStorefront && areStorefrontArraysEqual && areStorefrontNamesEqual) {
      this.isSameStorefront = true;
    } else {
      this.isSameStorefront = false;
    }

    localStorage.setItem('selectedStoreFront', JSON.stringify(storefront));

    this.currentStorefront = storefront;
    this._state.next(storefront);
  }

  /**
   * Check if there is only one storefront
   *
   * @returns boolean
   */
  public isSingleStorefront(): boolean {
    const storefronts = this.storefronts;

    if (storefronts && storefronts.length === 1) {
      return true;
    }

    return false;
  }

  /**
   * Set the selected productIds
   *
   * @param productIds string[]
   *
   * @returns void
   */
  public setProductIds(productIds: string[]): void {
    const areProductIdArraysEqual = this.checkIfTwoArraysAreEqual(this.currentProductIds, productIds);

    if (this.currentProductIds && !areProductIdArraysEqual) {
      this.isSameProductIds = false;
    } else if (this.currentStorefront && areProductIdArraysEqual) {
      this.isSameProductIds = true;
    }

    this.currentProductIds = productIds;
  }

  /**
   * Check if self bills is allowed for the selected storefront and selected product ids
   *
   * @note The selected storefront and product ids come from local storage
   *
   * @returns boolean
   */
  public isSelfBills(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    const productIds: string[] = storefront.productIds;
    let isSelfBills = false;

    if (!!productIds && productIds.length > 0 && !!storefront) {
      productIds.forEach((productId) => {
        if (storefront.showSelfbillsFor.includes(productId) || storefront.isshowSelfbillsForOrg) {
          isSelfBills = true;
          return;
        }
      });
    }

    return isSelfBills;
  }

  /**
   * Check estimated delivery report permission for selected storefront
   *
   * @returns boolean
   */
  public isEdr(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isEdrVisible = false;

    storefront.dataAppIds.forEach((config: StorefrontConfig) => {
      const type = config.type.toLowerCase();
      if (type === 'data.edr' && !isEdrVisible && config.productId.toLowerCase() === 'drs') {
        isEdrVisible = true;
      }
    });

    return isEdrVisible;
  }

  /**
   * Check if RVP is enabled for the selected account
   *
   *  @returns boolean
   */
  public isRvp(): boolean {
    const storeFront: Storefront = this.getSelectedStorefront();
    let isRVPVisible = false;

    storeFront.dataAppIds.forEach((val) => {
      if (val.type.toLowerCase() === 'data.rvp' && val.productId.toLowerCase() === 'drs') {
        isRVPVisible = true;
        return isRVPVisible;
      }
    });

    return isRVPVisible;
  }

  /**
   * Check induction report permission for selected storefront
   *
   * @returns boolean
   */
  public isInductReport(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isInductPackageVisible = false;

    storefront.dataAppIds.forEach((config: StorefrontConfig) => {
      const type: string = config.type.toLowerCase();
      if (
        type === 'data.induct' &&
        !isInductPackageVisible &&
        config.id.toLowerCase() === 'true' &&
        config.productId.toLowerCase() === 'dds'
      ) {
        isInductPackageVisible = true;
      }
    });

    return isInductPackageVisible;
  }

  /**
   *
   */
  public hasForecastRole(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let parentStoreFront;
    const storefronts = this.storefronts;
    let storefrontCount = 0;
    for (let eachStoreFront of storefronts) {
      if (eachStoreFront.globalClientId === storefront.globalClientId) {
        storefrontCount++;
        if (eachStoreFront.isParent || storefrontCount === 1) {
          parentStoreFront = eachStoreFront;
        }
      }
    }
    let roles = parentStoreFront.roles.filter((role) => role.toLowerCase() === 'cc_capture_client_forecast_readwrite');

    return roles.length > 0;
  }

  /**
   * Check forecast report permission for selected storefront
   *
   * @returns boolean
   */
  public isForecastReport(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let parentStoreFront;
    let isForecastsVisible = false;
    const storefronts = this.storefronts;
    let storefrontCount = 0;
    for (let eachStoreFront of storefronts) {
      if (eachStoreFront.globalClientId === storefront.globalClientId) {
        storefrontCount++;
        if (eachStoreFront.isParent || storefrontCount === 1) {
          parentStoreFront = eachStoreFront;
        }
      }
    }
    let roles = parentStoreFront.roles.filter((role) => role.toLowerCase() === 'cc_capture_client_forecast_readwrite');
    parentStoreFront.forecastAppIds.forEach((config: StorefrontConfig) => {
      const type: string = config.type.toLowerCase();
      if (
        type === 'capture.client.forecast' &&
        config.id.toLowerCase() === 'true' &&
        !isForecastsVisible &&
        roles.length > 0
      ) {
        isForecastsVisible = true;
      }
    });

    return isForecastsVisible;
  }

  /**
   * Check if user has edr view permission
   * and selected store front combination.
   *
   * @returns boolean
   */
  public checkCycleTimePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isCycleTimeVisible = false;
    storefront.dataAppIds.forEach((val) => {
      if (
        val.type.toLowerCase() === 'data.cycletime' &&
        val.id.toLowerCase() === 'true' &&
        val.productId.toLowerCase() === 'dds'
      ) {
        isCycleTimeVisible = true;
        return isCycleTimeVisible;
      }
    });
    return isCycleTimeVisible;
  }
  /**
   * Check if user has cbds view permission
   * and selected store front combination.
   *
   * @returns boolean
   */
  public checkCbdsCycleTimePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isCbdsCycleTimeVisible = false;
    storefront.dataAppIds.forEach((val) => {
      if (
        val.type.toLowerCase() === 'data.cycletime' &&
        val.id.toLowerCase() === 'true' &&
        val.productId.toLowerCase() === 'cbds'
      ) {
        isCbdsCycleTimeVisible = true;
        return isCbdsCycleTimeVisible;
      }
    });
    return isCbdsCycleTimeVisible;
  }

  /**
   * Check if user has edr view permission
   * and selected store front combination.
   *
   * @returns boolean
   */
  public checkExpeditedDeliveryCycleTimePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isExpeditedDeliveryCycleTimeVisible = false;
    storefront.dataAppIds.forEach((val) => {
      if (
        val.type.toLowerCase() === 'data.expdel_cycle' &&
        val.id.toLowerCase() === 'true' &&
        val.productId.toLowerCase() === 'expdeliver'
      ) {
        isExpeditedDeliveryCycleTimeVisible = true;
        return isExpeditedDeliveryCycleTimeVisible;
      }
    });
    return isExpeditedDeliveryCycleTimeVisible;
  }

  /**
   * Check if expedited delivery is enabled for the selected storefront
   *
   *  @returns boolean
   */
  public isExpeditedDelivery(): boolean {
    const showExpeditedDelivery =
      this._state.getValue().productIds && this._state.getValue().productIds.includes('expdeliver');
    return false || showExpeditedDelivery;
  }

  /**
   * Check if CBRConsulate is enabled for the selected storefront
   *
   *  @returns boolean
   */
  public isCBRConsulate(): boolean {
    return false || (this._state.getValue().productIds && this._state.getValue().productIds.includes('cbrconsulate'));
  }

  /**
   * Check if CBDS is enabled for the selected storefront
   *
   *  @returns boolean
   */
  public isCBDS(): boolean {
    return false || (this._state.getValue().productIds && this._state.getValue().productIds.includes('cbds'));
  }

  /**
   * Check if the selected storefront is the same as the previously selected storefront
   * @returns boolean
   */
  public getSameStorefront(): boolean {
    return this.isSameStorefront;
  }

  /**
   * Check if the selected products is the same as the previously selected products
   * @returns boolean
   */
  public getSameProductIds(): boolean {
    return this.isSameProductIds;
  }

  /**
   * Method to set if the current storefront matches the selected storefront
   *
   * @param isSameStorefront boolean
   */
  public setSameStorefront(isSameStorefront: boolean): void {
    this.isSameStorefront = isSameStorefront;
  }

  /**
   * Get all storefronts
   *
   * @deprecated Use the property getter instead, for example this.storefrontService.storefronts
   *
   * @returns Array of Storefronts
   */
  public getStorefronts(): Storefront[] {
    return this.storefronts;
  }

  /**
   * Set the list of all Storefronts
   *
   * @param storefronts Array of Storefront
   *
   * @returns void
   */
  set storefronts(storefronts: Storefront[]) {
    this._storefronts = storefronts;
  }

  /**
   * Get the list of all Storefronts
   *
   * @returns Storefront[] or empty array
   */
  get storefronts(): Storefront[] {
    return this._storefronts || [];
  }

  /**
   * Force selection of the first storefront
   *
   * @returns void
   */
  public selectFirstStorefront(): void {
    const storefront = this.storefronts[0];
    this.set(storefront);
  }

  /**
   * Get organization name for particular client id
   *
   * @note The selected store front information comes from local storage
   * User can selected multiple product ids which can belong to different organizations,
   * and name of the organization will also be different.
   *
   * @param clientId ClientId
   *
   * @returns string
   */
  public getOrganizationName(clientId: string): string {
    const storefront: Storefront = this.getSelectedStorefront();
    let config: StorefrontConfig;

    storefront.supportAppIds.forEach((storefrontConfig: StorefrontConfig) => {
      if (storefrontConfig.id === clientId) {
        config = storefrontConfig;
      }
    });

    return config.organizationName;
  }

  /**
   * Check if Returns Cycle Time Reports is enabled for the selected account
   *
   *  @returns boolean
   */
  public isReturnsCycleTimeReport(): boolean {
    // debugger;
    const showCycleTimeReport = this.checkReturnsCycleTimePermission();
    return false || showCycleTimeReport;
  }

  public checkReturnsCycleTimePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isCycleTimeVisible = false;
    let isBiReportsVisible = false;

    storefront.dataAppIds.forEach((storefrontConfig: StorefrontConfig) => {
      if (
        storefrontConfig.type.toLowerCase() === 'data.cycletime' &&
        storefrontConfig.id.toLowerCase() === 'true' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isCycleTimeVisible = true;
      }

      if (
        storefrontConfig.type.toLowerCase() === 'data.bi' &&
        storefrontConfig.name.toLowerCase() === 'merchantorgkey' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isBiReportsVisible = true;
      }
    });

    return isCycleTimeVisible && isBiReportsVisible;
  }

  public checkreturnsDeliveredPackagesPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isReturnsDeliveredPackages = false;
    let isBiReportsVisible = false;

    storefront.dataAppIds.forEach((storefrontConfig: StorefrontConfig) => {
      if (
        storefrontConfig.type.toLowerCase() === 'data.returnsdeliveredpackages' &&
        storefrontConfig.id.toLowerCase() === 'true' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isReturnsDeliveredPackages = true;
      }

      if (
        storefrontConfig.type.toLowerCase() === 'data.bi' &&
        storefrontConfig.name.toLowerCase() === 'merchantorgkey' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isBiReportsVisible = true;
      }
    });

    return isReturnsDeliveredPackages && isBiReportsVisible;
  }

  public checkReturnsPackageActivityPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isReturnsPackageActivity = false;
    let isBiReportsVisible = false;

    storefront.dataAppIds.forEach((storefrontConfig: StorefrontConfig) => {
      if (
        storefrontConfig.type.toLowerCase() === 'data.returnspackageactivity' &&
        storefrontConfig.id.toLowerCase() === 'true' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isReturnsPackageActivity = true;
      }

      if (
        storefrontConfig.type.toLowerCase() === 'data.bi' &&
        storefrontConfig.name.toLowerCase() === 'merchantorgkey' &&
        storefrontConfig.productId.toLowerCase() === 'drs'
      ) {
        isBiReportsVisible = true;
      }
    });

    return isReturnsPackageActivity && isBiReportsVisible;
  }

  public getSelectedProductIds(): string[] {
    return this.getSelectedStorefront().productIds;
  }

  /**
   * check if any store front have billing access
   *
   * @returns boolean
   */
  public checkBillingPermissionInAnyStorefront(): boolean {
    let isBillingPermissionFound = false;
    const storefronts = this.storefronts;

    if (storefronts !== null) {
      storefronts.find((storefront: Storefront) => {
        if (storefront.financeAppIds.length > 0) {
          isBillingPermissionFound = true;
        }
        return isBillingPermissionFound;
      });
    }
    return isBillingPermissionFound;
  }

  /**
   * Checking if current screen is visible or not
   *
   * @param zone : Zone name i.e. billing, support, insights
   * @param screenName : Screen Name
   *
   * @returns boolean
   */
  public checkIfScreenVisible(zone: string, screenName: string, storefront?: Storefront): boolean {
    let productIds: string[];
    if (!storefront) {
      storefront = this.getSelectedStorefront();
      productIds = storefront.productIds;
    } else {
      productIds = storefront.productIds;
    }

    let storefrontConfig: StorefrontConfig[];
    if (zone === 'billing') {
      storefrontConfig = storefront.financeAppIds;
    } else if (zone === 'support') {
      storefrontConfig = storefront.supportAppIds;
    } else {
      storefrontConfig = storefront.dataAppIds;
    }
    if (!!storefrontConfig) {
      const filteredProductIds =
        screenName === 'self-bills'
          ? productIds.filter((e) => storefront.showSelfbillsFor.includes(e) || storefront.isshowSelfbillsForOrg)
          : productIds;
      const filteredStorefronts = storefrontConfig.filter(
        (e) => filteredProductIds.includes(e.productId) && !e.hideScreens.includes(screenName)
      );
      return filteredStorefronts.length > 0;
    }
    return false;
  }

  /**
   * Check if self bill allowed for selected store fronts
   * and selected product ids
   *
   * @note The selected store front information comes from local storage
   * The selected product ids information comes from local storage
   *
   * @returns boolean
   */
  public checkSelfBillAllowed(storefront?: Storefront): boolean {
    storefront = storefront ? storefront : this.getSelectedStorefront();
    const productIds: string[] = storefront.productIds;
    let selfBillFlag = false;
    if (!!productIds && productIds.length > 0 && !!storefront) {
      productIds.forEach((productId) => {
        if (storefront.showSelfbillsFor.includes(productId) || storefront.isshowSelfbillsForOrg) {
          selfBillFlag = true;
          return;
        }
      });
    }
    return selfBillFlag;
  }

  private validateUserRole(selectedUserRoles = [], name: string): boolean {
    const selectedApp = this.userRoles.find((e) => e.name === name);
    let visibleRoles = false;
    if (selectedApp === undefined || selectedUserRoles === undefined) {
      return false;
    }
    selectedUserRoles.forEach((role: string) => {
      const selectedAppRoles = selectedApp.roles;
      if (selectedAppRoles.includes(role)) {
        visibleRoles = true;
      }
    });
    return visibleRoles;
  }

  private checkUserRoleForId(id: string, requireRole: string, config: StorefrontConfig[]): boolean {
    let role: string;
    if (!!config) {
      config.forEach((val) => {
        if (val.id === id) {
          role = val.permission;
        }
      });
    }
    return !!role ? this.validateUserRole([role], requireRole) : false;
  }

  private checkUserRoleForProductIds(requireRole: string, config: StorefrontConfig[], productIds: string[]) {
    const roles: string[] = [];
    if (!!productIds && productIds.length > 0 && !!config) {
      productIds.forEach((productId) => {
        config.forEach((val) => {
          if (val.productId === productId) {
            roles.push(val.permission);
          }
        });
      });
    }
    return !!roles ? this.validateUserRole([...new Set(roles)], requireRole) : false;
  }

  /**
   * Check if user has billing view permission for particular BPN
   * and selected store front combination.
   *
   * @note The selected store front information comes from local storage
   *
   * @param bpn BPN
   *
   * @returns boolean
   */
  public checkBillingViewPermissionForBPN(bpn: string, storefront?: Storefront): boolean {
    if (storefront) {
      return this.checkUserRoleForId(bpn, 'billing', storefront.financeAppIds);
    } else {
      const selectedStorefront: Storefront = this.getSelectedStorefront();
      return this.checkUserRoleForId(bpn, 'billing', selectedStorefront.financeAppIds);
    }
  }

  /**
   * Check if selected store front is having billing view permission
   * else check if any of the store front is having billing view permission
   * set first as selected store front having the view permission
   * @returns boolean
   */
  public checkAndSelectFirstSFwithBillingViewPermission(isSelfBillingPage?: boolean): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    if (storefront === null) {
      return this.checkAndSelectFirstSFwithBillingPermission(isSelfBillingPage);
    } else {
      return this.checkUserRoleForProductIds('billing', storefront.financeAppIds, storefront.productIds);
    }
  }

  /**
   * Check if user has support update permission on perticular client id
   *
   * @note The selected store front information comes from local storage
   *
   * @param clientId ClientId
   *
   * @returns boolean
   */
  public checkSupportUpdatePermissionForClientId(clientId: string): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    return this.checkUserRoleForId(clientId, 'support-update', storefront.supportAppIds);
  }

  /**
   * Check if user has support view permission on perticular client id
   *
   * @note The selected store front information comes from local storage
   *
   * @param clientId ClientId
   *
   * @returns boolean
   */
  public checkSupportViewPermissionForClientId(clientId: string, storefront?: Storefront): boolean {
    if (storefront) {
      return this.checkUserRoleForId(clientId, 'support', storefront.supportAppIds);
    } else {
      const selectedStorefront: Storefront = this.getSelectedStorefront();
      return this.checkUserRoleForId(clientId, 'support', selectedStorefront.supportAppIds);
    }
  }

  /**
   * Check if user has support view permission for selected product ids
   * and selected store front combination.
   *
   * @note The selected store front information comes from local storage
   * The selected product ids information comes from local storage
   *
   * @returns boolean
   */
  public checkSupportViewPermission(storefront?: Storefront): boolean {
    if (storefront) {
      return this.checkUserRoleForProductIds('support', storefront.supportAppIds, storefront.productIds);
    } else {
      const selectedStorefront: Storefront = this.getSelectedStorefront();
      return selectedStorefront
        ? this.checkUserRoleForProductIds('support', selectedStorefront.supportAppIds, selectedStorefront.productIds)
        : true;
    }
  }

  /**
   * Check if user has support create permission for selected product ids
   * and selected store front combination.
   *
   * @note The selected store front information comes from local storage
   * The selected product ids information comes from local storage
   *
   * @returns boolean
   */
  public checkSupportCreatePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    /**
     * Selected store front can be null on very first url hit(deep link URL),
     * if return false in that case user will stuck on blank page
     * and he/she will have to hit refresh again to load login page
     */
    return storefront !== null
      ? this.checkUserRoleForProductIds('support-create', storefront.supportAppIds, storefront.productIds)
      : true;
  }

  /**
   * Check if user has billing view permission for selected product ids
   * and selected store front combination.
   *
   * @note The selected store front information comes from local storage
   * The selected product ids information comes from local storage
   *
   * @returns boolean
   */
  public checkBillingViewPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    /**
     * Selected store front can be null on very first url hit(deep link URL),
     * if return false in that case user will stuck on blank page
     * and he/she will have to hit refresh again to load login page
     */
    return storefront !== null
      ? this.checkUserRoleForProductIds('billing', storefront.financeAppIds, storefront.productIds)
      : true;
  }

  /**
   * Get bpn list
   *
   * @note Check billing view permission on all BPNs
   *
   * @param configs StorefrontConfig[]
   * @param selectedProductIds string[]
   * @param screenName string
   *
   * @returns boolean
   */
  public filterBPNs(configs: StorefrontConfig[], selectedProductIds: string[], screenName: string): string[] {
    const ids = [];
    for (const config of configs) {
      if (
        selectedProductIds.includes(config.productId) &&
        this.checkBillingViewPermissionForBPN(config.id) &&
        this.checkIfStorefrontVisibleForSpecificId(config.id, config.productId, screenName, configs)
      ) {
        let bpn = config.id;
        // Remove leading zeros if the account contains alphabet characters and starts with '00'
        if (/[a-zA-Z]/.test(bpn) && bpn.startsWith('00')) {
          bpn = bpn.substring(2);
        }
        ids.push(bpn);
      }
    }
    return ids;
  }

  /**
   * Check if store front is visible based on screen name show/hide config
   *
   * @param id
   * @param productId
   * @param screenName
   * @param storefrontConfig
   */
  public checkIfStorefrontVisibleForSpecificId(
    id: string,
    productId: string,
    screenName: string,
    storefrontConfig: StorefrontConfig[]
  ): boolean {
    const filteredStorefronts = storefrontConfig.filter(
      (e) => e.id === id && e.productId === productId && e.hideScreens.includes(screenName)
    );
    return filteredStorefronts.length === 0;
  }

  /**
   * Check if selected store front is having support view permission For ClientId
   * @param clientId CLIENTID
   * @returns string
   */
  public checkAndSelectFirstSFwithSupportViewPermissionForClientId(clientId: string) {
    const storefronts = this.storefronts;
    let isValid = false;
    for (const storefront of storefronts) {
      const supportAppIds = storefront.supportAppIds.filter((supportAppId) => supportAppId.id === clientId);
      if (
        (!storefront.isParent && supportAppIds.length) ||
        (storefront.isParent && supportAppIds.length && storefronts.length === 1)
      ) {
        isValid = this.checkSupportViewPermissionForClientId(supportAppIds[0].id, storefront);
        // Required for deep linking when store front is not selected yet
        if (isValid && !this.getSelectedStorefront()) {
          this.set(storefront);
        }
        break;
      }
    }
    return isValid;
  }

  /**
   * Check if selected store front is having support view permission
   * else check if any of the store front is having support view permission
   * Set first as selected store front having the view permission
   * @returns boolean
   */
  public checkAndSelectFirstSFwithSupportViewPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    if (storefront === null) {
      return this.checkAndSelectFirstSFwithSupportPermission('support');
    } else {
      return this.checkUserRoleForProductIds('support', storefront.supportAppIds, storefront.productIds);
    }
  }

  /**
   * Check if selected store front is having support create permission
   * else check if any of the store front is having support create permission
   * set first as selected store front having the create permission
   * @returns boolean
   */
  public checkAndSelectFirstSFwithSupportCreatePermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    if (storefront === null) {
      return this.checkAndSelectFirstSFwithSupportPermission('support-create');
    } else {
      return this.checkSupportCreatePermission();
    }
  }

  /**
   * Check if user has insights view permission
   * and selected store front combination.
   *
   * @note The selected store front information comes from local storage
   * The selected product ids information comes from local storage
   *
   * @returns boolean
   */
  public checkAnalyticsViewPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    /**
     * Selected store front can be null on very first url hit(deep link URL),
     * if return false in that case user will stuck on blank page
     * and he/she will have to hit refresh again to load login page
     */
    return storefront !== null
      ? this.checkUserRoleForProductIds('analytics', storefront.dataAppIds, storefront.productIds)
      : true;
  }

  /**
   * Get data ids list
   *
   * @note Check insight view permission on all data ids
   *
   * @param configs StorefrontConfig[]
   *
   * @returns any
   */
  public filterDataIds(configs: StorefrontConfig[]): any {
    const inductIds = [];
    const edrIds = [];
    const rvpIds = [];
    const biIds = [];
    const cbdsIds = [];
    const expeditedIds = [];
    const fulfillmentIds = [];
    for (const config of configs) {
      if (config.type.toLowerCase() === 'data.induct') {
        if (config.name.toLowerCase() === 'merchantorgkey' && this.checkAnalyticsViewPermission()) {
          inductIds.push(config.id);
        }
      } else if (config.type.toLowerCase() === 'data.bi') {
        if (config.name.toLowerCase() === 'merchantorgkey' && this.checkAnalyticsViewPermission()) {
          config.id.split('|').forEach((conf) => {
            if (config.productId == 'drs') {
              if (!biIds.includes(conf)) {
                biIds.push(conf);
              }
            } else if (config.productId == 'cbds') {
              if (!cbdsIds.includes(conf)) {
                cbdsIds.push(conf);
              }
            }
          });
        } else {
          if (config.name.toLowerCase() === 'organizationid' && this.checkAnalyticsViewPermission()) {
            expeditedIds.push(config.id);
          }
        }
      } else if (config.type.toLowerCase() === 'data.edr') {
        if (config.name.toLowerCase() === 'shiptoname' && this.checkAnalyticsViewPermission()) {
          config.id.split('|').forEach((conf) => {
            if (!edrIds.includes(conf)) {
              edrIds.push(conf);
            }
          });
        }
      } else if (config.type.toLowerCase() === 'data.rvp') {
        if (config.name.toLowerCase() === 'shiptoname' && this.checkAnalyticsViewPermission()) {
          config.id.split('|').forEach((conf) => {
            if (!rvpIds.includes(conf)) {
              rvpIds.push(conf);
            }
          });
        }
      } else if (config.type.toLowerCase() === 'data.fulfillment') {
        if (config.name.toLowerCase() === 'merchantid' && this.checkAnalyticsViewPermission()) {
          fulfillmentIds.push(config.id);
        }
      }
    }
    return { inductIds, biIds, edrIds, rvpIds, expeditedIds, fulfillmentIds, cbdsIds };
  }

  public checkRVPPermission(): boolean {
    const storeFront: Storefront = this.getSelectedStorefront();
    let isRVPVisible = false;
    storeFront.dataAppIds.forEach((val) => {
      if (val.type.toLowerCase() === 'data.rvp' && val.productId.toLowerCase() === 'drs') {
        isRVPVisible = true;
        return isRVPVisible;
      }
    });
    return isRVPVisible;
  }

  /**
   * Check Velocity report permission for selected storefront
   *
   * @returns boolean
   */
  public isFFVelocityReport(): boolean {
    let isFFVelocityReportVisible = false;
    let isFFConfigAvailable = false;
    let isFFVisible = false;
    let isFFVelocity = false;
    const storefront: Storefront = this.getSelectedStorefront();

    const isFFProductIdAvailable = storefront.productIds.includes('fdrfulfillment');

    storefront.dataAppIds.forEach((config: StorefrontConfig) => {
      if (config.type.toLowerCase() === 'data.fulfillment') {
        isFFVisible = true;
      }

      if (
        config.type.toLowerCase() === 'data.fulfillment_velocity' &&
        config.id.toLowerCase() === 'true' &&
        !isFFVelocityReportVisible
      ) {
        isFFVelocity = true;
      }

      isFFConfigAvailable = isFFVisible && isFFVelocity;
    });

    isFFVelocityReportVisible = isFFProductIdAvailable && isFFConfigAvailable;
    return isFFVelocityReportVisible;
  }

  public checkRVPDailyPredictionPermission(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isRVPDailyPredictionVisible = false;
    storefront.dataAppIds.forEach((val) => {
      if (val.type.toLowerCase() === 'data.rvp_daily') {
        isRVPDailyPredictionVisible = true;
        return isRVPDailyPredictionVisible;
      }
    });
    return isRVPDailyPredictionVisible;
  }

  public checkPermissionForEDRDeliveryRange(): boolean {
    const storefront: Storefront = this.getSelectedStorefront();
    let isEstimatedDeliveryRangeVisible = false;
    storefront.dataAppIds.forEach((val) => {
      if (val.name.toLowerCase() === 'estimateddelivery_range') {
        isEstimatedDeliveryRangeVisible = true;
        return isEstimatedDeliveryRangeVisible;
      }
    });
    return isEstimatedDeliveryRangeVisible;
  }

  /**
   * Filter store fronts who all have support create case permission
   *
   * @param storefronts Storefront[]
   *
   * @returns Storefront[]
   */
  public filterStorefrontsWithCaseCreatePermission(storefronts: Storefront[]): Storefront[] {
    const finalStorefronts = [];
    storefronts.forEach((val) => {
      const filter = val.supportAppIds.filter((e) => e.permission === 'CC_SUPPORT_CREATE');
      if (filter.length > 0) {
        finalStorefronts.push(val);
      }
    });
    return finalStorefronts;
  }

  /**
   * Filter store fronts who all have doc hub role
   *
   * @param storefronts Storefront[]
   *
   * @returns Storefront[]
   */
  public filterStorefrontsWithDocHubUploadPermission(storefronts: Storefront[]): Storefront[] {
    const finalStorefronts = [];
    storefronts.forEach((val) => {
      if (val.isDochub) {
        finalStorefronts.push(val);
      }
    });
    return finalStorefronts;
  }

  /**
   * Filter productIds which have support create case permission
   *
   * @param productIds string[]
   * @param storefront Storefront
   *
   * @returns productIds string[]
   */
  public filterProductIdsWithCaseCreatePermission(productIds: string[], storefront: Storefront): string[] {
    const finalProductIds = [];

    productIds.forEach((val) => {
      const conf = storefront.supportAppIds.filter((e) => e.productId === val)[0];
      if (!!conf && conf.permission === 'CC_SUPPORT_CREATE') {
        finalProductIds.push(val);
      }
    });

    return finalProductIds;
  }

  /**
   * Get client ids list
   *
   * @note Check support view permission on all client ids
   *
   * @param configs StorefrontConfig[]
   * @param selectedProductIds string[]
   *
   * @returns boolean
   */
  public filterClientIds(configs: StorefrontConfig[], selectedProductIds: string[]): string[] {
    const ids = [];
    for (const config of configs) {
      if (selectedProductIds.includes(config.productId) && this.checkSupportViewPermissionForClientId(config.id)) {
        ids.push(config.id);
      }
    }
    return ids;
  }

  /**
   * Get client id for select product id
   *
   * @param configs StorefrontConfig[]
   * @param productId string
   *
   * @returns boolean
   */
  public getSingleClientIdForSelectedProductId(configs: StorefrontConfig[], productId: string): string {
    const config = configs.find((e) => e.productId === productId);
    return !!config ? config.id : undefined;
  }

  /**
   * Check if any of the store front having billing permission
   * @returns boolean
   */
  private checkAndSelectFirstSFwithBillingPermission(isSelfBillingPage?: boolean): boolean {
    let isBillingPermissionFound = false;
    const storefronts = this.storefronts;
    if (storefronts) {
      const firstBillingStorefront = storefronts.find((strFrnt) => {
        const ids = [];
        for (const config of strFrnt.financeAppIds) {
          if (
            strFrnt.productIds.includes(config.productId) &&
            this.checkBillingViewPermissionForBPN(config.id, strFrnt)
          ) {
            if (!isSelfBillingPage) {
              ids.push(config.id);
            } else if (
              this.checkSelfBillAllowed(strFrnt) &&
              this.checkIfScreenVisible('billing', 'self-bills', strFrnt)
            ) {
              ids.push(config.id);
            }
          }
        }
        return ids.length > 0;
      });
      if (firstBillingStorefront) {
        this.set(firstBillingStorefront);
        isBillingPermissionFound = true;
      }
    }
    return isBillingPermissionFound;
  }

  /**
   * Check if any of the store fronts having support view/crete permission
   * Pass permission type support | support-create as parameter
   * @param permissionType string
   * @returns boolean
   */
  private checkAndSelectFirstSFwithSupportPermission(permissionType: string): boolean {
    let isSupportedPermissionFound = false;
    const storefronts = this.storefronts;
    if (storefronts !== null) {
      const firstSupportedStorefront = storefronts.find((storefront: Storefront) => {
        const ids = [];
        for (const config of storefront.supportAppIds) {
          if (storefront.productIds.includes(config.productId)) {
            if (
              permissionType === 'support-create' &&
              this.checkUserRoleForId(config.id, 'support-create', storefront.supportAppIds)
            ) {
              ids.push(config.id);
            } else if (
              permissionType === 'support' &&
              this.checkSupportViewPermissionForClientId(config.id, storefront)
            ) {
              ids.push(config.id);
            }
          }
        }
        return ids.length > 0;
      });
      if (firstSupportedStorefront) {
        this.set(firstSupportedStorefront);
        isSupportedPermissionFound = true;
      }
    }
    return isSupportedPermissionFound;
  }

  /**
   * Check if two strings arrays are equal
   *
   * @param firstArray
   * @param secondArray
   *
   * @returns boolean
   */
  private checkIfTwoArraysAreEqual(firstArray: string[], secondArray: string[]): boolean {
    if (firstArray === undefined && secondArray === undefined) {
      return true;
    } else if (firstArray === undefined || secondArray === undefined) {
      return false;
    } else {
      return JSON.stringify(firstArray.sort()) === JSON.stringify(secondArray.sort());
    }
  }
}
