import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';

import { PasswordDetails } from '../modal/change-password-modal/change-password-modal.component';
import { UserDetails } from '../modal/contact-information-modal/contact-information-modal.component';

export interface Preferences {
  dataSubjectId: string;
  purposes: Purposes[];
}

export interface Purposes {
  id: string;
  label: string;
  description: string;
  status: string;
  transactionStatus: 'ACTIVE' | 'WITHDRAWN';
}

export interface PreferencesUpdate {
  errors: any[];
  mssg: string;
  status: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PreferenceManagementService {
  public userNameObservable = new Subject();
  public onUserNameChanged$ = this.userNameObservable.asObservable();

  public passwordChangedObservable = new Subject();
  public passwordChanged$ = this.passwordChangedObservable.asObservable();

  constructor(private readonly http: HttpClient) {}

  /**
   * Get the profile for a user by email address
   *
   * @returns Observable of Preferences
   */
  public getUserProfile(): Observable<Preferences> {
    return this.http.get<Preferences>('api/preferenceManagement/preferenceCentre/profiles');
  }

  /**
   * Post updated preferences by the users email address
   *
   * @param ids Array of purpose ids
   * @param email String of the users email address
   * @returns Observable of PreferencesUpdate
   */
  public updatePreferences(ids: string[]): Observable<PreferencesUpdate> {
    return this.http.post<PreferencesUpdate>('api/preferenceManagement/preferenceCentre/updatePreference/', ids);
  }

  public updatePassword(passwordDetails: PasswordDetails) {
    return this.http.post('api/preferenceManagement/preferenceCentre/profiles/changePassword', passwordDetails);
  }

  public getLastPasswordChangedDate() {
    return this.http.get('api/preferenceManagement/preferenceCentre/profiles/getLastPasswordChangedDate');
  }

  public updateUserProfile(userDetails: UserDetails) {
    return this.http.post('api/preferenceManagement/preferenceCentre/profiles/updateUserProfile', userDetails);
  }

  // Get user to mfa group
  public getMFAUserDetails() {
    return this.http.get('api/mfa/user');
  }

  // add user to mfa group
  public addUserToMFAGroup() {
    return this.http.put('api/mfa/user', null);
  }

  // remove user from mfa group
  public removeUserFromMFAGroup() {
    return this.http.delete('api/mfa/user');
  }
}
