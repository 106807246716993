import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';

import { Observable } from 'rxjs';

import { StorefrontService } from '@gec-shared-services';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';

@Injectable({
  providedIn: 'root'
})
export class DocHubCanLoadGuard implements CanLoad {
  constructor(
    private readonly router: Router,
    private readonly notAllowedService: NotAllowedService,
    private readonly storefrontService: StorefrontService
  ) {}
  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    const selectedStorefront = this.storefrontService.getSelectedStorefront();
    const isDocHubPermission = selectedStorefront.isDochub;

    if (!isDocHubPermission) {
      // redirect if user does not have user management access
      this.notAllowedService.setMessage({
        pageTitle: 'NOT-ALLOWED.DOC-HUB.PAGE-TITLE',
        title: 'NOT-ALLOWED.DOC-HUB.TITLE',
        summary: 'NOT-ALLOWED.DOC-HUB.SUMMARY',
        url: ''
      });

      this.router.navigate(['not-allowed']);
    }

    return isDocHubPermission;
  }
}
