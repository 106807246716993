import { NgModule } from '@angular/core';
import { GecCoreModule } from '@gec-core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { L10nTranslationModule, L10nIntlModule } from 'angular-l10n';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { FeatureToggleModule } from 'ngx-feature-toggle';

import { FulfillmentMenuComponent } from './fulfillment-menu.component';

@NgModule({
    declarations: [FulfillmentMenuComponent],
  imports: [GecCoreModule],
    exports: [FulfillmentMenuComponent]
})
export class FulfillmentMenuModule {}
