import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CurrentInventoryComponent } from './current-inventory/current-inventory.component';
import { HeaderComponent } from '@app/shared';
import { InventoryActivityComponent } from './inventory-activity/inventory-activity.component';
import { BackorderedComponent } from './backordered/backordered.component';
import { InventoryVelocityComponent } from './inventory-velocity/inventory-velocity.component';

const routes: Routes = [
  {
    path: 'current-inventory',
    component: CurrentInventoryComponent
  },
  {
    path: 'inventory-activity',
    component: InventoryActivityComponent
  },
  {
    path: 'inventory-velocity',
    component: InventoryVelocityComponent
  },
  {
    path: 'backordered',
    component: BackorderedComponent
  },
  {
    path: '',
    outlet: 'header',
    component: HeaderComponent
  },
  { path: 'receiving', loadChildren: () => import('./receiving/receiving.module').then((m) => m.ReceivingModule) },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then((m) => m.OrderModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FulfillmentRoutingModule {}
