import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';
import { Observable } from 'rxjs';
import { StorefrontService, LaunchDarklyService } from '@gec-shared-services';

@Injectable({
  providedIn: 'root'
})
export class ForecastCanLoadGuard implements CanLoad {
  private readonly featureFlags = this.launchDarklyService.getFlags();

  constructor(
    private readonly router: Router,
    private readonly notAllowedService: NotAllowedService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly storefrontService: StorefrontService
  ) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    let isForecastModuleLoad: boolean = false;
    if (this.featureFlags.featureForecast) {
      const isForecastsReport = this.storefrontService.isForecastReport();
      if (isForecastsReport) {
        isForecastModuleLoad = true;
      }
    }
    if (!isForecastModuleLoad || !this.featureFlags.featureForecast) {
      this.notAllowedService.setMessage({
        pageTitle: 'NOT-ALLOWED.FORECASTS.PAGE-TITLE',
        title: 'NOT-ALLOWED.FORECASTS.TITLE',
        summary: 'NOT-ALLOWED.FORECASTS.SUMMARY',
        url: ''
      });

      this.router.navigate(['not-allowed']);
    }

    return isForecastModuleLoad;
  }
}
