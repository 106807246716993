import { Injectable } from '@angular/core';

import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver/src/FileSaver';

import { StorefrontService, AngularticsService } from '@gec-shared-services';
import { CaseRequest } from '@app/shared/services/support-service/support.service';
import { PbdsDaterangePreset, PbdsDaterangeChange, PbdsDaterangePresetValue } from 'pb-design-system/daterange-popover';
import { SERVICES } from '@app/support/content/case-list-constants';
import { AuthService } from '@app/shared/services/auth-service/auth.service';

const CASEREQUEST = {
  prodNameAndClientIds: {
    supportAppId: ''
  },
  productIds: [],
  sortBy: 'createdDate',
  sortOrder: 'DESC',
  statusFilter: '',
  serviceFilter: '',
  createdByFilter: '',
  include: '',
  dateFilter: ''
} as CaseRequest;

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public dataForDownload: any[];
  public productIdObjList = SERVICES;

  public constructor(
    private readonly angularticsService: AngularticsService,
    private readonly storefrontService: StorefrontService,
    private readonly ngbCalendar: NgbCalendar,
    private readonly ngbDateParserFormatter: NgbDateParserFormatter,
    private readonly authService: AuthService
  ) {}

  public exportCSV(allData: boolean = false, caseList: any[], cols: any[], omIds: string, priorityFlag: any) {
    this.dataForDownload = [];
    if (allData) {
      this.prepareDownloadDataForAllCols(caseList, priorityFlag);
    } else {
      this.prepareDownloadDataForSelectedCols(caseList, cols, priorityFlag);
    }

    const replacer = (key, value) => (value === null ? 'NA' : value); // specify how you want to handle null values here
    const header = Object.keys(this.dataForDownload[0]);
    const csv = this.dataForDownload.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, `cases-${this.getFormattedDate()}.csv`);

    if (allData === false) {
      this.angularticsService.eventTrack(
        'case list - table action',
        'downloaded cases',
        `export visible columns as csv##${omIds}##`
      );
    } else {
      this.angularticsService.eventTrack(
        'case list - table action',
        'downloaded cases',
        `export all data as csv##${omIds}##`
      );
    }
  }

  /**
   * Creates a DateRangeChange object from a Case Request.
   * If there is no date range in the Case Request, or there is no recent Case Request, defaults to the past seven days.
   *
   * @param daterangePresets Array of presets used in the Date Range Popup component
   *
   * @returns
   * DateRangeChange
   */
  public createDateRangeObjectFromPreviouslySelectedFilters(
    daterangePresets: PbdsDaterangePreset[]
  ): PbdsDaterangeChange {
    const today: NgbDate = this.ngbCalendar.getToday();
    const sevenDaysAgo: NgbDate = this.ngbCalendar.getPrev(today, 'd', 7);
    const caseRequest: CaseRequest = this.getRecentCaseRequest() || this.createCaseRequest();

    let filterSelected: number = 1;
    let days: null | PbdsDaterangePresetValue = null;
    let toDate: NgbDateStruct = today;
    let fromDate: NgbDateStruct = sevenDaysAgo;
    let isCustom: boolean = false;

    // set the filter selected
    if (caseRequest) {
      filterSelected = daterangePresets.findIndex((f) => f.value === caseRequest.days);
    }

    // set from date
    if (caseRequest.fromDate && caseRequest.fromDate !== '') {
      if (caseRequest.dateSelection === 'CUSTOM') {
        fromDate = this.ngbDateParserFormatter.parse(caseRequest.fromDate);
      } else if (caseRequest.days === 'YEAR_TO_DATE') {
        fromDate = NgbDate.from({ year: today.year, month: 1, day: 1 });
      } else if (caseRequest.days === 30) {
        fromDate = this.ngbCalendar.getPrev(today, 'd', 30);
      }
    }

    // set selected end date
    if (caseRequest.endDate && caseRequest.endDate !== '' && caseRequest.dateSelection === 'CUSTOM') {
      toDate = this.ngbDateParserFormatter.parse(caseRequest.endDate);
    }

    // set custom
    if (caseRequest && caseRequest.dateSelection === 'CUSTOM') {
      isCustom = true;
    }

    /* Handle the following for case request days:
    //
    // if days is null, keep as null for "All Dates"
    //
    // if days is undefined (not in the case request) and the date selection is not defined (not "CUSTOM"),
    // set to the default of 7 days
    //
    // otherwise set the days equal to the case request (i.e. 7, 30, Year to Date)
    */
    if (caseRequest.days === undefined && !caseRequest.dateSelection) {
      days = 7;
    } else {
      days = caseRequest.days;
    }

    this.fromDate = NgbDate.from(fromDate);
    this.toDate = NgbDate.from(toDate);

    return {
      fromDate: this.fromDate,
      toDate: this.toDate,
      formattedDate: filterSelected !== -1 ? daterangePresets[filterSelected].label : '',
      filter: caseRequest && caseRequest.dateFilter ? caseRequest.dateFilter : 'createdDate',
      value: isCustom ? 'CUSTOM' : days
    };
  }

  public createCaseRequest() {
    const account = this.storefrontService.getSelectedStorefront();
    const caseRequest = this.getRecentCaseRequest() || CASEREQUEST;

    caseRequest.productIds = account.productIds;
    caseRequest.prodNameAndClientIds.supportAppId = account.supportAppIds;
    caseRequest.serviceFilter = this.productIdObjList[0];
    caseRequest.statusFilter = '';
    caseRequest.createdByFilter = '';

    return caseRequest;
  }

  public setDateFilterInRequest(caseRequest: CaseRequest, days: number, fromDate?: NgbDate, endDate?: NgbDate) {
    const resp = this.selectedDaysCount(days, fromDate, endDate);

    caseRequest.fromDate = resp.fromDate;
    caseRequest.endDate = resp.endDate;

    return caseRequest;
  }

  public prepareDownloadDataForAllCols(caseList: any[], priorityFlag: any) {
    const isInternalUser = this.authService.isInternalAccount();
    caseList.forEach((el) => {
      const tableData = {};
      tableData['Case ID'] = el.caseNumber;
      tableData['Account'] = el.account;
      tableData['Service'] = el.productName;
      tableData['Created By'] = el.caseReporter;
      tableData['Created By Email'] = el.createdBy;
      tableData['Created'] = el.createdDate;
      tableData['Assigned To'] = el.assignTo;
      tableData['Last Modified'] = el.lastModified;
      tableData['Last Modified By'] = el.lastModifiedBy;
      tableData['Request'] = el.reason;
      tableData['Summary'] = el.summary;
      tableData['Status'] = el.status;
      if (isInternalUser && priorityFlag) {
        tableData['Priority'] = el.casePriority;
      }

      if (el.trackingNumber != null) {
        tableData['Tracking Number'] = "'" + el.trackingNumber + "'";
      } else {
        tableData['Tracking Number'] = el.trackingNumber;
      }
      if (el.merchantOrderNumber) {
        tableData['Order Number'] = "'" + el.merchantOrderNumber + "'";
      } else {
        tableData['Order Number'] = el.merchantOrderNumber;
      }
      if (el.shipmentNumber) {
        tableData['Shipment ID'] = "'" + el.shipmentNumber + "'";
      } else {
        tableData['Shipment ID'] = el.shipmentNumber;
      }
      if (el.clientReferenceNumber) {
        tableData['Reference Number'] = "'" + el.clientReferenceNumber + "'";
      } else {
        tableData['Reference Number'] = el.clientReferenceNumber;
      }
      if (el.containerNumber) {
        tableData['Container Number'] = "'" + el.containerNumber + "'";
      } else {
        tableData['Container Number'] = el.containerNumber;
      }
      if (el.lotNumber) {
        tableData['Lot Number'] = "'" + el.lotNumber + "'";
      } else {
        tableData['Lot Number'] = el.lotNumber;
      }
      if (el.itemSKUNumber) {
        tableData['SKU'] = "'" + el.itemSKUNumber + "'";
      } else {
        tableData['SKU'] = el.itemSKUNumber;
      }
      if (el.invoiceNumber != null) {
        tableData['Invoice Number'] = "'" + el.invoiceNumber + "'";
      } else {
        tableData['Invoice Number'] = el.invoiceNumber;
      }
      if (el.domesticTrackingNumber != null) {
        tableData['Inbound Tracking Number'] = "'" + el.domesticTrackingNumber + "'";
      } else {
        tableData['Inbound Tracking Number'] = el.domesticTrackingNumber;
      }
      if (el.upid != null) {
        tableData['Universal Parcel ID'] = "'" + el.upid + "'";
      } else {
        tableData['Universal Parcel ID'] = el.upid;
      }
      this.dataForDownload.push(tableData);
    });
  }

  public prepareDownloadDataForSelectedCols(caseList: any[], cols, priorityFlag: any) {
    let fields: any = localStorage.getItem('caseListColumns');
    fields = fields !== undefined ? JSON.parse(fields) : cols;
    const isInternalUser = this.authService.isInternalAccount();

    const selectedFields = fields.map((val) => {
      if (val.toggle.selected === true) {
        return val.field;
      }
    });

    caseList.forEach((el) => {
      const tableData = {};
      if (selectedFields.includes('caseNumber')) {
        tableData['Case ID'] = el.caseNumber;
      }
      if (selectedFields.includes('account')) {
        tableData['Account'] = el.account;
      }
      if (selectedFields.includes('productName')) {
        tableData['Service'] = el.productName;
      }
      if (selectedFields.includes('caseReporter')) {
        tableData['Created By'] = el.caseReporter;
      }
      if (selectedFields.includes('createdDate')) {
        tableData['Created'] = el.createdDate;
      }
      if (selectedFields.includes('assigned')) {
        tableData['Assigned'] = el.assignTo;
      }
      if (selectedFields.includes('lastModified')) {
        tableData['Last Modified'] = el.lastModified;
      }
      if (selectedFields.includes('reason')) {
        tableData['Request'] = el.reason;
      }
      if (selectedFields.includes('summary')) {
        tableData['Summary'] = el.summary;
      }
      if (selectedFields.includes('status')) {
        tableData['Status'] = el.status;
      }
      if (selectedFields.includes('casePriority') && isInternalUser && priorityFlag) {
        tableData['Priority'] = el.casePriority;
      }
      if (selectedFields.includes('domesticTrackingNumber')) {
        if (el.domesticTrackingNumber != null) {
          tableData['Inbound Tracking Number'] = "'" + el.domesticTrackingNumber + "'";
        } else {
          tableData['Inbound Tracking Number'] = el.domesticTrackingNumber;
        }
      }
      if (selectedFields.includes('upid')) {
        if (el.upid != null) {
          tableData['Universal Parcel ID'] = "'" + el.upid + "'";
        } else {
          tableData['Universal Parcel ID'] = el.upid;
        }
      }

      this.dataForDownload.push(tableData);
    });
  }

  public getCaseList(): any[] {
    return JSON.parse(localStorage.getItem('caseList'));
  }

  public setCaseList(caseList: any[]): void {
    localStorage.setItem('caseList', JSON.stringify(caseList));
  }

  public getRecentCaseRequest(): CaseRequest {
    return JSON.parse(localStorage.getItem('caseRequest'));
  }

  public setRecentCaseRequest(caseRequest: any): void {
    localStorage.setItem('caseRequest', JSON.stringify(caseRequest));
  }

  /**
   * Clears the search text from the recent case request and case list table state
   *
   * @note The primeng table local storage state will not be updated by this method.
   * Update in the component class as needed. For example, `delete this.dt.filters.global;`
   *
   * @param caseRequest Optionally pass a caseRequest object to clear the search text.
   * Will read the recent case request from local storage if not passed.
   *
   * @returns void
   */
  public resetCaseRequestSearchText(caseRequest = null): void {
    caseRequest = caseRequest || this.getRecentCaseRequest();

    // remove search filter from case list
    if (caseRequest) {
      // remove the search filter from caseRequest
      delete caseRequest.searchFilter;
      // reset the case request page on change of storefront
      caseRequest.page = 1;
      this.setRecentCaseRequest(caseRequest);
    }
  }

  /**
   * Returns todays date as a string without hypens,
   * for example `20200819` is four digit year, then two digit month, then two digit day
   *
   * @returns
   * string
   */
  private getFormattedDate(): string {
    const today: NgbDate = this.ngbCalendar.getToday();
    const todayString: string = this.ngbDateParserFormatter.format(today).replace(/-/g, '');

    return todayString;
  }

  /**
   * Returns strings for `fromDate` and `endDate`. If no fromDate passed, will default to today's date.
   * If no endDate, will be the number of `days` prior to the fromDate.
   *
   * @param days number of days prior to current date
   * @param fromDate optional from date as NgbDate
   * @param endDate optional end date as NgbDate
   *
   * @returns
   * Object with fromDate and endDate strings in the format yyyy-MM-dd
   */
  private selectedDaysCount(
    days: number,
    fromDate?: NgbDate,
    endDate?: NgbDate
  ): { fromDate: string; endDate: string } {
    const today: NgbDate = this.ngbCalendar.getToday();

    fromDate = fromDate || this.ngbCalendar.getPrev(today, 'd', days);
    endDate = endDate || this.ngbCalendar.getToday();

    // convert NgbDate to string
    const fromDateString: string = this.ngbDateParserFormatter.format(fromDate);
    const endDateString: string = this.ngbDateParserFormatter.format(endDate);

    return {
      fromDate: fromDateString,
      endDate: endDateString
    };
  }
}
