import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularticsService } from '@gec-shared-services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-client-connect',
  templateUrl: './about-client-connect.component.html'
})
export class AboutClientConnectComponent {
  public currentYear = new Date().getFullYear();
  public startYear = 2020;

  constructor(
    private readonly modal: NgbActiveModal,
    private readonly router: Router,
    private readonly angularticsService: AngularticsService
  ) {}

  public onClose() {
    this.modal.close();
  }

  public navigateToTnc($event) {
    $event.preventDefault();
    this.modal.close();
    this.angularticsService.eventTrack('navigation', 'navigated to terms and conditions', 'terms and conditions');
    this.router.navigate(['terms-and-conditions']);
  }
}
