import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';

import { Observable } from 'rxjs';

import {
  ApplicationContext,
  Organization,
  ProductOrganization
} from '@app/shared/services/application-context/application-context';
import { NotAllowedService } from '@app/shared/services/not-allowed-service/not-allowed.service';
import { LaunchDarklyService } from '@gec-shared-services';

@Injectable({
  providedIn: 'root'
})
export class UserManagementCanLoadGuard implements CanLoad {
  private readonly featureFlags = this.launchDarklyService.getFlags();

  constructor(
    private readonly router: Router,
    private readonly applicationContext: ApplicationContext,
    private readonly notAllowedService: NotAllowedService,
    private readonly launchDarklyService: LaunchDarklyService
  ) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    let isUserManagementModuleLoad: boolean = false;

    const productOrgInfo = this.applicationContext.productOrgInfo;

    if (this.featureFlags.featureUserManagement) {
      productOrgInfo.productOrganizations.forEach((productOrg: ProductOrganization) => {
        if (!isUserManagementModuleLoad) {
          productOrg.organizations.forEach((organization: Organization) => {
            if (organization.permissions.includes('CC_USER_MANAGEMENT')) {
              isUserManagementModuleLoad = true;
              return true;
            }
          });
        }
      });
    }

    if (!isUserManagementModuleLoad || !this.featureFlags.featureUserManagement) {
      // redirect if user does not have user management access
      this.notAllowedService.setMessage({
        pageTitle: 'NOT-ALLOWED.USER-MANAGEMENT.PAGE-TITLE',
        title: 'NOT-ALLOWED.USER-MANAGEMENT.TITLE',
        summary: 'NOT-ALLOWED.USER-MANAGEMENT.SUMMARY',
        url: ''
      });

      this.router.navigate(['not-allowed']);
    }

    return isUserManagementModuleLoad;
  }
}
