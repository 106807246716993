import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth-service/auth.service';

@Component({
  selector: 'app-logout',
  template: '',
  styles: []
})
export class LogoutComponent implements OnInit {
  constructor(private readonly authService: AuthService) { }

  public ngOnInit() {
  if(this.authService.isEnterpriseUser()) {
    this.authService.enterpriseUserLogout()
  } else {
    this.authService.logout();
  }

  }
}
