export interface CaseTypes {
  dds: CaseType[]; // delivery
  drs: CaseType[]; // returns
  fdrfulfillment: CaseType[];
  cbrconsulate: CaseType[];
  cbds: CaseType[];
  expdeliver: CaseType[];
}

export interface CaseType {
  label: string;
  value: string;
  icon: string;
  reasons: CaseReason[];
}

export interface CaseReason {
  label: string;
  labelNew?: string;
  value: string;
  eventId: string;
  visible: boolean;
  eventIdNew?: string;
  description?: string;
  descriptionWithSuggestionForm?: boolean;
  invoiceAmountForm?: boolean;
  multipleAmountNumberForm?: boolean;
  multipleAmountNumberForInquiryForm?: boolean;
  multipleInvoiceNumberForm?: boolean;
  reasonForm?: boolean;
  dateForm?: boolean;
  subReasonForm?: boolean;
  frequencyForm?: boolean;
  paymentTransactionNumberForm?: boolean;
  subReasonFormOptions?: CaseReason[];
  subReasonRadioForm?: boolean;
  subReasonRadioFormOptions?: CaseReason[];
  achPullSubReasonForm?: boolean;
  achPullSubReasonFormOptions?: CaseReason[];
  reasonInfo?: boolean;
  alertMessageForm?: boolean;
  dueDateForm?: boolean;
  paymentDateForm?: boolean;
  paymentDateWithPresetsValueForm?: boolean;
  paymentAmountForm?: boolean;
  bankTransactionOrCheckNumber?: boolean;
  invoiceOrCreditMemo?: boolean;
  invoiceDocumentOrFile?: boolean;
  summaryForm?: boolean;
  trackingForm?: boolean;
  trackingFormDescription?: boolean;
  descriptionForm?: boolean;
  trackingOrSmartlabelForm?: boolean;
  referenceNumberForm?: boolean;
  inquireAboutPostageForm?: boolean;
  fileDeliveryGuaranteeClaim?: boolean;
  containerNumberForm?: boolean;
  shipmentNumberForm?: boolean;
  invoiceNumberForm?: boolean;
  amountNumberForm?: boolean;
  uspsTrackingNumberForm?: boolean;
  directLabelForm?: boolean;
  applicationForm?: boolean;
  interceptPackageForm?: boolean;
  orderNumberForm?: boolean;
  shipmentIdForm?: boolean;
  skuForm?: boolean;
  lotNumberForm?: boolean;
  facilitySelectForm?: boolean;
  advancedShippingNoticeForm?: boolean;
  addressBlockForm?: boolean;
  workOrderIdForm?: boolean;
  oneOrMoreForm?: boolean;
  oneOrMoreFormWithoutMandatoryField?: boolean;
  newDestinationForm?: boolean;
  originalDestinationForm?: boolean;
  recipientDetailsForm?: boolean;
  fileUploadForm?: boolean;
  validations?: Validations;
  strings?: Strings;
  priorityForm?: boolean;
}

export interface Validations {
  trackingNumber?: Validation;
  orderNumber?: Validation;
  referenceNumber?: Validation;
  multipleAmountNumberForm?: Validation;
  amountNumberForm?: Validation;
}

export interface Validation {
  required?: boolean;
  minlength?: number;
  maxlength?: number;
}

export interface Strings {
  [propName: string]: string;
}

const TRACKINGNUMBER_MAXLENGTH = 255;
const ORDERNUMBER_MAXLENGTH = 255;

export const caseTypes: CaseTypes = {
  dds: [
    {
      label: 'CASE-CREATE.CATEGORY.SHIPPING-AND-DELIVERY',
      value: 'shipping-and-delivery',
      icon: 'pbi-icon-outline pbi-delivery-fast',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEED-TRACKING-UPDATE',
          value: 'need-tracking-update',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AF-023',
          summaryForm: false,
          trackingForm: true,
          descriptionForm: false,
          referenceNumberForm: false,
          priorityForm: false,
          validations: {
            trackingNumber: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: false
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AD-101',
          // eventId: 'CASE-CC-QSTN-D&R-DDS-AB-005',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INTERCEPT-A-PACKAGE.LABEL',
          value: 'intercept-a-package',
          description: 'CASE-CREATE.REASON.INTERCEPT-A-PACKAGE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AD-010',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: true,
          validations: {
            trackingNumber: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          },
          interceptPackageForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.FINANCIALS',
      value: 'financials',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SETUP.LABEL',
          value: 'new-client-setup',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BF-115',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.LABEL',
          value: 'new-client-ach-form',
          visible: true,
          eventId: '',
          description: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.DESCRIPTION'
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-LAUNCH.LABEL',
          value: 'new-client-launch',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BF-114',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.LABEL',
          value: 'new-client-credit-appication',
          visible: true,
          eventId: '',
          description: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.LABEL',
              value: 'credit-application-form',
              eventId: '',
              visible: true,
              description:
                'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.INQUIRE-ABOUT-A-CREDIT-APPLICATION',
              value: 'inquire-about-a-credit-application',
              eventId: 'CASE-CC-QSTN-FIN-DDS-AX-090',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              alertMessageForm: true,
              fileUploadForm: true,
              priorityForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SECURITY-DEPOSIT',
          value: 'new-client-security-deposit',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AX-091',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          alertMessageForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MANUAL-BILLING-REQUEST',
          value: 'manual-billing-request',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AW-088',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-COLLECTIONS.LABEL',
          value: 'Inquire about a collection',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BI-121',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CLIENT-SEPARATION.LABEL',
          value: 'client-separation',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BF-116',
          description: 'CASE-CREATE.REASON.CLIENT-SEPARATION.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.LABEL',
          value: 'inquire-about-a-charge',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AW-082',
          eventIdNew: 'CASE-CC-QSTN-FIN-DDS-AW-102',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          multipleInvoiceNumberForm: true,
          fileUploadForm: true,
          reasonInfo: true,
          uspsTrackingNumberForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.LABEL',
          value: 'inquire-about-a-payment',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AZ-098',
          eventIdNew: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.BANK-TRANSFER',
              value: 'bank-transfer',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BD-105',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.ACH-PULL',
              value: 'ach-pull',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BD-106',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CHECK',
              value: 'check',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BD-107',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CREDIT-CARD',
              value: 'credit-card',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BD-108',
              visible: true
            }
          ],
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          paymentAmountForm: true,
          bankTransactionOrCheckNumber: true,
          paymentDateWithPresetsValueForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CREDIT-OR-CREDIT-NOTE',
          value: 'inquire-about-a-credit-or-credit-note',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AW-085',
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          invoiceOrCreditMemo: true,
          uspsTrackingNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.AUDIT_REQUEST',
          value: 'audit-request',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AW-084',
          eventIdNew: 'CASE-CC-QSTN-FIN-DDS-BF-113',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          invoiceOrCreditMemo: true,
          dateForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENT-OR-FILE',
          value: 'document-or-file',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AW-086',
          eventIdNew: 'CASE-CC-QSTN-FIN-DDS-BF-119',
          summaryForm: true,
          descriptionWithSuggestionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          invoiceDocumentOrFile: true,
          validations: {
            amountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.LABEL',
          value: 'payment-remittance-advice-submit',
          description: '',
          visible: true,
          eventId: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.LABEL',
              value: 'submit-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-DDS-AZ-096',
              visible: true,
              description:
                'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.INQUIRE-ABOUT-A-REMITTANCE-ADVICE',
              value: 'inquire-about-a-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-DDS-AZ-097',
              eventIdNew: 'CASE-CC-QSTN-FIN-DDS-AZ-110',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              paymentAmountForm: true,
              referenceNumberForm: true,
              priorityForm: true,
              fileUploadForm: true,
              paymentDateForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABEL',
          labelNew: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABELNEW',
          value: 'security-deposit-refund',
          description: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-AZ-099',
          eventIdNew: 'CASE-CC-QSTN-FIN-DDS-AZ-109',
          reasonInfo: true,
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.CREDIT-TERMS.LABEL',
          value: 'credit-terms',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BG-117',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.NEW-APPLICATION',
              value: 'new-application',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BG-118',
              visible: true,
              description: 'CASE-CREATE.REASON.CREDIT-TERMS.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.CURRENT-CLIENTS',
              value: 'current-clients',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BG-117',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE.LABEL',
          value: 'account-update',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DDS-BF-112',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-CLAIM.LABEL',
          value: 'file-a-claim',
          description: 'CASE-CREATE.REASON.FILE-A-CLAIM.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AY-011',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue-new',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.BILLING',
              value: 'billing-issue',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BC-104',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.PAYMENT',
              value: 'payment-issue',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BE-111',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.COLLECTIONS',
              value: 'collections-issue',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BJ-122',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.ACCOUNT',
              value: 'account-issue',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BH-120',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.OTHER',
              value: 'other-issue',
              eventId: 'CASE-CC-QSTN-FIN-DDS-BK-123',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ANALYTICS-AND-TECHNICAL-SUPPORT',
      value: 'analytics-and-technical-support',
      icon: 'pbi-icon-outline pbi-hubvis-graph',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.DATA-OR-REPORT-INQUIRY',
          value: 'data-or-report-inquiry',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AO-064',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical-support',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-CSTS-DDS-AO-078',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AU-101',
          // eventId: 'CASE-CC-QSTN-D&R-DDS-AB-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AO-079',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-USER-SETTINGS',
      value: 'account-and-user-settings',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.LABEL',
          value: 'invite-or-remove-users',
          description: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-003',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-NAME.LABEL',
          value: 'change-name',
          description: 'CASE-CREATE.REASON.CHANGE-NAME.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-001',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-EMAIL.LABEL',
          value: 'change-email',
          description: 'CASE-CREATE.REASON.CHANGE-EMAIL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    }
  ],
  drs: [
    {
      label: 'CASE-CREATE.CATEGORY.SHIPPING-AND-DELIVERY',
      value: 'shipping-and-delivery',
      icon: 'pbi-icon-outline pbi-delivery-fast',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEED-TRACKING-UPDATE',
          value: 'need-tracking-update',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-023',
          summaryForm: false,
          trackingForm: true,
          trackingFormDescription: false,
          descriptionForm: false,
          referenceNumberForm: false,
          priorityForm: false,
          validations: {
            trackingNumber: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: false
        },
        {
          label: 'CASE-CREATE.REASON.CONTAINER-ISSUE',
          value: 'container-issue',
          description: '',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-017',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          containerNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CONTAINER-PACKAGE-COUNT',
          value: 'container-package-count',
          description: '',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-016',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          containerNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MISSORTED-PACKAGE',
          value: 'missorted-package',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-019',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          trackingOrSmartlabelForm: true,
          validations: {
            trackingNumber: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MISSORTED-PALLET',
          value: 'missorted-pallet',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-020',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          containerNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CARRIER-ARRIVED-TOO-EARLY',
          value: 'carrier-arrived-too-early',
          description: '',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-021',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          shipmentNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CARRIER-ARRIVED-TOO-LATE',
          value: 'carrier-arrived-too-late',
          description: '',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-022',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          shipmentNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DAMAGED-ITEM',
          value: 'damaged-item',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AD-012',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: true,
          validations: {
            trackingNumber: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AD-101',
          // eventId: 'CASE-CC-QSTN-D&R-DRS-AB-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ESTIMATED-DELIVERY-DISCREPANCY.LABEL',
          value: 'estimated-delivery-discrepancy',
          description: 'CASE-CREATE.REASON.ESTIMATED-DELIVERY-DISCREPANCY.DESCRIPTION',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-074',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DIRECT-LABEL-REQUEST',
          value: 'direct-label-request',
          description: '',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AD-009',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          directLabelForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.STORE-PICKUP-AND-LOCATIONS.LABEL',
          value: 'store-pickup-and-locations',
          description: 'CASE-CREATE.REASON.STORE-PICKUP-AND-LOCATIONS.DESCRIPTION',
          visible: false,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AF-018',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.FINANCIALS',
      value: 'financials',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SETUP.LABEL',
          value: 'new-client-setup',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BF-115',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.LABEL',
          value: 'new-client-ach-form',
          visible: true,
          eventId: '',
          description: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.DESCRIPTION'
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-LAUNCH.LABEL',
          value: 'new-client-launch',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BF-114',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.LABEL',
          value: 'new-client-credit-appication',
          visible: true,
          eventId: '',
          description: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.LABEL',
              value: 'credit-application-form',
              eventId: '',
              visible: true,
              description:
                'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.INQUIRE-ABOUT-A-CREDIT-APPLICATION',
              value: 'inquire-about-a-credit-application',
              eventId: 'CASE-CC-QSTN-FIN-DRS-AX-090',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              alertMessageForm: true,
              fileUploadForm: true,
              priorityForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SECURITY-DEPOSIT',
          value: 'new-client-security-deposit',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AX-091',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          alertMessageForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MANUAL-BILLING-REQUEST',
          value: 'manual-billing-request',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AW-088',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-COLLECTIONS.LABEL',
          value: 'Inquire about a collection',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BI-121',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CLIENT-SEPARATION.LABEL',
          value: 'client-separation',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BF-116',
          description: 'CASE-CREATE.REASON.CLIENT-SEPARATION.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.LABEL',
          value: 'inquire-about-a-charge',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AW-082',
          eventIdNew: 'CASE-CC-QSTN-FIN-DRS-AW-102',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          multipleInvoiceNumberForm: true,
          fileUploadForm: true,
          reasonInfo: true,
          uspsTrackingNumberForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.LABEL',
          value: 'inquire-about-a-payment',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AZ-098',
          eventIdNew: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.BANK-TRANSFER',
              value: 'bank-transfer',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BD-105',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.ACH-PULL',
              value: 'ach-pull',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BD-106',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CHECK',
              value: 'check',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BD-107',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CREDIT-CARD',
              value: 'credit-card',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BD-108',
              visible: true
            }
          ],
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          paymentAmountForm: true,
          bankTransactionOrCheckNumber: true,
          paymentDateWithPresetsValueForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CREDIT-OR-CREDIT-NOTE',
          value: 'inquire-about-a-credit-or-credit-note',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AW-085',
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          invoiceOrCreditMemo: true,
          uspsTrackingNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.AUDIT_REQUEST',
          value: 'audit-request',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AW-084',
          eventIdNew: 'CASE-CC-QSTN-FIN-DRS-BF-113',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          invoiceOrCreditMemo: true,
          dateForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENT-OR-FILE',
          value: 'document-or-file',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AW-086',
          eventIdNew: 'CASE-CC-QSTN-FIN-DRS-BF-119',
          summaryForm: true,
          descriptionWithSuggestionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          invoiceDocumentOrFile: true,
          validations: {
            amountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.LABEL',
          value: 'payment-remittance-advice-submit',
          description: '',
          visible: true,
          eventId: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.LABEL',
              value: 'submit-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-DRS-AZ-096',
              visible: true,
              description:
                'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.INQUIRE-ABOUT-A-REMITTANCE-ADVICE',
              value: 'inquire-about-a-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-DRS-AZ-097',
              eventIdNew: 'CASE-CC-QSTN-FIN-DRS-AZ-110',
              summaryForm: true,
              descriptionForm: true,
              referenceNumberForm: true,
              priorityForm: true,
              fileUploadForm: true,
              visible: true,
              paymentAmountForm: true,
              paymentDateForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABEL',
          labelNew: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABELNEW',
          value: 'security-deposit-refund',
          description: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-AZ-099',
          eventIdNew: 'CASE-CC-QSTN-FIN-DRS-AZ-109',
          reasonInfo: true,
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.CREDIT-TERMS.LABEL',
          value: 'credit-terms',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BG-117',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.NEW-APPLICATION',
              value: 'new-application',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BG-118',
              visible: true,
              description: 'CASE-CREATE.REASON.CREDIT-TERMS.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.CURRENT-CLIENTS',
              value: 'current-clients',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BG-117',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE.LABEL',
          value: 'account-update',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-DRS-BF-112',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-CLAIM.LABEL',
          value: 'file-a-claim',
          description: 'CASE-CREATE.REASON.FILE-A-CLAIM.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AY-011',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue-new',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.BILLING',
              value: 'billing-issue',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BC-104',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.PAYMENT',
              value: 'payment-issue',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BE-111',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.COLLECTIONS',
              value: 'collections-issue',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BJ-122',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.ACCOUNT',
              value: 'account-issue',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BH-120',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.OTHER',
              value: 'other-issue',
              eventId: 'CASE-CC-QSTN-FIN-DRS-BK-123',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ANALYTICS-AND-TECHNICAL-SUPPORT',
      value: 'analytics-and-technical-support',
      icon: 'pbi-icon-outline pbi-hubvis-graph',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.DATA-OR-REPORT-INQUIRY',
          value: 'data-or-report-inquiry',
          description: 'CASE-CREATE.REASON.DATA-OR-REPORT-INQUIRY-RETURN.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AO-064',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical-support',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-CSTS-DRS-AO-078',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AU-101',
          // eventId: 'CASE-CC-QSTN-D&R-DRS-AB-005',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AO-079',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-USER-SETTINGS',
      value: 'account-and-user-settings',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.LABEL',
          value: 'invite-or-remove-users',
          description: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AB-003',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-NAME.LABEL',
          value: 'change-name',
          description: 'CASE-CREATE.REASON.CHANGE-NAME.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AB-001',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-EMAIL.LABEL',
          value: 'change-email',
          description: 'CASE-CREATE.REASON.CHANGE-EMAIL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AB-002',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DRS-AB-005',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    }
  ],
  cbrconsulate: [
    {
      label: 'CASE-CREATE.CATEGORY.SHIPPING-AND-DELIVERY',
      value: 'shipping-and-delivery',
      icon: 'pbi-icon-outline pbi-delivery-fast',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEED-TRACKING-UPDATE',
          value: 'need-tracking-update',
          description: 'CASE-CREATE.FORMS.TRACKING.DESCRIPTION',
          visible: true,
          eventId: 'ABC-000-000-000',
          summaryForm: false,
          trackingForm: true,
          descriptionForm: false,
          referenceNumberForm: false,
          priorityForm: false,
          validations: {
            trackingNumber: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PACKAGE-LOST',
          value: 'package-lost',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.PACKAGE-IS-NOT-DELIVERABLE',
          value: 'package-is-not-deliverable',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-SHIPPING-ADDRESS',
          value: 'change-shipping-address',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ITEM-DELIVERED-TOO-LATE',
          value: 'item-delivered-too-late',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.REQUEST-SHIPPING-DOCUMENTATION',
          value: 'request-shipping-documentation',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DELAYED-BY-CUSTOMS',
          value: 'delayed-by-customs',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ORDER',
      value: 'order',
      icon: 'pbi-icon-outline pbi-cart',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NO-ORDER-CONFIRMATION',
          value: 'no-order-confirmation',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ITEM-MISSING',
          value: 'item-missing',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CANCEL-ORDER.LABEL',
          value: 'cancel-order',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CANCEL-ITEM-FROM-ORDER',
          value: 'cancel-item-from-order',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.RETURN-ITEM',
      value: 'return-item',
      icon: 'pbi-icon-outline pbi-box',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NO-LONGER-NEEDED',
          value: 'no-longer-needed',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DO-NOT-LIKE-PRODUCT',
          value: 'do-not-like-product',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.BOUGHT-BY-MISTAKE',
          value: 'bought-by-mistake',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.WRONG-ITEM-WAS-SENT',
          value: 'wrong-item-was-sent',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DAMAGED-ITEM',
          value: 'damaged-item',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ITEM-DOES-NOT-FIT-OR-IS-DEFECTIVE',
          value: 'item-does-not-fit-or-is-defective',
          description: '',
          visible: true,
          eventId: 'ABC-000-000-000',
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.BILLING',
      value: 'billing',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.REQUEST-SERVICE-GESTURE',
          value: 'request-service-gesture',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-REFUND',
          value: 'inquire-about-a-refund',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-CHARGE',
          value: 'inquire-about-a-charge',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DISPUTE-A-CHARGE',
          value: 'dispute-a-charge',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CONSUMER-CHARGE-REQUEST',
          value: 'consumer-charge-request',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CARRIER-REQUESTING-ADDITIONAL',
          value: 'carrier-requesting-additional-payment',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.REQUEST-NEW-V-CARD',
          value: 'request-new-v-card',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-CLAIM.LABEL',
          value: 'file-a-claim',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-TECHNICAL-ISSUES',
      value: 'account-and-technical-issues',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.LOCKED-OUT-OF-ACCOUNT',
          value: 'locked-out-of-account',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ADD-OR-MANAGE-USER',
          value: 'add-or-manage-user',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical-support',
          description: '',
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.DESCRIPTION',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'ABC-000-000-000',
          descriptionForm: true
        }
      ]
    }
  ],
  cbds: [
    {
      label: 'CASE-CREATE.CATEGORY.SHIPPING-AND-DELIVERY',
      value: 'shipping-and-delivery',
      icon: 'pbi-icon-outline pbi-delivery-fast',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEED-TRACKING-UPDATE',
          value: 'need-tracking-update',
          description: '',
          visible: true,
          summaryForm: false,
          trackingForm: true,
          descriptionForm: false,
          trackingOrSmartlabelForm: false,
          orderNumberForm: false,
          referenceNumberForm: false,
          priorityForm: false,
          validations: {
            trackingNumber: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            orderNumber: {
              required: false,
              maxlength: ORDERNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AF-023'
        },
        {
          label: 'CASE-CREATE.REASON.DAMAGED-ITEM',
          value: 'damaged-item',
          description: '',
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AD-062',
          summaryForm: true,
          descriptionForm: true,
          trackingOrSmartlabelForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-SHIPPING-ADDRESS',
          value: 'change-shipping-address',
          description: '',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          orderNumberForm: true,
          originalDestinationForm: true,
          newDestinationForm: true,
          fileUploadForm: true,
          validations: {
            orderNumber: {
              required: true,
              maxlength: ORDERNUMBER_MAXLENGTH
            }
          },
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AF-068'
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENTATION-REQUEST',
          value: 'documentation-request',
          description: '',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          orderNumberForm: true,
          fileUploadForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          validations: {
            orderNumber: {
              required: true,
              maxlength: ORDERNUMBER_MAXLENGTH
            }
          },
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AF-070'
        },
        {
          label: 'CASE-CREATE.REASON.MISROUTED-PACKAGE',
          value: 'misrouted-package',
          description: '',
          visible: true,
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          trackingOrSmartlabelForm: true,
          orderNumberForm: true,
          validations: {
            trackingNumber: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            orderNumber: {
              required: false,
              maxlength: ORDERNUMBER_MAXLENGTH
            }
          },
          fileUploadForm: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AF-071'
        },
        {
          label: 'CASE-CREATE.REASON.CANCEL-ORDER.LABEL',
          value: 'cancel-order',
          description: '',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          orderNumberForm: true,
          recipientDetailsForm: true,
          fileUploadForm: true,
          validations: {
            orderNumber: {
              required: true,
              maxlength: ORDERNUMBER_MAXLENGTH
            }
          },
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AT-053'
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AD-072',
          // eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-005',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.FINANCIALS',
      value: 'financials',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SETUP.LABEL',
          value: 'new-client-setup',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BF-115',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.LABEL',
          value: 'new-client-ach-form',
          visible: true,
          eventId: '',
          description: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.DESCRIPTION'
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-LAUNCH.LABEL',
          value: 'new-client-launch',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BF-114',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.LABEL',
          value: 'new-client-credit-appication',
          visible: true,
          eventId: '',
          description: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.LABEL',
              value: 'credit-application-form',
              eventId: '',
              visible: true,
              description:
                'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.INQUIRE-ABOUT-A-CREDIT-APPLICATION',
              value: 'inquire-about-a-credit-application',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-AX-090',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              alertMessageForm: true,
              fileUploadForm: true,
              priorityForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SECURITY-DEPOSIT',
          value: 'new-client-security-deposit',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AX-091',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          alertMessageForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MANUAL-BILLING-REQUEST',
          value: 'manual-billing-request',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AW-088',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-COLLECTIONS.LABEL',
          value: 'Inquire about a collection',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BI-121',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CLIENT-SEPARATION.LABEL',
          value: 'client-separation',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BF-116',
          description: 'CASE-CREATE.REASON.CLIENT-SEPARATION.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.LABEL',
          value: 'inquire-about-a-charge',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AW-082',
          eventIdNew: 'CASE-CC-QSTN-FIN-CBDS-AW-102',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          multipleInvoiceNumberForm: true,
          fileUploadForm: true,
          reasonInfo: true,
          uspsTrackingNumberForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.LABEL',
          value: 'inquire-about-a-payment',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AZ-098',
          eventIdNew: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.BANK-TRANSFER',
              value: 'bank-transfer',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BD-105',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.ACH-PULL',
              value: 'ach-pull',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BD-106',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CHECK',
              value: 'check',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BD-107',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CREDIT-CARD',
              value: 'credit-card',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BD-108',
              visible: true
            }
          ],
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          paymentAmountForm: true,
          bankTransactionOrCheckNumber: true,
          paymentDateWithPresetsValueForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CREDIT-OR-CREDIT-NOTE',
          value: 'inquire-about-a-credit-or-credit-note',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AW-085',
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          invoiceOrCreditMemo: true,
          uspsTrackingNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.AUDIT_REQUEST',
          value: 'audit-request',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AW-084',
          eventIdNew: 'CASE-CC-QSTN-FIN-CBDS-BF-113',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          invoiceOrCreditMemo: true,
          dateForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENT-OR-FILE',
          value: 'document-or-file',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AW-086',
          eventIdNew: 'CASE-CC-QSTN-FIN-CBDS-BF-119',
          summaryForm: true,
          descriptionWithSuggestionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          invoiceDocumentOrFile: true,
          validations: {
            amountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.LABEL',
          value: 'payment-remittance-advice-submit',
          description: '',
          visible: true,
          eventId: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.LABEL',
              value: 'submit-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-AZ-096',
              visible: true,
              description:
                'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.INQUIRE-ABOUT-A-REMITTANCE-ADVICE',
              value: 'inquire-about-a-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-AZ-097',
              eventIdNew: 'CASE-CC-QSTN-FIN-CBDS-AZ-110',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              referenceNumberForm: true,
              priorityForm: true,
              fileUploadForm: true,
              paymentAmountForm: true,
              paymentDateForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABEL',
          labelNew: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABELNEW',
          value: 'security-deposit-refund',
          description: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-AZ-099',
          eventIdNew: 'CASE-CC-QSTN-FIN-CBDS-AZ-109',
          reasonInfo: true,
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.CREDIT-TERMS.LABEL',
          value: 'credit-terms',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BG-117',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.NEW-APPLICATION',
              value: 'new-application',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BG-118',
              visible: true,
              description: 'CASE-CREATE.REASON.CREDIT-TERMS.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.CURRENT-CLIENTS',
              value: 'current-clients',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BG-117',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE.LABEL',
          value: 'account-update',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-CBDS-BF-112',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-CLAIM.LABEL',
          value: 'file-a-claim',
          description: 'CASE-CREATE.REASON.FILE-A-CLAIM.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AY-011',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue-new',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.BILLING',
              value: 'billing-issue',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BC-104',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.PAYMENT',
              value: 'payment-issue',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BE-111',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.COLLECTIONS',
              value: 'collections-issue',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BJ-122',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.ACCOUNT',
              value: 'account-issue',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BH-120',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.OTHER',
              value: 'other-issue',
              eventId: 'CASE-CC-QSTN-FIN-CBDS-BK-123',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ANALYTICS-AND-TECHNICAL-SUPPORT',
      value: 'analytics-and-technical-support',
      icon: 'pbi-icon-outline pbi-hubvis-graph',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.DATA-OR-REPORT-INQUIRY',
          value: 'data-or-report-inquiry',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AO-064',
          referenceNumberForm: true,
          priorityForm: true,
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical-support',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          eventId: 'CASE-CC-QSTN-CSTS-CBDS-AO-078'
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          referenceNumberForm: true,
          priorityForm: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AU-072',
          // eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-005',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AO-079',
          referenceNumberForm: true,
          priorityForm: true,
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-USER-SETTINGS',
      value: 'account-and-user-settings',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.LABEL',
          value: 'invite-or-remove-users',
          description: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-003',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.USERNAME-AND-PASSWORD-RESET.LABEL',
          value: 'username-and-password-reset',
          description: 'CASE-CREATE.REASON.USERNAME-AND-PASSWORD-RESET.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-025',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-NAME.LABEL',
          value: 'change-name',
          description: 'CASE-CREATE.REASON.CHANGE-NAME.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-001',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-EMAIL.LABEL',
          value: 'change-email',
          description: 'CASE-CREATE.REASON.CHANGE-EMAIL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-002',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-ENT-CBDS-AG-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    }
  ],
  fdrfulfillment: [
    {
      label: 'CASE-CREATE.CATEGORY.ORDER',
      value: 'order',
      icon: 'pbi-icon-outline pbi-cart',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.ORDER-STATUS',
          value: 'order-status',
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-054',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-RETURN',
          value: 'inquire-about-a-return',
          eventId: 'CASE-CC-QSTN-D&R-FF-AN-057',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CREATE-A-RETURN-LABEL.LABEL',
          value: 'create-a-return-label',
          description: 'CASE-CREATE.REASON.CREATE-A-RETURN-LABEL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AN-066',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          oneOrMoreForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.HOLD-ORDER',
          value: 'hold-order',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-076',
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MODIFY-ORDER.LABEL',
          value: 'modify-order',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.MODIFY-DESTINATION-ADDRESS',
              value: 'modify-destiation-address',
              eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
              visible: true,
              addressBlockForm: true
            },
            {
              label: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.MODIFY-SHIPMENT-METHOD.LABEL',
              value: 'modify-shipment-method',
              eventId: 'CASE-CC-QSTN-D&R-FF-AM-048',
              visible: true,
              description: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.MODIFY-SHIPMENT-METHOD.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.SHIP-ORDER-TODAY',
              value: 'ship-order-today',
              eventId: 'CASE-CC-QSTN-D&R-FF-AM-049',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.RESHIP-ORDER.LABEL',
              value: 'reship-order',
              eventId: 'CASE-CC-QSTN-D&R-FF-AM-050',
              visible: true,
              description: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.RESHIP-ORDER.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.ADD-OR-REMOVE-ITEMS-FROM-ORDER.LABEL',
              value: 'add-or-remove-items-from-order',
              eventId: 'CASE-CC-QSTN-D&R-FF-AM-051',
              visible: true,
              description: 'CASE-CREATE.REASON.MODIFY-ORDER.SUB-REASON.ADD-OR-REMOVE-ITEMS-FROM-ORDER.DESCRIPTION'
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          visible: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CANCEL-ORDER.LABEL',
          value: 'cancel-order',
          description: 'CASE-CREATE.REASON.CANCEL-ORDER.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-053',
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INCORRECT-ITEMS-IN-ORDER',
          value: 'incorrect-items-in-order',
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-055',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          oneOrMoreForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.SPECIAL-INSTRUCTIONS',
          value: 'special-instructions',
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-075',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DAMAGED-ITEM',
          value: 'damaged-item',
          eventId: 'CASE-CC-QSTN-D&R-FF-AN-062',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.RETAIL-RETURN',
          value: 'retail-return',
          eventId: 'CASE-CC-QSTN-D&R-FF-AN-056',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ADD-SHIPMENT-INSERT-TO-ORDERS',
          value: 'add-shipment-insert-to-orders',
          eventId: 'CASE-CC-QSTN-D&R-FF-AM-052',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          skuForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.FULFILLMENT-AND-INVENTORY',
      value: 'fulfillment-and-inventory',
      icon: 'pbi-icon-outline pbi-label',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVENTORY-COUNT.LABEL',
          value: 'inventory-count',
          description: 'CASE-CREATE.REASON.INVENTORY-COUNT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AH-031',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.RECEIVING-DISCREPANCY',
          value: 'receiving-discrepancy',
          eventId: 'CASE-CC-QSTN-D&R-FF-AK-042',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          advancedShippingNoticeForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENTATION',
          value: 'documentation',
          description: '',
          eventId: 'CASE-CC-QSTN-D&R-FF-AK-100',
          // eventId: 'CASE-CC-QSTN-D&R-FF-AD-060',
          visible: true,
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          oneOrMoreFormWithoutMandatoryField: true,
          trackingOrSmartlabelForm: false,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ADVANCED-SHIPPING-NOTICE-STATUS',
          value: 'advanced-shipping-notice-status',
          eventId: 'CASE-CC-QSTN-D&R-FF-AK-077',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          advancedShippingNoticeForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CANCEL-ADVANCED-SHIPPING-NOTICE',
          value: 'cancel-advanced-shipping-notice',
          eventId: 'CASE-CC-QSTN-D&R-FF-AK-046',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          advancedShippingNoticeForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.RETAIL-PICKUP-WORK-ORDER',
          value: 'retail-pickup-work-order',
          eventId: 'CASE-CC-QSTN-D&R-FF-AI-034',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          oneOrMoreForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TRANSFER-SKU.LABEL',
          value: 'transfer-sku',
          description: 'CASE-CREATE.REASON.TRANSFER-SKU.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AH-033',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-CHECK.LABEL',
          value: 'label-check',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.LABEL-CHECK.SUB-REASON.EXPIRATION-DATE-CHECK',
              value: 'expiration-date-check',
              eventId: 'CASE-CC-QSTN-D&R-FF-AH-026',
              visible: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.LABEL-CHECK.SUB-REASON.INGREDIENTS-CHECK',
              value: 'ingredients-check',
              eventId: 'CASE-CC-QSTN-D&R-FF-AH-027',
              visible: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.LABEL-CHECK.SUB-REASON.PACKAGING-CHECK',
              value: 'packaging-check',
              eventId: 'CASE-CC-QSTN-D&R-FF-AH-028',
              visible: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.LABEL-CHECK.SUB-REASON.SIZE-CHECK',
              value: 'size-check',
              eventId: 'CASE-CC-QSTN-D&R-FF-AH-029',
              visible: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.LABEL-CHECK.SUB-REASON.PROCESSING-CHECK',
              value: 'processing-check',
              eventId: 'CASE-CC-QSTN-D&R-FF-AH-030',
              visible: true,
              skuForm: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LOT-TRACKING',
          value: 'lot-tracking',
          eventId: 'CASE-CC-QSTN-D&R-FF-AH-032',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          skuForm: true,
          lotNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.QUOTE-REQUEST.LABEL',
          value: 'quote-request',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.QUOTE-REQUEST.SUB-REASON.SPECIAL-PROJECT-QUOTE',
              value: 'special-project-quote',
              eventId: 'CASE-CC-QSTN-D&R-FF-AJ-039',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.QUOTE-REQUEST.SUB-REASON.SHIPPING-QUOTE',
              value: 'shipping-quote',
              eventId: 'CASE-CC-QSTN-D&R-FF-AJ-040',
              visible: true,
              oneOrMoreForm: true,
              trackingOrSmartlabelForm: false
            },
            {
              label: 'CASE-CREATE.REASON.QUOTE-REQUEST.SUB-REASON.LABOR-QUOTE',
              value: 'labor-quote',
              eventId: 'CASE-CC-QSTN-D&R-FF-AJ-041',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.KITTING.LABEL',
          value: 'kitting',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.KITTING.SUB-REASON.KITTING-WORK-ORDER',
              value: 'kitting-work-order',
              eventId: 'CASE-CC-QSTN-D&R-FF-AI-035',
              visible: true,
              workOrderIdForm: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.KITTING.SUB-REASON.REWORK-KITTING-ORDER',
              value: 'rework-kitting-order',
              eventId: 'CASE-CC-QSTN-D&R-FF-AI-037',
              visible: true,
              workOrderIdForm: true,
              skuForm: true
            },
            {
              label: 'CASE-CREATE.REASON.KITTING.SUB-REASON.DE-KIT-WORK-ORDER',
              value: 'de-kit-work-order',
              eventId: 'CASE-CC-QSTN-D&R-FF-AI-038',
              visible: true,
              workOrderIdForm: true,
              skuForm: true
            }
          ],
          descriptionForm: true,
          visible: true,
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          strings: { skuLabel: 'CASE-CREATE.REASON.KITTING.SKU-LABEL' }
        },
        {
          label: 'CASE-CREATE.REASON.RETURN-ITEMS-TO-VENDOR.LABEL',
          value: 'return-items-to-vendor',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AL-059',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.RECEIVING-APPOINTMENT.LABEL',
          value: 'receiving-appointment',
          description: 'CASE-CREATE.REASON.RECEIVING-APPOINTMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AK-063',
          // eventId: 'CASE-CC-QSTN-D&R-FF-AF-063',
          summaryForm: true,
          descriptionForm: true,
          facilitySelectForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          advancedShippingNoticeForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.SHIPPING-AND-DELIVERY',
      value: 'shipping-and-delivery',
      icon: 'pbi-icon-outline pbi-delivery-fast',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEED-TRACKING-UPDATE',
          value: 'need-tracking-update',
          eventId: 'CASE-CC-QSTN-D&R-FF-AF-023',
          visible: true,
          summaryForm: false,
          trackingForm: true,
          descriptionForm: false,
          oneOrMoreForm: false,
          referenceNumberForm: false,
          priorityForm: false,
          fileUploadForm: false,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INTERCEPT-A-PACKAGE.LABEL',
          value: 'intercept-a-package',
          description: 'CASE-CREATE.REASON.INTERCEPT-A-PACKAGE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AD-061',
          summaryForm: true,
          descriptionForm: true,
          trackingOrSmartlabelForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          interceptPackageForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENTATION',
          value: 'documentation',
          description: '',
          eventId: 'CASE-CC-QSTN-D&R-FF-BA-100',
          // eventId: 'CASE-CC-QSTN-D&R-FF-AD-060',
          visible: true,
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          oneOrMoreFormWithoutMandatoryField: true,
          trackingOrSmartlabelForm: false,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.PACKAGE-COULD-NOT-BE-DELIVERED',
          value: 'package-could-not-be-delivered',
          description: '',
          eventId: 'CASE-CC-QSTN-D&R-FF-AR-058',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: true,
          oneOrMoreForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.CARRIER-ARRIVED-TOO-EARLY',
          value: 'carrier-arrived-too-early',
          description: '',
          eventId: 'CASE-CC-QSTN-D&R-FF-AF-021',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          oneOrMoreForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CARRIER-ARRIVED-TOO-LATE',
          value: 'carrier-arrived-too-late',
          description: '',
          eventId: 'CASE-CC-QSTN-D&R-FF-AF-022',
          visible: true,
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          trackingOrSmartlabelForm: false, // hides tracking field from oneOrMoreForm
          oneOrMoreForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AD-101',
          // eventId: 'CASE-CC-QSTN-D&R-FF-AG-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.FINANCIALS',
      value: 'financials',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SETUP.LABEL',
          value: 'new-client-setup',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BF-115',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.LABEL',
          value: 'new-client-ach-form',
          visible: true,
          eventId: '',
          description: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.DESCRIPTION'
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-LAUNCH.LABEL',
          value: 'new-client-launch',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BF-114',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.LABEL',
          value: 'new-client-credit-appication',
          visible: true,
          eventId: '',
          description: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.LABEL',
              value: 'credit-application-form',
              eventId: '',
              visible: true,
              description:
                'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.INQUIRE-ABOUT-A-CREDIT-APPLICATION',
              value: 'inquire-about-a-credit-application',
              eventId: 'CASE-CC-QSTN-FIN-FF-AX-090',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              alertMessageForm: true,
              fileUploadForm: true,
              priorityForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SECURITY-DEPOSIT',
          value: 'new-client-security-deposit',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AX-091',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          alertMessageForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MANUAL-BILLING-REQUEST',
          value: 'manual-billing-request',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AW-088',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-COLLECTIONS.LABEL',
          value: 'Inquire about a collection',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BI-121',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CLIENT-SEPARATION.LABEL',
          value: 'client-separation',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BF-116',
          description: 'CASE-CREATE.REASON.CLIENT-SEPARATION.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.LABEL',
          value: 'inquire-about-a-charge',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AW-082',
          eventIdNew: 'CASE-CC-QSTN-FIN-FF-AW-102',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CHARGE.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          multipleInvoiceNumberForm: true,
          fileUploadForm: true,
          reasonInfo: true,
          uspsTrackingNumberForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.LABEL',
          value: 'inquire-about-a-payment',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AZ-098',
          eventIdNew: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.BANK-TRANSFER',
              value: 'bank-transfer',
              eventId: 'CASE-CC-QSTN-FIN-FF-BD-105',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.ACH-PULL',
              value: 'ach-pull',
              eventId: 'CASE-CC-QSTN-FIN-FF-BD-106',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CHECK',
              value: 'check',
              eventId: 'CASE-CC-QSTN-FIN-FF-BD-107',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CREDIT-CARD',
              value: 'credit-card',
              eventId: 'CASE-CC-QSTN-FIN-FF-BD-108',
              visible: true
            }
          ],
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          paymentAmountForm: true,
          bankTransactionOrCheckNumber: true,
          paymentDateWithPresetsValueForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CREDIT-OR-CREDIT-NOTE',
          value: 'inquire-about-a-credit-or-credit-note',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AW-085',
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          invoiceOrCreditMemo: true,
          uspsTrackingNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        // {
        //   label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.LABEL',
        //   value: 'invoice-credit-or-payment-history',
        //   description: '',
        //   visible: true,
        //   eventId: 'CASE-CC-QSTN-D&R-FF-AC-007',
        //   summaryForm: true,
        //   descriptionForm: true,
        //   referenceNumberForm: true,
        //   fileUploadForm: true,
        //   subReasonRadioForm: true,
        //   subReasonRadioFormOptions: [
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.INVOICES-CREDITS',
        //       value: 'invoices-credits',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       multipleAmountNumberForm: true,
        //       invoiceOrCreditMemo: true,
        //       dateForm: true
        //     },
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.PAYMENTS',
        //       value: 'payments',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       multipleAmountNumberForm: true,
        //       paymentTransactionNumberForm: true,
        //       invoiceOrCreditMemo: false,
        //       dateForm: true
        //     },
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.SCHEDULED-STATEMENTS',
        //       value: 'scheduled-statements',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       paymentAmountForm: false,
        //       amountNumberForm: false,
        //       frequencyForm: true
        //     }
        //   ]
        // },
        {
          label: 'CASE-CREATE.REASON.AUDIT_REQUEST',
          value: 'audit-request',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AW-084',
          eventIdNew: 'CASE-CC-QSTN-FIN-FF-BF-113',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          invoiceOrCreditMemo: true,
          dateForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENT-OR-FILE',
          value: 'document-or-file',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AW-086',
          eventIdNew: 'CASE-CC-QSTN-FIN-FF-BF-119',
          summaryForm: true,
          descriptionWithSuggestionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          invoiceDocumentOrFile: true,
          validations: {
            amountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.LABEL',
          value: 'payment-remittance-advice-submit',
          description: '',
          visible: true,
          eventId: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.LABEL',
              value: 'submit-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-FF-AZ-096',
              visible: true,
              description:
                'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.INQUIRE-ABOUT-A-REMITTANCE-ADVICE',
              value: 'inquire-about-a-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-FF-AZ-097',
              eventIdNew: 'CASE-CC-QSTN-FIN-FF-AZ-110',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              referenceNumberForm: true,
              priorityForm: true,
              fileUploadForm: true,
              paymentAmountForm: true,
              paymentDateForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABEL',
          labelNew: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABELNEW',
          value: 'security-deposit-refund',
          description: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-AZ-099',
          eventIdNew: 'CASE-CC-QSTN-FIN-FF-AZ-109',
          reasonInfo: true,
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.CREDIT-TERMS.LABEL',
          value: 'credit-terms',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BG-117',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.NEW-APPLICATION',
              value: 'new-application',
              eventId: 'CASE-CC-QSTN-FIN-FF-BG-118',
              visible: true,
              description: 'CASE-CREATE.REASON.CREDIT-TERMS.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.CURRENT-CLIENTS',
              value: 'current-clients',
              eventId: 'CASE-CC-QSTN-FIN-FF-BG-117',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE.LABEL',
          value: 'account-update',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-FF-BF-112',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-CLAIM.LABEL',
          value: 'file-a-claim',
          description: 'CASE-CREATE.REASON.FILE-A-CLAIM.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AY-011',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue-new',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.BILLING',
              value: 'billing-issue',
              eventId: 'CASE-CC-QSTN-FIN-FF-BC-104',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.PAYMENT',
              value: 'payment-issue',
              eventId: 'CASE-CC-QSTN-FIN-FF-BE-111',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.COLLECTIONS',
              value: 'collections-issue',
              eventId: 'CASE-CC-QSTN-FIN-FF-BJ-122',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.ACCOUNT',
              value: 'account-issue',
              eventId: 'CASE-CC-QSTN-FIN-FF-BH-120',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.OTHER',
              value: 'other-issue',
              eventId: 'CASE-CC-QSTN-FIN-FF-BK-123',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ANALYTICS-AND-TECHNICAL-SUPPORT',
      value: 'analytics-and-technical-support',
      icon: 'pbi-icon-outline pbi-hubvis-graph',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.DATA-OR-REPORT-INQUIRY',
          value: 'data-or-report-inquiry',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AO-064',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical-support',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-CSTS-FF-AO-078',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.LABEL-ISSUE',
          value: 'label-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AU-101',
          // eventId: 'CASE-CC-QSTN-D&R-FF-AG-005',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AO-079',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-USER-SETTINGS',
      value: 'account-and-user-settings',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.LABEL',
          value: 'invite-or-remove-users',
          description: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AG-024',
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.USERNAME-AND-PASSWORD-RESET.LABEL',
          value: 'username-and-password-reset',
          description: 'CASE-CREATE.REASON.USERNAME-AND-PASSWORD-RESET.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AG-025',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-NAME.LABEL',
          value: 'change-name',
          description: 'CASE-CREATE.REASON.CHANGE-NAME.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AG-001',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-EMAIL.LABEL',
          value: 'change-email',
          description: 'CASE-CREATE.REASON.CHANGE-EMAIL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AG-002',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-FF-AG-005',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    }
  ],
  expdeliver: [
    {
      label: 'CASE-CREATE.CATEGORY.FINANCIALS',
      value: 'financials',
      icon: 'pbi-icon-outline pbi-credit-card',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SETUP.LABEL',
          value: 'new-client-setup',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BF-115',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.LABEL',
          value: 'new-client-ach-form',
          visible: true,
          eventId: '',
          description: 'CASE-CREATE.REASON.NEW-CLIENT-ACH-FORM.DESCRIPTION'
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-LAUNCH.LABEL',
          value: 'new-client-launch',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BF-114',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.LABEL',
          value: 'new-client-credit-appication',
          visible: true,
          eventId: '',
          description: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.LABEL',
              value: 'credit-application-form',
              eventId: '',
              visible: true,
              description:
                'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.CREDIT-APPLICATION-FORM.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.NEW-CLIENT-CREDIT-APPLICATION.SUB-REASON.INQUIRE-ABOUT-A-CREDIT-APPLICATION',
              value: 'inquire-about-a-credit-application',
              eventId: 'CASE-CC-QSTN-FIN-ED-AX-090',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              alertMessageForm: true,
              fileUploadForm: true,
              priorityForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.NEW-CLIENT-SECURITY-DEPOSIT',
          value: 'new-client-security-deposit',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AX-091',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          alertMessageForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.MANUAL-BILLING-REQUEST',
          value: 'manual-billing-request',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AW-088',
          description: '',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-COLLECTIONS.LABEL',
          value: 'Inquire about a collection',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BI-121',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CLIENT-SEPARATION.LABEL',
          value: 'client-separation',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BF-116',
          description: 'CASE-CREATE.REASON.CLIENT-SEPARATION.DESCRIPTION',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          priorityForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-AN-INVOICE-CHARGE',
          value: 'inquire about an invoice charge',
          description: '',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          invoiceNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AW-083',
          eventIdNew: 'CASE-CC-QSTN-FIN-ED-AW-102'
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-POSTAGE-TRANSACTIONS-OR-FUNDS',
          value: 'inquire-about-postage-transactions-or-funds',
          description: '',
          visible: true,
          inquireAboutPostageForm: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.LABEL',
          value: 'inquire-about-a-payment',
          description: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AZ-098',
          eventIdNew: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.BANK-TRANSFER',
              value: 'bank-transfer',
              eventId: 'CASE-CC-QSTN-FIN-ED-BD-105',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.ACH-PULL',
              value: 'ach-pull',
              eventId: 'CASE-CC-QSTN-FIN-ED-BD-106',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CHECK',
              value: 'check',
              eventId: 'CASE-CC-QSTN-FIN-ED-BD-107',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-PAYMENT.SUB-REASON.CREDIT-CARD',
              value: 'credit-card',
              eventId: 'CASE-CC-QSTN-FIN-ED-BD-108',
              visible: true
            }
          ],
          summaryForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          descriptionForm: true,
          paymentAmountForm: true,
          bankTransactionOrCheckNumber: true,
          paymentDateWithPresetsValueForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.INQUIRE-ABOUT-A-CREDIT-OR-CREDIT-NOTE',
          value: 'inquire-about-a-credit-or-credit-note',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AW-085',
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          invoiceOrCreditMemo: true,
          uspsTrackingNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            trackingNumber: {
              maxlength: TRACKINGNUMBER_MAXLENGTH
            },
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        // {
        //   label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.LABEL',
        //   value: 'invoice-credit-or-payment-history',
        //   description: '',
        //   visible: true,
        //   eventId: 'CASE-CC-QSTN-D&R-FF-AC-007',
        //   summaryForm: true,
        //   descriptionForm: true,
        //   referenceNumberForm: true,
        //   fileUploadForm: true,
        //   subReasonRadioForm: true,
        //   subReasonRadioFormOptions: [
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.INVOICES-CREDITS',
        //       value: 'invoices-credits',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       multipleAmountNumberForm: true,
        //       invoiceOrCreditMemo: true,
        //       dateForm: true
        //     },
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.PAYMENTS',
        //       value: 'payments',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       multipleAmountNumberForm: true,
        //       paymentTransactionNumberForm: true,
        //       invoiceOrCreditMemo: false,
        //       dateForm: true
        //     },
        //     {
        //       label: 'CASE-CREATE.REASON.INVOICE-CREDIT-OR-PAYMENT-HISTORY.SUB-REASON.SCHEDULED-STATEMENTS',
        //       value: 'scheduled-statements',
        //       eventId: 'CASE-CC-QSTN-D&R-FF-AM-047',
        //       visible: true,
        //       paymentAmountForm: false,
        //       amountNumberForm: false,
        //       frequencyForm: true
        //     }
        //   ]
        // },
        {
          label: 'CASE-CREATE.REASON.AUDIT_REQUEST',
          value: 'audit-request',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AW-084',
          eventIdNew: 'CASE-CC-QSTN-FIN-ED-BF-113',
          summaryForm: true,
          descriptionForm: true,
          multipleAmountNumberForm: true,
          invoiceOrCreditMemo: true,
          dateForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            multipleAmountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.DOCUMENT-OR-FILE',
          value: 'document-or-file',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AW-086',
          eventIdNew: 'CASE-CC-QSTN-FIN-ED-BF-119',
          summaryForm: true,
          descriptionWithSuggestionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          invoiceDocumentOrFile: true,
          validations: {
            amountNumberForm: {
              required: false,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.LABEL',
          value: 'payment-remittance-advice-submit',
          description: '',
          visible: true,
          eventId: '',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.LABEL',
              value: 'submit-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-ED-AZ-096',
              visible: true,
              description:
                'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.SUBMIT-A-REMITTANCE-ADVICE.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.PAYMENT-REMITTANCE-ADVICE-SUBMIT.SUB-REASON.INQUIRE-ABOUT-A-REMITTANCE-ADVICE',
              value: 'inquire-about-a-remittance-advice',
              eventId: 'CASE-CC-QSTN-FIN-ED-AZ-097',
              eventIdNew: 'CASE-CC-QSTN-FIN-ED-AZ-110',
              visible: true,
              summaryForm: true,
              descriptionForm: true,
              referenceNumberForm: true,
              priorityForm: true,
              fileUploadForm: true,
              paymentAmountForm: true,
              paymentDateForm: true
            }
          ]
        },
        {
          label: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABEL',
          labelNew: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.LABELNEW',
          value: 'security-deposit-refund',
          description: 'CASE-CREATE.REASON.SECURITY-DEPOSIT-REFUND.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-AZ-099',
          eventIdNew: 'CASE-CC-QSTN-FIN-ED-AZ-109',
          reasonInfo: true,
          summaryForm: true,
          descriptionForm: true,
          amountNumberForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          validations: {
            amountNumberForm: {
              required: true,
              maxlength: TRACKINGNUMBER_MAXLENGTH
            }
          }
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.CREDIT-TERMS.LABEL',
          value: 'credit-terms',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BG-117',
          subReasonRadioForm: true,
          subReasonRadioFormOptions: [
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.NEW-APPLICATION',
              value: 'new-application',
              eventId: 'CASE-CC-QSTN-FIN-ED-BG-118',
              visible: true,
              description: 'CASE-CREATE.REASON.CREDIT-TERMS.DESCRIPTION'
            },
            {
              label: 'CASE-CREATE.REASON.CREDIT-TERMS.SUB-REASON.CURRENT-CLIENTS',
              value: 'current-clients',
              eventId: 'CASE-CC-QSTN-FIN-ED-BG-117',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE.LABEL',
          value: 'account-update',
          visible: true,
          eventId: 'CASE-CC-QSTN-FIN-ED-BF-112',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.FILE-A-DELIVERY-GUARANTEE-CLAIM',
          value: 'File-a-Delivery-Guarantee-claim',
          description: '',
          visible: true,
          fileDeliveryGuaranteeClaim: true,
          eventId: ''
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue-new',
          eventId: '',
          subReasonForm: true,
          subReasonFormOptions: [
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.BILLING',
              value: 'billing-issue',
              eventId: 'CASE-CC-QSTN-FIN-ED-BC-104',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.PAYMENT',
              value: 'payment-issue',
              eventId: 'CASE-CC-QSTN-FIN-ED-BE-111',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.COLLECTIONS',
              value: 'collections-issue',
              eventId: 'CASE-CC-QSTN-FIN-ED-BJ-122',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.ACCOUNT',
              value: 'account-issue',
              eventId: 'CASE-CC-QSTN-FIN-ED-BH-120',
              visible: true
            },
            {
              label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.SUB-REASON.OTHER',
              value: 'other-issue',
              eventId: 'CASE-CC-QSTN-FIN-ED-BK-123',
              visible: true
            }
          ],
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          visible: true,
          fileUploadForm: true
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.TECHNICAL-SUPPORT',
      value: 'technical support',
      icon: 'pbi-icon-outline pbi-hubvis-graph',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.TECHNICAL-SUPPORT',
          value: 'technical support',
          description: '',
          visible: true,
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true,
          eventId: 'CASE-CC-QSTN-CSTS-ED-AV-078'
        }
      ]
    },
    {
      label: 'CASE-CREATE.CATEGORY.ACCOUNT-AND-USER-SETTINGS',
      value: 'account-and-user-settings',
      icon: 'pbi-icon-outline pbi-user',
      reasons: [
        {
          label: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.LABEL',
          value: 'invite-or-remove-users',
          description: 'CASE-CREATE.REASON.INVITE-OR-REMOVE-USERS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-ED-AG-003',
          summaryForm: true,
          descriptionForm: true,
          fileUploadForm: true,
          referenceNumberForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-NAME.LABEL',
          value: 'change-name',
          description: 'CASE-CREATE.REASON.CHANGE-NAME.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-ED-AG-001',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.CHANGE-EMAIL.LABEL',
          value: 'change-email',
          description: 'CASE-CREATE.REASON.CHANGE-EMAIL.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-ED-AG-002',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        },
        {
          label: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.LABEL',
          value: 'account-update-banking-details',
          description: 'CASE-CREATE.REASON.ACCOUNT-UPDATE-BANKING-DETAILS.DESCRIPTION',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-DDS-AB-002'
        },
        {
          label: 'CASE-CREATE.REASON.I-DONT-SEE-MY-ISSUE.LABEL',
          value: 'i-dont-see-my-issue',
          description: '',
          visible: true,
          eventId: 'CASE-CC-QSTN-D&R-ED-AG-005',
          summaryForm: true,
          descriptionForm: true,
          referenceNumberForm: true,
          priorityForm: true,
          fileUploadForm: true
        }
      ]
    }
  ]
};
