import { Component, Input } from '@angular/core';
import { animate, style, trigger, transition } from '@angular/animations';
import { GlobalAlert } from '../services/dynamic-content/dynamic-content.service';

@Component({
  selector: 'app-dynamic-global-alert',
  templateUrl: './dynamic-global-alert.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('.5s', style({ opacity: 1 }))]),
      transition(':leave', [animate('.5s', style({ opacity: 0 }))])
    ])
  ]
})
export class DynamicGlobalAlertComponent {
  @Input()
  public alert: GlobalAlert;

  public onClose($event) {
    $event.preventDefault();

    this.alert.alertClosed = true;
  }
}
