export interface VendorDetails {
  name: string;
  code: string;
}

export interface ShippingDetails {
  carrier: string;
  serviceId: string;
  serviceName: string;
  trackingNumber: string;
}

export interface TotalWeight {
  weight: number;
  unitOfMeasurement: string;
}

export interface TotalVolume {
  volume: number;
  unitOfMeasurement: string;
}

export interface FromAddress {
  name: string;
  company: string;
  phone: string;
  email: string;
  addressLines: string[];
  cityTown: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
}

export interface ToAddress {
  name: string;
  company: string;
  phone: string;
  email: string;
  addressLines: string[];
  cityTown: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
  airportCode: string;
}

export interface BillingAddress {
  name: string;
  company: string;
  phone: string;
  email: string;
  addressLines: string[];
  cityTown: string;
  stateProvince: string;
  postalCode: string;
  countryCode: string;
}

export interface AdditionalReference {
  name: string;
  value: string;
}

export interface CostDetails {
  retailPrice: number;
  wholeSalePrice: number;
  unitCost: number;
  currency: string;
}

export interface LicensePlateDetail {
  licensePlate: string;
  lotNumber: string;
  lotExpirationDate: Date;
  expectedQuantity: number;
}

export interface AsnLine {
  lineId: number;
  purchaseOrderNumberLineId: number;
  PBlineId: number;
  sku: string;
  alternateSKU: string;
  inventoryType: string;
  originCountryCode: string;
  expectedQuantity: number;
  unitOfMeasurement: string;
  estimatedDeliveryDate: Date;
  costDetails: CostDetails;
  licensePlateDetails: LicensePlateDetail[];
  receivedQuantity: number;
}

export interface AsnList {
  clientId: string;
  brand: string;
  inboundType: string;
  clientASNId: string;
  purchaseOrderNumber: string;
  invoiceNumber: string;
  receivingNode: string;
  vendorDetails: VendorDetails;
  receivingASNId: string;
  status: string;
  shippingDetails: ShippingDetails;
  createDate: Date;
  expectedDeliveryDate: Date;
  pbCreateDate: Date;
  totalWeight: TotalWeight;
  totalVolume: TotalVolume;
  fromAddress: FromAddress;
  toAddress: ToAddress;
  billingAddress: BillingAddress;
  notes: string;
  additionalReferences: AdditionalReference[];
  asnLines: AsnLine[];
  receivedDate: Date;
  pbUpdateDate: Date;
  pbDeliveredDate: Date;
  vendor: string;
  destination: string;
  totalExpectedQuantity: number;
  totalReceivedQuantity: number;
  variance: string;
}

export interface Receiving {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  page: number;
  sort: string[];
  asnList: AsnList[];
}

export class ReceivingRequest {
  clientId?: string;
  sort?: string[];
  searchFields?: SearchField[];
}

export class DateFilterType {
  name?: string;
  to?: string;
  from?: string;
}

export class SearchField {
  name?: string;
  values?: string[];
  to?: string;
  from?: string;
}

export interface RelatedASN {
  purchaseOrderNumber: string;
  clientASNId: string;
  receivedDate: string;
  status: string;
  totalExpectedQuantity: number | string;
  totalReceivedQuantity: number | string;
  receivingASNId: string;
}

export class FacetedSearchUpdate {
  'updatedFilterName': string;
  'updatedValue': any;
}

export class ReceivingDocument {
  asnDocumentName?: string;
  asnDocumentData?: string;
}
