import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, map, Observable, of } from 'rxjs';

import {
  ApplicationContext,
  ProductOrganizationInfo
} from '@app/shared/services/application-context/application-context';
import { CaseForm } from '@app/support/case-create/models/case-form';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { LaunchDarklyService, StorefrontService } from '@gec-shared-services';
import { AuthService, User } from '@app/shared/services/auth-service/auth.service';
import { questionAndLinks } from '@app/support/case-create/models/services-faq';

export interface CaseObject {
  financialTransactionNumber?: string;
  bankTransactionOrCheckNumber?: string;
  frequency?: string;
  reason?: string;
  invoiceDocumentOrFile?: string;
  caseCreatorOktaID: string; // oktaid
  clientConnectCaseReporterName: string; // user first/last name
  clientId: string; // account.number
  eventId: string;
  merchantCreatedBy: string; // user email address
  product: string; // account.productId
  multipleAmountNumber?: string;
  amountNumber?: string;
  containerNumber?: string;
  summary?: string;
  number?: string;
  description?: string;
  interceptRequestType?: string;
  invoiceNumber?: string;
  recipientApt?: string;
  recipientCity?: string;
  recipientName?: string;
  recipientState?: string;
  recipientStreetAddress?: string;
  recipientZipCode?: string;
  redirectApt?: string;
  redirectCity?: string;
  redirectName?: string;
  redirectState?: string;
  redirectStreetAddress?: string;
  redirectZipCode?: string;
  senderApt?: string;
  senderCity?: string;
  senderName?: string;
  senderState?: string;
  senderStreetAddress?: string;
  senderZipCode?: string;
  shipmentNumber?: string;
  smartLabelQuantity?: string; // a.k.a. direct label quantity
  trackingNumber?: string;
  referenceNumber?: string;
  orderNumber?: string;
  orderId?: string;
  // shipmentId?: string;
  lotNumber?: string;
  sku?: string;
  facility?: string;
  advancedShippingNotice?: string;
  workOrderId?: string;
  newDestination?: string;
  originalDestination?: string;
  recipientDetails?: string;
  financeStartDate?: string;
  financeEndDate?: string;
  casePriority?: any;
}

export interface CaseCreateDate {
  endDate: string;
  fromDate: string;
}

export interface CaseResponse {
  caseId: string;
  caseNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class CaseCreateService {
  private readonly featureFlags = this.launchDarklyService.getFlags();
  constructor(
    private readonly http: HttpClient,
    private readonly applicationContext: ApplicationContext,
    private readonly ngbCalendar: NgbCalendar,
    private readonly ngbDateParserFormatter: NgbDateParserFormatter,
    private readonly storefronService: StorefrontService,
    private readonly authService: AuthService,
    private readonly launchDarklyService: LaunchDarklyService
  ) {}

  /**
   * Submit a case
   *
   * @param caseForm case form values object
   *
   * @returns Observable of CaseResponse
   */
  public submit(caseForm: CaseForm, flag: string): Observable<CaseResponse> {
    const caseObject: CaseObject = this.requestTransform(caseForm);

    return this.caseCreate(caseObject, flag);
  }

  /**
   * Save a support case
   *
   * @param caseObject Support case data object to save
   *
   * @returns Observable of CaseResponse
   */
  public caseCreate(caseObject: CaseObject, flag: string): Observable<CaseResponse> {
    const url: string = `api/support/client/case/create`;

    return this.http.post<CaseResponse>(url, caseObject, {
      params: {
        flagValue: flag
      }
    });
  }

  public getNewEventIds(caseForm: CaseForm, type: string) {
    if (this.featureFlags.featureSFDCRelease15October) {
      if (
        caseForm.caseTypesForm.reason.value === 'inquire-about-a-charge' ||
        caseForm.caseTypesForm.reason.value === 'inquire about an invoice charge' ||
        caseForm.caseTypesForm.reason.value === 'audit-request' ||
        caseForm.caseTypesForm.reason.value === 'document-or-file' ||
        caseForm.caseTypesForm.reason.value === 'security-deposit-refund'
      ) {
        return caseForm.caseTypesForm.reason.eventIdNew;
      } else if (
        caseForm.caseTypesForm.reason.value === 'inquire-about-a-payment' ||
        caseForm.caseTypesForm.reason.value === 'i-dont-see-my-issue-new'
      ) {
        return caseForm.subReasonForm.subReason.eventId;
      } else if (caseForm.caseTypesForm.reason.value === 'credit-terms') {
        return caseForm.subReasonRadioForm.subReason.eventId;
      } else if (caseForm.caseTypesForm.reason.value === 'payment-remittance-advice-submit') {
        return caseForm.subReasonRadioForm.subReason.eventIdNew;
      }
    }
    if (type === 'form') {
      return caseForm.caseTypesForm.reason.eventId;
    } else if (type === 'reasonform') {
      return caseForm.subReasonForm.subReason.eventId;
    } else if (type === 'radioform') {
      return caseForm.subReasonRadioForm.subReason.eventId;
    }
  }

  public getEventId(caseForm: CaseForm): string {
    if (
      !!caseForm &&
      !!caseForm.caseTypesForm &&
      !!caseForm.caseTypesForm.reason &&
      !!caseForm.caseTypesForm.reason.eventId
    ) {
      return this.getNewEventIds(caseForm, 'form');
    } else if (
      !!caseForm &&
      !!caseForm.subReasonForm &&
      !!caseForm.subReasonForm.subReason &&
      !!caseForm.subReasonForm.subReason.eventId
    ) {
      return this.getNewEventIds(caseForm, 'reasonform');
    } else if (
      !!caseForm &&
      !!caseForm.subReasonRadioForm &&
      !!caseForm.subReasonRadioForm.subReason &&
      !!caseForm.subReasonRadioForm.subReason.eventId
    ) {
      return this.getNewEventIds(caseForm, 'radioform');
    }
  }

  /**
   * Transform case form values into a CaseObject.
   *
   * @param caseForm case form values object
   *
   * @returns CaseObject to POST
   */
  public requestTransform(caseForm: CaseForm): CaseObject {
    const user: User = this.authService.getCurrentUser();
    const productOrgInfo: ProductOrganizationInfo = this.applicationContext.productOrgInfo;
    const userName: string = `${user.firstName} ${user.lastName}`;
    const eventid = this.getEventId(caseForm);
    const caseObject: CaseObject = {
      caseCreatorOktaID: productOrgInfo.user.id,
      clientConnectCaseReporterName: userName,
      clientId: this.storefronService.getSingleClientIdForSelectedProductId(
        caseForm.account.supportAppIds,
        caseForm.service.value
      ),
      eventId: eventid,
      merchantCreatedBy: user.email,
      product: caseForm.service.value
    };

    // summary
    if (caseForm.summaryForm) {
      caseObject.summary = caseForm.summaryForm.summary;
    }
    if (caseForm.trackingForm) {
      caseObject.trackingNumber = caseForm.trackingForm.number;
    }

    // description or description with suggestion
    if (caseForm.descriptionForm || caseForm.descriptionWithSuggestionForm) {
      if (caseForm.descriptionForm) {
        caseObject.description = caseForm.descriptionForm.description;
      } else if (caseForm.descriptionWithSuggestionForm) {
        caseObject.description = caseForm.descriptionWithSuggestionForm.description;
      }
    }

    // invoice number or multiple invoice number or invoice credit memo
    if (caseForm.invoiceNumberForm || caseForm.multipleInvoiceNumberForm || caseForm.invoiceOrCreditMemo) {
      if (caseForm.invoiceNumberForm) {
        caseObject.invoiceNumber = caseForm.invoiceNumberForm.number;
      } else if (caseForm.multipleInvoiceNumberForm) {
        caseObject.invoiceNumber = caseForm.multipleInvoiceNumberForm.number;
      } else if (caseForm.invoiceOrCreditMemo) {
        caseObject.invoiceNumber = caseForm.invoiceOrCreditMemo.number;
      }
    }

    // amount number or multiple amount number or payment amount or invoice amount
    if (
      caseForm.amountNumberForm ||
      caseForm.multipleAmountNumberForm ||
      caseForm.paymentAmountForm ||
      caseForm.invoiceAmountForm
    ) {
      if (caseForm.amountNumberForm) {
        caseObject.amountNumber = caseForm.amountNumberForm.number;
      } else if (caseForm.multipleAmountNumberForm) {
        caseObject.amountNumber = caseForm.multipleAmountNumberForm.number;
      } else if (caseForm.paymentAmountForm) {
        caseObject.amountNumber = caseForm.paymentAmountForm.number;
      } else if (caseForm.invoiceAmountForm) {
        caseObject.amountNumber = caseForm.invoiceAmountForm.number;
      }
    }

    // reason form
    if (caseForm.reasonForm) {
      caseObject.reason = caseForm.reasonForm.reason;
    }

    // frequencyForm
    if (caseForm.frequencyForm) {
      caseObject.frequency = caseForm.frequencyForm.frequency;
    }

    // invoiceDocumentOrFile
    if (caseForm.invoiceDocumentOrFile) {
      caseObject.invoiceDocumentOrFile = caseForm.invoiceDocumentOrFile.invoiceDocument;
    }

    // tracking or smart label or usps tracking number
    if (caseForm.trackingOrSmartlabelForm || caseForm.uspsTrackingNumberForm) {
      if (caseForm.trackingOrSmartlabelForm) {
        caseObject.trackingNumber = caseForm.trackingOrSmartlabelForm.number;
      } else if (caseForm.uspsTrackingNumberForm) {
        caseObject.trackingNumber = caseForm.uspsTrackingNumberForm.number;
      }
    }

    // bankTransactionOrCheckNumber
    if (caseForm.bankTransactionOrCheckNumber) {
      caseObject.financialTransactionNumber = caseForm.bankTransactionOrCheckNumber.number;
    }

    // paymentDateWithPresetsValueForm, dueDateForm, date form
    if (
      caseForm.paymentDateWithPresetsValueForm ||
      caseForm.dueDateForm ||
      caseForm.dateForm ||
      caseForm.paymentDateForm
    ) {
      if (caseForm.paymentDateWithPresetsValueForm) {
        caseObject.financeStartDate = caseForm.paymentDateWithPresetsValueForm.financeDate.fromDate;
        caseObject.financeEndDate = caseForm.paymentDateWithPresetsValueForm.financeDate.endDate;
      } else if (caseForm.dueDateForm) {
        caseObject.financeStartDate = caseForm.dueDateForm.financeDate.fromDate;
        caseObject.financeEndDate = caseForm.dueDateForm.financeDate.endDate;
      } else if (caseForm.dateForm) {
        caseObject.financeStartDate = caseForm.dateForm.financeDate.fromDate;
        caseObject.financeEndDate = caseForm.dateForm.financeDate.endDate;
      } else if (caseForm.paymentDateForm) {
        caseObject.financeStartDate = caseForm.paymentDateForm.financeDate;
        caseObject.financeEndDate = caseForm.paymentDateForm.financeDate;
      }
    }

    // reference number
    if (caseForm.referenceNumberForm) {
      caseObject.referenceNumber = caseForm.referenceNumberForm.number;
    }

    // case priority
    if (caseForm.priorityForm) {
      caseObject.casePriority = caseForm.priorityForm.priority;
    }

    // container number
    if (caseForm.containerNumberForm) {
      caseObject.containerNumber = caseForm.containerNumberForm.number;
    }

    // shipment number
    if (caseForm.shipmentNumberForm) {
      caseObject.shipmentNumber = caseForm.shipmentNumberForm.number;
    }

    // direct label
    if (caseForm.directLabelForm) {
      // remove commas from the value (i.e. 700,000 should be 700000)
      const quantity = caseForm.directLabelForm.quantity;
      const quantityNumber = quantity.replace(',', '');

      caseObject.smartLabelQuantity = quantityNumber;
    }

    // intercept package
    if (caseForm.interceptPackageForm) {
      const type = caseForm.interceptPackageForm.requestType;
      const sender = caseForm.interceptPackageForm.senderAddressForm;
      const recipient = caseForm.interceptPackageForm.recipientAddressForm;

      caseObject.interceptRequestType = type;

      // sender
      caseObject.senderName = sender.nameOrCompany;
      caseObject.senderStreetAddress = sender.address;
      caseObject.senderApt = sender.address2;
      caseObject.senderCity = sender.city;
      caseObject.senderState = sender.state;
      caseObject.senderZipCode = sender.zip;

      // recipient
      caseObject.recipientName = recipient.nameOrCompany;
      caseObject.recipientStreetAddress = recipient.address;
      caseObject.recipientApt = recipient.address2;
      caseObject.recipientCity = recipient.city;
      caseObject.recipientState = recipient.state;
      caseObject.recipientZipCode = recipient.zip;

      // redirect
      if (caseForm.interceptPackageForm.redirectAddressForm) {
        const redirect = caseForm.interceptPackageForm.redirectAddressForm;

        // redirect
        caseObject.redirectName = redirect.nameOrCompany;
        caseObject.redirectStreetAddress = redirect.address;
        caseObject.redirectApt = redirect.address2;
        caseObject.redirectCity = redirect.city;
        caseObject.redirectState = redirect.state;
        caseObject.redirectZipCode = redirect.zip;
      }
    }

    // address block
    if (caseForm.addressBlockForm) {
      const type = caseForm.addressBlockForm.type;

      // redirect
      caseObject[`${type}Name`] = caseForm.addressBlockForm.nameOrCompany;
      caseObject[`${type}StreetAddress`] = caseForm.addressBlockForm.address;
      caseObject[`${type}Apt`] = caseForm.addressBlockForm.address2;
      caseObject[`${type}City`] = caseForm.addressBlockForm.city;
      caseObject[`${type}State`] = caseForm.addressBlockForm.state;
      caseObject[`${type}ZipCode`] = caseForm.addressBlockForm.zip;
    }

    // lot number
    if (caseForm.lotNumberForm) {
      caseObject.lotNumber = caseForm.lotNumberForm.number;
    }

    // sku
    if (caseForm.skuForm) {
      caseObject.sku = caseForm.skuForm.number;
    }

    // facility select
    if (caseForm.facilitySelectForm) {
      caseObject.facility = caseForm.facilitySelectForm.facility.value;
    }

    // advanced shipping notice
    if (caseForm.advancedShippingNoticeForm) {
      caseObject.advancedShippingNotice = caseForm.advancedShippingNoticeForm.number;
    }

    // order id
    if (caseForm.orderNumberForm) {
      caseObject.orderNumber = caseForm.orderNumberForm.number;
    }

    // shipment id
    if (caseForm.shipmentIdForm) {
      caseObject.shipmentNumber = caseForm.shipmentIdForm.number;
    }

    // work order id
    if (caseForm.workOrderIdForm) {
      caseObject.workOrderId = caseForm.workOrderIdForm.number;
    }

    // new destination
    if (caseForm.newDestinationForm) {
      caseObject.newDestination = caseForm.newDestinationForm.address;
    }

    // original destination
    if (caseForm.originalDestinationForm) {
      caseObject.originalDestination = caseForm.originalDestinationForm.address;
    }

    // recipient details
    if (caseForm.recipientDetailsForm) {
      caseObject.recipientDetails = caseForm.recipientDetailsForm.address;
    }

    // one or more form
    if (caseForm.oneOrMoreForm) {
      const trackingNumber = caseForm.oneOrMoreForm.trackingNumber;
      const shipmentId = caseForm.oneOrMoreForm.shipmentId;
      const orderNumber = caseForm.oneOrMoreForm.orderNumber;

      if (trackingNumber !== '') {
        caseObject.trackingNumber = trackingNumber;
      }

      if (shipmentId !== '') {
        caseObject.shipmentNumber = shipmentId;
      }

      if (orderNumber !== '') {
        caseObject.orderNumber = orderNumber;
      }
    }

    // one or more form
    if (caseForm.oneOrMoreFormWithoutMandatoryField) {
      const trackingNumber = caseForm.oneOrMoreFormWithoutMandatoryField.trackingNumber;
      const shipmentId = caseForm.oneOrMoreFormWithoutMandatoryField.shipmentId;
      const orderNumber = caseForm.oneOrMoreFormWithoutMandatoryField.orderNumber;

      if (trackingNumber !== '') {
        caseObject.trackingNumber = trackingNumber;
      }

      if (shipmentId !== '') {
        caseObject.shipmentNumber = shipmentId;
      }

      if (orderNumber !== '') {
        caseObject.orderNumber = orderNumber;
      }
    }

    return caseObject;
  }

  /**
   * Returns strings for `fromDate` and `endDate`. If no fromDate passed, will default to today's date.
   * If no endDate, will be the number of `days` prior to the fromDate.
   *
   * @param days number of days prior to current date
   * @param fromDate optional from date as NgbDate
   * @param endDate optional end date as NgbDate
   *
   * @returns
   * Object with fromDate and endDate strings in the format yyyy-MM-dd
   */
  public selectedDaysCount(days: number, fromDate?: NgbDate, endDate?: NgbDate): { fromDate: string; endDate: string } {
    const today: NgbDate = this.ngbCalendar.getToday();

    fromDate = fromDate || this.ngbCalendar.getPrev(today, 'd', days);
    endDate = endDate || this.ngbCalendar.getToday();

    // convert NgbDate to string
    const fromDateString: string = this.ngbDateParserFormatter.format(fromDate);
    const endDateString: string = this.ngbDateParserFormatter.format(endDate);

    return {
      fromDate: fromDateString,
      endDate: endDateString
    };
  }

  public getFaqs(): Observable<questionAndLinks[]> {
    return this.http.get<questionAndLinks[]>('api/dynamiccontent/wordpress/v1/faqs').pipe(
      map((faqs: questionAndLinks[]) => {
        return faqs;
      }),
      catchError(() => {
        return of([]);
      })
    );
  }
}
