<div>
  <div class="page-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 l10nTranslate>BACKORDER.TITLE</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="toolbar margin-bottom">
          <div class="toolbar-left">
            <div ngbDropdown>
              <button
                class="btn btn-secondary btn-sm mr-2 ml-2"
                id="export"
                ngbDropdownToggle
                aria-label="Export"
                l10nTranslate
              >
                <i class="pbi-icon-mini pbi-download"></i>
                BACKORDER.DOWNLOAD
              </button>
              <div ngbDropdownMenu aria-labelledby="export">
                <button class="dropdown-item" (click)="downloadExcel('all')" l10nTranslate>
                  BACKORDER.DOWNLOAD_ALL_DATA
                </button>
                <button class="dropdown-item" (click)="downloadExcel('filtered')" l10nTranslate>
                  BACKORDER.DOWNLOAD_FILTERED_DATA
                </button>
              </div>
            </div>
          </div>

          <div class="toolbar-right">
            <div class="toolbar-divider">&nbsp;</div>
            <div class="input-group">
              <div class="input-group-text">
                <span class="input-group-text" id="basic-addon4" aria-label="Search">
                  <i class="pbi-icon-mini pbi-search" aria-hidden="true"></i>
                </span>
              </div>
              <input
                type="search"
                class="form-control"
                placeholder="{{ searchSKU }}"
                aria-label="Search"
                aria-describedby="basic-addon4"
                [(ngModel)]="productSKU"
                (keypress)="searchBySKU($event)"
                (input)="handleSearchInput($event)"
              />
            </div>
          </div>
        </div>
        <p-table
          #dt
          [value]="backorderedInventoryDetails"
          [columns]="cols"
          responsiveLayout="scroll"
          [paginator]="true"
          [rows]="initialRowsCount"
          [first]="startIndex"
          [totalRecords]="totalRecordCount"
          [showCurrentPageReport]="true"
          [(first)]="startIndex"
          [lazy]="true"
          (onLazyLoad)="getParametersDetails($event)"
          [loading]="isLoading"
          [currentPageReportTemplate]="
            totalRecordCount ? 'Showing {first} to {last} of ' + totalRecordCount + ' entries' : ''
          "
          [rowsPerPageOptions]="[10, 25, 50, 100]"
          [lazyLoadOnInit]="false"
          [filterDelay]="1000"
          [sortField]="sortType.name"
          [sortOrder]="sortType.sortOrder"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns; let i = index"
                scope="col"
                [pSortableColumn]="col.field"
                [pSortableColumnDisabled]="!col.sortable ? true : false"
                [ngStyle]="{ 'text-align': col.align }"
              >
                {{ col.header
                }}<i
                  class="pbi-icon-mini pbi-info-circle-open ml-1 mr-1"
                  ngbTooltip="{{ col.tooltipMsg }}"
                  placement="top"
                  triggers="hover focus:blur"
                  container="body"
                  *ngIf="col.isTooltip"
                ></i>
                <ng-container *ngIf="col.sortable">
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </ng-container>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr>
              <ng-container *ngFor="let column of cols; let inx = index">
                <td [ngStyle]="{ 'text-align': column.align }">
                  <span [ngSwitch]="true">
                    <ng-container *ngSwitchCase="column.field === 'productSKU'">
                      <a>{{ rowData['productDescription'] }}</a
                      ><br />
                      {{ rowData[column.field] }}
                    </ng-container>
                    <ng-container *ngSwitchCase="column.field === 'oldestOrder'">
                      {{ rowData[column.field] | date : 'mediumDate' }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ rowData[column.field] }}
                    </ng-container>
                  </span>
                </td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr *ngIf="!isLoading">
              <td class="text-center" colspan="13">
                <div class="empty-state empty-state-search">
                  <div *ngIf="backorderedInventoryDetails.length === 0">
                    <div class="empty-state-icon" aria-hidden="true">
                      <img src="assets/images/commonIcon/empty-state-icon.svg" />
                    </div>
                    <div class="empty-state-head" l10nTranslate>BACKORDER.NO-RESULTS-FOUND</div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
