import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService, JwtResponse } from '@app/shared/services/auth-service/auth.service';
import {
  ApplicationContext,
  Configuration,
  Organization,
  ProductOrganization
} from '@app/shared/services/application-context/application-context';
import { Storefront, StorefrontService, AngularticsService } from '@gec-shared-services';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  public trackingURL = environment.trackingAPIURL;
  public storefronts: Storefront[];
  public jwtResponse: JwtResponse;

  constructor(
    private readonly authService: AuthService,
    private readonly storefrontService: StorefrontService,
    private readonly applicationContext: ApplicationContext,
    private readonly angularticsService: AngularticsService,
    private readonly router: Router
  ) {}

  public canActivate() {
    // monorepo-mw: call middleware internal api to determine if session is active or not
    return this.authService.isAuthenticated().then(
      (isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.logout();
          return false;
        }

        let rolesOtherThanStartAReturn;
        const storefronts = this.storefrontService.storefronts;
        const roles = this.applicationContext.calculatePermissionsForParentStoreFront(storefronts);
        const roleName = roles.findIndex((role) => {
          return role.toUpperCase() === 'CC_START_RETURN_ONLY';
        });

        if (roleName > -1) {
          const productOrgs: ProductOrganization[] = this.applicationContext.productOrgInfo.productOrganizations;
          let vanityUrl: string | undefined;
          let returnsStorefronts: Organization[];

          productOrgs.forEach((productOrg: ProductOrganization) => {
            returnsStorefronts = productOrg.organizations.filter((org: Organization) => {
              const hasDrs = org.productId.toUpperCase() === 'DRS';
              const hasStartReturn = org.configuration.filter(
                (config: Configuration) =>
                  config.configName.toUpperCase() === 'START_A_RETURN' && config.configValue.toUpperCase() === 'TRUE'
              );
              rolesOtherThanStartAReturn = org.configuration.filter(
                (config: Configuration) =>
                  config.configName.toUpperCase() === 'START_A_RETURN' &&
                  config.configValue.toUpperCase() === 'FALSE' &&
                  roles.length > 1
              );
              const hasVanityUrl = org.configuration.filter(
                (config: Configuration) => config.configName.toUpperCase() === 'VANITY_URL'
              );

              return hasDrs && hasVanityUrl.length && hasStartReturn.length;
            });
          });

          if (rolesOtherThanStartAReturn.length) {
            return true;
          }

          // if any child storefront has start a return, use the first one for the vanity url
          if (returnsStorefronts.length) {
            const vanityConfig = returnsStorefronts[0].configuration.find((config: Configuration) => {
              return config.configName.toUpperCase() === 'VANITY_URL';
            });

            vanityUrl = vanityConfig.configValue;
          }

          this.trackingURL = !!vanityUrl ? vanityUrl : environment.trackingAPIURL;
          this.jwtResponse = this.authService.getJwtResponse();
          this.angularticsService.eventTrack('Return only role', 'Start a Return', 'navigated to Tracking Page');

          window.open(`${this.trackingURL}?#${encodeURIComponent(this.jwtResponse.id_token)}`, '_self');

          return false;
        }

        if (
          isAuthenticated &&
          this.authService.isInternalAccount() &&
          this.applicationContext.productOrgInfo === null
        ) {
          this.router.navigate(['cc-internal', 'choose-account']);
          return false;
        }

        return true;
      },
      () => {
        this.authService.logout();
        return false;
      }
    );
  }
}
