import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, ElementRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { InputNumberModule } from 'primeng/inputnumber';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { FormsModule } from '@angular/forms';

import {
  L10nLoader,
  L10nTranslationModule,
  L10nIntlModule,
  L10nTranslationService,
  L10nTranslationLoader
} from 'angular-l10n';
import { l10nConfig, initL10n, AppStorage, HttpTranslationLoader, l10nPreload } from './l10n-config';

import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AuthService } from '@app/shared/services/auth-service/auth.service';
import { HttpInterceptor } from '@app/shared/interceptor/httpInterceptor';
import { LanguageLocaleService } from '@app/shared/services/language-locale-service/language-locale.service';

import { initApp } from './appInit';
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

//import { initAdobeAnalytics } from './initAdobeAnalytics';
//import { AdobeAnalyticsService } from './shared/services/adobe-analytics/adobe-analytics.service';
import { filter, tap } from 'rxjs/operators';
import { ApplicationContext } from './shared/services/application-context/application-context';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    SharedModule,
    InputNumberModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    L10nTranslationModule.forRoot(l10nConfig, {
      storage: AppStorage,
      translationLoader: HttpTranslationLoader
    }),
    L10nIntlModule,
    Angulartics2Module.forRoot()
  ],
  providers: [
    // !! do not remove or comment this related to Confirmit code and Adobe analitics popup
    /* {
      provide: APP_INITIALIZER,
      useFactory: initAdobeAnalytics,
      deps: [],
      multi: true
    }, */
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AuthService, ApplicationContext],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: l10nPreload,
      deps: [L10nTranslationService, L10nTranslationLoader],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
    NgbDateNativeAdapter
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private readonly scroll: ViewportScroller,
    private readonly languageService: LanguageLocaleService,
    private readonly router: Router,
    //private readonly adobeAnalyticsService: AdobeAnalyticsService,
    private readonly authService: AuthService
  ) {
    this.languageService.setLocale();

    // adobe analytics
    let previousEvent: NavigationEnd = null;
    let currentEvent: NavigationEnd = null;

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          previousEvent = currentEvent;
          currentEvent = event;
        })
      )
      .subscribe(() => {
        // handle duplicate router end events due to access check
        if (previousEvent && previousEvent?.url === currentEvent?.url) {
          return;
        }

        const idpUser = this.authService.getIdpUser();
        //this.adobeAnalyticsService.track(idpUser);
      });

    // scroll to window top on route change
    this.router.events.subscribe((event) => {
      let start = {
        url: '',
        fragment: ''
      };
      let end = {
        url: '',
        fragment: ''
      };

      if (event instanceof NavigationStart) {
        start = splitUrl(this.router.routerState.snapshot.url);
      } else if (event instanceof NavigationEnd) {
        end = splitUrl(this.router.routerState.snapshot.url);
      }

      if (start.url !== end.url && !end.fragment) {
        this.scroll.scrollToPosition([0, 0]);
      }

      function splitUrl(path) {
        path = path.split('#');

        return {
          url: path[0] || '',
          fragment: path[1] || ''
        };
      }
    });
  }
}
