import { Injectable } from '@angular/core';

import { initialize, LDClient, LDFlagSet, LDOptions } from 'launchdarkly-js-client-sdk';

import { set as setFeatureToggle } from 'feature-toggle-service';
import { globalFlags } from '@app/shared/models/launch-darkley-global-flags';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  ldClient: LDClient;
  public launchDarkleyGlobalFlags = globalFlags;
  /**
   * Initialize the LaunchDarkly client.
   *
   * @param clientId {string} - The client id for the LaunchDarkly environment
   * @returns An empty promise that resolves when the LaunchDarkly client is ready
   */
  public init(clientId: string): Promise<void> {
    this.initializeClient(clientId);

    return this.ldClient
      .waitForInitialization()
      .catch((err) => console.log('There was an error getting the feature flags: ', err));
  }

  /**
   * Set the user id for use in LaunchDarkly.
   *
   * @param id {string} - The uniquie id for the LaunchDarkly user. This is passed as the key property to LaunchDarkly.
   */
  public identify(id: string, email?: string, name?: string) {
    this.ldClient.identify({ key: id, anonymous: false, email, name });
  }

  /**
   * Get all feature flags.
   *
   * @returns {LDFlagSet} The current object of feature flags
   */
  public getFlags(): LDFlagSet {
    return this.ldClient.allFlags();
  }

  /**
   * Get the value of a feature flag.
   *
   * @param flag {string} - The name of the flag to check
   * @returns The value of the flag
   */
  public getFlag(flag: string): any {
    const flags: LDFlagSet = this.ldClient.allFlags();

    return flags[flag];
  }

  /**
   * Private method to initialize the LaunchDarkly client.
   *
   * @param clientId {string} - The client id for the LaunchDarkly environment
   * @param user {{anonymous: boolean} } - The user id for the LaunchDarkly user, defaulted to anonymous. Use the identify method to set the user id.
   */
  private initializeClient(clientId: string, user: { anonymous: boolean } = { anonymous: true }): void {
    const options: LDOptions = {
      bootstrap: 'localStorage'
    };

    this.ldClient = initialize(clientId, user, options);

    this.ldClient.on('ready', () => {
      this.setFlags();
    });

    this.ldClient.on('change', () => {
      this.setFlags();
    });
  }

  /**
   * Set the feature flags in the plugin from LaunchDarkly.
   */
  private setFlags(): void {
    const flags = this.ldClient.allFlags();
    setFeatureToggle(flags);
  }

  public getGlobalFlags(): LDFlagSet {
    const featureFlags = this.getFlags();
    if (featureFlags != null || JSON.stringify(featureFlags) != '{}') {
      for (const FFKey in featureFlags) {
        for (const LDkey in this.launchDarkleyGlobalFlags) {
          if (LDkey == FFKey) {
            this.launchDarkleyGlobalFlags[LDkey] = featureFlags[FFKey];
          }
        }
      }
    }
    return this.launchDarkleyGlobalFlags; 
  }
}
