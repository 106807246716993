import { NgModule } from '@angular/core';
import { GecCoreModule } from '@gec-core';
import { StatusIndicatorComponent } from '../status-indicator/status-indicator.component';
import { TextTruncatePipe } from './pipe/text-truncate.pipe';

@NgModule({
  imports: [GecCoreModule],
  declarations: [StatusIndicatorComponent, TextTruncatePipe],
  exports: [GecCoreModule, StatusIndicatorComponent, TextTruncatePipe],
  providers: []
})
export class SharedModule {}
