<div class="modal-header">
  <h4 class="modal-title" l10nTranslate>FORECAST.DISCARD.ERROR.TITLE</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    angulartics2On="click"
    angularticsAction="You have unsaved changes"
    angularticsCategory="navigation"
    angularticsLabel="Close Discard Case Modal"
    (click)="modal.dismiss()"
  ></button>
</div>
<div class="modal-body">
  <p l10nTranslate>FORECAST.DISCARD.ERROR.ERRORFOUND</p>
  <p l10nTranslate>FORECAST.DISCARD.ERROR.WARNING</p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    angulartics2On="click"
    angularticsAction="Discard case?"
    angularticsCategory="navigation"
    angularticsLabel="Yes, Discard"
    (click)="modal.close('Leave Page')"
    l10nTranslate
  >
    FORECAST.DISCARD.ERROR.LEAVE
  </button>
  <button
    type="button"
    l10nTranslate
    class="btn btn-secondary"
    angulartics2On="click"
    angularticsAction="Discard case?"
    angularticsCategory="navigation"
    angularticsLabel="Stay on Page"
    (click)="modal.dismiss('Stay')"
    l10nTranslate
  >
    FORECAST.DISCARD.ERROR.CANCEL
  </button>
</div>
