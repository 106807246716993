import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map, Observable, retry, throwError } from 'rxjs';

import { Storefront, StorefrontConfig } from '@gec-shared-services';
import { User } from '../auth-service/auth.service';

export interface ProductOrganizationInfo {
  user: OmsUser;
  productOrganizations: ProductOrganization[];
}

export interface OmsUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  locale: string;
}

export interface ProductOrganization {
  globalClientId: string;
  globalClientName: string;
  organizations: Organization[];
}

export interface Organization {
  organizationId: string;
  organizationType: string;
  productOrganizationId: string;
  organizationName: string;
  groupId: string;
  groupName: string;
  productId: string;
  configuration: Configuration[];
  productAttributes: ProductAttributes;
  permissions: string[];
}

export interface Configuration {
  configType?: string;
  configName?: string;
  configValue?: string;
}

export interface ProductAttributes {
  selfBills: string;
}

export interface GlobalClientAccount {
  businessUnit: 'COMMERCE SERVICES';
  globalClientId: number;
  globalClientName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationContext {
  private _productOrgInfo: ProductOrganizationInfo;
  public customStoreName: any = {
    globalClientId: '00000',
    globalClientName: 'All My Clients'
  };
  private readonly dataConfig = [
    'data.edr',
    'data.bi',
    'data.induct',
    'data.rvp',
    'data.cycletime',
    'data.rvp_daily',
    'data.expdel_cycle',
    'data.fulfillment',
    'data.fulfillment_velocity',
    'data.returnspackageactivity',
    'data.returnsdeliveredpackages'
    // 'capture.client.forecast' will uncomment after testing
  ];

  constructor(private readonly http: HttpClient) {}

  /**
   * Get OMS information, used in the client login flow
   *
   * @returns Observable of Product Organization Info
   */
  public getOmsInfo(user: User): Observable<ProductOrganizationInfo> {
    if (!user) {
      return throwError(() => new Error('User does not exist'));
    } else {
      const body = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        locale: user.ccbsLocale
      };

      // monorepo-mw: call middleware to get OMS data
      return this.http.post('api/ClientConnect/oms', body).pipe(
        map((productOrgInfo: ProductOrganizationInfo) => {
          this.productOrgInfo = productOrgInfo;
          return productOrgInfo;
        })
      );
    }
  }

  /**
   * Get the OMS info by global client id, used in the internal user login flow
   *
   * @param id number - the global client id
   * ]
   * @returns Observable of type ProductOrganizationInfo
   */
  public getOmsInfoById(id: number): Observable<ProductOrganizationInfo> {
    // get integration user info from JWT token

    const body = {
      firstName: 'Client Connect',
      lastName: 'Internal User',
      email: 'integration@clientconnect.internal',
      locale: 'en_US'
    };
    // monorepo-mw: call middleware to get OMS data with global client id
    return this.http
      .post('api/oms/ClientConnect/oms', body, {
        params: {
          globalClientId: id
        }
      })
      .pipe(
        map((productOrgInfo: ProductOrganizationInfo) => {
          this.productOrgInfo = productOrgInfo;

          return productOrgInfo;
        }),
        retry(3)
      );
  }

  public getOmsInfoByViewAllClient(ids): Observable<ProductOrganizationInfo> {
    // get integration user info from JWT token
    const body = {
      firstName: 'Client Connect',
      lastName: 'Internal User',
      email: 'integration@clientconnect.internal',
      locale: 'en_US'
    };
    // monorepo-mw: call middleware to get OMS data with global client id
    return this.http
      .post('/api/auth/client/list/oms/productOrg', body, {
        params: {
          size: 0,
          globalClientId: ids.toString()
        }
      })
      .pipe(
        map((productOrgInfo: ProductOrganizationInfo) => {
          this.productOrgInfo = productOrgInfo;

          return productOrgInfo;
        }),
        retry(3)
      );
  }

  /**
   * get a list of account that an internal employee has access to
   *
   * @returns Observable of type GlobalClientList
   */
  public getGlobalClientList(): Observable<GlobalClientAccount[]> {
    return this.http
      .get('api/oms/ClientConnect/client/details', {
        params: {
          businessUnit: 'COMMERCE SERVICES'
        }
      })
      .pipe(
        map((response: any) => {
          return response.items;
        }),
        retry(3)
      );
  }

  public getInternalRecentAccounts(): GlobalClientAccount[] {
    return JSON.parse(localStorage.getItem('internalRecentAccounts'));
  }

  public setInternalRecentAccounts(accounts: GlobalClientAccount[]): void {
    localStorage.setItem('internalRecentAccounts', JSON.stringify(accounts));
  }

  // azure-ad: get selected account (globalClientId)
  public getSelectedAccount(): GlobalClientAccount {
    return JSON.parse(localStorage.getItem('selectedAccount'));
  }

  // azure-ad: persist selected account (globalClientId)
  public setSelectedAccount(account: GlobalClientAccount): void {
    localStorage.setItem('selectedAccount', JSON.stringify(account));
  }

  // azure-ad: base64 encode employee sso unique id and the selected merchant/account
  public getEmployeeUniqueIdentifier(user: User): string {
    const uid = user.id;
    const selectedAccount = this.getSelectedAccount();
    if (uid && selectedAccount && selectedAccount.globalClientId) {
      const localStorageStorefront = JSON.parse(localStorage.getItem('selectedStoreFront'));

      if (localStorageStorefront && localStorageStorefront.globalClientId) {
        if (selectedAccount.globalClientId.toString() != localStorageStorefront.globalClientId) {
          return btoa(`${uid}_${localStorageStorefront.globalClientId}`);
        } else {
          return btoa(`${uid}_${selectedAccount.globalClientId}`);
        }
      } else {
        return btoa(`${uid}_${selectedAccount.globalClientId}`);
      }
    } else {
      // backend should parse this as null object and understand something went wrong
      return btoa('null');
    }
  }

  public get productOrgInfo(): ProductOrganizationInfo {
    return this._productOrgInfo;
  }

  public set productOrgInfo(productOrgInfo: ProductOrganizationInfo) {
    this._productOrgInfo = productOrgInfo;
  }

  public calculatePermissionsForParentStoreFront(storeFronts: Storefront[]) {
    let permissions = [];
    storeFronts.forEach((val) => {
      permissions = permissions.concat(val.roles);
    });
    return [...new Set(permissions)];
  }

  private getHideScreens(organization: Organization): string[] {
    let hideScreens = [];
    organization.configuration.forEach((e) => {
      if (e.configType.toLowerCase() === 'ui' && e.configName.toLowerCase() === 'hide-screen') {
        hideScreens = hideScreens.concat(e.configValue.toLowerCase());
      }
    });
    return hideScreens;
  }

  /**
   * Create Store Fronts from OMS response(product organizations)
   *
   * @returns void
   */
  public createStorefrontsAndStore(): Storefront[] {
    let finalStorefronts: Storefront[] = [];
    const oms = this.productOrgInfo;
    if (oms !== null && oms !== undefined) {
      const productOrgs = oms.productOrganizations;

      if (productOrgs.length > 0) {
        let interStorefronts: Storefront[];
        productOrgs.forEach((prodOrg) => {
          interStorefronts = [];
          prodOrg.organizations.forEach((org) => {
            // Create single store front
            const childStorefront = this.createChildStorefront(org, prodOrg.globalClientId);
            // if its custom list then addc 1 attribute to hide the child storefront
            if (prodOrg.globalClientName.toLowerCase() === this.customStoreName.globalClientName.toLowerCase()) {
              childStorefront.isCustomChild = true;
            } else {
              childStorefront.isCustomChild = false;
            }
            // No need to add store front if it's all configurations are empty
            if (
              childStorefront.financeAppIds.length > 0 ||
              childStorefront.supportAppIds.length > 0 ||
              childStorefront.dataAppIds.length > 0 ||
              childStorefront.forecastAppIds.length > 0 ||
              childStorefront.roles.indexOf('CC_START_RETURN_ONLY') > -1
            ) {
              interStorefronts.push(childStorefront);
            }
          });
          // Merge Store Fronts if Organization Id is same of two or more store fronts
          interStorefronts = this.mergeStorefronts(interStorefronts);
          let checkIfAnyStorefrontHasVisibleFalse = false;
          // No need to add parent store front if there is only one child store front
          const storefrontsWithVisibilityFalse = interStorefronts.filter((val) => val.isVisibleToUser === false);
          if (storefrontsWithVisibilityFalse.length > 0) {
            checkIfAnyStorefrontHasVisibleFalse = true;
          }
          if (!!interStorefronts && interStorefronts.length > 1) {
            this.setStorefrontVisibilityFlagtoTrueOnCondition(interStorefronts);
          }

          if ((!!interStorefronts && interStorefronts.length > 1) || checkIfAnyStorefrontHasVisibleFalse) {
            const parentStoreFront = this.createParentStoreFront(prodOrg, interStorefronts);
            // Filter out store fronts which have visible flag true to display on UI
            interStorefronts = interStorefronts.filter((val) => val.isVisibleToUser === true);
            if (interStorefronts.length > 1) {
              interStorefronts.unshift(parentStoreFront);
              interStorefronts.forEach((storefront) => {
                storefront.parentVisibleToUser = true;
              });
            } else {
              /**
               * This condition will execute in any of two conditions:
               * 1. If there is single child store front with visible true(along with others with visible false)
               * 2. If there is no store front with visiblity true
               */
              interStorefronts = [];
              parentStoreFront.inList = true;
              interStorefronts.push(parentStoreFront);
            }
          }

          if (
            !!interStorefronts &&
            interStorefronts.length == 1 &&
            interStorefronts[0].globalClientId === this.customStoreName.globalClientId
          ) {
            interStorefronts[0].name = this.customStoreName.globalClientName;
            //everything is above
            const parentStoreFront = this.createParentStoreFront(prodOrg, interStorefronts);
            // Filter out store fronts which have visible flag true to display on UI
            interStorefronts = interStorefronts.filter((val) => val.isVisibleToUser === true);
            /**
             * This condition will execute in any of two conditions:
             * 1. If there is single child store front with visible true(along with others with visible false)
             * 2. If there is no store front with visiblity true
             */
            interStorefronts = [];
            interStorefronts.push(parentStoreFront);
          } else if (
            !!interStorefronts &&
            interStorefronts.length == 1 &&
            interStorefronts[0].globalClientId != this.customStoreName.globalClientId
          ) {
            interStorefronts[0].isSingleStore = true;
          }
          finalStorefronts = finalStorefronts.concat(interStorefronts);
        });
      }
    }

    return finalStorefronts;
  }

  private setStorefrontVisibilityFlagtoTrueOnCondition(childStorefronts: Storefront[]) {
    // Fetch all store fronts with visibility false
    const storefrontsWithVisibilityFalse = childStorefronts.filter((val) => val.isVisibleToUser === false);
    // Check if childStorefronts length != StorefrontsWithVisibilityFalse length
    if (!!storefrontsWithVisibilityFalse && childStorefronts.length !== storefrontsWithVisibilityFalse.length) {
      // then Find store front which have visibility true
      const storefrontsWithVisibilityTrue = childStorefronts.filter((val) => val.isVisibleToUser === true);
      let visibleTrueProductIds = [];
      // Collect productIds of all store front with visibility true
      storefrontsWithVisibilityTrue.forEach(
        (val) => (visibleTrueProductIds = visibleTrueProductIds.concat(val.productIds))
      );
      if (!!storefrontsWithVisibilityTrue) {
        // Check if any of StorefrontsWithVisibilityFalse's productids is not present
        // in storefrontsWithVisibilityTrue's productids then set visible flag to true
        storefrontsWithVisibilityFalse.forEach((val) => {
          val.productIds.forEach((productId) => {
            if (!visibleTrueProductIds.includes(productId)) {
              // then set store front's visibility to true
              val.isVisibleToUser = true;
              return;
            }
          });
        });
      }
    }
  }

  private createChildStorefront(val: Organization, globalClientId: string) {
    return {
      productIds: [val.productId],
      groupdId: val.groupId,
      globalClientId: globalClientId,
      productOrganizationId: val.productOrganizationId,
      organizationIds: [val.organizationId],
      showSelfbillsFor: JSON.parse(val.productAttributes.selfBills) === true ? [val.productId] : [],
      isshowSelfbillsForOrg: this.getSelfBillsConfigValuesofOrganization('self-bills', val),
      name: val.organizationName,
      financeAppIds: this.getConfigValuesofOrganization('finance', val),
      dataAppIds: this.getConfigValuesofOrganization(this.dataConfig, val),
      supportAppIds: this.getConfigValuesofOrganization('support', val),
      forecastAppIds: this.getConfigValuesofOrganization('capture.client.forecast', val),
      selected: false,
      roles: val.permissions,
      isParent: false,
      isCustomChild: false,
      isSingleStore: false,
      inList: true,
      isVisibleToUser: !val.configuration.some(this.checkIfStorefrontNotVisibleToUser),
      isBoxpoll: val.permissions.includes('CC_INSIGHTS_VIEW'),
      isDochub: this.getDocHub(false, [val]),
      isUserManagement: val.permissions.includes('CC_USER_MANAGEMENT'),
      isStartReturn: this.getStartReturn(false, [val]),
      vanityUrl: this.getVanityURL(false, [val])
      // NOTE: if adding additional properties, make sure to check they are merged properly in the mergeTwoStorefronts method
    } as Storefront;
  }

  private createParentStoreFront(val: ProductOrganization, storeFronts: Storefront[]) {
    return {
      globalClientId: val.globalClientId,
      productIds: this.getProductIdsofProductOrganizations(val),
      showSelfbillsFor: this.calculateSelfBillsVisisbilityForParentStorefront(storeFronts),
      isshowSelfbillsForOrg: this.getSelfBillsConfigValuesofProductOrganization('self-bills', val),
      name: val.globalClientName,
      groupdId: val.globalClientId,
      financeAppIds: this.getConfigValuesofProductOrganization('finance', val),
      dataAppIds: this.getConfigValuesofProductOrganization(this.dataConfig, val),
      supportAppIds: this.getConfigValuesofProductOrganization('support', val),
      forecastAppIds: this.getConfigValuesofProductOrganization('capture.client.forecast', val),
      selected: false,
      roles: this.calculatePermissionsForParentStoreFront(storeFronts),
      isParent: true,
      inList: false,
      isVisibleToUser: true,
      isBoxpoll: val.organizations.some((org: Organization) => org.permissions.includes('CC_INSIGHTS_VIEW')),
      isDochub: this.getDocHub(true, val.organizations),
      isUserManagement: val.organizations.some((org: Organization) => org.permissions.includes('CC_USER_MANAGEMENT')),
      isStartReturn: this.getStartReturn(true, val.organizations),
      vanityUrl: this.getVanityURL(true, val.organizations)
      // NOTE: if adding additional properties, make sure to check they are merged properly in the mergeTwoStorefronts method
    } as Storefront;
  }

  public getDocHub(isParent: boolean = false, organizations: Organization[]): boolean {
    let isDocHub = false;

    // if parent, find any child with dochub
    if (isParent) {
      isDocHub = organizations.some((org: Organization) => {
        const isDocHubPermission = org.permissions.includes('CC_DOC_HUB_ACCESS');
        const hasDocHubConfig = org.configuration.filter((config: Configuration) => {
          return config.configType.toLowerCase() === 'document.hub' && config.configValue.toLowerCase() === 'true';
        });

        return hasDocHubConfig.length > 0 && isDocHubPermission;
      });
    }

    // if child, check for dochub
    if (!isParent) {
      organizations.forEach((org: Organization) => {
        const isDocHubPermission = org.permissions.includes('CC_DOC_HUB_ACCESS');

        const hasDocHubConfig = org.configuration.filter((config: Configuration) => {
          return config.configType.toLowerCase() === 'document.hub' && config.configValue.toLowerCase() === 'true';
        });

        isDocHub = hasDocHubConfig.length > 0 && isDocHubPermission;
      });

      return isDocHub;
    }

    return isDocHub;
  }

  public getStartReturn(isParent: boolean = false, organizations: Organization[]): boolean {
    let isStartReturn = false;

    // if parent, find any child with start a return
    if (isParent) {
      const returnsStorefronts = organizations.filter((org: Organization) => {
        const hasDrs = org.productId === 'drs';
        const hasStartReturn = org.configuration.filter(
          (config: Configuration) =>
            config.configName === 'start_a_return' && config.configValue.toLowerCase() === 'true'
        );

        return hasDrs && hasStartReturn.length;
      });

      isStartReturn = returnsStorefronts.length ? true : false;
    }

    // if child, check for start a return
    if (!isParent) {
      let hasStartReturn = false;

      organizations.forEach((org: Organization) => {
        org.configuration.forEach((config: Configuration) => {
          if (config.configName === 'start_a_return' && config.configValue === 'TRUE') {
            hasStartReturn = true;
          }
        });
      });

      isStartReturn = hasStartReturn;
    }

    return isStartReturn;
  }

  /**
   * Get vanity URL.
   * For a parent storefront, get the first child with start a return, else returns (drs) or delivery (dds).
   * For a child storefront, use returns (drs), delivery (dds), otherwise any vanity url.
   */
  public getVanityURL(isParent: boolean = false, organizations: Organization[]): string {
    let vanityUrl: string | undefined;

    // if parent, find any child with start a return
    if (isParent) {
      const returnsStorefronts: Organization[] = organizations.filter((org: Organization) => {
        const hasDrs = org.productId === 'drs';
        const hasStartReturn = org.configuration.filter(
          (config: Configuration) => config.configName === 'start_a_return' && config.configValue === 'TRUE'
        );
        const hasVanityUrl = org.configuration.filter((config: Configuration) => config.configName === 'vanity_url');

        return hasDrs && hasStartReturn.length && hasVanityUrl.length;
      });

      // if any child storefront has start a return, use the first one for the vanity url
      if (returnsStorefronts.length) {
        const vanityConfig = returnsStorefronts[0].configuration.find((config: Configuration) => {
          return config.configName === 'vanity_url';
        });

        vanityUrl = vanityConfig.configValue;
      }
    }

    if (!vanityUrl) {
      const organization = organizations[0];

      const config: Configuration = organization.configuration.find(
        (config: Configuration) => config.configType === 'tracking' && config.configName === 'vanity_url'
      );

      if (config) {
        vanityUrl = config.configValue;
      }
    }

    return vanityUrl;
  }

  /**
   * Get a list of all productOrganizationId from each Organization
   *
   * @returns Array of product ids as strings
   */
  public getOrganizationIdList(): string[] {
    const organizationIdList: string[] = [];
    const productOrgInfo = this.productOrgInfo;

    productOrgInfo.productOrganizations.forEach((productOrg: ProductOrganization) => {
      productOrg.organizations.forEach((organization: Organization) => {
        organizationIdList.push(organization.productOrganizationId);
      });
    });

    return organizationIdList;
  }

  /**
   * Merge two or more store fronts into one
   *
   * @note product id and organization id combination is unique for any organization,
   * Needs to merge all store fronts which have same organization Ids.
   * Ex: Store Front 1: organizationId: 3434, productId: DDS
   *     Store Front 2: organizationId: 3434, productId: DRS
   * Final Store front would be: organizationId: 3434, productId: [DDS, DRS]
   *
   * @param storefronts Storefront[]
   *
   */
  private mergeStorefronts(storefronts: Storefront[]): Storefront[] {
    if (storefronts.length < 2) {
      return storefronts;
    }
    for (let i = 0; i < storefronts.length; i++) {
      let isSameElementPresent = false;
      let calStorefronts = [];
      if (storefronts.length < 2) {
        return storefronts;
      }
      for (let k = 0; k <= i; k++) {
        calStorefronts.push(storefronts[k]);
      }
      for (let j = i + 1; j < storefronts.length; j++) {
        if (storefronts[i].name === storefronts[j].name) {
          // If found same, remove old store front(with same name) from list
          calStorefronts = calStorefronts.filter((e) => e.name !== storefronts[i].name);
          calStorefronts.push(this.mergeTwoStorefronts(storefronts[i], storefronts[j]));
          isSameElementPresent = true;
        } else {
          calStorefronts.push(storefronts[j]);
        }
      }
      if (isSameElementPresent) {
        i--;
        storefronts = calStorefronts;
      }
    }
    return storefronts;
  }

  private mergeTwoStorefronts(previousStorefront: Storefront, currentStorefront: Storefront): Storefront {
    previousStorefront.productIds = [...new Set(previousStorefront.productIds.concat(currentStorefront.productIds))];

    previousStorefront.supportAppIds = [
      ...new Set(previousStorefront.supportAppIds.concat(currentStorefront.supportAppIds))
    ];

    previousStorefront.financeAppIds = [
      ...new Set(previousStorefront.financeAppIds.concat(currentStorefront.financeAppIds))
    ];

    previousStorefront.dataAppIds = [...new Set(previousStorefront.dataAppIds.concat(currentStorefront.dataAppIds))];

    previousStorefront.forecastAppIds = [
      ...new Set(previousStorefront.forecastAppIds.concat(currentStorefront.forecastAppIds))
    ];

    previousStorefront.isVisibleToUser = previousStorefront.isVisibleToUser || currentStorefront.isVisibleToUser;

    previousStorefront.showSelfbillsFor = [
      ...new Set(previousStorefront.showSelfbillsFor.concat(currentStorefront.showSelfbillsFor))
    ];

    previousStorefront.roles = [...new Set(previousStorefront.roles.concat(currentStorefront.roles))];

    previousStorefront.organizationIds = [
      ...new Set(previousStorefront.organizationIds.concat(currentStorefront.organizationIds))
    ];

    previousStorefront.isBoxpoll = previousStorefront.isBoxpoll || currentStorefront.isBoxpoll;

    previousStorefront.isDochub = previousStorefront.isDochub || currentStorefront.isDochub;

    previousStorefront.isUserManagement = previousStorefront.isUserManagement || currentStorefront.isUserManagement;

    previousStorefront.isStartReturn = previousStorefront.isStartReturn || currentStorefront.isStartReturn;

    previousStorefront.vanityUrl = previousStorefront.vanityUrl || currentStorefront.vanityUrl;

    return previousStorefront;
  }

  private getProductIdsofProductOrganizations(prodOrg: ProductOrganization) {
    const productIds: Set<string> = new Set();
    prodOrg.organizations.forEach((val) => {
      productIds.add(val.productId);
    });
    return Array.from(productIds.values());
  }

  private getSelfBillsConfigValuesofOrganization(configType: string, organization: Organization) {
    let isSelfBillsConfigForOrg = false;
    organization.configuration.forEach((conf) => {
      if (conf.configType.toLowerCase() === configType && conf.configValue.toUpperCase() === 'TRUE') {
        isSelfBillsConfigForOrg = true;
        return;
      }
    });
    return isSelfBillsConfigForOrg;
  }

  private getSelfBillsConfigValuesofProductOrganization(configType: string, prodOrg: ProductOrganization) {
    let isSelfBillsConfigForOrg = false;
    prodOrg.organizations.forEach((organization) => {
      organization.configuration.forEach((conf) => {
        if (conf.configType.toLowerCase() === configType && conf.configValue.toUpperCase() === 'TRUE') {
          isSelfBillsConfigForOrg = true;
          return;
        }
      });
    });
    return isSelfBillsConfigForOrg;
  }

  private getConfigValuesofOrganization(configType: string[] | string, organization: Organization) {
    const configValues: Set<StorefrontConfig> = new Set();
    organization.configuration.forEach((conf) => {
      if (configType.includes(conf.configType.toLowerCase())) {
        const configValue = this.appendZeroIfConfigTypeIsFinance(conf.configType, conf.configValue);
        const storeConfig = this.createconfigValueKey(
          configValue,
          organization.productId,
          conf.configType,
          conf.configName,
          organization
        );
        if (!!storeConfig) {
          configValues.add(storeConfig);
        }
      }
    });
    return Array.from(configValues.values());
  }

  /**
   * Get vanity url for organization
   * @param organization
   *
   * @returns vanity url: string
   */
  private getVanityURLForOrganization(organization: Organization): string {
    const config: Configuration = organization.configuration.find(
      (e) => e.configType.toLowerCase() === 'tracking' && e.configName.toLowerCase() === 'vanity_url'
    );
    return !!config ? config.configValue : undefined;
  }

  private createconfigValueKey(
    configValue: string,
    productId: string,
    configType: string,
    configName: string,
    organization: Organization
  ): StorefrontConfig {
    let role;
    if (configType.toLowerCase() === 'support') {
      if (organization.permissions.includes('CC_SUPPORT_CREATE')) {
        role = 'CC_SUPPORT_CREATE';
      } else if (organization.permissions.includes('CC_SUPPORT_UPDATE')) {
        role = 'CC_SUPPORT_UPDATE';
      } else if (organization.permissions.includes('CC_SUPPORT_VIEW')) {
        role = 'CC_SUPPORT_VIEW';
      }
    } else if (configType.toLowerCase() === 'finance' && organization.permissions.includes('CC_BILLING_VIEW')) {
      role = 'CC_BILLING_VIEW';
    } else if (
      configType.toLowerCase() === 'data.edr' ||
      configType.toLowerCase() === 'data.induct' ||
      configType.toLowerCase() === 'data.bi' ||
      configType.toLowerCase() === 'data.rvp' ||
      configType.toLowerCase() === 'data.cycletime' ||
      configType.toLowerCase() === 'data.rvp_daily' ||
      configType.toLowerCase() === 'data.fulfillment' ||
      configType.toLowerCase() === 'data.fulfillment_velocity' ||
      configType.toLowerCase() === 'data.returnspackageactivity' ||
      configType.toLowerCase() === 'data.returnsdeliveredpackages' ||
      // configType.toLowerCase() === 'capture.client.forecast' ||  will uncomment after testing
      configType.toLowerCase() === 'data.expdel_cycle'
    ) {
      if (
        organization.permissions.includes('CC_INSIGHTS_VIEW') ||
        organization.permissions.includes('CC_ANALYTICS_VIEW')
      ) {
        role = 'CC_ANALYTICS_VIEW';
      }
       else if (
        
        organization.permissions.includes('CC_ANALYTICS_EDR')
      ) {
        role = 'CC_ANALYTICS_EDR';
      }
    } else if (configType.toLowerCase() === 'capture.client.forecast') {
      role = 'CC_CAPTURE_CLIENT_FORECAST_ReadWrite';
    }

    if (role === undefined || configValue === undefined) {
      return undefined;
    }
    const vanityURL = this.getVanityURLForOrganization(organization);
    return {
      id: configValue,
      name: configName,
      type: configType,
      productId,
      permission: role,
      hideScreens: this.getHideScreens(organization),
      organizationName: organization.organizationName,
      vanityURL,
      productOrganizationId: organization.productOrganizationId,
      organizationId: organization.organizationId
    } as StorefrontConfig;
  }

  private checkIfStorefrontNotVisibleToUser(conf: Configuration) {
    return (
      conf.configType.toLowerCase() === 'client_connect_ui' &&
      conf.configName.toLowerCase() === 'visible_on_client_connect' &&
      JSON.parse(conf.configValue.toLowerCase()) === false
    );
  }

  private getConfigValuesofProductOrganization(configType: string[] | string, prodOrg: ProductOrganization) {
    const configValues: Set<StorefrontConfig> = new Set();
    prodOrg.organizations.forEach((organization) => {
      organization.configuration.forEach((conf) => {
        if (configType.includes(conf.configType.toLowerCase())) {
          const configValue = this.appendZeroIfConfigTypeIsFinance(conf.configType, conf.configValue);
          const storeConfig = this.createconfigValueKey(
            configValue,
            organization.productId,
            conf.configType,
            conf.configName,
            organization
          );
          if (!!storeConfig) {
            configValues.add(storeConfig);
          }
        }
      });
    });
    return Array.from(configValues.values());
  }

  private calculateSelfBillsVisisbilityForParentStorefront(storefronts: Storefront[]) {
    let selfBillsFor = [];
    storefronts.forEach((val) => {
      selfBillsFor = selfBillsFor.concat(val.showSelfbillsFor);
    });
    return [...new Set(selfBillsFor)];
  }

  private appendZeroIfConfigTypeIsFinance(configType: string, configValue: string) {
    return configType.toLowerCase() === 'finance' ? configValue.padStart(10, '0') : configValue;
  }
}
