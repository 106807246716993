import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';
import { L10nTranslationService } from 'angular-l10n';

import { HttpClient } from '@angular/common/http';
import { StorefrontService } from '@gec-shared-services';
import { AuthService } from '../auth-service/auth.service';

export interface ToursSequence {
  tourName: string;
  tourViewed: boolean;
  tourDescription: string;
  tourSequence: number;
}

@Injectable({
  providedIn: 'root'
})
export class TourService {
  public tours: any;

  constructor(
    private readonly shepherdService: ShepherdService,
    private readonly l10nTranslationService: L10nTranslationService,
    private readonly http: HttpClient,
    private readonly storefrontService: StorefrontService,
    private readonly authService: AuthService
  ) {
    const isTokenExpired = this.authService.isTokenExpired();
    if (!this.tours && isTokenExpired === false) {
      this.authService
        .getUserDetails()
        .pipe(
          take(1),
          tap((response: any) => (this.tours = response.toursList))
        )
        .subscribe();
    }
  }

  public setTours(tours) {
    this.tours = tours;
  }

  /**
   * To check if the tour is already viewed or not
   * @param lPage landing page
   * @param tName tour name
   * @returns
   */
  public getTourViewedStatus(lPage: string, tName: string) {
    return this.tours.landingPage
      .find((page) => page.landingPageName.toLowerCase() === lPage)
      .tours.find((tourGlobal) => tourGlobal.tourName.toLowerCase() === tName);
  }

  /**
   *
   * @returns Invokes storefront tour
   */
  public storefrontMenu(): void {
    const acceptedTermsConditions = this.authService.isTermsConditionsAccepted;

    if (!acceptedTermsConditions) {
      return;
    }

    const tour = this.getTourViewedStatus('home', 'storefront_menu');

    if (tour === undefined || tour.tourViewed) {
      this.checkForInvoicesTour();
    } else {
      const translate = this.l10nTranslationService.translate('TOUR.HOME.STOREFRONTMENU');
      const buttonText = translate.BUTTON;
      const stepText = translate.TEXT;

      const steps: any = [
        {
          id: 'storefront-menu',
          canClickTarget: false,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
          },
          attachTo: {
            element: '#storefront-menu',
            on: 'bottom'
          },
          text: [stepText],
          buttons: [
            {
              classes: 'btn btn-link btn-block',
              text: buttonText,
              action: () => {
                this.updateTour('home', 'storefront_menu')
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.tours.landingPage.forEach((n) => {
                      if (n.landingPageName.toLowerCase() === 'home') {
                        n.tours.forEach((tourGlobal) => {
                          if (tourGlobal.tourName.toLowerCase() === 'storefront_menu') {
                            tourGlobal.tourViewed = result;
                          }
                        });
                      }
                    });
                    this.shepherdService.complete();
                    this.checkForInvoicesTour();
                  });
              }
            }
          ]
        }
      ];

      this.shepherdService.modal = true;
      this.shepherdService.addSteps(steps);
      this.shepherdService.defaultStepOptions = {
        classes: 'shepherd-storefront-menu-tour',
        scrollTo: false,
        cancelIcon: {
          enabled: false
        }
      };

      this.shepherdService.start();
    }
  }
  /**
   * check if Invoices tour should be displayed or MFA Tour
   */
  public checkForInvoicesTour() {
    if (this.storefrontService.checkIfScreenVisible('billing', 'invoices-transactions')) {
      this.invoicesTour();
    } else {
      this.mfaTour();
    }
  }

  /**
   *
   * @returns Invokes MFA tour
   */
  public mfaTour(): void {
    const acceptedTermsConditions = this.authService.isTermsConditionsAccepted;
    if (!acceptedTermsConditions) {
      return;
    }
    const tour = this.getTourViewedStatus('home', 'mfa_tour');
    if (tour === undefined || tour.tourViewed || this.authService.isEnterpriseUser()) {
      return;
    }
    const translate = this.l10nTranslationService.translate('TOUR.HOME.MFATOUR');
    const buttonText = translate.BUTTON;
    const stepText = translate.TEXT;
    const steps: any = [
      {
        id: 'settingsMenu',
        canClickTarget: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
        },
        attachTo: {
          element: '#settingsMenu',
          on: 'bottom'
        },
        text: [stepText],
        buttons: [
          {
            classes: 'btn btn-link btn-block',
            text: buttonText,
            action: () => {
              this.updateTour('home', 'mfa_tour')
                .pipe(take(1))
                .subscribe((result) => {
                  this.tours.landingPage.forEach((n) => {
                    if (n.landingPageName.toLowerCase() === 'home') {
                      n.tours.forEach((tourGlobal) => {
                        if (tourGlobal.tourName.toLowerCase() === 'mfa_tour') {
                          tourGlobal.tourViewed = result;
                        }
                      });
                    }
                  });
                  this.shepherdService.complete();
                });
            }
          }
        ]
      }
    ];
    this.shepherdService.modal = true;
    this.shepherdService.addSteps(steps);
    this.shepherdService.defaultStepOptions = {
      classes: 'shepherd-storefront-menu-tour',
      scrollTo: false,
      cancelIcon: {
        enabled: false
      }
    };
    this.shepherdService.start();
  }

  /**
   *
   * Invokes Invoices tour
   */
  public invoicesTour(): void {
    const acceptedTermsConditions = this.authService.isTermsConditionsAccepted;
    if (!acceptedTermsConditions) {
      return;
    }
    const tour = this.getTourViewedStatus('home', 'invoices_tour');
    if (tour === undefined || tour.tourViewed) {
      this.mfaTour();
    } else {
      const translate = this.l10nTranslationService.translate('TOUR.HOME.INVOICESTOUR');
      const buttonText = translate.BUTTON;
      const stepText = translate.TEXT;
      const steps: any = [
        {
          id: 'invoicesWidget',
          canClickTarget: false,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
          },
          attachTo: {
            element: '#invoicesWidget',
            on: 'right'
          },
          text: [stepText],
          buttons: [
            {
              classes: 'btn btn-link btn-block',
              text: buttonText,
              action: () => {
                this.updateTour('home', 'invoices_tour')
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.tours.landingPage.forEach((n) => {
                      if (n.landingPageName.toLowerCase() === 'home') {
                        n.tours.forEach((tourGlobal) => {
                          if (tourGlobal.tourName.toLowerCase() === 'invoices_tour') {
                            tourGlobal.tourViewed = result;
                          }
                        });
                      }
                    });
                    this.shepherdService.complete();
                    this.mfaTour();
                  });
              }
            }
          ]
        }
      ];
      this.shepherdService.modal = true;
      this.shepherdService.addSteps(steps);
      this.shepherdService.defaultStepOptions = {
        classes: 'shepherd-storefront-menu-tour',
        scrollTo: false,
        cancelIcon: {
          enabled: false
        }
      };
      this.shepherdService.start();
    }
  }

  /**
   *
   * @returns Invokes Case List tour
   */
  public caseListTour(): void {
    const translate = this.l10nTranslationService.translate('TOUR.SUPPORT.CASES.TABLETIPS');

    const tour = this.getTourViewedStatus('support', 'case_create');

    if (tour === undefined || tour.tourViewed) {
      return;
    }

    const steps: any = [
      {
        id: 'step1',
        canClickTarget: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
        },
        attachTo: {
          element: '#togColumn',
          on: 'left'
        },
        text: [translate.STEP1.TEXT],
        buttons: [
          {
            classes: 'btn btn-link btn-block m-0',
            text: translate.STEP1.BUTTON.NEXT,
            action: () => {
              this.shepherdService.next();
            }
          }
        ]
      },
      {
        id: 'step2',
        canClickTarget: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
        },
        attachTo: {
          element: '#caseFilter',
          on: 'bottom'
        },
        text: [translate.STEP2.TEXT],
        buttons: [
          {
            classes: 'btn btn-link btn-block m-0',
            text: translate.STEP2.BUTTON.PREVIOUS,
            action: () => {
              this.shepherdService.back();
            }
          },
          {
            classes: 'btn btn-link btn-block m-0',
            text: translate.STEP2.BUTTON.GOTIT,
            action: () => {
              this.updateTour('support', 'case_create')
                .pipe(take(1))
                .subscribe((result) => {
                  this.tours.landingPage.forEach((n) => {
                    if (n.landingPageName.toLowerCase() === 'support') {
                      n.tours.forEach((tourGlobal) => {
                        if (tourGlobal.tourName.toLowerCase() === 'case_create') {
                          tourGlobal.tourViewed = result;
                        }
                      });
                    }
                  });
                  this.shepherdService.complete();
                });
            }
          }
        ]
      }
    ];

    this.shepherdService.modal = true;

    this.shepherdService.addSteps(steps);

    this.shepherdService.defaultStepOptions = {
      classes: 'shepherd-tour',
      scrollTo: false,
      cancelIcon: {
        enabled: false
      }
    };
    this.shepherdService.start();
  }

  /**
   *
   * @returns Invokes Column tour
   */
  public columnTour() {
    const acceptedTermsConditions = this.authService.isTermsConditionsAccepted;

    if (!acceptedTermsConditions) {
      return;
    }

    const tour = this.getTourViewedStatus('analytics', 'edr_columns');

    if (tour === undefined || tour.tourViewed) {
      return;
    }

    const translate = this.l10nTranslationService.translate('TOUR.ANALYTICS.EDR-COLUMNS');

    const buttonText = translate.BUTTON;
    const stepText = translate.TEXT;

    const steps: any = [
      {
        id: 'edr-columns',
        canClickTarget: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
        },
        attachTo: {
          element: '#smallColumnToggle',
          on: 'bottom'
        },
        text: [stepText],
        buttons: [
          {
            classes: 'btn btn-link btn-block',
            text: buttonText,
            action: () => {
              this.shepherdService.complete();
              this.updateTour('analytics', 'edr_columns')
                .pipe(take(1))
                .subscribe((result) => {
                  this.tours.landingPage.forEach((n) => {
                    if (n.landingPageName.toLowerCase() === 'analytics') {
                      n.tours.forEach((tourGlobal) => {
                        if (tourGlobal.tourName.toLowerCase() === 'edr_columns') {
                          tourGlobal.tourViewed = result;
                        }
                      });
                    }
                  });
                });
            }
          }
        ]
      }
    ];

    this.shepherdService.modal = true;
    this.shepherdService.addSteps(steps);
    this.shepherdService.defaultStepOptions = {
      classes: 'shepherd-columns-menu-tour',
      scrollTo: false,
      cancelIcon: {
        enabled: false
      }
    };

    this.shepherdService.start();
  }

  /**
   *
   * Invokes Invoices tour
   */
  public needTrackingUpdateTour(): void {
    const acceptedTermsConditions = this.authService.isTermsConditionsAccepted;
    if (!acceptedTermsConditions) {
      return;
    }
    const tour = this.getTourViewedStatus('support', 'needtrackingupdate_tour');
    if (tour === undefined || tour.tourViewed) {
      this.mfaTour();
    } else {
      const translate = this.l10nTranslationService.translate('TOUR.SUPPORT.NEEDTRACKINGUPDATE');
      const buttonText = translate.BUTTON;
      const stepText = translate.TEXT;
      const steps: any = [
        {
          id: 'needTrackingUpdate',
          canClickTarget: false,
          popperOptions: {
            modifiers: [{ name: 'offset', options: { offset: [0, 10] } }]
          },
          attachTo: {
            element: '#needTrackingUpdateTour',
            on: 'right'
          },
          modalOverlayOpeningPadding: 15,
          text: [stepText],
          buttons: [
            {
              classes: 'btn btn-link btn-block',
              text: buttonText,
              action: () => {
                this.updateTour('support', 'needtrackingupdate_tour')
                  .pipe(take(1))
                  .subscribe((result) => {
                    this.tours.landingPage.forEach((n) => {
                      if (n.landingPageName.toLowerCase() === 'support') {
                        n.tours.forEach((tourGlobal) => {
                          if (tourGlobal.tourName.toLowerCase() === 'needtrackingupdate_tour') {
                            tourGlobal.tourViewed = result;
                          }
                        });
                      }
                    });
                    this.shepherdService.complete();
                    this.mfaTour();
                  });
              }
            }
          ]
        }
      ];
      this.shepherdService.modal = true;
      this.shepherdService.addSteps(steps);
      this.shepherdService.defaultStepOptions = {
        classes: 'shepherd-storefront-menu-tour',
        scrollTo: false,
        cancelIcon: {
          enabled: false
        }
      };
      this.shepherdService.start();
    }
  }

  public cancel() {
    this.shepherdService.cancel();
  }

  /**
   * invoke api to set the tour viewed
   * @param landingPage
   * @param tourName
   * @returns
   */
  private updateTour(landingPage: string, tourName: string) {
    const tourRequest = {
      landingPage,
      tourName
    };

    return this.http.post('api/auth/user/info/tour/updated', tourRequest);
  }
}
