import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StorefrontService } from '@gec-shared-services';
import { FileUploadResponse } from '@app/shared/services/support-service/support.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {
  public forecastError: boolean = false;
  public forecastContextSwitcher: boolean = false;
  public forecastURL: string = '';

  constructor(
    private readonly http: HttpClient,
    private readonly storefrontService: StorefrontService,
    private readonly router: Router
  ) {}

  public getForecastDataForParentStoreFront(productOrgInfo, isCEMView): Observable<any> {
    if (isCEMView) {
      this.forecastURL = `api/analytics/forecastReport/getCemData`;
    } else {
      this.forecastURL = `api/analytics/forecastReport/getData`;
    }
    return this.http
      .post<any>(this.forecastURL, productOrgInfo)
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getSelectedFacilityForChildStoreFront(storeFrontConfig): Observable<any> {
    return this.http
      .post(`api/analytics/forecastReport/get/facility`, storeFrontConfig)
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getAllAvailableFacilitiesList(): Observable<any> {
    return this.http.get(`../../../assets/topo/facilities.json`);
  }

  public updateFacilitiesForChildStoreFront(facilityConfig): Observable<any> {
    return this.http
      .post(`api/analytics/forecastReport/add/facility`, facilityConfig)
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public savePackageCountForChildStoreFront(storeFront): Observable<any> {
    return this.http
      .post(`api/analytics/forecastReport/updateForecast`, storeFront)
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public savePackageCountForChildStoreFrontCEM(storeFront): Observable<any> {
    return this.http
      .post(`api/analytics/forecastReport/updateCemForecast`, storeFront)
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getAllAvailableChildStoreFrontList(ProductOrg): Observable<any> {
    return this.http.get(`../../../assets/topo/child-store-front.json`);
  }

  public getCurrentWeekNumber(): Observable<any> {
    return this.http
      .get('api/analytics/forecastReport/weekNumber')
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getDeliveryActualsPackages(organizationIds, merchantIds, gid): Observable<any> {
    return this.http
      .get(
        `api/analytics/forecastReport/deliveryActualsPackages?organizationIds=${organizationIds}&merchantIds=${merchantIds}&gid=${gid}`
      )
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getReturnsActualsPackages(organizationIds, merchantIds, gid): Observable<any> {
    return this.http
      .get(
        `api/analytics/forecastReport/returnsActualsPackages?organizationIds=${organizationIds}&merchantIds=${merchantIds}&gid=${gid}`
      )
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getWeekDetails(): Observable<any> {
    return this.http
      .get('api/analytics/forecastReport/WeekDetails')
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public getDeliveryForecastGraph(merchantIds, organizationIds, gid): Observable<any> {
    return this.http.get(
      `api/analytics/forecastReport/deliveryForecastGraph?organizationIds=${organizationIds}&merchantIds=${merchantIds}&gid=${gid}`
    );
  }

  public getReturnsForecastGraph(merchantIds, organizationIds, gid): Observable<any> {
    return this.http.get(
      ` api/analytics/forecastReport/returnsForecastGraph?organizationIds=${organizationIds}&merchantIds=${merchantIds}&gid=${gid}`
    );
  }

  // kd
  // For Download Delivery Cycle Time Data
  public downloadForecastTemplate(): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append('Accept', 'application/csv,application/zip,application/octet-stream');

    return this.http
      .get(`api/analytics/forecastReport/template/download`, {
        headers,
        responseType: 'blob'
      })
      .pipe(catchError((err) => this.handleErrorForeCast(err)));
  }

  public uploadAttachments(files: File[],username:string,gid:string): Observable<FileUploadResponse> {
    const formData: FormData = new FormData();

    for (const file of files) {
      formData.append('file', file, file.name);
    }

    return this.http.post<FileUploadResponse>(`api/analytics/forecastReport/template/upload`, formData, {
      params: {username,gid}
    });
  }

  // kd

  private handleErrorForeCast(errorRespose: HttpErrorResponse) {
    let errorResponseMessage: {
      status?: number;
      message?: string;
      isError?: boolean;
      error?: string;
    };
    const errorStatus = errorRespose.status;
    switch (errorStatus) {
      case 401:
        this.router.navigate(['logout']);
        break;
      default:
        errorResponseMessage = {
          error: 'Internal Server Error'
        };
        break;
    }
    return throwError(errorResponseMessage);
  }
}
