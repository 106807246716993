import { Injectable } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { ToastrService } from 'ngx-toastr';
import { StorefrontService } from '@gec-shared-services';
import { ReceivingDocument } from '../models/receiving.model';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(
    private readonly toastrService: ToastrService,
    private readonly l10nTranslationService: L10nTranslationService,
    private readonly storefrontService: StorefrontService
  ) {}

  toasterOnScreenTime: number = 2500; //time in milliseconds

  public success(message: string, title?: string) {
    this.toastrService.success(this.l10nTranslationService.translate(message), title, {
      extendedTimeOut: this.toasterOnScreenTime
    });
  }

  public dynamicSuccessMessage(localizationKey: string, replaceWord: string, replaceWith: any, title?: string) {
    this.toastrService.success(this.translate(localizationKey).replace(replaceWord, replaceWith), '', {
      extendedTimeOut: this.toasterOnScreenTime,
      enableHtml: true
    });
  }

  public error(message: string, title?: string) {
    this.toastrService.error(this.l10nTranslationService.translate(message), title, {
      extendedTimeOut: this.toasterOnScreenTime
    });
  }

  public warning(message: string, title?: string) {
    this.toastrService.warning(this.l10nTranslationService.translate(message), title, {
      extendedTimeOut: this.toasterOnScreenTime
    });
  }

  public translate(keys: string | string[], params?: any, language?: string): string {
    return this.l10nTranslationService.translate(keys);
  }

  public async convertFileToBase64(file: File): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result?.toString().split(',').pop());
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  public async createDocumentUploadRequest(files: File[]): Promise<ReceivingDocument[]> {
    const receivingDocuments = new Array<ReceivingDocument>();
    for (let file of files) {
      const document = new ReceivingDocument();
      document.asnDocumentName = file.name;
      document.asnDocumentData = await this.convertFileToBase64(file);
      receivingDocuments.push(document);
    }
    return receivingDocuments;
  }

  public getProdOrgIDs(): string {
    const orgId = this.storefrontService.getSelectedStorefront().organizationIds[0];
    const dataAppIds = this.storefrontService.getSelectedStorefront().dataAppIds;
    const omids = dataAppIds
      .filter((x) => x.id == orgId || x.organizationId == orgId)
      .map((x) => x.productOrganizationId);

    const uniqueOmIds = omids.filter((element, i) => i === omids.indexOf(element));
    return uniqueOmIds.join(',');
  }
  public dynamicTranslation(
    localizationKey: string | string[],
    replaceWord: string,
    replaceWith: any,
    params?: any,
    language?: string
  ) {
    return this.translate(localizationKey).replace(replaceWord, replaceWith);
  }

  public getFacilityList(): any[] {
    return [
      {
        id: 1,
        field: 'CVG Hebron, KY',
        address: '1200 WORLDWIDE BLVD',
        city: 'HEBRON',
        state: 'KY',
        zip: '41048',
        country: 'United States',
        node: '157',
        code: 'CVG'
      },
      {
        id: 2,
        field: 'IND Greenwood, IN',
        address: '1415 Collins Rd.',
        city: 'Greenwood',
        state: 'IN',
        zip: '46143',
        country: 'United States',
        node: '160',
        code: 'IND'
      },
      {
        id: 3,
        field: 'ONT Bloomington, CA',
        address: '11260 CEDAR AVE',
        city: 'BLOOMINGTON',
        state: 'CA',
        zip: '92316',
        country: 'United States',
        node: '162',
        code: 'ONT'
      },
      {
        id: 4,
        field: 'RNO Reno, NV',
        address: '38 Isidor Ct.',
        city: 'Sparks',
        state: 'NV',
        zip: '89441',
        country: 'United States',
        node: '159',
        code: 'RNO'
      }
    ];
  }
}
