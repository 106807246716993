import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AngularticsService, StorefrontService } from '@gec-shared-services';
import { AuthService } from '../services/auth-service/auth.service';
import { ApplicationContext } from '../services/application-context/application-context';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  private readonly employeeSSOURL = environment.employeeSSOURL;

  constructor(
    private readonly router: Router,
    private readonly angulartics: AngularticsService,
    private readonly applicationContext: ApplicationContext,
    private readonly authService: AuthService,
    private readonly storefrontService: StorefrontService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPbUser = this.authService.isPbAccount();
    const isInternalAccount = this.authService.isInternalAccount();
    let newHeaders = req.headers;

    const omsIds = this.storefrontService.omsIds;
    newHeaders = newHeaders.append('omsIds', `${omsIds}`);

    if (isPbUser && isInternalAccount) {
      const loggedInUser = this.authService.getCurrentUser();

      if (loggedInUser && !isInternalAccount) {
        newHeaders = newHeaders.append('X-PB-From', `${loggedInUser.email},${loggedInUser.name}`);
      }

      // azure-ad: backend will understand to decode and use this as unique identifer for making engage one calls
      if (loggedInUser && isInternalAccount) {
        newHeaders = newHeaders.append(
          'X-PB-User-ID',
          this.applicationContext.getEmployeeUniqueIdentifier(loggedInUser)
        );
      }
    }
    const authReq = req.clone({ headers: newHeaders, withCredentials: true });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            if (this.isOMScallForPBaccount(req)) {
              error = { status: 400 } as HttpErrorResponse;
              return throwError(error);
            } else {
              this.angulartics.eventTrack('navigation', 'Error 401', 'Error 401');

              // CCP-5969 - redirect to choose accounts page for intenral emp login for 401 error
              if (isInternalAccount) {
                window.location.href = this.employeeSSOURL;
                return;
              } else {
                this.router.navigate(['logout']);
              }
            }

            break;
        }

        return throwError(error);
      })
    );
  }

  private isOMScallForPBaccount(req: HttpRequest<any>): boolean {
    return req.url.indexOf('/oms?globalClientId') !== -1;
  }
}
